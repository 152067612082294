import React, { useEffect } from "react";
import style from "./NotificationScreen.module.css";
import { useSelector } from "react-redux";
import Button from "../UI/Button/Button";

const NotificationScreen = (props: any) => {
  const cartState = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let sellerDomainAfterRedirection = params.get("seller") != null ? params.get("seller") : null;
  
  const continueShopping = () => {
    if(props.isHeadless && (sellerDomainAfterRedirection || loginstate.sellerConfig?.domainName)) {
      window.parent.postMessage(
        {
          trigger: "headless-redirect",
          actions: [
            {
              action: "redirectTo",
              data: {
                url: loginstate.sellerConfig?.domainName ? `https://${loginstate.sellerConfig.domainName}` : `https://${sellerDomainAfterRedirection}`,
              },
            },
          ],
        },
        "*"
      );
    } else {
      window.parent.postMessage({ trigger: "headless-close" }, "*");
    }
  };

    useEffect(() => {
      cartState.verifyingPayment = false
      window.scrollTo(0, 0);
    },[])

    

  return (
    <>
      {!cartState?.cardDesignEnabled  ?
        <div className={style.wholeContainer}>
          <div className={style.orderSummaryContainer}>
            <img 
              src={process.env.PUBLIC_URL + `./assets/images/${props?.image}.svg`} 
              style={{marginBottom: '32px', width: '140px'}} 
              alt = "error"
              />
              <div className={style.subheading1}>{props?.heading}</div>
            <div className={style.container1} style={{display:'flex', justifyContent:'center'}} >
                <p style={{width: '50%'}}>
                    {props?.subheading}
                </p>
            </div>
            { !props.showButton &&
            <div style={{width: '60%', margin: 'auto'}}>
            {
              props.isHeadless ? 
              (
                loginstate.sellerConfig.domainName && (
                  <button
                  className="btn white full-width primary mb-10"
                  onClick={continueShopping}
                >
                Back To Home
                </button>
                )
              ) :
              (
              <button
                className="btn white full-width primary mb-10"
                onClick={continueShopping}
              >
                {props.ctaText ? props.ctaText : "Continue Shopping"}
              </button>
              )
            }
            </div>
          }
          </div>        
          
        </div>
        :
        <div className={style.wholeContainerCard}>
          <div className='card-container' style={{display :'flex', alignItems : 'center',height : '-webkit-fill-available',justifyContent : 'center',width : '100%',gap : '8px'}}>
            <div className={style.subheading1Card}>{props?.heading}</div>
            <img 
              src={process.env.PUBLIC_URL + `./assets/images/${props?.image}Card.svg`} 
              alt = "error"
              />
            <div className={style.container1Card} style={{display:'flex', justifyContent:'center'}} >
                <p style={{width: '90%'}}>
                    {props?.subheading}
                </p>
            </div>
            { !props.showButton &&
            <div style={{width: '60%', margin: '12px auto'}}>
            {
              props.isHeadless ? 
              (
                loginstate.sellerConfig.domainName && (
                  <Button
                  onClick={continueShopping}
                  isCardDesign={true}
                  >
                  Back To Home
                  </Button>
                  )
              ) :
              (
              <Button
                onClick={continueShopping}
                isCardDesign={true}
              >
                {props.ctaText ? props.ctaText : "Continue Shopping"}
              </Button>
              )
            }
            </div>
            }
          </div>        
          
        </div>
      }
    </>
  );
};

export default NotificationScreen;
