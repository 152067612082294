import axios from "axios";
import apiUrls from "../../api/Apis";
import srapi from "../../api/srapi";
import Http from "../../service/Http";
import HttpService from "../../service/HttpService";
import * as Sentry from "@sentry/react";

export const fetchShipping = (payload) => async (dispatch) => {
    dispatch({type: 'FETCH_Shipping_REQUEST'})
      const response = await HttpService.getWithParams(apiUrls.GET_SHIPPING(payload.channel_code,payload.channel_id),payload.cart_id)
      .then(response =>{
        response.data.data.sort(
      function(a, b) { 
       if(a.price === b.price){
           return a.edddate > b.edddate ? 1 : -1
       }         
      return a.price > b.price ? 1 : -1;
    });
    let updateShippingPayload = {
      cart_id: payload.cart_id.cart_id,
      shipping_id: response.data.data[0].id
    } 
        dispatch({type: 'FETCH_Shipping_SUCCESS',payload: response.data})}
      ).catch(error=>{
        dispatch({type: 'FETCH_Shipping_FAILURE',payload: error})}
      );
  };
  export const checkServicability = (payload, pim_sid, seller_id,savedCodFormAddress) => async (dispatch)=>{
    const UrlQueryStrings = window.location.search;
    let params = new URLSearchParams(UrlQueryStrings);

    let userDeviceId =  params.get("userDeviceId") != null ? params.get("userDeviceId") : null;
    let userSessionId =  params.get("userSessionId") != null ? params.get("userSessionId") : null;

    let headers= {
      ...(userDeviceId && {'X-USER-DEVICE-ID': userDeviceId}),
      ...(userSessionId && {'X-USER-SESSION-ID': userSessionId}),
    }

    if(!savedCodFormAddress){
      dispatch({type: 'FETCH_SERVICE_REQUEST'})
    }
  
   try{
    const response = await Http.postWithHeaders(apiUrls.SERVICABILITY_STATUS(),payload, seller_id, headers)
    if(response.data.status) {
      dispatch({type: 'FETCH_SERVICE_SUCCESS', payload: response?.data?.data,requestPayload : payload,savedCodFormAddress})
    }
   }
   catch(error){
    dispatch({type: 'FETCH_SERVICE_FAILED',  payload: error})
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers, 
          error: error?.toJSON(),
          request_body: payload,
          response_body: JSON.stringify(error?.response?.data)
        })
        Sentry.captureMessage(`Fetch-Service-Failure-${apiUrls.SERVICABILITY_STATUS()}`)
      })
    }
   }
  }
  

