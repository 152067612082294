import GoogleAnalytics from "./GoogleAnalytics";
import FacebookPixel from "./FacebookPixel";
import AppStorage from "../AppStorage";
import Clevertap from "./Clevertap";
import GoogleAdSense from "./GoogleAdSense";
import Wigzo from "./Wigzo";
import GTM from "./GTM";
import WebEngage from "./WebEngage";
import * as Moengage from './Moengage'

const initialiseAnalytics = (
  clientGaId?: any,
  clientFbpId?: any,
  clientWigzoId?: any,
  clientGoogleAdsDetails?: any,
  cartId?: any,
  userAddress?: any,
  initAllEvents?: string,
  firstName?:string,
  lastName?:string,
) => {

  const pixelPayload = {
    ph:userAddress?.phone?.length === 10 ? 
      `91${userAddress?.phone}` : userAddress?.phone, 
    ct: userAddress?.city?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
    country: 'in',
    st: userAddress?.state?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
    zp: userAddress?.pincode,
    fn:firstName,
    ln:lastName
}



if(initAllEvents === 'fireAllEvents') {
  GoogleAnalytics.initialiseGa(clientGaId);
  // FacebookPixel.initialiseFb(clientFbpId, cartId, pixelPayload);
  // Clevertap.initialiseClevertap();
  GoogleAdSense.initialiseGoogleAds(clientGoogleAdsDetails);
    Wigzo.initializeWigzo(clientWigzoId);
    // console.log('===> fire all events first time', pixelPayload)
  }
  else if(initAllEvents === 'onlyPixel'){
    // console.log(userAddress, 'only fire pixel events')  
    console.log(cartId, 'checking cart id')
    FacebookPixel.initialiseFb(clientFbpId, cartId, pixelPayload);
  }

};

const initialiseGTM=(containerId:any )=>{
  GTM.initialiseGTM(containerId);
}

const createUserProfile = (mobileNumber: any) => {
  //Clevertap.createUserProfile(mobileNumber);
  Wigzo.createUserProfile(mobileNumber);
};
// Services related to Google Analytics or Facebook Pixel or Clevertap

const trackCreateOrder = (cartData?: any) => {
  // XXXXXXXXXX

  let properties = {
    currency: cartData?.currency,
    value: cartData?.total_price,
    items: cartData?.line_items?.length,
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("create_order", properties, true);
 // Clevertap.track("create_order", properties);
};

const beginCheckout = (FbCheckoutInitPayload?: any, fbIncludedEvents?: any) => {

  console.log(`cart data `,FbCheckoutInitPayload)
  //GoogleAnalytics.track("begin_checkout", properties, true);
  if(fbIncludedEvents['checkout_initiated']){
  FacebookPixel.trackEvent('InitiateCheckout', FbCheckoutInitPayload)
  }

  //Clevertap.track("begin_checkout", properties);
};

const viewEventsFB = (FbCheckoutInitPayload?: any) => {
  FacebookPixel.trackEvent('PageView')
  FacebookPixel.trackEvent('ViewContent', FbCheckoutInitPayload)
}

const mobileNumberScreenOpened = () => {
  // DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("mobile_number_screen_opened", properties, false);
  //Clevertap.track("mobile_number_screen_opened", properties);
};
const checkoutStarted = (cartData?: any) => {
  //DONE CHECKED|
  const properties = {
    ...cartData?.cart_data,
    cart_token: cartData?.cart_id,
    phone: cartData.mobile,
    buyer_accepts_marketing: true,
    price: cartData?.total_price,
    abandoned_checkout_url : cartData?.domain_name
  };
  Wigzo.track("checkoutstarted", properties);
};

const checkoutInitiated=(
  marketingConfig:any, 
  cartData:any,
  couponCode:any, 
  sso:boolean, 
  newType: boolean,
  fbp: any,
  fbc: any,
  isAbandonCart=false,
  abandonCartUrl:string,
  userData:any,
  )=>{
  GTM.cartInIt(marketingConfig, cartData, couponCode, sso, newType, fbp, fbc);
  isAbandonCart?(marketingConfig?.moengage?.is_active=="false")&&Moengage.abandonCheckout("",cartData,couponCode,abandonCartUrl,userData,marketingConfig?.moengage?.customer_id):(marketingConfig?.moengage?.is_active=="false")&&Moengage.checkoutInitated(cartData,couponCode,userData,marketingConfig?.moengage?.customer_id)
}
const deliverySpeedChanged =(
  cartData:any,
  couponCode:any, 
  sso:boolean, 
  newType: boolean,
  old_delivery_name : any,
  old_delivery_price : any,
  new_delivery_name : any,
  new_delivery_price : any,
  )=>{
  GTM.deliverySpeedChanged(cartData, couponCode, sso, newType,old_delivery_name,old_delivery_price,new_delivery_name,new_delivery_price);
}

const additionalEvents = (
  eventName : any,
  cartData:any,
  couponCode:any, 
  sso:boolean, 
  newType: boolean
  )=>{
  GTM.additionalEvents(eventName,cartData, couponCode, sso, newType);
}
const phoneEntered=(
  phoneNo:any, 
  cartData:any,
  couponCode:any, 
  userTypeChecking: boolean,
  marketingConfig:any,
  )=>{
  GTM.phoneEntered(phoneNo, cartData, couponCode, userTypeChecking);
  (marketingConfig?.moengage?.is_active=="false")&&marketingConfig?.moengage?.events?.phone_number_added&&Moengage.phoneEntered(phoneNo,cartData, couponCode,marketingConfig?.moengage?.customer_id)
}
const loggedIn =(phoneNo:any, cartData:any,couponCode:any,marketingConfig:any)=>{
  GTM.loggedIn(phoneNo, cartData, couponCode);
  (marketingConfig?.moengage?.is_active=="false")&&marketingConfig?.moengage?.events?.phone_number_added&&Moengage.otpVerified(phoneNo,cartData, couponCode,marketingConfig?.moengage?.customer_id)
}
const paymentInIt=(phoneNo:any, cartData:any, addressDetails:any,couponCode:any, paymentType:any, shippingstate: any, orderDetails:any
  ,marketingConfig:any,disableMoengage=false)=>{
  GTM.paymentInIt(phoneNo, cartData, addressDetails, couponCode, paymentType, shippingstate?.selected_service_data?.pickrr_tag, orderDetails);
 if((marketingConfig?.moengage?.is_active=="false")&&!disableMoengage&&marketingConfig?.moengage?.events?.payment_initiated)Moengage.paymentInitiated(phoneNo, cartData, addressDetails, couponCode, paymentType, shippingstate?.selected_service_data?.pickrr_tag, orderDetails,shippingstate,marketingConfig?.moengage?.customer_id)
}

const orderPlaced= (phoneNo:any, cartData:any, addressDetails:any,couponCode:any, orderDetails:any, paymentType: any, shippingstate:any, paymentMode:any, clientOrderId: any, partially:any,transaction_id:any,marketingConfig:any)=>{
  GTM.orderPlaced(phoneNo, cartData, addressDetails,couponCode, orderDetails, paymentType, shippingstate?.selected_service_data?.pickrr_tag, paymentMode, clientOrderId, partially);
  (marketingConfig?.moengage?.is_active=="false")&&marketingConfig?.moengage?.events?.order_placed&&Moengage.orderPlaced(phoneNo, cartData, addressDetails,couponCode, orderDetails, paymentType, shippingstate?.selected_service_data?.pickrr_tag, paymentMode, clientOrderId, partially,transaction_id,shippingstate,marketingConfig?.moengage?.customer_id);
}

const orderScreen= (phoneNo:any, cartData:any, addressDetails:any,couponCode:any, orderDetails:any, paymentType: any, shippingstate:any, paymentMode:any, clientOrderId: any, partially:any,marketingConfig:any)=>{
  (marketingConfig?.moengage?.is_active=="false")&&marketingConfig?.moengage?.events?.order_screen&&Moengage.landedOnOrderScreen(phoneNo, cartData, addressDetails,couponCode, orderDetails, paymentType, shippingstate, paymentMode, clientOrderId, partially,marketingConfig?.moengage?.customer_id);
}
const paymentSuccess= (phoneNo:any, cartData:any, addressDetails:any,couponCode:any, orderDetails:any, pickrrTag:any, paymentMethod:any)=>{
  GTM.paymentSuccess(phoneNo, cartData, addressDetails, couponCode, orderDetails, pickrrTag, paymentMethod);
}

const orderPlacedBilldesk= (marketingConfig: any,phoneNo:any, cartData:any, addressDetails:any,couponCode:any, orderDetails:any, paymentType: any, pickrrTag:any, paymentMode:any, clientOrderId: any)=>{
  GTM.orderPlacedBilldesk(marketingConfig, phoneNo, cartData, addressDetails,couponCode, orderDetails, paymentType, pickrrTag, paymentMode, clientOrderId);
}

const paymentSuccessBilldesk= (marketingConfig: any,phoneNo:any, cartData:any, addressDetails:any,couponCode:any, orderDetails:any, pickrrTag:any, paymentMethod:any)=>{
  GTM.paymentSuccessBilldesk(marketingConfig, phoneNo, cartData, addressDetails, couponCode, orderDetails, pickrrTag, paymentMethod);
}


const pincodeFieldDisplayed = () => {
  //DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("pincode_field_displayed", properties, false);
  //Clevertap.track("pincode_field_displayed", properties);
};
const pincodeAdded = () => {
  //DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("pincode_added", properties, false);
 // Clevertap.track("pincode_added", properties);
};
const addressFieldsDisplayed = () => {
  //DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("address_fields_displayed", properties, false);
//  Clevertap.track("address_fields_displayed", properties);
};
const addressAdded = (phoneNo:any, cartData:any, addressDetails:any, couponCode:any) => {
  //DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  GTM.addressAdded(phoneNo, cartData, addressDetails,couponCode);

 // GoogleAnalytics.track("address_added", properties, false);
 // Clevertap.track("address_added", properties);
};
const addShippingInfo = (cartData?: any) => {
  //DONE CHECKEDXXXX
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
    event_category: "ecommerce",
    currency: cartData?.currency,
    value: cartData?.totalPrice,
  };

 // GoogleAnalytics.track("add_shipping_info", properties, true);
 // Clevertap.track("add_shipping_info", properties);
};
const addDiscountCodeClicked = () => {
  //DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("add_discount_code_clicked", properties, false);
 // Clevertap.track("add_discount_code_clicked", properties);
};
const discountCodeApplied = () => {
  //DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("discount_code_applied", properties, false);
 // Clevertap.track("discount_code_applied", properties);
};
const discountCodeRemoved = () => {
  // XXXXXXXXX
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("discount_code_removed", properties, false);
 // Clevertap.track("discount_code_removed", properties);
};
const otpRequested = () => {
  // DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("otp_requested", properties, false);
 // Clevertap.track("otp_requested", properties);
};
const addOtpScreenOpened = () => {
  // DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("add_otp_screen_opened", properties, false);
//  Clevertap.track("add_otp_screen_opened", properties);
};
const otpAdded = () => {
  // DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("otp_added", properties, false);
 // Clevertap.track("otp_added", properties);
};
const changeAddressClicked = () => {
  //DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("change_address_clicked", properties, false);
  //Clevertap.track("change_address_clicked", properties);
};
const savedAddressesDrawerOpened = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("saved_addresses_drawer_opened", properties, false);
  //Clevertap.track("saved_addresses_drawer_opened", properties);
};
const shippingMethodDrawerOpens = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("shipping_method_drawer_opens", properties, false);
 // Clevertap.track("shipping_method_drawer_opens", properties);
};
const shippingOptionSelected = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("shipping_option_selected", properties, false);
 // Clevertap.track("shipping_option_selected", properties);
};
const addNewAddressClicked = (
  cartData?: any,
  couponCode?: any,
  totalAddress?: any
) => {

  //DONE CHECKED|
  let properties = {
    currency: cartData?.currency,
    value: cartData?.totalPrice,
    items: cartData?.lineItems?.length,
    address_type: "saved",
    AddressCount: totalAddress,
    coupon: couponCode,
    userType: AppStorage.getItem("user_type") || "new",
  };
console.log("here",properties)
//  GoogleAnalytics.track("add_new_address_clicked", properties, false);
//  Clevertap.track("add_new_address_clicked", properties);
};
const savedAddressChanged = (
  cartData?: any,
  couponCode?: any,
  totalAddress?: any
) => {
  // DONE CHECKED|
  let properties = {
    currency: cartData?.currency,
    value: cartData?.total_price,
    items: cartData?.line_items?.length,
    address_type: "saved",
    AddressCount: totalAddress,
    coupon: couponCode,
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("saved_address_changed", properties, false);
//  Clevertap.track("saved_address_changed", properties);
};
const newAddressDrawerOpened = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("new_address_drawer_opened", properties, false);
  //Clevertap.track("new_address_drawer_opened", properties);
};
const newAddressSelected = (cartData?: any, couponCode?: any) => {
  //   XXXXXXXX AA
  let properties = {
    currency: cartData?.currency,
    value: cartData?.total_price,
    address_type: "new",
    items: cartData?.line_items?.length,
    // coupon: cartItems['coupon_code'] !=undefined ? cartItems['coupon_code'][0]: '',
    coupon: couponCode,
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("new_address_selected", properties, false);
//  Clevertap.track("new_address_selected", properties);
};
const upiOptionSelected = () => {
  //    DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };
  /// payment upi qr code selected

 // GoogleAnalytics.track("upi_option_selected", properties, false);
 // Clevertap.track("upi_option_selected", properties);
};
const changeShippingMethodClicked = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("change_shipping_method_clicked", properties, false);
 // Clevertap.track("change_shipping_method_clicked", properties);
};
const paymentScreenOpened = (phoneNo:any, cartData:any, addressDetails:any, couponCode: any, pickrrTag:any, orderDetails:any) => {
  //    DONE CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };
  GTM.paymentScreenOpened(phoneNo,cartData,addressDetails, couponCode,pickrrTag, orderDetails);
  // PAYMENT SCREEN OPENED EVENT
  //GoogleAnalytics.track("payment_screen_opened", properties, false);
 // Clevertap.track("payment_screen_opened", properties);
};
const changePaymentMethodClicked = () => {
  //DONE  CHECKED|
  let properties = {
    // "COD_display": paymentResponse['data']['COD']?.is_enabled == 1 ? 'true': 'false',
    // "RTO_predicted": paymentResponse['data']['rto_prediction']['probability'],
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("change_payment_method_clicked", properties, false);
  //Clevertap.track("change_payment_method_clicked", properties);
};
const paymentModesDrawerOpens = () => {
  //DONE  CHECKED|
  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("payment_modes_drawer_opened", properties, false);
 // Clevertap.track("payment_modes_drawer_opened", properties);
};

const orderSummaryExpanded = () => {
  //DONE  CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("order_summary_expanded", properties, false);
//  Clevertap.track("order_summary_expanded", properties);
};
const orderSummaryCollapsed = () => {
  //DONE  CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("order_summary_collapsed", properties, false);
 // Clevertap.track("order_summary_collapsed", properties);
};
const codButtonDisplayed = () => {
  // DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };
 // GoogleAnalytics.track("cod_button_displayed", properties, false);
 // Clevertap.track("cod_button_displayed", properties);
};
const addPaymentInfo = (FbAddPaymentInfoPayload?: any,fbIncludedEvents?:any) => {
  // DONE

  // let propertiesForGA = {
  //   userType: AppStorage.getItem("user_type") || "new",
  //   event_category: "ecommerce",
  //   currency: cartData?.currency,
  //   value: cartData?.totalPrice,
  // };
  // let propertiesForFacebookPixel = {
  //   currency: cartData?.currency,
  //   value: cartData?.totalPrice,
  // };

  //GoogleAnalytics.track("add_payment_info", propertiesForGA, true);
  if(fbIncludedEvents?.['payment_initiated'])
  FacebookPixel.trackEvent("AddPaymentInfo", FbAddPaymentInfoPayload);
 // Clevertap.track("add_payment_info", propertiesForGA);
};

const purchase = (
  FbPurchasePayload?: any, 
  fbIncludedEvents?: any, 
  ) => {

  if(fbIncludedEvents?.['order_placed']) {
    console.log("FIRING", FbPurchasePayload)
    FacebookPixel.trackEvent("Purchase", FbPurchasePayload);
  }


  // DONE for upi and qrcode
  // let propertiesForGA = {
  //   event_category: "ecommerce",
  //   userType: AppStorage.getItem("user_type") || "new",
  //   currency: cartData?.currency,
  //   value: cartData?.final_total,
  //   transaction_id:
  //     cartData?.transaction_id != 0
  //       ? cartData?.transaction_id
  //       : cartData?.order_id + "_cod",
  //   shipping: cartData.shipping_price || 0,
  // };


  // let propertiesForFacebookPixel = {
  //   currency: "INR",
  //   //items: cartData?.line_items?.length,
  //   value: cartData?.final_total,
  // };

  //GoogleAnalytics.track("purchase", propertiesForGA, true);
  // console.log('yes 7 rings' ,fbIncludedEvents)
  // Clevertap.track("purchase", propertiesForGA);

};

// not being called from anywhere at this juncture--prashant
const purchaseConversion = (cartData?: any, userData?: any) => {
  GoogleAdSense.trackPurchase(userData, cartData);
};

const orderSuccessScreenOpened = () => {
  // DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("order_success_screen_opened", properties, true);
 // Clevertap.track("order_success_screen_opened", properties);
};
const exitCheckout = (cartData?: any) => {
  //DONE CHECKED|

  let properties = {
    currency: cartData?.currency,
    value: cartData?.totalPrice,
    items: cartData?.lineItems?.length,
    address_type: "saved",
    userType: AppStorage.getItem("user_type") || "new",
  };

  //GoogleAnalytics.track("exit_checkout", properties);
 // Clevertap.track("exit_checkout", properties);
};
const changeNumber = () => {
  //DONE CHECKED|

  let properties = {
    userType: AppStorage.getItem("user_type") || "new",
  };

 // GoogleAnalytics.track("change_number", properties);
 // Clevertap.track("change_number", properties);
};

const buy = (productIdArr:[]) => {
  Wigzo.buy(productIdArr);
}


const checkoutStartedWebengage = (data : any) => {
  WebEngage.checkoutStartedWebengage(data);
}

const loginWebengage = (data : any) => {
  WebEngage.loginWebengage(data);
}

const phoneWebengage = (data : any) => {
  WebEngage.phoneWebengage(data);
}
const firstNameWebengage = (data : any) => {
  WebEngage.firstNameWebengage(data);
}

const lastNameWebengage = (data : any) => {
  WebEngage.lastNameWebengage(data);
}

const emailWebengage = (data : any) => {
  WebEngage.emailWebengage(data);
}
const checkoutUpdatedWebengage = (data : any) => {
  WebEngage.checkoutUpdatedWebengage(data);
}

// const savedAddress = () => {};
// const newAddress = () => {};
// const trackLogin = () => {
// let eventProperties:string = ''
//   GoogleAnalytics.track("createOrder", eventproperties, true);
// };
var AnalyticService: any = {
  initialiseAnalytics,
  createUserProfile,
  trackCreateOrder,
  beginCheckout,
  mobileNumberScreenOpened,
  checkoutStarted,
  pincodeFieldDisplayed,
  pincodeAdded,
  addressFieldsDisplayed,
  addressAdded,
  addShippingInfo,
  addDiscountCodeClicked,
  discountCodeApplied,
  discountCodeRemoved,
  otpRequested,
  addOtpScreenOpened,
  otpAdded,
  changeAddressClicked,
  savedAddressesDrawerOpened,
  changeShippingMethodClicked,
  shippingMethodDrawerOpens,
  shippingOptionSelected,
  addNewAddressClicked,
  savedAddressChanged,
  newAddressDrawerOpened,
  newAddressSelected,
  upiOptionSelected,
  paymentScreenOpened,
  changePaymentMethodClicked,
  paymentModesDrawerOpens,
  orderSummaryExpanded,
  orderSummaryCollapsed,
  codButtonDisplayed,
  addPaymentInfo,
  purchase,
  purchaseConversion,
  orderSuccessScreenOpened,
  exitCheckout,
  changeNumber,
  buy,
  checkoutInitiated,
  phoneEntered,
  loggedIn,
  paymentInIt,
  orderPlaced,
  initialiseGTM,
  paymentSuccess,
  orderPlacedBilldesk,
  paymentSuccessBilldesk,
  deliverySpeedChanged,
  additionalEvents,
  checkoutStartedWebengage,
  loginWebengage,
  phoneWebengage,
  firstNameWebengage,
  lastNameWebengage,
  emailWebengage,
  checkoutUpdatedWebengage,
  viewEventsFB,
  orderScreen
  // savedAddress,
  // newAddress,
  // trackLogin,
};
try {
  let sS = typeof sessionStorage;
  let lS = typeof localStorage;
  if (sS == lS) {
    console.log("Analytics enabled");
  }
} catch (e) {
  for (let k in AnalyticService) {
    AnalyticService[k] = () => {};
  }
  console.log("Analytics disabled");
}

export default AnalyticService;
