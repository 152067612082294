import style from "./DeleteModal.module.css";
import clsx from "clsx";
import Loader from "../Utils/Loader";
import { useSelector } from "react-redux";

export default function DeleteModal(props: any) {

  const cartstate = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  return (
    <>
      <div className={style.backDrop} />
      <div
        className={clsx(!cartstate?.cardDesignEnabled ? style.modal : style.modalCard)}
      >
        {!cartstate?.cardDesignEnabled ?
        <div className={style.body}>
          <img 
            src="https://d10srchmli830n.cloudfront.net/1674393793181_fc5da3ed-42a1-4504-8ae1-7b5dc42a4021_Group-27717Delete.svg"
             alt="delete-icon" 
          />
          <h4 className={style.title}>Delete address</h4>
          <h5 className={style.subtitle}>Are you sure, you want to delete your saved address?</h5>
          <div className={style.actionWrapper}>
              <button
                className={style.deleteButton}
                onClick={() => {
                  props.setDeleteVisible(false)
                  loginstate.isAnyModalOpen = false
                  props.setDeleteID()
                }}
              >
                No
              </button>
              <button 
                className={style.confirmButton}
                onClick={() => props.deleteAddress()}
              >
                {props.deletingAddress ? <Loader smallLoader="small" noMarginTop="-3px" margin="0 auto"/> : "Yes"}
              </button>
          </div>
        </div>
        :
        <div className={style.body}>
          <img 
            src={process.env.PUBLIC_URL +"./assets/images/deleteAddress.gif"}
             alt="delete-icon" 
             style={{position : 'absolute', top : '-52px',height : '97px', width : '97px'}}
          />
          <h4 style={{marginTop : '30px'}} className={style.titleCard}>Delete address</h4>
          <h5 className={style.subtitleCrad}>Are you sure, you want to delete your saved address?</h5>
          <div className={style.actionWrapperCard}>
              <button 
                className={style.confirmButtonCard}
                onClick={() => props.deleteAddress()}
              >
                {props.deletingAddress ? <Loader smallLoader="small" noMarginTop="-3px" margin="0 auto"/> : "Yes"}
              </button>
              <button
                className={style.deleteButtonCard}
                onClick={() => {
                  props.setDeleteVisible(false)
                  loginstate.isAnyModalOpen = false
                  props.setDeleteID()
                }}
              >
                No
              </button>
          </div>
        </div>
        }
      </div>
    </>
  );
}
