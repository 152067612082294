import Modal from "../../components/Modal/Modal";
import AnalyticService from "../../service/analytics/AnalyticsService";
import Loader from "../../components/Utils/Loader";
import { Store } from "../../service/StorageHelper";
import ExitForm  from "../containers/ExitFormContainer";
import { memo } from "react";


const Header = (
  {setActiveButton,activeButton,changeNumber,showLoader,cartstate,couponState,loginstate,addressstate,isHeadless,otpstate, fetchingDetails, applicationType,SetIsOpen,isOpen,backgroundColor,openModalHandler ,shopName,exitCheckoutHandler,tryAnotherPaymentMethod,showExitForm,setShowExitForm} : 
  {setActiveButton:any,activeButton:any,changeNumber:any,showLoader:any,cartstate:any,couponState:any,loginstate:any,addressstate:any,isHeadless: any, otpstate:any,fetchingDetails?: any, applicationType?:any,SetIsOpen:any,isOpen:any,backgroundColor:any,openModalHandler:any,shopName:any,exitCheckoutHandler:any,tryAnotherPaymentMethod:any , showExitForm:any,setShowExitForm:any }) => {
 
return (
    <header 
      className="align-v-center container d-flex flex-center"
      style={{paddingBottom: loginstate.sellerConfig.domainName === "www.soni.fashion" ? 0 : '10px'}}
    >
      <Modal
        open={isOpen}
        title={showExitForm ? "Sorry to see you go.." : (loginstate.user_type_for_text || loginstate.onPage !== "shipping") ? "You want to?": "Are you sure you want to exit?"}
        onClose={() => {
          SetIsOpen(false);
          setShowExitForm(false)
          setActiveButton(false)
        
          cartstate.fireSetuRequest = true;
          if(loginstate.onPage === "shipping" && !loginstate.user_type_for_text){
            AnalyticService.additionalEvents(
              "exit-drawer-closed",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }
          else{
            AnalyticService.additionalEvents(
              "back-drawer-closed",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }
        }}
        showBanner={true}
        showClose={true}
        showAnotherMobile={true}
        size={showExitForm ? "mid" : "sm"}
      > 
        {!showExitForm ?
        <>
        {((!loginstate.onLoginPage && !cartstate.orderPending && !cartstate.outOfStock) || addressstate.Address_data?.length  === 0 || !addressstate?.defaultAddressCorrect || loginstate.otpSkip) ? (
            <button
              disabled={showLoader}
              className={`modal_button full-width primary relative ${!activeButton ? 'modal_button_bg' : ''}`}
              onClick={changeNumber}
            >
              Change number
              {showLoader && (
                <div className="loading">
                  {" "}
                  <Loader />
                </div>
              )}
            </button>
          ) : null}
          { (!isHeadless && applicationType !== "appmaker") &&
          <button
            className="modal_button full-width text-center"
            onClick={exitCheckoutHandler}
            onMouseOver = {() => setActiveButton(true)}
            onMouseLeave = {() => setActiveButton(false)}
            style={{marginTop: (loginstate.onPage !== "shipping" || loginstate.user_type_for_text) ? "20px" : ""}}
          >
          {(loginstate.onPage === "shipping" && !loginstate.user_type_for_text) ? "Yes, exit checkout" : "Exit checkout"}
          </button>
          }
          { loginstate.onPage === "shipping" && !loginstate.user_type_for_text &&
          <button
            className={`modal_button full-width relative mt-20 ${!activeButton ? 'modal_button_bg' : ''}`}
            onClick={() => {
              SetIsOpen(false)
              AnalyticService.additionalEvents(
                "no-continue-checkout",
                cartstate?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
            }}
            onMouseOver = {() => setActiveButton(true)}
            onMouseLeave = {() => setActiveButton(false)}
          >
          No, continue checkout
          </button>
          }
        </>
        :<ExitForm
        loginstate={loginstate}
        cartstate={cartstate}
        couponState={couponState}
        isCardDesign={false}   
        />
        }    
      </Modal>
      {
        ((cartstate.orderPending && cartstate.activePaymentMode !== "UPI") || (isHeadless && !otpstate.verified) || (cartstate.orderPending && isHeadless) || cartstate.resumeCartFailed || cartstate.outofstock === true || fetchingDetails || (cartstate.isPaymentSuccess && (cartstate.verifyingPayment || cartstate.paymentProcessing))) ? (
          <></>
        ) :
        loginstate.onPage === "shipping" && !loginstate.user_type_for_text ? 
        <>
          {applicationType !== "appmaker" &&(
              <div className="back" style={{color:backgroundColor && 'white'}} onClick={()=>{
                openModalHandler()
                AnalyticService.additionalEvents(
                  "close-button-clicked",
                  cartstate?.Cart_data,
                  couponState.couponCode, 
                  Store.getItem('refreshToken') ? true : false,
                  false
                );
              }}>
              <span className="cross-icon">&#x2715;</span>
            </div>
            )
          }
        
        </> :
        (
          !cartstate.isPaymentSuccess && (cartstate.verifyingPayment || cartstate.paymentProcessing) ?
          <div className="back" style={{
            backgroundColor: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "#333333": 'white', 
            border:'none',
            borderRadius: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "6px": '', 
            padding: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "5px 7px": '', 
          }} 
          onClick={() => {
              tryAnotherPaymentMethod()
          }}
          >
            <img
              src={process.env.PUBLIC_URL + "./assets/images/grey-arrow.svg"}
              alt="arrow"
              style={{transform: "scaleX(-1)"}}
            />
            <h5 style={{fontWeight:'400', marginLeft:'5px', color:'#aeaeae'}}>Back</h5>
          </div> :
          <div className="back" style={{
            backgroundColor: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "#333333": 'white', 
            border:'none',
            borderRadius: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "6px": '', 
            padding: loginstate?.sellerConfig?.domainName === "www.soni.fashion" ? "5px 7px": '', 
          }} 
          onClick={() => {
              openModalHandler()
              AnalyticService.additionalEvents(
                "back-button-clicked",
                cartstate?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
          }}
          >
            <img
              src={process.env.PUBLIC_URL + "./assets/images/grey-arrow.svg"}
              alt="arrow"
              style={{transform: "scaleX(-1)"}}
            />
            <h5 style={{fontWeight:'400', marginLeft:'5px', color: backgroundColor ? 'white' :'#717171', fontSize:'14px', marginTop:'2px'}}>Back</h5>
          </div>
        )
      }
      {
        loginstate?.sellerConfig?.logUrl ?
        <img 
          className="logo" src={loginstate?.sellerConfig?.logUrl} alt={shopName} 
          style={{
              width: loginstate.sellerConfig.domainName === "www.soni.fashion" ? '60%' : 'auto',
              maxHeight: loginstate.sellerConfig.domainName === "www.soni.fashion" ? '100%' : '30px',
              minHeight: loginstate.sellerConfig.domainName === "www.soni.fashion" ? '100%' : '30px',
            }}
          /> :
        <></>
      }
    </header>
  );
};
export default memo(Header);
