import { combineReducers } from "redux";
import Address_reducer from "./Address/add_reducer";
import Delivery_reducer from "./Delivery/del_reducer";
import Login_reducer from "./Login/Login_reducer";
import OrderSummary_reducer from "./Order Summary/OS_reducer";
import Payment_reducer from "./Payment/Pay_reducer";
import Shipping_reducer from "./Shipping/Ship_reducer";
import Otp_reducer from "./Otp/Otp_reducer";
import Upi_reducer from "./Upi/Upi_reducer";
import Cart_reducer from "./Cart/Cart_reducer";
import Coupon_reducer from "./Coupon/Coupon_reducer";

const rootReducer = combineReducers({
  Shipping: Shipping_reducer,
  Address: Address_reducer,
  Payment: Payment_reducer,
  OrderSummary: OrderSummary_reducer,
  Delivery: Delivery_reducer,
  Login: Login_reducer,
  Otp: Otp_reducer,
  Upi: Upi_reducer,
  Cart: Cart_reducer,
  Coupon: Coupon_reducer,
});

export default rootReducer;
