import React, { useState, useEffect, useMemo } from "react";
import styles from "./EditAddressNew.module.css";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addExistingUserAddress,
  addNewUserAddress,
  updateAddress,
} from "../../Redux/Address/add_actions";
import ErrorHandler from "../Error/ErrorHandler";
import AnalyticService from "../../service/analytics/AnalyticsService";
import Loader from "../Utils/Loader";
import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";
import { Store } from "../../service/StorageHelper";
import { channels } from "../../utils/constants";
import Select from "../UI/Select/Select";
import {errMssgs} from '../../utils/addressUtil';
import { CITY_REGEX, CITY_REGEX_ERROR_MESSAGE } from "../../utils/constants";


const EditAddressNew = (props: any) => {

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  
  const [pincodeTouched, setPincodeTouched] = useState(false);
  const [pincodeHasError, setPincodeHasError] = useState(false);
  const [state, updateState] = useState(0);
  const forceUpdate = () => updateState((prevState) => prevState ^ 1);
  const deliverystate = useSelector((state: any) => state.Delivery);
  const couponState = useSelector((state: any) => state.Coupon);
  const otpstate = useSelector((state:any )=> state.Otp);
  const addressstate = useSelector((state: any) => state.Address);
  const cartstate = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const [pincodeFieldDisplayed, setPincodeFieldDisplayed] = useState(false);
  const [wrongPincode, setWrongPincode] = useState(false)
  const [pincode, setPincode] = useState(props?.editAddressData?.pincode ?? "")
  const [pincodeDataFetched, setPincodeDataFetched] = useState(false)
  const [pincodeLoader, setPincodeLoader] = useState(false)
  const [valid, setValid] = useState(true)
  const [skipErrorField, setSkipErrorField] = useState<any>([])
  const [phoneNumber, setPhoneNumber] = useState<any>(loginstate?.sellerConfig?.enableAdrrPhone ? props?.editAddressData?.alternatePhone ? props?.editAddressData?.alternatePhone : props?.editAddressData?.phone : props?.editAddressData?.phone)
  const [showOtherTag, setShowOtherTag] = useState<boolean>(props?.editAddressData?.tags === "others" ? true : false);
  const [selectedTag, setSelectedTag] = useState<string>(props?.editAddressData?.tags ?? 'home');
 
  let tagsValues;
  if(props?.editAddressData?.tags && props?.editAddressData?.tags?.includes('other')){
    tagsValues =  props?.editAddressData?.tags?.split(':')?.[1]?.trim();
  }else {
    tagsValues = "";
  }
  
 
  const [values, setValues] = useState({
    city: props?.editAddressData?.city ?? "",
    state: props?.editAddressData?.state ?? "",
    name: props?.editAddressData?.fullName ?? "",
    gender :props?.editAddressData?.gender ?? "",
    email: loginstate?.sellerConfig?.removeEmailFromCodForm ? '' : props?.editAddressData?.email ? props?.editAddressData?.email : "",
    line1: props?.editAddressData?.fullAddressLine ?? "",
    completeAdd: "",
    altPhone : props?.editAddressData?.alternatePhone ?? "", 
    tags: tagsValues,
  });


  const [errors, setErrors] = useState({
    name: errMssgs.name,
    gender : loginstate?.sellerConfig?.genderInAddressEnabled ? props?.editAddressData?.gender ? '' : errMssgs.gender : '',
    line1: errMssgs.line1,
    email:loginstate?.sellerConfig?.removeEmailFromCodForm ? '' : loginstate.sellerConfig?.showEmail ? errMssgs.email : "",
    city: errMssgs.city,
    state: "",
    phone : loginstate?.sellerConfig?.enableAdrrPhone ? (props?.editAddressData?.alternatePhone || props?.editAddressData?.phone) ? '' : 'Enter your phone number' : '',
    tags: selectedTag === "others" ? errMssgs?.tags : ''
  });
  const [activeErrors, setActiveErrors] = useState({
    name: "",
    gender : '',
    line1: "",
    email: "",
    city: "",
    state: "",
    phone : "",
    tags: ""
  }); 

  const [lenError, setLenError] = useState("")
  const dispatch = useDispatch();

  const setErrorMssg = (key: string, error: string) => {
    setErrors((prevState) => ({ ...prevState, [key]: error }));
  };

  const setActiveErrorsMssg = (key: string, error: string) => {
    setActiveErrors((prevState) => ({ ...prevState, [key]: error }));
  };

  useEffect(() => {
    let tagName:any;
    if(props?.editAddressData?.tags){
      tagName = (props?.editAddressData?.tags === "home" || props?.editAddressData?.tags === "office") ? props?.editAddressData?.tags : "others";
      setShowOtherTag(true)
    }else {
      tagName = "home"
      setShowOtherTag(false)
    }
    setSelectedTag(tagName)
  }, [])


  useEffect(() => {
    if(addressstate.addressUpdated) {
      if(props.SetIsOpen){
        props.SetIsOpen(false)
        }
      AnalyticService.additionalEvents(
        "address-edited",
        cartstate?.Cart_data,
        couponState.couponCode, 
        Store.getItem('refreshToken') ? true : false,
        false
      );
      let existingAddress = addressstate?.Address_data
      let newAddressData = existingAddress?.map((address: any) => {
        if(address?.addressID === addressstate?.updatedAddress.addressID) {
          return addressstate?.updatedAddress
        } else {
          return address
        }
      })
      addressstate.Address_data = newAddressData
      //if(addressstate?.selectedAddress?.addressID === addressstate?.updatedAddress?.addressID) {
        addressstate.selectedAddress = addressstate?.updatedAddress
        shippingstate.serviceFetched = false
        cartstate.orderFetched = false
        addressstate.defaultAddressCorrect = true
        if(props?.verifyAddress){
          props?.onRender()
        }
      //}
    }
  },[addressstate.addressUpdated])

useEffect(() => {
    let noMandatoryErrors = true
    let noActiveErrors = true
    Object.values(errors).forEach((error) => {
      if (error !== "") {
        noMandatoryErrors = false;
      }
    });
    Object.values(activeErrors).forEach((error) => {
      if (error !== "") {
        noActiveErrors = false;
      }
    });
    setValid(noMandatoryErrors && noActiveErrors)
  }, [errors,activeErrors]);

  function getFirstErrorKey(object : any) {
    return Object.keys(object).find(key =>
      object[key] !== "");
  }

  const firstErrorKey = useMemo(()=>{
    let firstErrorKeyName = 'pincode'
    let firstMandatoryError = getFirstErrorKey(errors)
    let firstActiveError = getFirstErrorKey(activeErrors)

    firstErrorKeyName = Object.keys(errors).find(key =>key === firstMandatoryError || key === firstActiveError) ?? 'pincode';
    return firstErrorKeyName
  },[errors,activeErrors])

  const pincodeErrorValue = useMemo(
    () =>
        !pincode ? 
        "Enter your pincode"
        :
        (pincode.length < 6 && pincode.length >= 1)||
        pincode.length > 6 ||
        pincodeHasError
        ? "Pincode must consist of 6 digit"
        : "",
    [pincode, pincodeHasError]
  );

  const activePincodeError = useMemo(
    ()=>
      wrongPincode ? 
        "Please enter a valid pincode"
        : "",
      [wrongPincode]
  )


  useEffect(() => {
    console.log("IaminErrorBlock")
    // error to be shown only if error msg received
    if (addressstate.failMsg !== null) {
      values.line1 = "";
      deliverystate.Pincode_data = {};
      deliverystate.pincode = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
      forceUpdate();
      if (!pincodeFieldDisplayed) {
        AnalyticService.pincodeFieldDisplayed();
        console.log("hi parv")
      }
      setPincodeFieldDisplayed(true);
    }
  }, [addressstate.failMsg]);

  useEffect(() => {
    if (addressstate.invalidEmail !== null) {
      values.email = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
    }
  }, [addressstate.invalidEmail]);

  useEffect(() => {
    if(addressstate?.selectedAddress && cartstate?.orderDetails?.id) {
      if(!loginstate?.otpSkip){
        props.setAddressToShow({
          name: `${addressstate?.selectedAddress.firstName ?? "Customer"} ${addressstate?.selectedAddress.lastName ?? ""}`,
          email: addressstate?.selectedAddress.email,
          address: `${addressstate?.selectedAddress.line1}, ${addressstate?.selectedAddress?.line2 ? (addressstate?.selectedAddress?.line2 + ',') : ""} ${addressstate?.selectedAddress?.landmark ? ("Landmark-" + addressstate?.selectedAddress?.landmark + ',') : ""} ${addressstate?.selectedAddress.city}, ${addressstate?.selectedAddress.state}, ${addressstate?.selectedAddress.pincode}`,
          address_id: addressstate?.selectedAddress.addressID,
          customer_id: loginstate.userInfo.user_id,
          count: "",
          index: "",
          alternatePhone : addressstate.selectedAddress?.alternatePhone, 
          tags: addressstate.selectedAddress?.tags
        });
      }else{
        props.setAddressToShow({
          name: `${addressstate?.selectedAddress.fullName ?? "Customer"}`,
          email: addressstate?.selectedAddress.email,
          address: `${addressstate?.selectedAddress.fullAddressLine}, ${addressstate?.selectedAddress.city}, ${addressstate?.selectedAddress.state}, ${addressstate?.selectedAddress.pincode}`,
          address_id: addressstate?.selectedAddress.addressID,
          customer_id: loginstate.userInfo.user_id,
          count: "",
          index: "",
          alternatePhone : addressstate.selectedAddress?.alternatePhone, 
          tags: addressstate.selectedAddress?.tags
        });
      }
    }
  }, [JSON.stringify(addressstate.selectedAddress)])

  const inputPincode = async (inputPin: string) => {
    setWrongPincode(false)
    addressstate.fail = false;
    setPincode(inputPin.slice(0,6))
    //deliverystate.pincode = inputPin.substring(0, 6);
    forceUpdate();
    let reqpayload = {
      postcode: inputPin.substring(0, 6),
    };
    if (inputPin.length === 6) {
      AnalyticService.pincodeAdded();
      AnalyticService.addressFieldsDisplayed();
      //deliverystate.spinner = true;
      setWrongPincode(false)
      //dispatch(fetchPincode(reqpayload));
      try {
        setPincodeLoader(true)
        const response  = await HttpService.getWithParams(apiUrls.GET_PINCODE(), reqpayload)
        setPincodeLoader(false)
        if(response)  {
          console.log(response)
          setValues({...values, city: response?.data?.postcode_details?.city, state: response?.data?.postcode_details?.state})
          inputValue("city", response?.data?.postcode_details?.city)
          setPincodeDataFetched(true)
          setWrongPincode(false)
        }
      }
       catch(e) {
        console.log(e)
        setWrongPincode(true)
        setPincodeLoader(false)
      }
    }
  };


  const isNumber = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = e.target.value.substring(0,13);
    var inputs = document.querySelectorAll('input[type="number"]');
    for (var i = inputs.length; i--; )
      inputs[i].setAttribute("pattern", "\\d*");
  };

  const mobileInputHandler = async (event: any) => {
    let number = event.target.value;
    setPhoneNumber(number)
    let zeroCheck = number.length > 0 && Number(number[0]) === 0;
    let flag = false;
    flag = zeroCheck ? true : false;

    if(!loginstate?.sellerConfig?.enableAdrrPhone){
      setErrorMssg("phone","")
    }else if(number.length === 0){
      setErrorMssg("phone","Enter your phone number")
    }else{
      setErrorMssg("phone","")
    }

    if(!loginstate?.sellerConfig?.enableAdrrPhone){
      setActiveErrorsMssg("phone","")
    }else if (number.length === 10 && !flag) {
      setActiveErrorsMssg("phone","")
    }else if(number.length === 0){
      setActiveErrorsMssg("phone","")
    }else{
      setActiveErrorsMssg("phone","Please enter valid phone number")
    }
  };

  //  tags handle
  const handleAddressTag = (tag:string) =>{
    if(!(pincode.length < 6 || wrongPincode ||  pincodeLoader)) {
    if(tag === "others"){
      setShowOtherTag(true)
      setErrors((prevState) => ({ ...prevState, tags: errMssgs.tags }));
    }else {
      setShowOtherTag(false)
      setValues((prevState) => ({ ...prevState, tags: '' }));
      setErrors((prevState) => ({ ...prevState, tags: '' }));
      setActiveErrors((prevState) => ({ ...prevState, tags: ''}));
    }
    setSelectedTag(tag)
    }
  }

  const handleInternationalShippingRedirect = () => {
    if(!params.get('cart-resume-id')){
      if (channels.shopify === loginstate?.platform) {
        //for Shopify
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:
                    loginstate.channel?.redirectUrl[loginstate.channel?.redirectUrl?.length - 1] === ","
                      ? loginstate.channel?.redirectUrl?.slice(0, loginstate.channel?.redirectUrl?.length - 1)
                      : loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }

      if (channels.wooCommerce === loginstate?.platform) {
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
    }else{
      if (channels.shopify === cartstate?.platform?.toUpperCase()) {
        let cartData = "";
        for (let item of cartstate?.orderDetails?.lineItems) {  
          cartData = cartData + item.itemId+':'+item.quantity+','
        }

        const redirectUrl  = `http://${loginstate?.sellerConfig?.domainName}/cart/${cartData}`;
        //for Shopify
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:
                  redirectUrl[redirectUrl?.length - 1] === ","
                      ? redirectUrl?.slice(0, redirectUrl?.length - 1)
                      : redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }

      if (channels.wooCommerce === cartstate?.platform?.toUpperCase()) {
        let redirectUrl =`http://${loginstate?.sellerConfig?.domainName}/checkout`;
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
    }
  }

  const addressTagSelectedClass  = 
  (pincode.length < 6 || wrongPincode ||  pincodeLoader) ? "" : 
  `${cartstate?.cardDesignEnabled ? styles.addressTag_selected_card : styles.addressTag_selected}`;

  const renderaddressdetails = () => {
    return (
      <>
        <div>
        {
          !addressstate?.defaultAddressCorrect &&
          (cartstate?.cardDesignEnabled ? <div className={ 'card-main-heading'} style={{paddingBottom:'10px'}}>Add shipping address</div>
          : <h4 style={{paddingBottom:'10px'}}>Add shipping address</h4>
          )
        }
        {loginstate?.sellerConfig?.enableInternationalAddress &&
          <h5 style={{paddingBottom:'10px',font : '500 14px/12px "Metropolis"'}}>Do you like to have your order <a onClick={()=>handleInternationalShippingRedirect()} style={{color : '#3173E3',cursor : 'pointer'}}> shipped outside India?</a></h5>
        }
          <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
            <Input
              label="Pincode"
              type="text"
              id="pincode"
              name="pincode"
              value={pincode}
              className="mt-18"
              onChange={(e: any) => {
                if(e.target.value.match(/^[\d]{0,6}$/g)){
                inputPincode(e.target.value)
                }
              }}
              error={pincodeErrorValue}
              activeErrors={activePincodeError}
              loading={pincodeLoader}
              touched={pincodeTouched}
              autoComplete="postal-code"
              mandatory = {true}
              marginTop={!cartstate?.cardDesignEnabled ? '' : '8px'}
            />
          </div>
          {addressstate.failMsg && (
            <ErrorHandler msg={addressstate.failMsg} />
          )}
          {
            true ? (
            <div>
                <Input
                  label="Full name"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  className="mr-10 mt-18"
                  maxLength="120"
                  onInput={(e: any) => inputValue("name", e.target.value)}
                  error={errors.name}
                  activeErrors = {activeErrors.name}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="given-name"
                  touched = {skipErrorField?.includes("name")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                {loginstate?.sellerConfig?.genderInAddressEnabled &&
                <Select
                id="gender"
                label="Gender"
                placeholder="Gender"
                name="gender"
                className="mt-18"
                value={values.gender}
                onChange={(e: any) => {inputValue("gender", e.target.value)}}
                error={errors.gender}
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                touched = {skipErrorField?.includes("gender")}
                activeErrors = {activeErrors.gender}
                skippedArray={["name","lastName"]}
                setSkipErrorField={setSkipErrorField}
                mandatory={true}
                >
                  <option disabled value = "" hidden>{!cartstate?.cardDesignEnabled ? 'Select your gender' : ''}</option>
                  <option value="male">
                    Male
                  </option>
                  <option value="female">
                   Female
                  </option>
                </Select>
                }
                <Input
                  label="Address"
                  type="text"
                  id="line1"
                  name="address"
                  value={values.line1}
                  onInput={(e: any) => inputValue("line1", `${e.target.value}`)}
                  error={errors.line1}
                  activeErrors = {activeErrors.line1}
                  lenError={lenError}
                  className="mt-18"
                  defaultValue={values.line1}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="address-line1"
                  maxLength="475"
                  skippedArray={["name","gender"]}
                  touched = {skipErrorField?.includes("line1")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                {loginstate?.sellerConfig?.enableAdrrPhone  &&
                  <Input
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="tel"
                    autoComplete="tel-local"
                    // placeholder="10-digit phone number"
                    className="mt-18"
                    onChange={mobileInputHandler}
                    onInput={isNumber}
                    skippedArray={["name","line1","gender"]}
                    touched = {skipErrorField?.includes("phone")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={ true}
                    activeErrors={activeErrors.phone}
                    error = {errors.phone}
                    value={phoneNumber}
                    disabled = {pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  />
                }
                {!loginstate?.sellerConfig?.removeEmailFromCodForm && 
                <>
              <Input
                label={loginstate.sellerConfig?.showEmail ? "E-mail" : "E-mail (optional)"}
                type="text"
                id="email"
                name="email"
                value={values.email}
                onInput={(e: any) => inputValue("email", e.target.value)}
                error={errors.email}
                className="mt-18"
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                autoComplete="email"
                skippedArray={["name","line1","phone","gender"]}
                touched = {skipErrorField?.includes("email")}
                setSkipErrorField={setSkipErrorField}
                mandatory={loginstate.sellerConfig?.showEmail ? true : false}
              />
              {addressstate.invalidEmail && (
                <ErrorHandler msg={addressstate.invalidEmail} />
              )}
              </>
              }
              
              <div className={`${styles.addressDiv}`}>
                <Input
                  label="City"
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                  onInput={(e: any) => inputValue("city", e.target.value)}
                  className="mr-18"
                  maxLength="100"
                  //disabled={true}
                  error={errors.city}
                  activeErrors={activeErrors.city}
                  autoComplete="address-level2"
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  skippedArray={["name","line1","phone","email","gender"]}
                  touched = {skipErrorField?.includes("city")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label="State"
                  type="text"
                  id="state"
                  name="state"
                  value={values.state}
                  disabled={true}
                  skippedArray={["name","line1","phone","email","city","gender"]}
                  touched = {skipErrorField?.includes("state")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                  skipPincodeFocus = {!(pincode.length < 6 || wrongPincode ||  pincodeLoader)}
                />
              </div>
              {/* tags comment */}
              <div className={`${styles.addressDiv}`}>
                <div style={{width: '100%', marginTop: 24}}>
                  <div style={{fontSize:12, marginBottom: 2, fontWeight:500, color:"#2F2F2F"}}>Address type</div>
                  <div className={styles.addressTagContainer}>
                    <div className={`${styles.addressTag} ${selectedTag === "home" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('home')}>Home</div>
                    <div className={`${styles.addressTag} ${selectedTag === "office" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('office')}>Office</div>
                    <div className={`${styles.addressTag} ${selectedTag === "others" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('others')} >Others</div>
                </div>
                {(showOtherTag && selectedTag === "others")
                  ? 
                  <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
                    <Input
                      label="Save address as"
                      type="text"
                      id="tags"
                      name="tags"
                      value={values.tags}
                      className="mr-10 mt-18"
                      onInput={(e: any) => inputValue("tags", e.target.value)}
                      error={errors.tags}
                      maxLength="25"
                      activeErrors = {activeErrors.tags}
                      autoComplete="tags"
                      // touched = {skipErrorField?.includes("addressTag")}
                      // setSkipErrorField={setSkipErrorField}
                      mandatory={selectedTag === "others" && true}
                    />
                  </div> 
                  : ''
                }
              </div>
            </div>


              <div className={!cartstate?.cardDesignEnabled ? styles.mb70 : ''}>
                {addressstate.spinner && <Loader />}
                <Button
                  loading={addressstate.updatingAddress}
                  id="addAddressBtn"
                  // disabled={!valid || pincode.length < 6 || addressstate.updatingAddress}
                  type="button"
                  //onClick={handleAddData}
                  onClick={() => {
                    //props.setEditAddress(false)
                    console.log("TheBat", values)
                    handleUpdateAddress()
                  }}
                  className={clsx("mt-30",{
                    [styles.disabled]: (!valid || pincode.length < 6 || addressstate.updatingAddress || wrongPincode),
                  })}
                  isCardDesign = {cartstate?.cardDesignEnabled}
                >
                  {addressstate.updatingAddress ? "Saving address... " : "Save and select"}
                </Button>
                <input type="hidden" id="editAddressFastrr" />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };


  const checkError = (key: string, inputValue: string) => {
    switch (key) {
      case "name": {
        const re = /^[A-Za-z\s]+$/
        if(inputValue === ""){
          setErrorMssg(key, "Enter your full name.")
        }else{
          setErrorMssg(key, "");
        }
        if(inputValue.length === 0){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key, "Avoid starting with a space")
        } else if(!re.test(inputValue)){
          setActiveErrorsMssg(key, "Use only alphabets (A-Z, a-z)")
        } else if(inputValue.length > 120){
          setActiveErrorsMssg(key, "Name can’t be longer than 120 characters")
        } else {
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "gender": {
        if(inputValue === ""){
          setErrorMssg(key, "Select your gender.")
        }else{
          setErrorMssg(key, "");
        }
        break;
      }
      case "email": {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((loginstate.sellerConfig?.showEmail && (inputValue === "" || !re.test(inputValue))) || (inputValue !== "" && !re.test(inputValue))) {
          setErrorMssg(key, errMssgs.email);
        } else {
          setErrorMssg(key, "");
        }
        break;
      }
      case "line1": {
        const re = /^[ A-Za-z0-9_@./,#&+-/\n]{0,475}$/;
        setLenError(inputValue.trim().length.toString())
        if(inputValue.trim() === ""){
          setErrorMssg(key, errMssgs.line1)
        }else if(inputValue.trim().length < 10){
          setErrorMssg(key,"Minimum 10 characters required")
        }else{
          setErrorMssg(key, "")
        }
        if(inputValue.trim() === ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue.trim().length > 475){
          setActiveErrorsMssg(key,"Address cannot be longer than 475 characters");
        }else if(!re.test(inputValue.trim())){
          setActiveErrorsMssg(key, "Allowed special characters _ @ . / , # & + - ");
        } else {
          setActiveErrorsMssg(key, "");
        }
        break;
      }
      case "city": {
        const re = CITY_REGEX;
        deliverystate.city = inputValue
        if (inputValue == "") {
          setErrorMssg(key,errMssgs.city);
        } else {
          setErrorMssg(key, "");
        }

        if(inputValue == ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key,"Avoid starting with a space")
        }else if(!re.test(inputValue)){
          setActiveErrorsMssg(key,CITY_REGEX_ERROR_MESSAGE)
        }else if(inputValue.length > 100){
          setActiveErrorsMssg(key,"City can’t be longer than 100 characters")
        }else{
          setActiveErrorsMssg(key, "")
        }
        break;
      }
      case "tags": {
        if (inputValue?.trim() == "" && selectedTag === "others") {
          setErrorMssg(key, errMssgs?.tags);
        }else {
          setErrorMssg(key, '');
        }
      
        const trimmedInput = inputValue?.trim();
        if (trimmedInput === "") {
          setActiveErrorsMssg(key, "");
        } else if (inputValue.startsWith(" ")) {
            setActiveErrorsMssg(key, "Avoid starting with a space");
        } else {
            setActiveErrorsMssg(key, "");
        }

        // if(inputValue == ""){
        //   setActiveErrorsMssg(key, "")
        // }else if(inputValue[0] == " "){
        //   setActiveErrorsMssg(key,"Avoid starting with a space")
        // }
        break;
      }
      case "contact": {
        inputValue = inputValue.substring(0, 10);
        if (inputValue === "" || inputValue.length !== 10) {
          setErrorMssg(key, errMssgs.contact);
        } else {
          setErrorMssg(key, "");
        }
      }
    }
  };

  const handleUpdateAddress = async() => {
    if(!(!valid || pincode.length < 6 || addressstate.updatingAddress || wrongPincode)){
      if(!loginstate?.otpSkip){
      dispatch(updateAddress(
        {
          ...props.editAddressData,
          city: values?.city.trim()?.replace(/\./g, " "),
          state: values?.state,
          fullName: values?.name.trim(),
          email: values?.email,
          fullAddressLine:values?.line1.trim(),
          pincode: pincode,
          gender : values.gender ?? '',
          alternatePhone : phoneNumber !== loginstate.mobileNumber ? phoneNumber : null,
          cartId : cartstate?.Cart_data?.id,
          versionId : "v2",
          ...(props?.editAddressData?.addressTag && {addressTag: null}), 
          tags: selectedTag === "others" ? `other: ${values?.tags}` : selectedTag
        },
        loginstate.userInfo.user_id,
        loginstate.sellerConfig.seller_id,
        props?.verifyAddress ? true : false
        ))
      }else{
        await dispatch({
          type : 'SKIP_Otp_Address_add',
          payload : {
          ...props.editAddressData,
          city: values?.city.trim()?.replace(/\./g, " "),
          state: values?.state,
          fullName: values?.name.trim(),
          email: values?.email,
          fullAddressLine:values?.line1.trim(),
          pincode: pincode,
          gender : values.gender ?? '',
          alternatePhone : phoneNumber !== loginstate.mobileNumber ? phoneNumber : null,
          cartId : cartstate?.Cart_data?.id,
          versionId : "v2",
          emailConfirmed : true,
          tags: selectedTag === "others" ? `other: ${values?.tags}` : selectedTag,
          ...(props?.editAddressData?.addressTag && {addressTag: null})
          }
          }
        )
        props.SetIsOpen(false)
        shippingstate.serviceFetched = false
        cartstate.orderFetched = false
        addressstate.defaultAddressCorrect = true
        props?.onRender()
      }
    }else{
      if(pincode.length < 6 || wrongPincode ||  pincodeLoader){
        document.getElementById("pincode")?.focus()
      }else{
        document.getElementById(firstErrorKey)?.focus()
      }
    }
  }


  const inputValue = (key: string, inputValue: string) => {
    checkError(key,inputValue)

    setValues((prevState) => ({ ...prevState, [key]: inputValue }));
  };

  useEffect(() => {
    inputValue("line1", values.line1)
    inputValue("city", values.city)
    inputValue("name", values.name)
    if(!loginstate?.sellerConfig?.removeEmailFromCodForm){
      inputValue("email", values.email)
    }
  }, [])

  return <>{renderaddressdetails()}</>;
};

export default EditAddressNew;
