import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";
import axios from "axios";
import Http from "../../service/Http";
import AppStorage from "../../service/AppStorage";
import { Store } from "../../service/StorageHelper";
import * as Sentry from "@sentry/react";

const setCookie = (c_name, c_value, date) => {
  var exdate = new Date(date);
  exdate.setDate(exdate.getDate());
  document.cookie =
    encodeURIComponent(c_name) +
    '=' +
    encodeURIComponent(c_value) +
    '; expires=' +
    exdate.toUTCString()+';path=/;SameSite=None;Secure';
};

export const isNumberExists = (number) => async (dispatch) => {
  dispatch({ type: "FETCH_Login_REQUEST" });
  const response = await HttpService.post(apiUrls.CUSTOMER_EXIST(), number)
    .then((response) => {
      dispatch({ type: "FETCH_Login_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      document.getElementById("mobile")?.removeAttribute("disabled");
      dispatch({ type: "FETCH_Login_FAILURE", payload: error });
    });
};

export const fetchSessiondetails = () => async (dispatch) => {
  dispatch({ type: "VERIFY_Session_REQUEST" });
  const response = await HttpService.get(apiUrls.VERIFY_SESSION())
    .then((response) => {
      dispatch({ type: "VERIFY_Session_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "VERIFY_Session_FAILURE", payload: error });
    });
};

export const fetchIntegrationdetails =
  (channelCode, channelId) => async (dispatch) => {
    dispatch({ type: "VERIFY_Integration_REQUEST" });
    const response = await HttpService.get(
      apiUrls.GET_INTEGRATION_STATUS(channelCode, channelId)
    )
      .then((response) => {
        dispatch({
          type: "VERIFY_Integration_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_Integration_FAILURE", payload: error });
      });
  };
export const createNewCustomer = (payload) => async (dispatch) => {
  dispatch({ type: "Create_Customer_REQUEST" });
  const response = await HttpService.post(
    apiUrls.CREATE_SILENT_CUSTOMER(),
    payload
  )
    .then((response) => {
      let authTokenPayload = {
        mobile_no: payload.mobile_no,
        customer_id: response.data.data.customer_id,
      };
      dispatch(createAuthToken(authTokenPayload));
      dispatch({ type: "Create_Customer_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "Create_Customer_FAILURE", payload: error });
    });
};

export const createAuthToken = (payload) => async (dispatch) => {
  dispatch({ type: "Auth_Token_REQUEST" });
  const response = await HttpService.post(
    apiUrls.AUTH_TOKEN_SILENT_CUSTOMER(),
    payload
  )
    .then((response) => {
      dispatch({ type: "Auth_Token_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "Auth_Token_FAILURE", payload: error });
    });
};

export const logoutUser = (payload) => (dispatch) => {
      Store.removeItem('refreshToken')
      Store.removeItem('refreshTokenValue')
      //AppStorage.removeItem('mobile')
      Store.removeItem('user_id')
      //AppStorage.removeItem('user_profile_id')
      Store.removeItem('seller_id')
      Store.removeItem('pim_sid')
      Store.removeItem('expireTime')
      //document.cookie = 'pim_sid=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=None;Secure'
      window.location.reload();
};

export const createGuestUser = () => async (dispatch) => {
  dispatch({ type: "Create_Guest_User_REQUESTED" });
  try {
    const response = await axios.get(apiUrls.CREATE_GUEST_USER());
    if(response.data.ok){
      const data = {...response?.data?.result, 'pim_sid': response?.headers['pim-sid'] }
      Store.setItem('pim_sid', response?.headers['pim-sid'])
      Store.setItem('expireTime', response?.headers['expires-at'])
      dispatch({type: 'Create_Guest_User_SUCCESS', payload: data})
    }
  } catch(error){
    dispatch({ type: "Create_Guest_User_FAILURE", payload: error });
    dispatch({ type: "FETCH_Config_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          error: error?.toJSON(),
          response_body: JSON.stringify(error?.response?.data)
        })
        Sentry.captureMessage(`Create-Guest-Failure-${apiUrls.CREATE_GUEST_USER()}`)
      })
    }
  }
}

export const fetchSellerConfigDetails = (pim_sid, uId, seller_id) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_Config_REQUEST"});
    const response = await Http.getWithUid(apiUrls.SELLER_CONFIG(), uId, seller_id);
    if(response) {
      dispatch({ type: "FETCH_Config_SUCCESS", payload: {...response?.data?.data, seller_id: response?.headers?.sid} });
    }
  } catch (error) { 
    if(error?.response?.status || error?.response?.status === 0) {
      dispatch({ type: "FETCH_Config_FAILURE", payload: error });
        Sentry.withScope(scope => {
          scope.setExtras({request_headers: error?.config?.headers})
          Sentry.captureMessage(`Fetch-Config-Failure-${apiUrls.SELLER_CONFIG()}`)
        })
      } else if(error.message === "Network Error"){
        dispatch({ type: "FETCH_Config_Network_Failure", payload: error });
      }
  }
};

export const fetchShopifyCredentials = (payload,seller_id,updateState) => async(dispatch) => {
  try{
    if(updateState){
      dispatch({type:"FETCH_SHOPIFY_CRED_REQUEST"})
    }
    const response = await Http.put(apiUrls.FETCH_SHOPIFY_CREDENTIALS(),payload,seller_id)
    if(response?.status === 200 && updateState){
      dispatch({ type: "FETCH_SHOPIFY_CRED_SUCCESS", payload: response.data });
    }
  } catch (error) { 
    console.log(`error`)
    if(updateState){
      dispatch({ type: "FETCH_SHOPIFY_CRED_FAILURE", payload: error });
    }
  }
}

export const createShopifyCreds = (payload,seller_id) => async(dispatch) => {
  try{
    dispatch({type:"CREATE_SHOPIFY_CRED_REQUEST"})
    const response = await Http.put(apiUrls.FETCH_SHOPIFY_CREDENTIALS(),payload,seller_id)
    if(response?.status === 200){
      dispatch({ type: "CREATE_SHOPIFY_CRED_SUCCESS", payload: response.data });
    }
  } catch (error) { 
    if(error?.response?.status === 422){
      dispatch({ type: "CREATE_SHOPIFY_CRED_EMAIL_ERROR", payload: error  });
    }else{
      dispatch({ type: "CREATE_SHOPIFY_CRED_FAILURE", payload: error  });
    }
  }
}


export const setUserDataAfterOtp = (data,pim_sid) =>(dispatch)=>{
  dispatch({type:"SET_USER_DATA_AFTER_OTP",payload:{...data,pim_sid:pim_sid}, pim_sid:pim_sid})
}

export const doesUserExist = (data, seller_id,updateSkipOtp) => async (dispatch) => {
  dispatch({ type: "FETCH_Login_REQUEST" });
  try {
    const response = await Http.post(apiUrls.LOGIN_CHECK(),data, seller_id)
    if(response.data.status) {
      dispatch({ type: "FETCH_Login_SUCCESS", payload: response.data.data });
      if(response?.data?.data?.user_type === "new" && updateSkipOtp){
        dispatch({ type: "SKIP_OTP_SUCCESS"})
      }
    }
  } catch (error) {
    document.getElementById("mobile")?.removeAttribute("disabled");
    dispatch({ type: "FETCH_Login_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          request_body: data,
          response_body: JSON.stringify(error?.response?.data),
          error:error?.toJSON()
        })
        Sentry.captureMessage(`Login-Failure-${apiUrls.LOGIN_CHECK()}`)
      })
    }
  }
};

export const fetchUserDetails = (seller_id, user_id) => async (dispatch) => {
  dispatch({ type: "FETCH_User_Details_REQUEST" });
  try {
    const response = await Http.get(apiUrls.FETCH_USER_DETAILS(seller_id, user_id), seller_id)
    if(response.data.ok) {
      dispatch({ type: "FETCH_User_Details_SUCCESS", payload: response.data.result.user_data });
    }
  } catch (error) {
    dispatch({ type: "FETCH_User_Details_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          response_body: JSON.stringify(error?.response?.data),
          error:error?.toJSON()
        })
        Sentry.captureMessage(`Fetch-User-Details-Failure-${apiUrls.FETCH_USER_DETAILS(seller_id, user_id)}`)
      })
    }
  }
};


export const verifyToken = (data, seller_id) => async (dispatch) => {
  

  dispatch({ type: "FETCH_VERIFY_TOKEN_REQUEST" });
  try {
    const response = await Http.post(apiUrls.VERIFY_TOKEN(),data)
    if(response.status) {
      dispatch({ type: "FETCH_VERIFY_TOKEN_SUCCESS", payload: response.data.result});
    }
  } catch (error) {
    dispatch({ type: "FETCH_VERIFY_TOKEN_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          request_body: data,
          response_body: JSON.stringify(error?.response?.data),
          error:error?.toJSON()
        })
        Sentry.captureMessage(`VERIFY_TOKEN-FAILURE-${apiUrls.VERIFY_TOKEN()}`)
      })
    }
  }
};


export const customSellerConfig = (token, utm_params_decode) => async (dispatch) => {
  try {
    dispatch({ type: "FETCH_CUSTOM_CHECKOUT_CONFIG_REQUEST"});

    const payload = {
      token, 
      ...(utm_params_decode && {utm_param: {...utm_params_decode}})
    }

    const response = await Http.post(apiUrls.CUSTOM_CHECKOUT_CONFIG(), payload);
    
    if(response) {
      dispatch({ type: "FETCH_CUSTOM_CHECKOUT_CONFIG_SUCCESS", payload: response.data.result});
    }
  } catch (error) { 
    if(error?.response?.status || error?.response?.status === 0) {
      dispatch({ type: "FETCH_CUSTOM_CHECKOUT_CONFIG_FAILURE", payload: error });
        Sentry.withScope(scope => {
          scope.setExtras({request_headers: error?.config?.headers})
          Sentry.captureMessage(`Fetch-Config-Failure-${apiUrls.SELLER_CONFIG()}`)
        })
      } else if(error.message === "Network Error"){
        dispatch({ type: "FETCH_Config_Network_Failure", payload: error });
      }
  }
};


 // new api call
 export const guestAPICall = (payload, uuid) => async (dispatch) => {
  dispatch({type:'COMBINATION_API_REQUEST'})
  try{
    const response = await axios.post(apiUrls.COMBINATION_API(), payload, {
      headers:{
        "uuid": uuid,
        "X-Device-Id": "fastrr",
      }
    })
    const data = {
      data: response.data.data, 
      headers: response?.headers
    }
    if(response) {
      dispatch({ type: "COMBINATION_API_SUCCESS", payload:  data});
    }
  }catch(error){
    dispatch({ type: "COMBINATION_API_FAILURE", payload: error });
    if(error?.response?.status!=402){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers, 
          request_body: payload,
          response_body: JSON.stringify(error?.response?.data),
          error:JSON.stringify(error)
        })
        Sentry.captureMessage(`batch-api-failure-guest-${apiUrls.COMBINATION_API()}`)
      })
    }
  } 
}

// new api call
export const loginAPICall = (payload, sid) => async (dispatch) => {
  dispatch({type:'COMBINATION_API_REQUEST'})
  try{
    const response = await Http.post(apiUrls.COMBINATION_API(), payload, sid)
    const data = {
      data: response.data.data, 
      headers: response?.headers
    }
    if(response) {
      dispatch({ type: "COMBINATION_API_SUCCESS", payload:  data});
    }
  }catch(error){
    if(error?.response){
      dispatch({ type: "COMBINATION_API_FAILURE", payload: error });
      if(error?.response?.status!=402){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers, 
          request_body: payload,
          response_body: JSON.stringify(error?.response?.data),
          error:JSON.stringify(error)
        })
        Sentry.captureMessage(`batch-api-failure-login-${apiUrls.COMBINATION_API()}`)
      })
    }
    }
  } 
}