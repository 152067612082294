import Address from "../../components/Address/Address";
import { updateAddress } from "./add_actions";

var Address_initialstate = {
  loader: 1,
  fail: false,
  sendAddressfired: true,
  Address_data: null,
  selectedAddress: {
    name: "",
    address: "",
    email: "",
  },
  selectedPaymentMode: "",
  selectedUpiApp: "",
  addAddress: false,
  Updated_address_data: {},
  failMsg: null,
  invalidEmail: null,
  Silent_User_Address: {},
  addressAdded: false,
  New_User_Address_Payload: null,
  spinner: false,
  newUserAddressAdded: false,
  fetchingAddress:false,
  addressFetched:false,
  authentication_token_for_otp: null,
  selectedAddress: null,
  addressFetched: false,
  existingAddressAdded: false,
  isAddressSavedCalled: false,
  allAddressFetched: false,
  fetchingAllAddress: false,
  addingNewAddress: 0,
  updatingAddress: false,
  addressUpdated: false,
  updatedAddress: null,
  initialAllAddressFetched: 0,
  addressUpdateFail: false,
  isEmailConfirmed: false,
  newAddressAdded: false,
  cachedAddressCalled: false,
  addressCachedFetched: false,
  emailConfirmed: false,
  networkFail: false,
  addressAdding: false,
  billingAddress : null,
  defaultAddressCorrect : true,
  editAddressCustomCheckout: false,
  customAddressNew:false
};

const Address_reducer = (state = Address_initialstate, action) => {
  switch (action.type) {
    case "FETCH_Address_REQUEST":
      return {
        ...state,
        //sendAddressfired: false,
        loader: 1,
        fetchingAddress:true,
        addressFetched: false
      };

    case "FETCH_Address_SUCCESS":
      return {
        ...state,
        Address_data: action.payload,
        loader: 0,
        fetchingAddress:false,
        addressFetched:true,
        selectedAddress: action?.cachedAddresses ? action?.selectedAddress : action?.payload[0],
        addressCachedFetched: true,
        defaultAddressCorrect: action?.cachedAddresses ? !action?.faultyAddress : true
      };

      case "FETCH_Address_SUCCESS_After_Add_New":
        return {
          ...state,
          Address_data: action.payload.data?.length === 0 ? [action?.payload?.addedAddress] : action.payload.data,
          loader: 0,
          fetchingAddress:false,
          selectedAddress: action.payload.data?.length === 0 ? action?.payload?.addedAddress :  action?.payload?.data?.filter((address) =>  address?.addressID  === action?.payload?.addedAddress?.addressID)?.length > 0 ? action.payload.data.filter((address) =>  address?.addressID  === action?.payload?.addedAddress?.addressID)[0] : action?.payload?.data[0],
          addressFetched: true,
          allAddressFetched: true,
          initialAllAddressFetched: 2
        }; 

    case "FETCH_Address_FAILURE":
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        loader: 0,
        addressFetched: true,
        fetchingAddress:false,
      };
    case "ADD_Address_REQUEST":
      return {
        ...state,
        fail: false,
        loader: 1,
        New_User_Address_Payload: null,
        spinner: true,
      };

    case "ADD_Address_SUCCESS":
      return {
        ...state,
        loader: 0,
        New_User_Address_Payload: null,
        Updated_address_data: action.payload,
        spinner: false,
      };

    case "ADD_Address_FAILURE":
      return {
        ...state,
        fail: true,
        loader: 0,
        failMsg: action.err,
        spinner: false,
      };

    case "Silent_User_Address_REQUEST":
      return {
        ...state,
        fail: false,
        loader: 1,
        spinner: true,
      };

    case "Silent_User_Address_SUCCESS":
      return {
        ...state,
        loader: 0,
        Silent_User_Address: action.payload,
        addressAdded: true,
        spinner: false,
        newUserAddressAdded: true,
      };

    case "Silent_User_Address_FAILURE":
      return {
        ...state,
        fail: true,
        loader: 0,
        invalidEmail: action.payload.response.data?.invalid_attributes?.email,
        spinner: false,
      };

    case "New_User_Address_REQUEST":
      return {
        ...state,
        fail: false,
        loader: 1,
        spinner: true,
        addressAdding : true
      };

    case "New_User_Address_SUCCESS":
      return {
        ...state,
        loader: 0,
        spinner: false,
        newUserAddressAdded: true,
        addressAdding : false,
        authentication_token_for_otp: action.payload.authentication_token
      };

    case "New_User_Address_FAILURE":
      return {
        ...state,
        fail: true,
        loader: 0,
        spinner: false,
        addressAdding : false,
      };

      // case "ADD_Existing_User_Address_REQUEST":
      //  return {
      //  ...state,
      //  addressAdding: true
      //    };
  
      case "ADD_Existing_User_Address_SUCCESS":
         return {
              ...state,
              newAddressAdded: true,
              customAddressNew:true,
              addressFetched: false,
              addressAdding: false,
              ...(!action?.payload?.fetchAddressPermission && {selectedAddress: action?.payload?.addedAddress, Address_data: [action?.payload?.addedAddress], fetchingAddress: false, addressFetched: true, initialAllAddressFetched: state.initialAllAddressFetched + 1, allAddressFetched: true, failMsg: null, invalidEmail: null}),
         };
  
      // case "ADD_Existing_User_Address_FAILURE":
      //   return {
      //     ...state,
      //     addressAdding: false,
      //   };

      case "FETCH_All_Address_REQUEST":
        return {
          ...state,
          //sendAddressfired: false,
          //loader: 1,
          fetchingAllAddress:true,
          allAddressFetched: false
        };
  
      case "FETCH_All_Address_SUCCESS":
        return {
          ...state,
          Address_data: action.payload,
          loader: 0,
          fetchingAllAddress:false,
          allAddressFetched:true,
          initialAllAddressFetched: state.initialAllAddressFetched + 1,
          ...(action.payload.length > 0 && { selectedAddress: action?.payload?.filter((address) => address?.addressID === state?.selectedAddress?.addressID)?.length > 0 ? action?.payload?.filter((address) => address?.addressID === state?.selectedAddress?.addressID)[0] : action?.payload[0] })
        };
  
      case "FETCH_All_Address_FAILURE":
        return {
          ...state,
          loader: 0,
          Address_data: [],
          allAddressFetched: true,
          fetchingAllAddress:false,
        };

        case "Update_Address_REQUEST":
          return {
            ...state,
            updatingAddress: true,
            addressUpdated: false
          };
    
        case "Update_Address_SUCCESS":
          return {
            ...state,
            updatingAddress: false,
            addressUpdated: true,
            updatedAddress: action.payload,
          };
    
        case "Update_Address_FAILURE":
          return {
            ...state,
            updatingAddress: false,
            addressUpdated: false,
            addressUpdateFail: true
          };
        case  "Bypass_FetchAddress":
          return{
            ...state,
            Address_data: [],
            loader: 0,
            fetchingAddress:false,
            addressFetched:true,
            selectedAddress: null,
            addressCachedFetched: true,
            defaultAddressCorrect:true
          }
        case "SKIP_Otp_Address_add" : 
          return{
            ...state,
            newAddressAdded: true,
            addressFetched: false,
            addressAdding: false,
            selectedAddress: action?.payload, 
            Address_data: [action?.payload], 
            fetchingAddress: false, 
            addressFetched: true, 
            initialAllAddressFetched: state.initialAllAddressFetched + 1, 
            allAddressFetched: true, 
          }
    default:
      return state;
  }
};

export default Address_reducer;
