const channels = {
  shopify: "SHOPIFY",
  wooCommerce: "WOOCOMMERCE",
};

const coverageCartId = {"50/50":/[a-sA-S]/,"80/20":/[0-6]/,"100/0":[]}

const custom_attributes_key_to_avoid = ['contentId','quantity','Quantity','gift_wrap','varient_id']
let pickCartFromLS = ["ranbazaar.com", "thehouseofrare.com", "aramya.in", "blackberrys.com","wordpress-dev.pickrr.com","teescult.in","stayclassy.in","printymind.com", "arabianaroma.co.in"]
const CITY_REGEX = /^[A-Za-z0-9-\s&().]*$/;
const CITY_REGEX_ERROR_MESSAGE = 'Allowed special characters -, &, ()'


export { channels,custom_attributes_key_to_avoid ,coverageCartId,pickCartFromLS, CITY_REGEX, CITY_REGEX_ERROR_MESSAGE};
