export const redirectionSuccess = (channel, url) => {
    if(channel?.toUpperCase() === "SHOPIFY" && url?.includes('authenticate')){
        setTimeout(() => {
            try {
              window.parent.postMessage(
                {
                  trigger: "headless-redirect-post",
                  data: url,
                },
                "*"
              );
            } catch (error) {
              console.log();
            }
          }, 4000) 
    }else {
        setTimeout(() => {
        window.parent.postMessage(
            {
            trigger: "headless-redirect",
            actions: [
                {
                action: "redirectTo",
                data: {
                    url: url,
                },
                },
            ],
            },
            "*"
        );
        }, 4000)
    }
}
