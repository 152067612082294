var wigzoId = "";
function initializeWigzo(clientWigzoId) {
  // Wigzo tracking
  if (clientWigzoId != null) {
    wigzoId = clientWigzoId;
    window.parent.postMessage(
      {
        trigger: "Wigzo",
        eventName : 'configure' ,
        data : clientWigzoId
      },
      "*"
    )
  }
}

const track = (eventName, properties) => {
  let wigzoProperties = {
    ...properties
  }
  if (wigzoId!="") {
    console.log("checkoutsarted", wigzoProperties)
    const trackData = {
      eventName : eventName,
      wigzoProperties : wigzoProperties
    }
    window.parent.postMessage(
      {
        trigger: "Wigzo",
        eventName : 'track' ,
        data : trackData
      },
      "*"
    )
  }
};

const createUserProfile = (mobileNumber) => {
  if (wigzoId!="") {
    console.log("identify", {
      phone: mobileNumber,
    })
    window.parent.postMessage(
      {
        trigger: "Wigzo",
        eventName : 'identify' ,
        data : {phone : mobileNumber}
      },
      "*"
    )
  }
};
const addEmail = (email,fullName) => {
  if (wigzoId!="") {
    window.parent.postMessage(
      {
        trigger: "Wigzo",
        eventName : 'identifyEmail' ,
        data : {email : email,fullName : fullName}
      },
      "*"
    )
  }
};

const buy = (productIdArr) => {
  if (wigzoId) {
    console.log("buy", productIdArr)
    window.parent.postMessage(
      {
        trigger: "Wigzo",
        eventName : 'trackBuy' ,
        data : productIdArr
      },
      "*"
    )
  }
};


var Wigzo = {
  track,
  initializeWigzo,
  createUserProfile,
  buy,
  addEmail
};

export default Wigzo;
