import { useState } from "react";
import "../UI/Common.css";
import "./Shipping.css";
import Address from "../Address/Address";
import { useDispatch, useSelector } from "react-redux";
import ShippingList from "./ShippingList";
import AnalyticService from "../../service/analytics/AnalyticsService";

const Shipping = (props: any) => {
  const shippingstate = useSelector((state: any) => state.Shipping);
  const loginState = useSelector((state: any) => state.Login);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  let shippingspeed = shippingstate.service_data;
  let shippingcontent: any;

  if (shippingspeed.length > 0) {
    shippingcontent = shippingspeed.map((shipping: any, index: number) => (
      <ShippingList
        onSelectShippingMethod={props.onSelectShippingMethod}
        SetIsOpen={props.SetIsOpen}
        name={shipping?.pickrr_tag}
        //code={shipping?.code}
        price={shipping.charges}
        date={shipping?.edd ? shipping.edd : null}
        msg={shipping.edd_message}
        id={index}
        key={index}
        selectedShipping={props.selectedShipping}
        shippingObject={shipping}
      />
    ));
  }
  const selectAddress = () => {
    props.SetTitle("Saved Addresses");
    setIsAddressSelected(true);
  };
  const selectShipping = (e: any) => {
    e.preventDefault();
  };

  const handleChange = (e: any) => { };

  const modalCloseHandler = () => {
    //for modal close
    props.onClose();
  };

  return (
    <>
      {!isAddressSelected ? (
        <section>
          <div className="whole-container">
            <div className="d-flex space-between">
              <p className="bold font-16 primary" style={{fontWeight:'700'}}>
              {loginState.sellerConfig?.uiConfig?.address?.label 
                ? loginState.sellerConfig?.uiConfig?.address?.label 
                : 'Deliver to'
              }  
              </p>
              {/*<button className="font-14 r-top chngbtn" onClick={selectAddress}>
                Change
              </button> */}
            </div>
            <p className="font-16 customerAddress mt-10"></p>
            <div className="mb-5">
              <label className="h3 grey-dark semi-bold capitalize">
                {props.addressToShow.name}
              </label>
            </div>
            <div className="mb-18 max-wid80">
              <label className="h4 grey-dark light" style={{overflowWrap:'break-word', width:'90%', fontWeight:'400'}}>
                {props.addressToShow.address}
              </label>
            </div>
            <p className="bold h2 primary">Delivery options</p>
            {shippingcontent}
          </div>
        </section>
      ) : (
        <section className="otp">
          <Address
            onSelectAddressMethod={props.onSelectAddressMethod}
            addressToShow={props.addressToShow}
            setAddressToShow={props.setAddressToShow}
            SetIsOpen={props.SetIsOpen}
            onClose={modalCloseHandler}
            onRender={props.onRender}
            onRenderLoading={props.onRenderLoading}
            SetTitle={props.SetTitle}
          />
        </section>
      )}
    </>
  );
};

export default Shipping;
