import React from "react";
import "../UI/Common.css";
import "./Shipping.css";
import { useSelector } from "react-redux";


const ShippingList = (props: any) => {

  const loginstate = useSelector((state: any) => state.Login);

  const updateShipping = () => {
    props.onSelectShippingMethod(props);
    props.SetIsOpen(false)
  };
  return (
    <>
      <div
        className="pt-18 pb-12 bb-1 row"
        onClick={(e) => {
          updateShipping()
        }}
      >
        <input type="radio" defaultChecked={props.name === props.selectedShipping.name} className="sr-radio" name="address" id={props.id} />
        <label htmlFor={props.id} className="sr-label font-14 primary bold">
          <span className="circle">
            <span className="circle-dot"></span>
          </span>
          <div
            className="full-width pl-20"
          >
          
{/*       
    //code commented by rizwan
  {props.code === "SR_STANDARD" && 'Standard Delivery'}

            {props.code === "SR_EXPRESS" && (<><img
              src={
                process.env.PUBLIC_URL + "./assets/images/expressdelivery.svg"
              }
              alt="Express Delivery"
              className="mr-5 express-img"
            />
              Delivery
            </>)}

            {props.code === "SR_RUSH" && (<><img
              src={process.env.PUBLIC_URL + "./assets/images/ruoh.svg"}
              className="ruoh-img"
              alt="Rush Delivery"
            />{" "}
              Delivery
            </>)} */}
            <p> {props.name.replace('Pickrr-', "") === "Serviceability_not_confirmed" ? "Standard" : props.name.replace('Pickrr-', "")} Delivery</p>
            <p className="bld r-top"> {props.price ? <>&#8377;{props?.price?.toFixed(2)}</> : loginstate?.sellerConfig?.domainName === "odette.in" ? "FREE SHIPPING" : "FREE"}</p>
            { props.date &&
            <p className="font-12 lft20 grey deiveryTime mb-15 delivery-time">
              {props.date}
            </p>
            }
          </div>
        </label>
      </div>
    </>
  );
};
export default React.memo(ShippingList);
