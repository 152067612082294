import React from 'react';
import './style.css'

const OtpSkeleton = () => {
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;
    return (
      <>
      {!LoginPopup ?
      <>
      <div className="skeleton__heading width40 mb10"></div>
      <div className='skeleton__text mb18'></div>
      <div className='skeleton__text mb6'></div>
      <div className='otp__container mb10'>
        <div className='otp__box'></div>
        <div className='otp__box'></div>
        <div className='otp__box'></div>
        <div className='otp__box'></div>
        <div className='otp__box'></div>
        <div className='otp__box'></div>
      </div>
      <div className='skeleton__text terms__and__condition mb6'></div>
      <div className='skeleton__text terms__and__condition mb10' 
        style={{width: '150px'}}></div>
      <div className='skeleton__text mb18'></div>
      </>
       : 
       <>
        <div className='skeleton__text__small mb8' style={{width : '70%',marginTop : '8px'}}></div>
        <div className='otp__container mb8'>
          <div className='otp__box__login_popup'></div>
          <div className='otp__box__login_popup'></div>
          <div className='otp__box__login_popup'></div>
          <div className='otp__box__login_popup'></div>
          <div className='otp__box__login_popup'></div>
          <div className='otp__box__login_popup'></div>
        </div>
        <div className='skeleton__text terms__and__condition mb12' style={{width : '45%'}}></div>
        <div className='skeleton__text terms__and__condition mb6' style={{width : '100%'}}></div>
        </>
      }
      </>
    );
  };
  
  export default OtpSkeleton;
  