import { useEffect, useMemo, useState } from "react";
import styles from "./OrderSummary.module.css";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";
import AnalyticService from "../../service/analytics/AnalyticsService";

import { Store } from "../../service/StorageHelper";
import OrderSummaryDrawer from "./OrderSummaryDrawer";
import apiUrls from "../../api/Apis";
import Http from "../../service/Http";
import { updateOrder } from "../../Redux/Cart/Cart_action";
import {custom_attributes_key_to_avoid} from '../../utils/constants.js'
import { getCouponSuggestion } from "../../Redux/Coupon/Coupon_action";
import Alert from "../Utils/Alert";
import Loader from "../Utils/Loader";

const parser = require("ua-parser-js");
let ua = new parser("", "");

const OrderSummary = (props: any) => {
  const dispatch = useDispatch();
  const loginstate = useSelector((state: any) => state.Login);

  const [finalPrice, setFinalPrice] = useState(0);
  const [finalPriceLoginPage, setFinalPriceLoginPage] = useState(0);
  const addressstate = useSelector((state: any) => state.Address);
  const shippingstate = useSelector((state: any) => state.Shipping);
  // const cartItems = useSelector((state: any) => state.Cart.Cart_data.data);
  const cartState = useSelector((state: any) => state.Cart);
  const couponState = useSelector((state: any) => state.Coupon);
  const [render, setRender] = useState(false)
  const otpstate = useSelector((state: any) => state.Otp);

  const checkIfAutomaticDiscountExists = () => {
    for(let i=0; i<cartState.Cart_data?.discountDetail?.discount_data?.length; i++) {
      if(cartState.Cart_data?.discountDetail?.discount_data[i]?.discount_type?.toUpperCase() === "AUTOMATIC") {
        return true;
      }
    }
    return false;
  }

  const openSummaryOnAutomatic = () => {
    if(cartState.openSummaryOnAutomatic?.includes(loginstate?.sellerConfig?.domainName) && cartState.Cart_data?.discountDetail?.discount_data?.length > 0 && checkIfAutomaticDiscountExists() && !cartState?.cardDesignEnabled) {
      return true
    } else {
      return false
    }
  }

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false

  const getPriceForGiftWrap = () => {
    let quantity = 0;
    if(cartState.orderDetails?.id) {
      quantity = cartState?.orderDetails?.lineItems?.filter((item : any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie))?.reduce((accumulator: any, currentValue:any) => accumulator + currentValue.quantity, 0)
    } else {
      quantity = loginstate?.cartItems?.filter((item : any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie))?.reduce((accumulator: any, currentValue:any) => accumulator + currentValue.quantity, 0)
    }

    if(quantity > 0 && loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) {
      quantity = 1
    }
    return (quantity*loginstate.sellerConfig?.giftWrapDetails?.price)?.toFixed(2);
  }
  

  const GiftWrap = async (products: any, type: any, id?:any) => {

    try {

    props.setGiftWrapLoading(true)

    let listOfItem: any = []
    let giftCount: number = 0

    if(products === "ALL") {

      listOfItem = (((otpstate?.verified || loginstate?.userDetailsFetched || loginstate?.otpSkip) && addressstate?.selectedAddress && addressstate?.defaultAddressCorrect) || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? 
      cartState.orderDetails.lineItems?.map((item: any) => {
        if((String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie)) {
          return {...item, customAttributes: {...item.customAttributes, gift_wrap: type === "APPLY" ? true : false}}
        } else {
          return item
        }

      })
      :
      cartState.Cart_data.items?.map((item: any) => {
        if((String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie)){
          return {...item, customAttributes: {...item.customAttributes, gift_wrap: type === "APPLY" ? true : false}}
        } else {
          return item
        }

      })

    } else {
      listOfItem = (((otpstate?.verified || loginstate?.userDetailsFetched || loginstate?.otpSkip) && addressstate?.selectedAddress && addressstate?.defaultAddressCorrect) || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? 
      cartState.orderDetails.lineItems?.map((item: any) => {
        if(String(item.itemId) === String(id) && !item.customAttributes?.freebie) {
          return {...item, customAttributes: {...item.customAttributes, gift_wrap: type === "APPLY" ? true : false}}
        } else {
          return item
        }
      })
      :
      cartState.Cart_data.items?.map((item: any) => {
        if(String(item.itemId) === String(id) && !item.customAttributes?.freebie) {
          return {...item, customAttributes: {...item.customAttributes, gift_wrap: type === "APPLY" ? true : false}}
        } else {
          return item
        }
      })

    }

    //listOfItem = listOfItem.filter((item: any) => item.itemId !== loginstate.sellerConfig?.giftWrapDetails?.variantId)

    giftCount = listOfItem.reduce((accumulator: any, currentValue: any) => accumulator + (currentValue?.customAttributes?.gift_wrap ? currentValue?.quantity : 0), 0)

    if(giftCount > 0 && loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) {
      giftCount = 1
    }
    if(giftCount > 0) {

      let ifGiftExists = listOfItem.filter((item: any) => String(item.itemId) === String(loginstate.sellerConfig?.giftWrapDetails?.variantId))?.length > 0 ? true : false

      if(ifGiftExists) {
        listOfItem = listOfItem.map((item: any) => {
          if(String(item.itemId) === String(loginstate.sellerConfig?.giftWrapDetails?.variantId)) {
            return {...item, quantity: giftCount}
          } else {
            return item
          }
        })
      } else {
        listOfItem = [...listOfItem, {itemId: loginstate.sellerConfig?.giftWrapDetails?.variantId, quantity: giftCount}]
      }

    } else {
      listOfItem = listOfItem.filter((item: any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId)))
    }

    let requestPayload = {
      items: listOfItem,
      couponCode: ((addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) && cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) ? cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.[0]?.discount_code : (cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) ? cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.[0]?.discount_code : "",
      channel: cartState?.platform?.toUpperCase(),
      ...(isProductPage && {variant: true})
    }

    const response = await Http.put(
      `${apiUrls.CREATE_CART_DETAILS()}/${
        loginstate?.userInfo?.user_profile_id
      }`,
      requestPayload,
      loginstate.sellerConfig.seller_id
    );  

    if(response) {
      dispatch(getCouponSuggestion(loginstate.sellerConfig.seller_id))
      let shopifyCart = cartState.shopifyCart;
      let cartToken = cartState.Cart_data?.id;
      cartState.Cart_data = response.data
      if(cartState.orderDetails.id && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched)) && !shippingstate?.orderBlock) {
        let ordersPayload:any = {
          userId: loginstate.userInfo.user_id,
          paymentType: shippingstate.paymentMode,
          sellerId: loginstate.sellerConfig.seller_id,
          shippingPlan: shippingstate?.selected_service_data?.pickrr_tag,
          shippingAddressId:addressstate?.selectedAddress?.addressID ?? '658eae861495e60037ef2642',
          orderId: cartState.orderDetails.id,
          isUpdateRequired: 1,
          source: ua.getResult().device.type === "mobile" ? "M_WEB" : "WEB",
          ...(cartState?.activePayment?.key && cartState.activePaymentGateway ? 
            {   
           payment: {
             paymentPlatform: cartState.activePaymentGateway.toUpperCase(),
             paymentMethod: cartState.activePaymentMode,
             paymentMode: "online"
           }
         } : 
           {
             payment: null
           }
         ),
         ...((shippingstate.platformCustomerId && loginstate.sellerConfig?.flitsEnable) && {
          loyaltyManagerRequest: {
              customerId:shippingstate.platformCustomerId,
              couponType: "FLITS",
              cart: window.btoa(unescape(encodeURIComponent(shopifyCart))),
              cartToken:cartToken,
              applyLoyaltyPoint: cartState.loyaltyPointDetails?.status === "APPLIED" ? true : false
            }
          }),
          ...((loginstate.sellerConfig?.capillaryEnabled) && {
            loyaltyManagerRequest: {
              couponType : "CAPILLARY",
              applyLoyaltyPoint: false
            }
          }),
          ...((loginstate.sellerConfig?.customLoyaltyPointsEnabled) && {
            loyaltyManagerRequest: {
              couponType : "CUSTOM",
              applyLoyaltyPoint: false
            }
          }),
          ...((loginstate.sellerConfig?.nectorEnabled) && {
            loyaltyManagerRequest: {
              couponType : "NECTOR",
              applyLoyaltyPoint: false
            }
          }),
          ...((loginstate.sellerConfig?.easyRewardEnabled) && {
            loyaltyManagerRequest: {
              couponType : "EASYREWARD",
              applyLoyaltyPoint: false
            }
          }),
          ...((loginstate.sellerConfig?.popcoinEnabled) && {
            loyaltyManagerRequest: {
              couponType : "POPCOIN",
              applyLoyaltyPoint: false
            }
          }),
        }
        dispatch(updateOrder(ordersPayload, loginstate.sellerConfig.seller_id));
      }
      props.onRenderLoading()
      props.setGiftWrapLoading(false)
    } else {
      props.setGiftWrapLoading(false)
    }

    } catch (e) {
      props.setGiftWrapLoading(false)
    }
  }

  const checkIfAllGiftWrap = () => {
    let arrayForCheck = cartState.orderDetails?.id && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? cartState.orderDetails?.lineItems : cartState.Cart_data?.items
    let filteredArray = arrayForCheck?.filter((item: any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie))
    for(let i=0; i<filteredArray?.length; i++) {
      if(!filteredArray[i]?.customAttributes?.gift_wrap) {
        return false
      }
    }
    return true
  }
  const checkGiftwrappedItem = () => {
    let arrayForCheck = cartState.orderDetails?.id && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? cartState.orderDetails?.lineItems : cartState.Cart_data?.items
    let filteredArray = arrayForCheck?.filter((item: any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie))
    let giftwrapItemCount = 0
    for(let i=0; i<filteredArray.length; i++) {
      if(filteredArray[i]?.customAttributes?.gift_wrap) {
        giftwrapItemCount = giftwrapItemCount+1
      }
    }
    return giftwrapItemCount
  }
  const checkIfAnyGiftWrap = () => {
    let arrayForCheck = cartState.orderDetails?.id && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock))? cartState.orderDetails?.lineItems : cartState.Cart_data?.items
    let filteredArray = arrayForCheck?.filter((item: any) => (String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId) && !item.customAttributes?.freebie))
    console.log("Fil", filteredArray)
    for(let i=0; i<filteredArray.length; i++) {
      if(filteredArray[i]?.customAttributes?.gift_wrap) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if(couponState.couponCode){
      //cartState.Cart_data = couponState.data;
    }
  }, [couponState.couponCode])
  
  useEffect(() => {
    if(cartState?.Cart_data?.items?.length) {
      loginstate.cartItems = cartState?.Cart_data?.items
    }
    // setRender(!render)
  }, [cartState.Cart_data])

  useEffect(() => {
    let sum: any = 0;
    if(loginstate?.cartItems){
      loginstate?.cartItems?.forEach((element: any) => {
        sum += element.price * element.quantity;
      });
    }
    setFinalPriceLoginPage(sum);
  }, []);
  useEffect(() => {
    setFinalPrice(cartState.total_price);
    if (addressstate.selectedPaymentMode === "COD") {
      setFinalPrice(cartState.cod_total_price);
    } else if (addressstate.selectedPaymentMode === "UPI") {
      setFinalPrice(cartState.total_price);
    }
  }, [addressstate.selectedPaymentMode, cartState.total_price]);


  const displayCoupon = (couponName:string) => {
    if(couponName?.length > 20){
      return `${couponName?.slice(0, 20)}...`
    }else {
      return couponName
    }
  }


  const orderId = cartState.orderDetails?.id;
  const selectedAddress = addressstate.selectedAddress;


  const getTotalMRP = (arr:any) => {
    if (arr?.length) {
      return arr?.reduce((sum: number, item: any) => {
          const maxPrice =  Math.max(Number(item?.compareAtPrice) || 0, Number(item?.price) || 0) * (Number(item?.quantity) || 0);
          return sum + maxPrice;
        }, 0);
    }
  }

  const totalOrderDetailsMRP = getTotalMRP(cartState?.orderDetails?.lineItems);
  const totalCartDetailsMRP = getTotalMRP(cartState?.Cart_data?.items);
  const totalOrderShippingMRP = totalOrderDetailsMRP + (shippingstate?.selected_service_data?.charges || 0);
  const totalCartShippingMRP = totalCartDetailsMRP + (shippingstate?.selected_service_data?.charges || 0);
  const isOrderMRPGreater = cartState.orderDetails?.totalPrice 
    ? totalOrderDetailsMRP > cartState.orderDetails?.totalPrice
    : totalCartDetailsMRP > cartState.Cart_data?.totalPrice

    

    
  useEffect(() => {
     if((!cartState.defaultOrderSummaryOpen && 
        (loginstate.cartItems || cartState.orderDetails?.lineItems?.length) && 
        loginstate?.sellerConfig?.uiConfig?.orderSummaryOpen && !cartState?.cardDesignEnabled) ||
        ((props.showItems || loginstate.sellerConfig?.draftOrderNativeShopify || openSummaryOnAutomatic()))
    ){
      props?.setShowDetails(true)
      cartState.defaultOrderSummaryOpen = true
    }
  },[cartState.Cart_data, cartState.orderFetched])


  return (
    <>
    <div className={`mb-18 mt-20 ${cartState?.cardDesignEnabled ? 'card-container' : ''}`} id="order-summary-id" style={{borderBottom: cartState?.cardDesignEnabled &&  checkIfAnyGiftWrap() ? '2px solid #DDAF6A': '', position: 'relative'}}>
      <div
        onClick={() => {
          if(!cartState?.cardDesignEnabled){
            if (props?.showDetails) {
              AnalyticService.orderSummaryCollapsed();
              AnalyticService.additionalEvents(
                "order-summary-collapsed",
                cartState?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
            } else {
              AnalyticService.orderSummaryExpanded();
              AnalyticService.additionalEvents(
                "order-summary-expanded",
                cartState?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
            }
            props?.setShowDetails((prevState: any) => !prevState);
          }else{
            AnalyticService.orderSummaryExpanded();
            AnalyticService.additionalEvents(
              "order-summary-expanded",
              cartState?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
            props?.setOrderSummaryModalVisible(true)
          }
        }}
        style={{cursor : 'pointer'}}
        // className={clsx(styles.header, !cartState?.cardDesignEnabled && "px-24")}
      >
        {
          cartState?.cardDesignEnabled && props?.hasAnyFreebie &&
          <div style={{display:"flex", justifyContent: "flex-end", marginBottom: '10px', marginRight: '-16px', marginTop: '-14px'}}>
        <div className={styles.topFreebieTag}>
            <span style={{borderRadius: "0px 4px 4px 0px", background: 'linear-gradient(90deg, #FFFFFF 0%, #D0DBF7 100%)', paddingRight: '10px' }}>Free Gift Added</span>
            <div className={styles.triangle}></div>
            <img src={process.env.PUBLIC_URL + "assets/images/gift.gif"} alt="gift gif" height={26} style={{border: '1px solid #D0DBF7', borderRadius:'50%', marginBottom:'-5px' }} ></img>
        </div>
        </div>
        }
        
        
        <div className={clsx(styles.header, !cartState?.cardDesignEnabled ? "px-24" : '')}>
        <div>
          <div style={{display:'flex', gap: 4,alignItems : cartState?.cardDesignEnabled ? 'center' : ''}}>
            <div className={cartState?.cardDesignEnabled ? 'card-main-heading' : styles.title}>Order summary <a className={cartState?.cardDesignEnabled ? 'card-heading-subtext' : styles.label}>(
              {cartState?.orderDetails?.lineItems?.length && addressstate.selectedAddress ? cartState?.orderDetails?.lineItems?.length : cartState?.Cart_data?.items?.length || cartState?.initialCartData?.length}&nbsp;Item
              {(cartState?.orderDetails?.lineItems?.length
                ? cartState?.orderDetails?.lineItems?.length
                : cartState?.Cart_data?.items?.length) === 1 ? "" : "s"}
              )
            </a></div>
          </div>

          {/* cartstate.orderDetails?.couponCode */} 
        </div>
        {((Object.keys(cartState.orderDetails).length > 0 && addressstate.selectedAddress) || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? (

              ((otpstate.verified && (!isProductPage || cartState.proceedToBuy) ?
                (couponState.loader || shippingstate.serviceFetching ||
                  addressstate.fetchingAllAddress ||
                  (addressstate?.Address_data?.length !== 0 &&
                    ((!cartState.orderFetched && !shippingstate.orderBlock) ||
                      cartState.globalPaymentLoader)) && addressstate?.defaultAddressCorrect) : couponState.loader))
                ?
                <div style={cartState?.cardDesignEnabled?{paddingRight:'5px'}:{paddingRight:'14px'}}><Loader smallLoader="small" noMarginTop="0" /></div>
                :
          <div className={clsx(!cartState?.cardDesignEnabled ? styles.title : styles.titleCard, styles.total)} style={{fontSize : !cartState?.cardDesignEnabled ? '' : '14px'}}>
                 
                {loginstate?.sellerConfig?.showCompareAtPrice && isOrderMRPGreater ?           
                  (<div className={styles.cardPrevTotal}>
                    &#8377;{
                      Number(cartState.orderDetails?.totalPrice && 
                        typeof shippingstate?.selected_service_data?.charges === "number"
                        ? totalOrderShippingMRP
                        : totalCartShippingMRP).toFixed(2)
                    }
                  </div>)
                  : (cartState?.cardDesignEnabled && Number(cartState.orderDetails?.totalPrice 
                    ? cartState.orderDetails?.totalPrice
                    : cartState.Cart_data?.totalPrice).toFixed(2) >
                    ((addressstate?.selectedPaymentMode === "COD") ?
                      cartState.orderDetails?.codPrice?.toFixed(2) :
                      (cartState.orderDetails?.totalAmount + cartState.orderDetails?.prepaidDiscount ?? 0)) &&
                    <div className={styles.cardPrevTotal}>&#8377;{
                      Number(cartState.orderDetails?.totalPrice
                        ? cartState.orderDetails?.totalPrice
                        : cartState.Cart_data?.totalPrice).toFixed(2)
                    }</div>
                  )}
           &#8377;{(addressstate?.selectedPaymentMode === "COD" || (cartState?.payPartialCod && cartState?.orderPending)) ? 
                cartState?.orderDetails?.codPrice?.toFixed(2) : 
                (cartState.orderDetails?.prepaidPrice + cartState.orderDetails?.prepaidDiscount ?? 0).toFixed(2)
              }
            <img
              className={`pl-5 ${!cartState?.cardDesignEnabled ? '' : 'back-card-opacity'}`}
              src={
                !cartState?.cardDesignEnabled ?
                props?.showDetails
                  ? process.env.PUBLIC_URL + "./assets/images/svg/up-arrow.svg"
                  : process.env.PUBLIC_URL +
                  "./assets/images/svg/down-arrow.svg"
                :
                process.env.PUBLIC_URL + "./assets/images/arrowIconCard.svg"
              }
              alt="toggle icon"
              style={{marginTop : !cartState?.cardDesignEnabled ? '' : '2px'}}
            />
          </div>
        ) : (
          <div className={clsx(!cartState?.cardDesignEnabled ? styles.title : styles.titleCard, styles.total)} style={{fontSize : !cartState?.cardDesignEnabled ? '' : '14px'}}>
            {loginstate?.sellerConfig?.showCompareAtPrice && isOrderMRPGreater ?           
                (<div className={styles.cardPrevTotal}>
                  &#8377;{
                    Number(cartState.orderDetails?.totalPrice && 
                      typeof shippingstate?.selected_service_data?.charges === "number"
                      ? totalOrderShippingMRP
                      : totalCartShippingMRP).toFixed(2)
                  }
                </div>) 
                : (cartState?.cardDesignEnabled && Number(cartState.orderDetails?.totalPrice 
                ? cartState.orderDetails?.totalPrice 
                : cartState.Cart_data?.totalPrice).toFixed(2) !== (cartState.Cart_data?.totalDiscount ? 
                (cartState.Cart_data?.totalPrice - cartState.Cart_data?.totalDiscount).toFixed(2) : 
                cartState.Cart_data?.totalPrice ? cartState.Cart_data?.totalPrice?.toFixed(2) : finalPriceLoginPage.toFixed(2)) &&
            <div className={styles.cardPrevTotal}>&#8377;{
              Number(cartState.orderDetails?.totalPrice 
                ? cartState.orderDetails?.totalPrice 
                : cartState.Cart_data?.totalPrice).toFixed(2)
            }</div>
          )}
            &#8377;{cartState.Cart_data?.totalDiscount ? 
              (cartState.Cart_data?.totalPrice - cartState.Cart_data?.totalDiscount).toFixed(2) : 
              cartState.Cart_data?.totalPrice ? cartState.Cart_data?.totalPrice?.toFixed(2) : finalPriceLoginPage.toFixed(2)} 
            <img
              className={`pl-5 ${!cartState?.cardDesignEnabled ? '' : 'back-card-opacity'}`}
              src={
                !cartState?.cardDesignEnabled ?
                props?.showDetails
                  ? process.env.PUBLIC_URL + "./assets/images/svg/up-arrow.svg"
                  : process.env.PUBLIC_URL +
                  "./assets/images/svg/down-arrow.svg"
                  :
                process.env.PUBLIC_URL + "./assets/images/arrowIconCard.svg"
              }
              alt="toggle icon"
              style={{marginTop : !cartState?.cardDesignEnabled ? '' : '2px'}}
            />
          </div>
        )}
        </div>
        {((cartState?.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0 && orderId && (selectedAddress !== null || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock))) || 
            (cartState?.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0 && (!orderId || (selectedAddress === null && !(loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock))))) && !loginstate.sellerConfig?.nativeShopifyCheckout &&
          <div className={` ${!cartState?.cardDesignEnabled ? 'px-24' : ''} new__green__text font-12`} style={{marginTop: 4}}>
              <img
                src={process.env.PUBLIC_URL + "./assets/images/svg/automatic_coupon.svg"}
                alt="Coupon Apply"
                style={{ verticalAlign: "middle" }}
              />
              <span className="pl-5">
                {
                (cartState?.Cart_data?.discountDetail?.discount_data?.length > 1 || cartState?.orderDetails?.discountDetail?.discount_data?.length > 1 ) ?
                `Multiple offers Applied!  You save ₹${cartState?.orderDetails?.discountDetail?.total_discount ??  cartState?.Cart_data?.discountDetail?.total_discount}!` 
                :
                <>
                {cartState?.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.[0]?.discount_code ?? cartState?.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.[0]?.discount_code} Offer Applied! You save ₹{
                  cartState?.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0
                  ? cartState?.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.[0]?.discount_amount
                  : cartState?.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0
                  ? cartState?.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.[0]?.discount_amount
                  : ''
                }!
                </>
                }
              </span>     
        </div>
        }
          {loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && !cartState.orderPending && !cartState?.hasBundleProduct &&
          <div className={!cartState?.cardDesignEnabled ? 'px-24' : ''} >
            <div className={styles.singleGiftWrapBox} style={{ background: checkIfAnyGiftWrap() ? 'linear-gradient(93.62deg, #FFEBBC 0.89%, #FFFCF4 80.74%' : 'linear-gradient(93.62deg, #F4F4F4 0.89%, #F9F9F9 80.74%)', marginTop: cartState?.cardDesignEnabled ? '10px': '', width: cartState?.cardDesignEnabled ? 'fit-content' : '', paddingRight: cartState?.cardDesignEnabled ? '10px' : '' }}>
              {!cartState?.cardDesignEnabled && <img
                src={process.env.PUBLIC_URL + "./assets/images/svg/goldGiftbox.svg"}
                alt="gift wrap"
                style={{ verticalAlign: "middle", marginRight: '6px' }}
              />}
              
                { checkIfAnyGiftWrap() ? 
                checkIfAllGiftWrap() ? <span style={{color: '#A97600'}}>Yay! Your order is gift-wrapped!</span> : <span style={{color: '#A97600'}}>Yay! {checkGiftwrappedItem()} of your items {checkGiftwrappedItem() > 1 ? 'are' : 'is'} gift-wrapped! </span>
                 : <span style={{color: '#6A6A6A'}}> {!cartState?.cardDesignEnabled ? '' : 'Gift'} wrap my order at ₹{getPriceForGiftWrap()} </span>}

              <img
                src={process.env.PUBLIC_URL + "./assets/images/svg/down-arrow-filled.svg"}
                alt="arrow"
                style={{marginLeft: '4px', transform: (props?.showDetails || props?.orderSummaryModalVisible) ? 'rotate(180deg)': ''}}
            />
            </div>
          </div>}
        {/* {!cartState?.cardDesignEnabled 
          ?
          !props?.showDetails && loginstate.sellerConfig?.giftWrapDetails?.variantId && loginstate.sellerConfig?.giftWrapDetails?.enabled && !cartState.orderPending && <div className={!cartState?.cardDesignEnabled ? `${styles.giftText} px-24` : styles.giftTextCard}>Send as a gift</div>
          :
          loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && !cartState.orderPending && (
              <div 
                className={styles.selectGiftRow} 
                style={{marginTop : '5px',alignItems : 'center'}}
              >
              <input
                id="selectAllGift"
                type="checkbox"
                onClick={(e) => {
                  e.stopPropagation()
                }} 
                onChange={(e) => {
                  if((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching)){
                    if(e.target.checked) {
                      GiftWrap("ALL", "APPLY")
                    } else {
                      GiftWrap("ALL", "REMOVE")
                    }
                  }
                }}
                disabled = {shippingstate?.orderBlock}
                style={{accentColor:'#3173E3',height : '16px',width : '16px'}}
                checked={checkIfAnyGiftWrap()}
              />
              <h3 className={styles.singleGiftText} style={{marginTop : '2px'}}>
                {checkIfAnyGiftWrap() ? checkIfAllGiftWrap() ? `${'This order is a gift!'}` : `${checkGiftwrappedItem()} of your items ${checkGiftwrappedItem() > 1 ? 'are' : 'is'} gift-wrapped!` : <span>Gift wrap? (₹{getPriceForGiftWrap()})</span>}
                </h3>
              </div> 
              
            )
        } */}
      </div>
       
      {props?.showDetails ? (
        <div className={styles.orderSummary}>
          {/* cartItems?.["subtotal_price"] */}
          {cartState.orderDetails?.totalPrice || cartState.Cart_data?.totalPrice ? (
            <div className={styles.summaryLabel}>
              <div>Sub total</div>
                <div>&#8377;{loginstate?.sellerConfig?.showCompareAtPrice && isOrderMRPGreater ? 
                Number(cartState.orderDetails?.totalPrice
                  ? totalOrderDetailsMRP
                  : totalCartDetailsMRP).toFixed(2) :
                Number(cartState.orderDetails?.totalPrice 
                  ? cartState.orderDetails?.totalPrice 
                  : cartState.Cart_data?.totalPrice).toFixed(2)
              }</div>
            </div>
          ) : null}
          
          {((!cartState.orderDetails?.id || addressstate.selectedAddress === null) && !(loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) && !loginstate.sellerConfig?.nativeShopifyCheckout && (
            <>
              {(loginstate?.sellerConfig?.showCompareAtPrice && isOrderMRPGreater &&
                <div className={styles.summaryLabel}>
                  <div>Discount on MRP</div>
                  <div className="new__green__text">- &#8377; {Number(cartState.orderDetails?.totalPrice
                    ? totalOrderDetailsMRP - cartState.orderDetails?.totalPrice
                    : totalCartDetailsMRP - cartState.Cart_data?.totalPrice).toFixed(2)}</div>
                </div>)
              }

              {(cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) &&
              (cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.map((couponCodeObj:any)=>{
                return(
                  <div className={styles.summaryLabel}>
                    <div>Coupon discount 
                    ({ displayCoupon(couponCodeObj?.discount_code)})
                    </div> 
                    <div className="new__green__text">- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                )
              })
              )}
              {(cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0) &&
              (cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.map((couponCodeObj:any)=>{
                return(
                  <div className={styles.summaryLabel}>
                    <div>Auto discount{couponCodeObj?.discount_code && `(${couponCodeObj?.discount_code})`}</div> 
                    <div className="new__green__text">- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                )
              })
              )}
            </>
          )}

{/* || 
        (
          cartState?.orderDetails?.id &&
          addressstate?.selectedAddress && 
          shippingstate?.platformCustomerId &&  
          cartState?.loyaltyPointDetails?.rule_id && 
          loginstate?.sellerConfig?.flitsEnable
        ) */}

        {(cartState.orderDetails?.id && (addressstate?.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) && !loginstate.sellerConfig?.nativeShopifyCheckout) 
        && (
            <>
              {(loginstate?.sellerConfig?.showCompareAtPrice && isOrderMRPGreater &&
                <div className={styles.summaryLabel}>
                  <div>Discount on MRP</div>
                  <div className="new__green__text">- &#8377; {Number(cartState.orderDetails?.totalPrice
                    ? totalOrderDetailsMRP - cartState.orderDetails?.totalPrice
                    : totalCartDetailsMRP - cartState.Cart_data?.totalPrice).toFixed(2)}</div>
                </div>)
              }

              {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) &&
              (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.map((couponCodeObj:any)=>{
                return(
                  <div className={styles.summaryLabel}>
                    <div>Coupon discount 
                    ({ displayCoupon(couponCodeObj?.discount_code)})
                    </div> 
                    <div className="new__green__text">- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                )
              })
              )}
              {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0) &&
              (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.map((couponCodeObj:any)=>{
                return(
                  <div className={styles.summaryLabel}>
                    <div>Auto discount{couponCodeObj?.discount_code && `(${couponCodeObj?.discount_code})`}</div> 
                    <div className="new__green__text">- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                )
              })
              )}
              {(cartState.loyaltyPointDetails?.status === "APPLIED" && cartState.loyaltyPointDetails?.applicable_credits>0 &&
              <div className={styles.summaryLabel}>
                <div>Rewards & Loyalty Points</div> 
                <div className="new__green__text">- &#8377;{cartState.loyaltyPointDetails?.applicable_credits}</div>
              </div>)
               
              }
            </>
        )}

          {/* {cartState.auto_discount ? (
            <div className={styles.summaryLabel}>
              <div>Auto Discount</div>
              <div className="ascent-green">- &#8377;{cartState.auto_discount.toFixed(2)}</div>
            </div>
          ) : null} */}
          {/* {addressstate.selectedPaymentMode !== "COD" && cartState?.orderDetails?.prepaidDiscount && addressstate.selectedAddress ? (
            cartState?.orderDetails?.prepaidDiscount ? (
              <div className={styles.summaryLabel}>
                <div>Prepaid discount</div>
                <div className="ascent-green">- &#8377;{cartState?.orderDetails?.prepaidDiscount.toFixed(2)}</div>
              </div>
            ) : null
          ) : null} */}
          {(addressstate.selectedPaymentMode === "COD" || (cartState.payPartialCod && cartState.orderPending)) &&
            cartState.orderDetails?.codCharges > 0 ? (
            <div className={styles.summaryLabel}>
              <div>COD charges</div>
              <div>&#8377;{cartState.orderDetails?.codCharges?.toFixed(2)}</div>
            </div>
          ) : null}
          
          {cartState?.orderDetails?.shippingCharges && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock) ) ? (
            <div className={styles.summaryLabel}>
              <div>Delivery charges</div>
              <div className={cartState?.orderDetails?.shippingCharges < 0 ? 'ascent-green' : ''}>
                {cartState?.orderDetails?.shippingCharges > 0 ? (
                  <>
                    &#8377;{Number(cartState.orderDetails?.shippingCharges).toFixed(2)}{" "}
                  </>
                ) : (
                  "FREE"
                )}
              </div>
            </div>
          ) : null}
          { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && !cartState.orderPending && !cartState?.hasBundleProduct && (
          (!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) ? 
            <div 
              className={styles.selectGiftRow} 
              onClick={(e) => {
                e.stopPropagation()
              }} 
              style={{padding:'0px 16px 10px 16px'}}
            >
            <input
              id="selectAllGift"
              type="checkbox"
              onChange={(e) => {
                if(e.target.checked) {
                  GiftWrap("ALL", "APPLY")
                } else {
                  GiftWrap("ALL", "REMOVE")
                }
              }}
              disabled = {shippingstate?.orderBlock}
              style={{accentColor:'var(--secondary-color)', marginTop:'4px'}}
              checked={checkIfAllGiftWrap()}
            />
            <h3 className={styles.singleGiftText}>Select all for gift wrapping at ₹{getPriceForGiftWrap()}</h3>
            </div> :
            <div className='skeleton__text__small width30' style={{marginLeft:'16px'}}></div>
          )
          }
          {cartState.orderDetails?.lineItems?.length > 0 && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? (
            <div className={styles.orderItems}>
              {cartState.orderDetails?.lineItems?.map((item: any, index: any) => {                
                return(
                  <div 
                  key={index} 
                  className={styles.item}
                  style={{
                    borderBottom: 
                      (loginstate.cartItems?.length === index + 1)
                      ? 'none' 
                      : '1px solid var(--grey-lite)'
                  }}
                >
                  <div className="d-flex ml-15" style={{position: 'relative'}}>
                  <div className={styles.itemImage}>
                    {item?.customAttributes?.gift_wrap && <img src="/assets/images/svg/gift-ribbon.svg" style={{ zIndex: '10', position: 'absolute', left: '-6px', top: '-6px', transform: 'rotate(-45deg)' }} width={24} />}
                      {loginstate.sellerConfig.domainName === "myxtur.com" ?
                        <img src={item?.customAttributes?.image ?? item?.imageUrl} alt={item?.name} style={{ overflow: 'hidden', height: 'auto', border: item?.customAttributes?.gift_wrap ? '1px solid #DDAF6A': '' }} /> :
                        <img src={item?.imageUrl} alt={item?.name} style={{ overflow: 'hidden', height: 'auto', border: item?.customAttributes?.gift_wrap ? '1px solid #DDAF6A': '' }} />}
                    </div>


                    <div className={styles.itemDetails}>
                      <div className={styles.itemTitle}>{item?.name}</div>
                      {loginstate?.sellerConfig?.domainName === "everlasting.shop" && item?.customAttributes && (
                        <div className={styles.itemTitle}>{
                          Object.keys(item?.customAttributes).map((key, i) => (
                          (key !== 'For COD orders team will ask you for an advance payment of INR 200' && key !== 'contentId') ? `${item?.customAttributes[key]} / ` : ''
                          ))
                          }</div>                        
                        )
                      }
                      <div className={styles.itemQuantity}>
                        Qty: {item?.quantity}
                      </div>
                      {shippingstate?.selected_service_data?.nonDeliverableItems?.find((nonDeliverableItem : any)=>nonDeliverableItem?.variantId == item?.itemId) && 
                          <Alert type = "error" alertMessage = "Product unavailable at selected pincode"/>
                          }
                      {loginstate?.sellerConfig?.domainName === "etchcraftemporium.in" && item?.item_meta_data?.properties && (
                        Object.keys(item?.item_meta_data?.properties).map((key, i) => (
                            <div className={styles.itemTitle}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                        ))
                      )}
                      {
                        ((loginstate?.sellerConfig?.domainName === "happyprintclub.com" || 
                        loginstate?.sellerConfig?.domainName === "zoocase.in") && 
                        item?.customAttributes) && 
                        Object.keys(item?.customAttributes)?.length > 0 && (
                        Object.keys(item?.customAttributes)?.map((key, i) => {
                          if(custom_attributes_key_to_avoid.some(item => item.includes(key))){
                            return false
                          }else{
                            return(
                              <div className={styles.itemTitle}>{`${key}: "${item?.customAttributes[key]}"`}</div>
                            )
                          }
                        })
                      )}
                      { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && String(loginstate.sellerConfig?.giftWrapDetails?.variantId) !== String(item.itemId) &&  
                      !cartState.orderPending && !(loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) &&
                        !item?.customAttributes?.freebie && !cartState?.hasBundleProduct &&
                        ((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) ? 
                        <div className={styles.selectGiftRow} onClick={(e) => e.stopPropagation()}>
                        <input
                          id="selectGift"
                          type="checkbox"
                          onChange={(e) => {
                            if(e.target.checked) {
                              GiftWrap("PRODUCT", "APPLY", item.itemId)
                            } else {
                              GiftWrap("PRODUCT", "REMOVE", item.itemId)
                            }
                          }}
                          disabled = {shippingstate?.orderBlock}
                          style={{ accentColor: 'var(--secondary-color)', marginTop: '4px' }}
                          checked={item?.customAttributes?.gift_wrap ? true : false}
                        />
                        <h3 className={styles.singleGiftText}>Gift wrap at ₹{((loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap?1:item.quantity)*loginstate.sellerConfig?.giftWrapDetails?.price).toFixed(2)}</h3>
                      </div> :
                      <div className='skeleton__text__small' style={{width:'100px'}}></div>
                        )
                      }

                    </div>
                  </div>
                  <div className={styles.itemPrice}>
                    {item?.customAttributes?.freebie ? <span style={{color:'#238017'}}>FREE</span> : `₹${item?.price?.toFixed(2)}`}
                    {loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price && (item?.compareAtPrice > item?.price) &&
                    <span style={{ fontWeight: 400, color:'#909090', textDecoration:'line-through'}}>₹{item?.compareAtPrice?.toFixed(2)}</span>}
                  </div>
                </div>
                )
                
})}
            </div>
          ) : (
              <div className={styles.orderItems}>
                {loginstate.cartItems && loginstate.cartItems?.map((item: any, index: any) => (
                  <div key={index} className={styles.item} 
                  style={{
                    borderBottom: 
                      (loginstate.cartItems?.length === index + 1)
                      ? 'none' 
                      : '1px solid var(--grey-lite)'
                  }}
                >
                    <div className="d-flex ml-15">
                      <div className={styles.itemImage}>
                      {item?.customAttributes?.gift_wrap && <img src="/assets/images/svg/gift-ribbon.svg" style={{ zIndex: '10', position: 'absolute', left: '-6px', top: '-6px', transform: 'rotate(-45deg)' }} width={24} />}
                        <img src={item?.image || item?.imageUrl} alt={item?.name} style={{ overflow: 'hidden', height: 'auto', border: item?.customAttributes?.gift_wrap ? '1px solid #DDAF6A': '' }}/>
                      </div>
                      <div className={styles.itemDetails}>
                        <div className={styles.itemTitle}>{item?.title || item?.name}</div>
                        {loginstate?.sellerConfig?.domainName === "everlasting.shop" && item?.customAttributes && (
                          <div className={styles.itemTitle}>{
                          Object.keys(item?.customAttributes).map((key, i) => (
                            // <div className={styles.itemTitle}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                            key !== 'For COD orders team will ask you for an advance payment of INR 200' ? `${item?.customAttributes[key]} / ` : ''
                        ))
                        }</div>
                        )
                        }
                        <div className={styles.itemQuantity}>
                          Qty: {item?.quantity}
                        </div>
                          {shippingstate?.selected_service_data?.nonDeliverableItems?.find((nonDeliverableItem : any)=>nonDeliverableItem?.variantId == item?.itemId) && 
                          <Alert type = "error" alertMessage = "Product unavailable at selected pincode"/>
                          }
                        {loginstate?.sellerConfig?.domainName === "etchcraftemporium.in" && item?.item_meta_data?.properties  && (
                        Object.keys(item?.item_meta_data?.properties).map((key, i) => (
                            <div className={styles.itemTitle}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                        ))
                      )}
                      {((loginstate?.sellerConfig?.domainName === "happyprintclub.com" || 
                      loginstate?.sellerConfig?.domainName === "zoocase.in") 
                      && item?.customAttributes) && 
                       Object.keys(item?.customAttributes)?.length > 0 && (
                        Object.keys(item?.customAttributes)?.map((key, i) => {
                          if(custom_attributes_key_to_avoid.some(item => item.includes(key))){
                            return false
                          }else{
                            return(
                              <div className={styles.itemTitle}>{`${key}: "${item?.customAttributes[key]}"`}</div>
                            )
                          }
                        })
                      )}
                      { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && String(loginstate.sellerConfig?.giftWrapDetails?.variantId) !== String(item.itemId) 
                      && !cartState.orderPending && !(loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) && !item?.customAttributes?.freebie && !cartState?.hasBundleProduct &&
                        ((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) ? 
                        <div className={styles.selectGiftRow} onClick={(e) => e.stopPropagation()}>
                        <input
                          id="selectGift"
                          type="checkbox"
                          onChange={(e) => {
                            if(e.target.checked) {
                              GiftWrap("PRODUCT", "APPLY", item.itemId)
                            } else {
                              GiftWrap("PRODUCT", "REMOVE", item.itemId)
                            }
                          }}
                          disabled = {shippingstate?.orderBlock}
                          style={{ accentColor: '#6F57E9', marginTop: '4px' }}
                          checked={item?.customAttributes?.gift_wrap ? true : false}
                        />
                        <h3 className={styles.singleGiftText}>Gift wrap at ₹{(item.quantity*loginstate.sellerConfig?.giftWrapDetails?.price).toFixed(2)}</h3>
                      </div> :
                      <div className='skeleton__text__small' style={{width:'100px'}}></div>
                      )
                      }

                        {/* variants rendering */}
                        {/* {item.optionsArr.map((option: any) => (
                          <div className={styles.itemQuantity}>
                            {option.name}: {option.value}
                          </div>
                        ))} */}
                      </div> 
                    </div>
                    <div className={styles.itemPrice}>
                    {item?.customAttributes?.freebie ? <span style={{color:'#238017'}}>FREE</span> : `₹${item?.price?.toFixed(2)}`}
                    {loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price  &&  (item?.compareAtPrice > item?.price) &&
                     <span style={{ fontWeight: 400, color:'#909090', textDecoration:'line-through'}}>₹{item?.compareAtPrice?.toFixed(2)}</span> }
                    </div>
                  </div>
                ))}
              </div>
          )}
          {shippingstate?.selected_service_data?.nonDeliverableItems?.length && !props.showItems && !isProductPage &&
          <div style={{width : '90%',margin : 'auto',display : 'flex', flexDirection : 'column',gap : '8px',textAlign : 'center'}}>
            <span style={{fontWeight : '400',fontSize : '12px',lineHeight : '14px'}}>
            You can continue checkout without that item
            </span>
            <button
              className={!cartState?.cardDesignEnabled ? "modal_button primary"  : "modal_button_white"}
              onClick={()=>{
                if(props.isHeadless) {
                window.parent.postMessage(
                  {
                    trigger: "headless-redirect",
                    actions: [
                      {
                        action: "redirectTo",
                        data: {
                          url: `https://${loginstate.sellerConfig.domainName}/`,
                        },
                      },
                    ],
                  },
                  "*"
                );
              } else {
                window.parent.postMessage({ trigger: "headless-close" }, "*");
              }}} 
              style={{color :!cartState?.cardDesignEnabled ?  '#6F57E9' : 'var(--Primary-shade, #3173E3)',fontWeight : 500, cursor : 'pointer'}}>
              Edit items in cart
            </button>
          </div>
          }
        </div>
      ) : null
      }
      {/* {orderSummaryModalVisible && */}
      <OrderSummaryDrawer
      orderSummaryModalVisible = {props?.orderSummaryModalVisible}
      setOrderSummaryModalVisible = {props?.setOrderSummaryModalVisible}
      finalPriceLoginPage = {finalPriceLoginPage}
      GiftWrap = {GiftWrap}
      checkIfAllGiftWrap = {checkIfAllGiftWrap}
      getPriceForGiftWrap = {getPriceForGiftWrap}
      checkIfAnyGiftWrap={checkIfAnyGiftWrap}
      giftWrapLoading = {props?.giftWrapLoading}
      totalOrderDetailsMRP={totalOrderDetailsMRP}
      totalCartDetailsMRP={totalCartDetailsMRP}
      isOrderMRPGreater={isOrderMRPGreater}
      onRenderLoading={props?.onRenderLoading}
      />
      {/* } */}
      {cartState?.cardDesignEnabled && loginstate.sellerConfig?.giftWrapDetails?.enabled && checkIfAnyGiftWrap() && <img src="/assets/images/svg/gift-ribbon.svg" width={24} style={{position: "absolute", bottom: '-8px', left: '48%' }}/>}
    </div >  
    </>
  );
};
export default OrderSummary;

