import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";
import axios from "axios";
import Http from "../../service/Http";
import AppStorage from "../../service/AppStorage";
import { Store } from "../../service/StorageHelper";
import * as Sentry from "@sentry/react";

/* export const verifyOtp = (otp) => async (dispatch) => {
  dispatch({type: 'FETCH_OtpVerify_REQUEST'})
    const response = await HttpService.post(apiUrls.VERIFY_OTP(), otp)
    .then(response =>{
      dispatch({type: 'FETCH_OtpVerify_SUCCESS',payload: response.data})}
    ).catch(error=>{
      dispatch({type: 'FETCH_OtpVerify_FAILURE',payload: error.data})}
    );
}; */


const setCookie = (c_name, c_value, date) => {
  var exdate = new Date(date);
  exdate.setDate(exdate.getDate());
  document.cookie =
    encodeURIComponent(c_name) +
    '=' +
    encodeURIComponent(c_value) +
    '; expires=' +
    exdate.toUTCString()+';path=/;SameSite=None;Secure';
};

export const verifyOtp = (otpData, seller_id) => async (dispatch) => {
  dispatch({ type: "FETCH_OtpVerify_REQUEST" });
  try {
    const response = await Http.post(apiUrls.VERIFY_OTP(), otpData, seller_id);
    if (response.data.ok) {
      //AppStorage.setItem('pim_sid', response?.headers['pim-sid'])
      //AppStorage.setItem('expireTime', response?.headers['expires-at'])
      Store.setItem('pim_sid', response?.headers['pim-sid'])
      Store.setItem('expireTime', response?.headers['expires-at'])
      dispatch({
        type: "FETCH_OtpVerify_SUCCESS",
        payload: response.data.result,
        pim_sid: response?.headers["pim-sid"],
        refresh_token: response.headers['refresh-token']
      });
    }
  } catch (error) {
    dispatch({ type: "FETCH_OtpVerify_FAILURE", payload: error?.response?.data?.error?.message });
  //   if(typeof error?.response?.status === "number"){
  //     Sentry.withScope(scope => {
  //       scope.setExtras({
  //         request_headers: error?.config?.headers,
  //         error: error?.toJSON(),
  //         request_body: otpData,
  //         response_body: JSON.stringify(error?.response?.data)
  //       })
  //       Sentry.captureMessage(`Opt-Verify-Failure-${apiUrls.VERIFY_OTP()}`)
  //     })
  // }
  }
};

export const requestOtp = (otp) => async (dispatch) => {
  dispatch({ type: "SEND_Otp_REQUEST" });
  const response = await HttpService.post(apiUrls.REQUEST_OTP(), otp)
    .then((response) => {
      dispatch({ type: "SEND_Otp_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "SEND_Otp_FAILURE", payload: error });
    });
};

export const reSendOtp = (payload, seller_id) => async (dispatch) => {
  dispatch({ type: "SEND_Otp_REQUEST" });
  try {
    const response = await Http.post(apiUrls.RESEND_OTP(), payload, seller_id);
    if (response) {
      dispatch({ type: "SEND_Otp_SUCCESS", payload: response?.data });
    }
  } catch (error) {
    dispatch({ type: "SEND_Otp_FAILURE", payload: error?.response?.data?.error?.message });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers, 
          error: error?.toJSON(),
          request_body: payload,
          response_body: JSON.stringify(error?.response?.data)
        })
        Sentry.captureMessage(`Resend-OTP-Failure-${apiUrls.RESEND_OTP()}`)
      })
    }
  }
};

export const loginAfterFiveOtp = (data, header) => async (dispatch) => {
  dispatch({ type: "SEND_Otp_REQUEST" });
  try {
    const response = await axios.post(
      apiUrls.LOGIN_CHECK(),
      data,

      {
        headers: header,
      }
    );
    if (response.data.status) {
      //  dispatch({type:"AFTER_FIVE_ATTEMPT"})
    }
  } catch (e) {
    document.getElementById("mobile")?.removeAttribute("disabled");
  }
};
