import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";
import axios from "axios";
import Http from "../../service/Http";
import * as Sentry from "@sentry/react";

export const fetchAddress = () => async (dispatch) => {
  dispatch({ type: "FETCH_Address_REQUEST" });
  const response = await HttpService.get(apiUrls.GET_ADDRESS())
    .then((response) => {
      //filter out old address with order_id and no address_id,fname_id,lname_id,email_id in them
      // response.data.data.addresses = response.data.data.addresses.filter(
      //   function (el) {
      //     return el.hasOwnProperty("fname_id") && el.hasOwnProperty("lname_id");
      //   }
      // );
      dispatch({ type: "FETCH_Address_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "FETCH_Address_FAILURE", payload: error });
    });
};

export const addAddress = (payload) => async (dispatch) => {
  dispatch({ type: "ADD_Address_REQUEST" });
  const response = await HttpService.post(
    apiUrls.POST_ADD_ADDRESS(payload.channel_code),
    payload.newAddress
  )
    .then((response) => {
      dispatch(fetchAddress());
      dispatch({ type: "ADD_Address_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      let err = null;
      if (typeof error?.response?.data?.errors === "object") {
        err = error?.response?.data?.errors?.email?.[0];
      } else if (typeof error?.response?.data?.errors === "string") {
        err = error?.response?.data?.errors;
      }
      dispatch({ type: "ADD_Address_FAILURE", payload: error, err });
    });
};

export const addSilentUserAddress = (payload) => async (dispatch) => {
  dispatch({ type: "Silent_User_Address_REQUEST" });
  const response = await HttpService.post(
    apiUrls.ADD_ADDRESS_SILENT_CUSTOMER(),
    payload.newAddress
  )
    .then((response) => {
      dispatch({ type: "Silent_User_Address_SUCCESS", payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: "Silent_User_Address_FAILURE", payload: error });
    });
};

export const addNewUserAddress = (payload) => async (dispatch) => {
  dispatch({ type: "New_User_Address_REQUEST" });
  try {
    const response = await Http.post(
      apiUrls.ADD_NEW_USER_ADDRESS(), 
      payload
    );
    if (response.data.status) {
      dispatch({
        type: "New_User_Address_SUCCESS",
        payload: response.data.data,
      });
    }
  } catch (error) {
    dispatch({ type: "New_User_Address_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          error:error?.toJSON(),
          request_body: payload,
          response_body: JSON.stringify(error?.response?.data)

        })
        Sentry.captureMessage(`New_User_Address_FAILURE-${apiUrls.ADD_NEW_USER_ADDRESS()}`)
      })
    }
  }
};

export const getAddressList =
  (user_id, fetchAddressPermission, isAddressSavedCalled, seller_id, addedAddress, blockOldAddress) => async (dispatch) => {
    dispatch({ type: "FETCH_Address_REQUEST" });
    try {
      const response = await Http.getAddressWithHeaders(apiUrls.GET_ADDRESS_LIST(user_id, blockOldAddress), fetchAddressPermission, isAddressSavedCalled, seller_id);
      if (response.data.status) {
        let filteredAddresses =[]
        if(seller_id === "6333fb454f354c57324887c0"){
          filteredAddresses = response?.data?.data?.filter((address)=>address?.line1?.length > 3)
        }
        dispatch({ 
          type: "FETCH_Address_SUCCESS_After_Add_New", 
          payload: {
            addedAddress: addedAddress,
            data: seller_id !== "6333fb454f354c57324887c0" ? response?.data?.data : filteredAddresses 
          }
        });
      }
    if(response?.data?.data?.length === 0) {
      Sentry.withScope(scope => {
        scope.setExtras({
            response: JSON.stringify(response),
            added_address: addedAddress
          })
        Sentry.captureMessage(`Add-Returned-Zero`)
      })
    }
    } catch (error) {
      dispatch({ type: "FETCH_Address_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers, 
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON()
            })
          Sentry.captureMessage(`Fetch-all-address-failure-${apiUrls.GET_ADDRESS_LIST(user_id)}`)
        })
      }
    }
  };

  export const getAllAddressList =
  (user_id, fetchAddressPermission, isAddressSavedCalled, seller_id, blockOldAddress) => async (dispatch) => {
    dispatch({ type: "FETCH_All_Address_REQUEST" });
    try {
      const response = await Http.getAddressWithHeaders(apiUrls.GET_ADDRESS_LIST(user_id, blockOldAddress), fetchAddressPermission, isAddressSavedCalled, seller_id);
      if (response.data.status) {
        let filteredAddresses =[]
        if(seller_id === "6333fb454f354c57324887c0"){
          filteredAddresses = response?.data?.data?.filter((address)=>address?.line1?.length > 3)
        }
        dispatch({ type: "FETCH_All_Address_SUCCESS", payload: seller_id !== "6333fb454f354c57324887c0" ? response?.data?.data : filteredAddresses });
      }
    } catch (error) {
      dispatch({ type: "FETCH_All_Address_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers, 
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON()
            })
          Sentry.captureMessage(`Fetch-all-address-failure-${apiUrls.GET_ADDRESS_LIST(user_id)}`)
        })
      }
    }
  };

  export const getAddressListCache =
  (user_id, fetchAddressPermission, isAddressSavedCalled, seller_id, blockOldAddress,genderInAddressEnabled) => async (dispatch) => {
    dispatch({ type: "FETCH_Address_REQUEST" });
    try {
      const response = await Http.getAddressWithHeaders(apiUrls.GET_ADDRESS_LIST_CACHE(user_id, blockOldAddress), fetchAddressPermission, isAddressSavedCalled, seller_id);
      if (response.data.status) {
        let filteredAddresses =[]
        let NdaAddressList = response?.data?.data?.filter((address)=>address?.doubtfulAddress !== true)
        let selectedAddress = NdaAddressList?.length > 0 ? NdaAddressList[0] : response?.data?.data[0]
        let faultyAddress = NdaAddressList?.length > 0 ? false : response?.data?.data?.length > 0 ? true : false

        if(seller_id === "6333fb454f354c57324887c0"){
          filteredAddresses = response?.data?.data?.filter((address)=>address?.line1?.length > 3)
          NdaAddressList = filteredAddresses?.filter((address)=>address?.doubtfulAddress !== true)
          selectedAddress = NdaAddressList?.length > 0 ? NdaAddressList[0] : filteredAddresses[0]
          faultyAddress = NdaAddressList?.length > 0 ? false : filteredAddresses?.length > 0 ? true : false
        }

        dispatch({ type: "FETCH_Address_SUCCESS", payload:seller_id !== "6333fb454f354c57324887c0" ? response?.data?.data : filteredAddresses , cachedAddresses : true , selectedAddress : selectedAddress,faultyAddress :genderInAddressEnabled ? response?.data?.fetchGender ? true :  faultyAddress :faultyAddress});
      }
    } catch (error) {
      dispatch({ type: "FETCH_Address_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON(),
          })
          Sentry.captureMessage(`Cache-Address-Fail-${apiUrls.GET_ADDRESS_LIST_CACHE(user_id)}`)
        })
      }
    }
  };

  export const addExistingUserAddress =
  (payload, user_id, fetchAddressPermission, seller_id, blockOldAddress) => async (dispatch) => {
   dispatch({ type: "ADD_Existing_User_Address_REQUEST" });
    const response = await Http.post(apiUrls.ADD_EXISTING_USER_ADDRESS(user_id), payload, seller_id)
      .then((response) => {
        if(response.data.status){
          dispatch({ type: "ADD_Existing_User_Address_SUCCESS", 
          payload: {
            fetchAddressPermission: fetchAddressPermission,
            addedAddress: response.data.data
          }
           });
          if(fetchAddressPermission) {
            dispatch(getAddressList(user_id, fetchAddressPermission, true, seller_id, response.data.data, blockOldAddress))
          }
        }
        })
      .catch((error) => {
        dispatch({ type: "ADD_Existing_User_Address_FAILURE", payload: error });
        document.getElementById("addAddressBtn").removeAttribute("disabled");
        document.getElementById('addAddressBtn').innerHTML = 'Add address'
        if(typeof error?.response?.status === "number"){
          Sentry.withScope(scope => {
            scope.setExtras({
              request_headers: error?.config?.headers,
              error: error?.toJSON(),
              request_body: payload,
              response_body: JSON.stringify(error?.response?.data)

            })
            Sentry.captureMessage(`Add-existing-user-address-failure-${apiUrls.ADD_EXISTING_USER_ADDRESS(user_id)}`)
          })
        }
      });
  };

  export const updateAddress = (payload, user_id, seller_id,addressVerified) => async (dispatch) => {
    dispatch({ type: "Update_Address_REQUEST" });
    try {
      const response = await Http.put(apiUrls.ADD_EXISTING_USER_ADDRESS(user_id,addressVerified), payload, seller_id)
      if(response) {
        dispatch({ type: "Update_Address_SUCCESS", payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: "Update_Address_FAILURE", payload: error });

      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            error:error?.toJSON(),
            request_body: payload,
            response_body: JSON.stringify(error?.response?.data)
          })
          Sentry.captureMessage(`Update-Address-Failure-${apiUrls.ADD_EXISTING_USER_ADDRESS(user_id)}`)
        })
      }
    }
  }
