import { useEffect, useState, useRef } from 'react';

export function useOnScreen(ref) {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const observerRef = useRef(null);

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false

  useEffect(() => {
    if(isProductPage) {
        observerRef.current = new IntersectionObserver(([entry]) =>
        setIsOnScreen(entry.isIntersecting)
      );
    }
  }, []);

  useEffect(() => {
    if(isProductPage) {
        observerRef.current.observe(ref.current);

        return () => {
          observerRef.current.disconnect();
        };
    }
  }, [ref]);
  return isOnScreen;
}