import React, { useEffect } from "react";
import style from "./OrderPendingNew.module.css";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    dataLayer: any; 
  }
}
const OrderPendingNew = (props: any) => {
  const addressState = useSelector((state: any) => state.Address);
  const cartState = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const otpstate = useSelector((state:any )=> state.Otp);
  
  const displayCoupon = (couponName:string) => {
    if(couponName?.length > 20){
      return `${couponName?.slice(0, 20)}...`
    }else {
      return couponName
    }
  }

  let   billingAddressData = 
    typeof(cartState.orderDetails.shippingAddress)  === "object" ?  
    cartState.orderDetails.shippingAddress : 
    JSON.parse(cartState.orderDetails.shippingAddress);

    useEffect(() => {
      cartState.verifyingPayment = false
      window.scrollTo(0, 0);
    },[])

    console.log(`cart state order details`,cartState)
  return (
    <>
      <div className={style.wholeContainerCard}>
        <div className="card-container">
        <div className={style.topContainer}>
          <div className={style.orderPendingContainer}>
            <div className={style.subheading1}>Order pending!!</div>
            <div className={style.container1} style={{display:'flex', justifyContent:'center'}} >
              {`Order #${cartState?.orderDetails?.id} is pending confirmation.`}
            </div>
            <div className={style.container2} style={{display:'flex', justifyContent:'center'}} >
              {`We'll send the order confirmation to your registered phone number ${loginstate.mobileNumber ?? otpstate.mobile_no} once confirmed.`}
            </div>
          </div>
          <div
            style={{
              height: '1px',
              backgroundImage: 'linear-gradient(90deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1) 75%, transparent 75%, transparent 100%)',
              backgroundSize: '8px 1px',
              border: 'none',
              width : '100%'
            }}
          />
          <div className={style.orderDetailsContainer}>
            <div className="card-main-heading">
              Order details
            </div>
            <div className="d-flex space-between" style={{flexDirection : 'column',gap : '0px'}}>
              <label className={style.addressName} style={{color:'#222222'}}>
                  {
                  `
                  ${billingAddressData?.firstName ? billingAddressData?.firstName : 'Customer' } 
                  ${billingAddressData?.lastName ? billingAddressData?.lastName : '' }
                  ` 
                  }
              </label>
              {/* {(cartstate.orderFetched || shippingstate.orderBlock) && !shippingstate.serviceFetching && !cartstate.orderFetching &&
              <button
                type="button"
                className="btn-link border-dashed"
                onClick={changeShippingPreferences}
              >
                Change
              </button>
              } */}
              <label 
                className={style.addressDetail} 
                style={{
                  overflowWrap:'break-word', 
                  fontWeight:'400', 
                  color:'#222222'
                }}>
                {
                  `
                    ${billingAddressData?.line1 ? billingAddressData?.line1 : '' } 
                    ${billingAddressData?.line2 ? billingAddressData?.line2 : '' }
                    ${billingAddressData?.city ? billingAddressData?.city : '' }
                    ${billingAddressData?.state ? billingAddressData?.state : '' }
                    ${billingAddressData?.pincode ? billingAddressData?.pincode : '' }
                  `
                }
              </label>
            </div>
            <div className="d-flex align-v-center">
              <img 
              src={process.env.PUBLIC_URL + "./assets/images/shippingIcon.svg"}
              alt="shipping-icon"
              height={17}
              width={17}
              style={{marginRight : '4px'}}
              />
              <label 
                className={'card-address-details'} 
                style={{
                  overflowWrap:'break-word', 
                  fontWeight:'400', 
                  color:'#222222',
                  lineHeight : 'normal'
                }}>
              {shippingstate?.selected_service_data?.edd}  <a style={{color : "rgba(253, 85, 48, 1)"}}>
                (to be confirmed)
              </a>
              </label>
            </div>
          </div>
          <div
            style={{
              height: '1px',
              backgroundImage: 'linear-gradient(90deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1) 75%, transparent 75%, transparent 100%)',
              backgroundSize: '8px 1px',
              border: 'none',
              width : '100%'
            }}
          />
          <div style={{overflowY:"auto", transition:'all .5s', maxHeight : '150px'}}>
          {
            cartState?.orderDetails?.lineItems?.map((item: any, index: any) => (
              <div key={index} className={style.orderItemsCard}>
                  <div className="d-flex ml-15">
                  <div className={style.itemImage}>
                  {loginstate.sellerConfig.domainName === "myxtur.com" ? <img src={item?.customAttributes?.image ?? item?.imageUrl} alt={item?.name}/> : <img src={item?.imageUrl} alt={item?.name} /> }
                  </div>
                  <div className={style.itemDetails}>
                      <div className={style.itemTitleCard}>{item?.name}</div>
                      {loginstate?.sellerConfig?.domainName === "everlasting.shop" && item?.customAttributes && (
                      <div className={style.itemTitleCard}>{
                          Object.keys(item?.customAttributes).map((key, i) => (
                          (key !== 'For COD orders team will ask you for an advance payment of INR 200' && key !== 'contentId') ? `${item?.customAttributes[key]} / ` : ''
                          ))
                          }</div>                        
                      )
                      }
                      {loginstate?.sellerConfig?.domainName === "etchcraftemporium.in" && item?.item_meta_data?.properties && (
                      Object.keys(item?.item_meta_data?.properties).map((key, i) => (
                          <div className={style.itemTitleCard}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                      ))
                      )}
                      <div className={style.itemQuantityCard}>
                      Qty: {item?.quantity}
                      </div>
                      <div className={style.itemPriceCard} >
                      &#8377;{item?.price?.toFixed(2)}
                      </div>
                  </div>
                  </div>
              </div>
              ))
          }
          </div>

          <div style={{display : 'flex',flexDirection : 'column'}}>
          <div className={style.subHeading}>
          Bill summary
          </div>

          {cartState.orderDetails?.totalPrice ? (
          <div className={style.summaryLabelCard}>
              <div>Sub total</div>
              <div>&#8377;{
              Number(cartState.orderDetails?.totalPrice).toFixed(2)
              }</div>
          </div>
          ) : null}
  

          {(!loginstate.sellerConfig?.nativeShopifyCheckout) 
          && (
              <>
              {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) &&
              (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.map((couponCodeObj:any)=>{
                  return(
                  <div className={style.summaryLabelCard} style={{color : '#238017'}}>
                      <div>Coupon discount 
                      ({ displayCoupon(couponCodeObj?.discount_code)})
                      </div> 
                      <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                  )
              })
              )}
              {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0) &&
              (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.map((couponCodeObj:any)=>{
                  return(
                  <div className={style.summaryLabelCard} style={{color : '#238017'}}>
                      <div>Auto discount</div> 
                      <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                  </div>
                  )
              })
              )}
              </>
          )} 
          
          {cartState?.orderDetails?.shippingCharges  ? (
          <div className={style.summaryLabelCard}>
              <div>Delivery charges</div>
              <div className={cartState?.orderDetails?.shippingCharges < 0 ? 'ascent-green' : ''}>
              {cartState?.orderDetails?.shippingCharges > 0 ? (
                  <>
                  &#8377;{Number(cartState.orderDetails?.shippingCharges).toFixed(2)}{" "}
                  </>
              ) : (
                  "FREE"
              )}
              </div>
          </div>
          ) : null}
          <div
            style={{
              height: '1px',
              backgroundImage: 'linear-gradient(90deg, rgba(0, 0, 0, .1), rgba(0, 0, 0, .1) 75%, transparent 75%, transparent 100%)',
              backgroundSize: '8px 1px',
              border: 'none',
              width : '100%'
            }}
          />
          <div className={style.summaryTotalAmountCard}>
          <div>Total amount</div>
          {
          <div>
              &#8377;{
              (cartState.orderDetails?.totalAmount + cartState.orderDetails?.prepaidDiscount ?? 0).toFixed(2)
              } 
          </div>
          }
          </div>
          </div>
        </div>
        <div className="order-pending-note-container">
          <span style={{fontWeight : 500}}>Note:</span> For any questions, please feel free to contact our customer support. Thank you for shopping with us!
        </div>
        </div>
      </div>
    </>
  );
};

export default OrderPendingNew;
