import React, {useEffect, useState } from "react"
import { useSelector } from "react-redux";
import apiUrls from "../../api/Apis";
import Http from "../../service/Http";
import Footer from "../layout/Footer";
import NotificationScreen from "../NotificationScreen/NotificationScreen";  // no need to lazy load as part of main
import OrderPending from "../Pending/OrderPending";
import BillDeskOrderPlaced from "../BillDeskOrderPlaced/BillDeskOrderPlaced";
import Alert from "../Utils/Alert";
import Loader from "../Utils/Loader";
import AnalyticService from "../../service/analytics/AnalyticsService";
import WooCommerceOrderPlaced from "../WooCommerceOrderPlaced";
import { redirectionSuccess } from "../../utils/redirectionSuccess";
import { Store } from "../../service/StorageHelper";
import OrderPendingNew from "../PendingNew/OrderPendingNew";

import HeaderContainer from "../../HeaderModule/containers/HeaderContainer";

interface IProps {
    billDeskTransactionId: any
    fastrrSellerId: any
    onRender: any
    sellerDomain: any
}

const BillDeskVerfication = ({billDeskTransactionId, fastrrSellerId, onRender, sellerDomain} : IProps) => {    
    const UrlQueryStrings = window.location.search;
    let params = new URLSearchParams(UrlQueryStrings);
    const cartState = useSelector((state: any) => state.Cart);
    const loginstate = useSelector((state: any) => state.Login);
    const [paymentFailed, setPaymentFailed] = useState(false)
    const [orderState, setOrderState] = useState("")
    const [error, setError] = useState(false)
    const addressstate = useSelector((state: any) => state.Address);

    const [fetchingDetails, setFetchingDetails] = useState(true)
    const [fetchingConfig, setFetchingConfig] = useState(true)
    const [backgroundColor, setBackgroundColor] = useState('');
    // verify call giving error of user validation
    const [verifyUserValidationFail,setVerifyUserValidationFail] = useState(false)

    const isUpsell = params.get('is_upsell') ? params.get('is_upsell') : false
    const [configFetchedTried, setConfigFetchTried] = useState(Store.getItem('configfetchAfterRedirectionTried') ? true : false)

    let uId = encodeURI(btoa(sellerDomain));

    useEffect(() => {
        AnalyticService.initialiseGTM("GTM-TTW8J69");
    }, [])

    useEffect(() => {
      if(!cartState.isCtpCookieSet) {
        cartState.isCtpCookieSet = true
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: "fastrrCtpData",
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            },
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrOrderPlacedTimeCtp',
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrSellerId',
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
      }
    }, [])

    const addMinutes = (date: any, minutes: number) => {
      date?.setMinutes(date?.getMinutes() + minutes)
      return date;
    } 

    const marketingConfigKeyValue = (marketingConfig: any) => {
        const marketingData:any = {};
    
        const marketingConfigKey:any = {
          'contlo':'contlo', 
          'facebook': 'facebook', 
          'ga4':'ga4', 
          'gads':'gads', 
          'moengage': 'moengage', 
          'wigzo':'wigzo'
        };
        
        if(marketingConfig !== null &&  Object.keys(marketingConfig).length > 0){
          for (let [key, value] of Object.entries(marketingConfig)) {
            // console.log(key);
            // console.log(key === marketingConfigKey[key], key);
            // key === marketingConfigKey[key]
            const marketingKey =  key;
      
            if(key === marketingConfigKey[key]){
              if(marketingConfig[marketingKey] !== null && marketingConfig[marketingKey] !== undefined && Object.keys(marketingConfig[marketingKey]).length > 0){
                for (let [key, value] of Object.entries(marketingConfig[marketingKey])) {
                  if(key !== 'events'){
                    marketingData[`${marketingKey}_${key}`] = value;
                  }
                  if(key === 'events'){
                    {/*// @ts-ignore */}
                    marketingData[`${marketingKey}_${'order_placed'}`] = value?.order_placed;            
                  }          
                }
              }
            }
          }
        }
    
        return marketingData;

      }


    const marketConfigPairs = marketingConfigKeyValue(loginstate?.sellerConfig?.marketingConfig ? loginstate?.sellerConfig?.marketingConfig : null );

    
    let redirectPaymentInterval:any; 



    const paymentStatusPullingHandle = () => {
      cartState.fireRedirectPullingRequest = true;
      let timer = 50;
      
      redirectPaymentInterval = setInterval(() => {
          if (cartState.fireRedirectPullingRequest && timer) {
            cartState.fireRedirectPullingRequest = false;
            timer = timer - 1;
            fetchTransactionDetails(timer);
          }
        }, 3000);
        loginstate.redirectPullingInterval = redirectPaymentInterval;
    }


    const fetchSellerConfig = async () => {
        try {
            if(!loginstate.configFetched) {
              Store.setItem('configfetchAfterRedirectionTried','1')
            const response = await Http.getWithUid(apiUrls.SELLER_CONFIG(), uId);
            if(response) {
                Store.removeItem('configfetchAfterRedirectionTried')
                loginstate.configFetched = true
                loginstate.sellerConfig = response.data.data
                setFetchingConfig(false)
                response?.data?.data?.uiConfig?.header?.background_color && setBackgroundColor(response?.data?.data?.uiConfig?.header?.background_color)
                if(response?.data?.data?.cardDesign){
                  cartState.cardDesignEnabled = Store.getItem('isCardDesign') === '1' ? true : false;
                  onRender()
                }
            }
        }
        } catch (error) {
            loginstate.configFetched = true
            setFetchingConfig(false)
        }
    }

    const fetchTransactionDetails = async (timer:number) => {

        setFetchingDetails(true)
        try {
            let response = await Http.post(
                apiUrls.GET_UPI_PAYMENT_DETAILS(billDeskTransactionId, fastrrSellerId),
                {},
                fastrrSellerId
                );
                console.log("Response", response)

            if(response) {
                cartState.billdeskDetailsFetched = true
                cartState.orderDetails = response?.data?.data
                addressstate.selectedAddress = response?.data?.data?.shippingAddress
                //setFetchingDetails(false)
            }

            if(response?.data?.data?.paymentStatus?.toUpperCase() === "PENDING") {
                if(timer > 0){
                  cartState.fireRedirectPullingRequest = true;
                }else{
                  setOrderState("Pending")
                  setFetchingDetails(false)
                  loginstate.onPage = "payment"
                  cartState.orderPending = true;
                  onRender()
                }
            }

            if(response?.data?.data?.paymentStatus?.toUpperCase() === "SUCCESS") {

                AnalyticService.paymentSuccessBilldesk(
                    marketConfigPairs,
                    response.data?.data?.shippingAddress?.phone, 
                    {items: response.data.data.lineItems}, 
                    response.data?.data?.shippingAddress,
                    response.data?.data?.couponCode,
                    cartState?.orderDetails,
                    response.data?.data?.shippingTag,
                    response.data?.data?.paymentMethod
                  );

                if(response?.data?.data?.status?.toUpperCase() === "SUCCESS") {
                  cartState.fireRedirectPullingRequest = false;
                  cartState.fireSetuRequest = false;
                  clearInterval(redirectPaymentInterval);
                  
                  if(!cartState.isAfterSellCookieSet) {
                    cartState.isAfterSellCookieSet = true
              
                    if(!isUpsell && loginstate?.sellerConfig?.aftersellConfig?.isEnabled) {
              
                    let variantArray = response?.data?.data?.lineItems?.map((item: any) => {
                      return {
                        id: item?.productId,
                        variants: [item?.itemId],
                        quantity: item?.quantity
                      }
                    })
              
                    window.parent.postMessage(
                      {
                        trigger: 'set-cookie',
                        data: {
                          name: 'fastrrAfterSellData',
                          data: JSON.stringify({
                            phoneNo: response?.data?.data?.shippingAddress?.phone,
                            cartTotal: response?.data?.data?.prepaidPrice,
                            variantArray: variantArray
                          }),
                          time: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time)
                        }
                      },
                      "*"
                    )
              
                    window.parent.postMessage(
                      {
                        trigger: 'set-cookie',
                        data: {
                          name: 'fastrrOrderPlacedTime',
                          data: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time),
                          time: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time)
                        }
                      },
                      "*"
                    )
              
                    } else {
                      window.parent.postMessage(
                        {
                          trigger: 'set-cookie',
                          data: {
                            name: 'fastrrAfterSellData',
                            data: {},
                            time: 'Thu, 01 Jan 1970 00:00:00 UTC'
                          }
                        },
                        "*"
                      )
                
                      window.parent.postMessage(
                        {
                          trigger: 'set-cookie',
                          data: {
                            name: 'fastrrOrderPlacedTime',
                            data: {},
                            time: 'Thu, 01 Jan 1970 00:00:00 UTC'
                          }
                        },
                        "*"
                      )

                      window.parent.postMessage(
                        {
                          trigger: 'set-cookie',
                          data: {
                            name: 'isUpsellFastrr',
                            data: {},
                            time: 'Thu, 01 Jan 1970 00:00:00 UTC'
                          }
                        },
                        "*"
                      )
                    }
                  }
                    cartState.orderPlacedDetails = response?.data?.data
                    setOrderState("Success")
                    setFetchingDetails(false)
                    loginstate.onPage = "payment"
                    onRender()
                }

                if(response?.data?.data?.status?.toUpperCase() === "PENDING") {
                  if(timer > 0){
                    cartState.fireRedirectPullingRequest = true;
                    //have to do something
                    if(loginstate?.sellerConfig?.channel === "woocommerce"){
                      setOrderState("Success")
                    }
                  }else{
                    if(loginstate?.sellerConfig?.channel !== "woocommerce"){
                      setOrderState("Pending")
                    }
                    setFetchingDetails(false)
                    loginstate.onPage = "payment"
                    onRender()
                  }
                }

                if(response?.data?.data?.status?.toUpperCase() === "FAILED") {
                    setOrderState("Pending")
                    setFetchingDetails(false)
                    loginstate.onPage = "payment"
                    onRender()
                }

            }

            if(response?.data?.data?.paymentStatus?.toUpperCase() === "FAILED") {
                setPaymentFailed(true)
                setFetchingDetails(false)
                loginstate.onPage = "payment"
                onRender()
            }

            if (response?.data?.data?.clientOrderReturnUrl && (response?.data?.data?.status).toUpperCase() === "SUCCESS") {
                cartState.fireRedirectPullingRequest = false;
                cartState.fireSetuRequest = false;
                clearInterval(redirectPaymentInterval);
                cartState.orderCompleteUrl = response?.data?.data?.clientOrderReturnUrl;
                redirectionSuccess(response?.data?.data?.channel, response?.data?.data?.clientOrderReturnUrl)
              }

        } catch (error : any) {
          if(error?.response?.data?.error?.message?.includes("order doesn't belong to this")){
            setVerifyUserValidationFail(true)
          }else{
            if(timer > 0){
              cartState.fireRedirectPullingRequest = true;
            }else{
              cartState.billdeskDetailsFetched = true
              setError(true)
              setFetchingDetails(false)
            }
          }
        }
    }

    useEffect(() => {
      if(!cartState.billdeskDetailsFetched && loginstate.configFetched)
      {
          // fetchTransactionDetails()
          paymentStatusPullingHandle()
      }
  }, [loginstate.configFetched])

    useEffect(() => {
        if(!loginstate.configFetched && !configFetchedTried) {
            fetchSellerConfig()
        }else if(configFetchedTried){
          Store.removeItem('configfetchAfterRedirectionTried')
        }
    }, [])

    return (
        <div className="App" style={{boxShadow: params.get('seller-domain') ? "0 5px 5px #E8ECFF, 2px 2px 5px #E8ECFF" : "", overflow:"scroll",height : '100vh'}}>
         { !paymentFailed && orderState !== "Success" &&
        <div className={cartState?.cardDesignEnabled ? 'HeaderComponentCard' : "HeaderComponent"}>
                <div className={!cartState?.cardDesignEnabled ? "px-24 pt-24" : 'px-12'}  style={{backgroundColor:backgroundColor ? backgroundColor : 'var(--white)',paddingTop: loginstate.sellerConfig.domainName === "www.soni.fashion" ? 0 : !cartState?.cardDesignEnabled ? '24px' : '8px'}}>
                  {
                      <HeaderContainer cardDesignEnabled={(!cartState?.cardDesignEnabled)} isHeadless={params.get('seller-domain') && !params.get('isFastrrProduct')} onRender={onRender} onRenderLoading={onRender} fetchingDetails={fetchingDetails} />
                  }
          
            </div>
          </div>
        }
            {
                ((fetchingDetails || fetchingConfig || (!cartState.fireRedirectPullingRequest && fetchingConfig)) && orderState !== "Success" && !verifyUserValidationFail) && !configFetchedTried &&
                    <div
                        style={{
                            display: 'flex', 
                            justifyContent:'center',
                            alignItems:'center',
                            height:'90vh',
                            flexDirection:'column',
                            backgroundColor: !cartState?.cardDesignEnabled ? '#FFF' : 'var(--Background-colour, #f5f6fb)'
                        }}
                    >
                        <Loader />
                        <div style={{marginTop:'20px'}}> 
                            <Alert
                                alertMessage="Verifying payment...."
                                type="success"
                            />
                        </div>
                    </div>
            }
            
            {loginstate?.sellerConfig?.channel === "woocommerce" && orderState === "Success" && <WooCommerceOrderPlaced />}
            { !fetchingConfig && orderState === "Success" &&  loginstate?.sellerConfig?.channel !== "woocommerce" &&
                (<BillDeskOrderPlaced isHeadless={params.get("seller-domain") ? params.get("seller-domain") : null} marketConfigPairs={marketConfigPairs} />)
            }
            { !fetchingConfig && paymentFailed && 
                (<NotificationScreen image = 'paymentFailed' heading = "Payment Failed" subheading = "Your payment was not completed. Any amount if deducted will get refunded within 4-7 days.." ctaText="Back to Home"/>)}
            { (!fetchingConfig && orderState === "Pending" && !verifyUserValidationFail && !configFetchedTried) &&
            (!cartState?.cardDesignEnabled  ?
                <OrderPending />
                :
                <OrderPendingNew />
            )}
            {
              (verifyUserValidationFail || configFetchedTried)&& 
              <NotificationScreen 
                image = 'pending_icon' 
                heading = "Order pending" 
                subheading = "It can take upto 15 minutes for payment confirmation after which your order will be placed. If the order is not placed and money is deducted from your account, the amount will be fully refunded within 5 working days." 
                ctaText="Back to Home"
              />
            }
            { !fetchingConfig && error && 
                (<div style={cartState?.cardDesignEnabled ? {height : '90vh', display : 'flex'} : {}}><NotificationScreen image = 'server_error' heading = "Something Went Wrong" subheading = "Please try again.." ctaText="Back to Home"/></div>)}
            <Footer show = {true}/>
        </div>
    )
}

export default BillDeskVerfication