import React, { useEffect, useState ,useRef} from "react";
import styles from "./Input.module.css";
import clsx from "clsx";
import { Input as AntdInput } from "antd";
import FloatLabel from "../../../utils/FloatLabel";
import { useSelector } from "react-redux";

const { TextArea } = AntdInput;
interface InputField {
  type?: string;
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  value?: string | number;
  onChange?: any;
  onInput?: any;
  onBlur?: any;
  onFocus?: any;
  error?: any;
  loading?: boolean;
  disabled?: boolean;
  touched?: boolean;
  autoComplete?: any;
  lenError?: string;
  defaultValue?: any,
  maxLength?: any
  marginTop?: any
  ref? : any
  activeErrors? : any
  skippedArray ? : any
  setSkipErrorField ? : any
  mandatory ? : boolean
  skipPincodeFocus ? : boolean
  marginRight ? : any
  pasteNotAllowed?: boolean
  fontSize?: string
}

const Input = (props: InputField) => {
  const ref : any = useRef(null);
  const ref1 : any = useRef(null);
  const [touched, setTouched] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const cartstate = useSelector((state: any) => state.Cart);

  useEffect(() => {
    if (props.touched) {
      setTouched(true);
    }
  }, [props.touched]);

  const onBlur = () => {
    setTouched(true);
    setIsActive(false)
    if (props.onBlur != null) props.onBlur();
  };
  const onFocus = () => {
    setIsActive(true)
    if(props.skippedArray?.length > 0){
      props.setSkipErrorField(props.skippedArray)
    }
    // if (props.onFocus != null) props.onFocus();
  };

  return (
    <div className={clsx(!cartstate?.cardDesignEnabled ? styles.fieldContainer : styles.fieldContainerCard, props.className)} style={{marginTop: props.marginTop ? props.marginTop : "" , marginRight : props.marginRight ? props.marginRight : ''}}>
      <div
        className={clsx("field d-flex text-left", styles.field, {
          [styles.error]: touched && (props.error || props.activeErrors),
        })}
      >
        {cartstate?.cardDesignEnabled ? 
        <>
        {props.type == "textarea" ? (
          <div onClick={()=>{
            if(props.disabled){ 
              document.getElementById("pincode")?.focus()
            }
          }}
          style={{width:'100%'}}>
          {/* <h5 style={{fontFamily:'Metropolis', fontWeight:'400'}}>{props.mandatory && <span className={styles.asterisk}>*</span>}{props.label}</h5> */}
          <FloatLabel disabled = {props.disabled} label={props.label} value={props.value} mandatory = {props.mandatory}>
          <TextArea
            onWheel={(e: any) => {
              e.target.blur();
            }}
            id={props.id}
            name={props.name}
            //placeholder={props.label}
            className={styles.antInput}
            onInput={props.onInput}
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={props.value}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            autoSize = {{maxRows : 4}}
            style={{ fontFamily: "Metropolis", resize: "none", height: "96px",display : 'flex' }}
            onKeyPress={(e) => {if(e.key === "Enter") {e.preventDefault()}}}
            defaultValue={props.defaultValue}
            maxLength={props.maxLength && props.maxLength}
          />
          </FloatLabel>
          </div>
        ) : (
          <div onClick={()=>{
            if(props.name !== "state" && props.disabled ){
              document.getElementById("pincode")?.focus()
            }else if (props.name === "state" && !props.skipPincodeFocus){
              document.getElementById("pincode")?.focus()
            }
          }} 
          style={{display:'flex', flexDirection:'column', width:'100%'}}>
          {/* <h5 style={{fontWeight:'400', fontFamily:'Metropolis'}}>{props.mandatory && <span className={styles.asterisk}>*</span>}{props.label}</h5> */}
          <FloatLabel disabled = {props.disabled} label={props.label} value={props.value} mandatory = {props.mandatory}>
          <AntdInput
            onWheel={(e: any) => {
              e.target.blur();
            }}
            type={props.type}
            id={props.id}
            name={props.name}
            //placeholder={props.label}
            className={styles.antInput}
            onInput={props.onInput}
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={props.value}
            disabled={props.disabled}
            //autoComplete={props.autoComplete ? "on" : "off"}
            autoComplete={props.autoComplete}
            defaultValue={props.defaultValue}
            style={{fontFamily:'Metropolis'}}
            maxLength={props.maxLength && props.maxLength}
          />
          </FloatLabel>
          </div>
        )}
        </>
        :
        <>
        {props.type == "textarea" ? (
          <div onClick={()=>{
            props.disabled && document.getElementById("pincode")?.focus()
          }}
          style={{width:'100%'}}>
          <h5 style={{fontFamily:'Metropolis', fontWeight:'400'}}>{props.mandatory && <span className={styles.asterisk}>*</span>}{props.label}</h5>
          <textarea
            onWheel={(e: any) => {
              e.target.blur();
            }}
            id={props.id}
            name={props.name}
            //placeholder={props.label}
            className={styles.input}
            onInput={props.onInput}
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={props.value}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            style={{ fontFamily: "Metropolis", resize: "none", height: "96px" }}
            onKeyPress={(e) => {if(e.key === "Enter") {e.preventDefault()}}}
            defaultValue={props.defaultValue}
            maxLength={props.maxLength && props.maxLength}
          />
          </div>
        ) : (
          <div onClick={()=>{
            if(props.name !== "state" && props.disabled){
              document.getElementById("pincode")?.focus()
            }else if (props.name === "state" && !props.skipPincodeFocus){
              document.getElementById("pincode")?.focus()
            }
          }} 
          style={{display:'flex', flexDirection:'column', width:'100%'}}>
          <h5 style={{fontWeight:'400', fontFamily:'Metropolis'}}>{props.mandatory && <span className={styles.asterisk}>*</span>}{props.label}</h5>
          <input
            onWheel={(e: any) => {
              e.target.blur();
            }}
            type={props.type}
            id={props.id}
            name={props.name}
            placeholder={props.placeholder}
            className={styles.input}
            onInput={props.onInput}
            onChange={props.onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            value={props.value}
            disabled={props.disabled}
            //autoComplete={props.autoComplete ? "on" : "off"}
            autoComplete={props.autoComplete}
            defaultValue={props.defaultValue}
            style={{fontFamily:'Metropolis'}}
            maxLength={props.maxLength && props.maxLength}
            onPaste={(e) => {
              if(props.pasteNotAllowed) {
                e.preventDefault()
                return false
              }
            }}
          />
          </div>
        )}
        </>

        }

        {/* <label htmlFor={props.id} className={clsx("", styles.label, (props.value ? styles.labelFocus : ""))}>
          {props.label}
        </label> */}
        {props.loading ? (
          <div className={!cartstate?.cardDesignEnabled ? styles.spinnerContainer : styles.spinnerContainerCard} style={{marginTop:!cartstate?.cardDesignEnabled ? '18px' : ''}}>
            <span className={!cartstate?.cardDesignEnabled ? styles.spinner : styles.spinnerCard}></span>
          </div>
        ) : null}
      </div>
      {(props.activeErrors && isActive) ? 
      (
        props.label == "Flat, House no., Floor, Building" || props.label == "Area, Street, Sector, Village" ? (
          <span className={styles.errorSpanParent}>
            <span className={styles.errorSpan}>{props.activeErrors}</span>
            <span className={styles.errorSpanRight}>{props.lenError ? props.lenError: "0"}/{props.maxLength}</span>
          </span>
        ) : (
          <span className={styles.errorSpan}>{props.activeErrors}</span>
        )
      ) 
      :
      (touched) ? 
      (props.name !== "email") ?
          (props.error || props.activeErrors) && (
            props.label == "Flat, House no., Floor, Building" || props.label == "Area, Street, Sector, Village" ? (
              <span className={styles.errorSpanParent}>
                <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
                <span className={styles.errorSpanRight}>{props.lenError ? props.lenError: "0"}/{props.maxLength}</span>
              </span>
            ) : (
              <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
            )
          )
        : 
          (!isActive && (props.error || props.activeErrors)) &&
          (
            <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
          )
      : null 
      }
    </div>
  );
};
export default Input;
