import * as Sentry from "@sentry/react";
import moengage from "@moengage/web-sdk"
import { formatPhoneNumber } from "../../utils/commonUtils";


const createMoenGageEvent = (obj) => {
    window.parent.postMessage(
      {
        trigger: "Moengage",
        eventName : obj.event,
        data : {...obj.data,domain:JSON.stringify(window.location)}
      },
      "*"
    )
};




export const checkoutInitated = async (
  cartData,
  couponCode,
  userData,
  unique_id_field,
  ) => { 
      let obj = {
        event: "fastrr_checkout_initiated",
        data:{
          unique_id_value:formatPhoneNumber(userData?.mobile_number),
          phoneNo:formatPhoneNumber(userData?.mobile_number),
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element.sku,
              productId:element.productId,
              title: element.name,
              variantId:element.itemId,
              currency: 'INR',
              // item_varient
              price: element.price,
              discount:element.discount,
              quantity: element.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}
  

export const phoneEntered = async (
  phoneNo,
  cartData,
  couponCode,
  unique_id_field
  ) => { 
      let obj = {
        event: "fastrr_phone_entered",
        data:{
          unique_id_value:formatPhoneNumber(phoneNo),
          phoneNo:formatPhoneNumber(phoneNo),
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element.sku,
              productId:element.productId,
              title: element.name,
              variantId:element.itemId,
              currency: 'INR',
              // item_varient
              price: element.price,
              discount:element.discount,
              quantity: element.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}

export const otpVerified = async (
  phoneNo,
  cartData,
  couponCode,
  unique_id_field
  ) => { 
      let obj = {
        event: "fastrr_otp_verified",
        data:{
          unique_id_value:formatPhoneNumber(phoneNo),
          phoneNo:formatPhoneNumber(phoneNo),
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element.sku,
              productId:element.productId,
              title: element.name,
              variantId:element.itemId,
              currency: 'INR',
              // item_varient
              price: element.price,
              discount:element.discount,
              quantity: element.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}

export const landedOnOrderScreen = async (
  phoneNo,
  cartData,
  addressDetails,
  couponCode,
  orderDetails,
  paymentType,
  shippingstate,
  paymentMode,
  clientOrderId,
  partially,
  unique_id_field
  ) => { 
    const firstName=orderDetails?.shippingAddress?.firstName
    const lastName=orderDetails?.shippingAddress?.lastName 
    const email=orderDetails?.shippingAddress?.email
      let obj = {
        event: "fastrr_order_screen",
        data:{
          unique_id_value:unique_id_field=="email"?email:formatPhoneNumber(phoneNo),
          platformCustomerId:shippingstate.platformCustomerId,
          phoneNo:formatPhoneNumber(phoneNo),
          firstName,
          lastName,
          email,
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element.sku,
              productId:element.productId,
              title: element.name,
              variantId:element.itemId,
              currency: 'INR',
              // item_varient
              price: element.price,
              discount:element.discount,
              quantity: element.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}

export const paymentInitiated = async (
  phoneNo,
  cartData,
  addressDetails,
  couponCode,
  paymentMethod,
  pickrrTag,
  orderDetails, 
  shippingstate,
  unique_id_field
  ) => { 
    try{
    const firstName=orderDetails?.shippingAddress?.firstName
    const lastName=orderDetails?.shippingAddress?.lastName 
    const email=orderDetails?.shippingAddress?.email
    let obj = {
      event: "fastrr_payment_inititated",
      data:{
        unique_id_value:unique_id_field=="email"?email:formatPhoneNumber(phoneNo),
        platformCustomerId:shippingstate.platformCustomerId,
        firstName,
        lastName,
        email,
        payment_mode:paymentMethod?.toUpperCase(),
        phoneNo:formatPhoneNumber(phoneNo),
        cart_id:cartData?.id,
        promoCode: couponCode,
        discount:cartData?.totalDiscount,
        totalAmount:orderDetails?.prepaidPrice ? orderDetails?.prepaidPrice : orderDetails?.codPrice,
        items: JSON.stringify( orderDetails?.lineItems?.map((element) => {
          return {
            id: element.sku,
            productId:element.productId,
            title: element.name,
            variantId:element.itemId,
            currency: 'INR',
            // item_varient
            price: element.price,
            discount:element.discount,
            quantity: element.quantity,
          };
        }))
      }
    }
  
  createMoenGageEvent(obj);
    }catch(err){
      console.log("moengage",err)
    }
}

export const orderPlaced = async (
  phoneNo,
  cartData,
  addressDetails,
  couponCode,
  orderDetails,
  paymentType,
  pickrrTag,
  paymentMode,
  clientOrderId,
  partially,
  transaction_ID,
  shippingstate,
  unique_id_field
  ) => { 
    try{
    const firstName=orderDetails?.shippingAddress?.firstName
    const lastName=orderDetails?.shippingAddress?.lastName
    const email=orderDetails?.shippingAddress?.email
      let obj = {
        event: "fastrr_order_placed",
        data:{
          unique_id_value:unique_id_field=="email"?email:formatPhoneNumber(phoneNo),
          platformCustomerId:shippingstate.platformCustomerId,
          firstName,
          lastName,
          email,
          transaction_id: transaction_ID,
          payment_mode:paymentMode,
          phoneNo:formatPhoneNumber(phoneNo),
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element?.sku,
              productId:element?.productId,
              title: element?.name,
              variantId:element?.itemId,
              currency: 'INR',
              // item_varient
              price: element?.price,
              discount:element?.discount,
              quantity: element?.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}catch(err){

}
  
}

export const abandonCheckout = async (
  phoneNo,
  cartData,
  couponCode,
  abandonCartUrl,
  userData,
  unique_id_field
  ) => { 
      let obj = {
        event: "fastrr_abandon_checkout",
        data:{
          unique_id_value:formatPhoneNumber(userData?.mobile_number),
          abandon_cart_url:abandonCartUrl,
          phoneNo:formatPhoneNumber(phoneNo||userData.mobile_number),
          cart_id:cartData?.id,
          promoCode: couponCode,
          discount:cartData?.totalDiscount,
          totalAmount:cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
          items: JSON.stringify(cartData?.items?.map((element) => {
            return {
              id: element?.sku,
              productId:element?.productId,
              title: element?.name,
              variantId:element?.itemId,
              currency: 'INR',
              // item_varient
              price: element?.price,
              discount:element?.discount,
              quantity: element?.quantity,
            };
          }))
        }
      // console.info(obj);
  }
  createMoenGageEvent(obj);
}
  
  

