import { API_ENDPOINT } from "../Environment";

const BASEURLV1 = () => {
  return `${API_ENDPOINT.apiBaseUrlV1}`;
};

const VERIFY_SESSION = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/verify`;
};
const CUSTOMER_EXIST = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/exists`;
};

const REQUEST_OTP = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/auth/send`;
};

const VERIFY_OTP = () => {
  return `${API_ENDPOINT.apiIdentityService}authenticate/otp`;
};

const POST_CREATE_CART = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/checkout/initiate`;
};



const UPDATE_CART_SHIPPING_SPEEED = () => {
  //status:2 //update address
  return `${API_ENDPOINT.apiBaseUrlV2}customer/checkout/shipping`;
};

const GET_ADDRESS = () => {
  return `${API_ENDPOINT.apiBaseUrlV1}customer/user`;
};

const GET_SHIPPING = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/shipping-plans`;
};

const GET_PAYMENT_METHOD = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/payment-methods`;
};

// fetching Setu Link
const GET_UPI_LIST = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/pgs/upi`;
};

const GET_QR_CODE = () => {
  return `${API_ENDPOINT.apiBaseUrlV1}qr-code/generate`;
};

// sending Setu Link
const GET_PAYMENT_LINK = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/payment/upi-send`;
};

const GET_PAYMENT_STATUS = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/setu-payment-details`;
};

const GET_PINCODE = () => {
  return `${API_ENDPOINT.srApiBaseUrl}external/open/postcode/details`;
};

const POST_ADD_ADDRESS = (channelCode: any, channelID: any) => {
  //add address
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/customer/add`;
};

const GET_INTEGRATION_STATUS = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/integration`;
};

const UPDATE_PAYMENT = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/checkout/payment`;
};

const POST_COMPLETE_CHECKOUT = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/checkout/complete`;
};

const COUPON_APPLY = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/checkout/coupon`;
};

const GET_SETU_PAYMENT_DETAILS = (channelCode: any, channelID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/setu-payment-details`;
};



const UPDATE_TRANSACTION = (channelCode: any, channelID: any, orderID: any) => {
  return `${API_ENDPOINT.apiBaseUrlV1}${channelCode}/${channelID}/orders/${orderID}/update-transaction`;
};

const CREATE_SILENT_CUSTOMER = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer`;
};

const AUTH_TOKEN_SILENT_CUSTOMER = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/auth/token`;
};

const ADD_ADDRESS_SILENT_CUSTOMER = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/address`;
};

const LOGOUT_USER = () => {
  return `${API_ENDPOINT.apiBaseUrlV2}customer/logout`;
};

// Pickrr End-points
const LOGIN_CHECK = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/user/login`
}

const CREATE_GUEST_USER = () => {
  return `${API_ENDPOINT.apiIdentityService}session/create`;
};

const SELLER_CONFIG = () =>{
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/seller/config`
}

const ADD_EXISTING_USER_ADDRESS = (user_id: string,addressVerified ? : any)=>{
  return `${API_ENDPOINT.pickrrAggregatorApi+'api/ve1/aggregator-service/address/users/'+user_id}/addresses${addressVerified ? '?addressVerified=true' : ''}`
}

const ADD_NEW_USER_ADDRESS=()=>{
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/save/address`
}

const CREATE_CART_DETAILS = () => {
  return `${API_ENDPOINT.pickrrCartService}api/ve1/cart-service`;
}

const GET_ADDRESS_LIST = (user_id: string, blockOldAddress: any) => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/address/users/${user_id}/addresses?blockOldAddress=${blockOldAddress}`
}
const SERVICABILITY_STATUS = ()=>{
 return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/serviceability`
}
const RESEND_OTP = () => {
  return `${API_ENDPOINT.apiIdentityService}authenticate/resend_otp`
}
const FETCH_ORDERS = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/order`
}

const GET_UPI_PAYMENT_DETAILS = (txn_id: string, seller_id: string) => {
  return `${API_ENDPOINT.pickrrOrderApi}api/ve1/order-service/payment/verify?txn_id=${txn_id}&seller_id=${seller_id}`
}

const COD_ORDER_VERIFY = (order_id: string, seller_id: string) => {
  return `${API_ENDPOINT.pickrrOrderApi}api/ve1/order-service/payment/verify?txn_id=${order_id}&seller_id=${seller_id}`
}

const CREATE_UPI_ORDER = (txn_id: string, seller_id: string) => {
  return `${API_ENDPOINT.pickrrOrderApi}api/ve1/order-service/payment/verify?txn_id=${txn_id}&seller_id=${seller_id}&type=createOrder`
}

const COD_COMPLETE_PURCHASE = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/order`;
};

const SEND_PAYMENT_LINK = (order_id: any, txn_id: any, payment_link: any) => {
  return `${API_ENDPOINT.pickrrOrderApi}${order_id}/payment/${txn_id}/communication?paymentLink=${payment_link}`
}

const FIRE_EVENT = ()=>{
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/publish-event`
}

const COUPON_SUGGESTION = () => {
  return `${API_ENDPOINT.couponSuggestionBaseUrl}api/ve1/promotion-service/cart/suggest-coupons`
}

const GET_PAYMENT_METHODS = (seller_id: string) => {
  return `${API_ENDPOINT.paymentServiceBaseUrl}api/ve1/payment-aggregator/seller/config/${seller_id}/?api_version=v2`
}

const UPI_COLLECT_VERIFY=(upiId:string)=>{
  return `${API_ENDPOINT.paymentServiceBaseUrl}api/ve1/payment-aggregator/transaction/verify/vpa?vpa=${upiId}`
}

const RAZORPAY_CALLBACK_URL = () => {
  return `${API_ENDPOINT.paymentHandler}`
}

const FETCH_USER_DETAILS = (seller_id: any, user_id: any) => {
  return `${API_ENDPOINT.apiIdentityService}user/details?user_id=${user_id}&tenant_id=${seller_id}&add_profile=true`
}

const GET_ADDRESS_LIST_CACHE = (user_id: string, blockOldAddress: any) => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/address/users/${user_id}/addresses?cached=true&blockOldAddress=${blockOldAddress}`
}

const RESUME_CART = () => {
  return `${API_ENDPOINT.resumeCartService}`
} 

const DELETE_ADDRESS = (user_id: any, address_ID: any, phone: any) => {
  return `${API_ENDPOINT.pickrrAggregatorApi+'api/ve1/aggregator-service/address/users/'+user_id}/addresses/?isDelete=${true}`
}

const FETCH_USER_DETAILS_TRUECALLER = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/truecaller/user/login`
} 

const FETCH_UPSELL_PRODUCTS = (domain: any, ids: any) => {
  return `${API_ENDPOINT.couponSuggestionBaseUrl}api/ve1/promotion-service/fastrr-discount/fetch/aftersell-discounts`
}

const FETCH_CTP_LINK = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/order/cod/prepaid`
}

const FETCH_CTP_DATA = (order_id: any) => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/cod/prepaid/discount?order_id=${order_id}`
}

const GET_CARD_DETAILS = (number: any) => {
  return `${API_ENDPOINT.paymentServiceBaseUrl}api/ve1/payment-aggregator/transaction/fetch/iin/${number}?paymentPlatform=RAZORPAY`
}

const GET_CUSTOMER_CREDS = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/shopify/native/initiate`
}

const EXIT_FORM_SUBMIT = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/exit-checkout-form`
}

const VERIFY_TOKEN = () => {
  return `${API_ENDPOINT.apiIdentityService}api/ve1/seller-customer-token/ui/access-token/login/data`;
}

const CHECKOUT_COMPELTE = () =>{
  return `${API_ENDPOINT.apiIdentityService}api/ve1/seller-customer-token/ui/access-token/customer`;
}

const CUSTOM_CHECKOUT_CONFIG = () =>{
  return `${API_ENDPOINT.apiIdentityService}api/ve1/seller-customer-token/ui/access-token/checkout/data`;
}

const FETCH_ESTIMATED_DELIVRY = (pincode: string) => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/serviceability/${pincode}`
}

const CATALOGUE_SYNC = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/sync/category`
}

const FETCH_SHOPIFY_CREDENTIALS = () => {
  return `${API_ENDPOINT.pickrrAggregatorApi}api/ve1/aggregator-service/shopify-login`
}

const COMBINATION_API = () => {
  return `${API_ENDPOINT.edgeService}batch/api/v1`
}

const apiUrls = {
  VERIFY_SESSION,
  CUSTOMER_EXIST, // Done
  REQUEST_OTP, //Done
  VERIFY_OTP, //Done
  POST_CREATE_CART,
  GET_ADDRESS, //Done
  GET_SHIPPING,
  GET_PAYMENT_LINK, //Done
  GET_PINCODE, //Done
  POST_ADD_ADDRESS, //Done
  GET_UPI_LIST, //Done
  GET_PAYMENT_STATUS, //Not called Anywhere
  GET_QR_CODE, //Not called Anywhere
  GET_PAYMENT_METHOD,
  GET_INTEGRATION_STATUS,
  POST_COMPLETE_CHECKOUT,
  UPDATE_PAYMENT,
  UPDATE_TRANSACTION,
  COUPON_APPLY,
  UPDATE_CART_SHIPPING_SPEEED,
  GET_SETU_PAYMENT_DETAILS,
  BASEURLV1,
  CREATE_SILENT_CUSTOMER,
  AUTH_TOKEN_SILENT_CUSTOMER,
  ADD_ADDRESS_SILENT_CUSTOMER,
  LOGOUT_USER,
  LOGIN_CHECK,
  CREATE_GUEST_USER,
  SELLER_CONFIG,
  ADD_EXISTING_USER_ADDRESS,
  ADD_NEW_USER_ADDRESS,
  CREATE_CART_DETAILS,
  GET_ADDRESS_LIST,
  SERVICABILITY_STATUS,
  RESEND_OTP,
  FETCH_ORDERS,
  GET_UPI_PAYMENT_DETAILS,
  COD_COMPLETE_PURCHASE,
  SEND_PAYMENT_LINK,
  FIRE_EVENT,
  COUPON_SUGGESTION,
  GET_PAYMENT_METHODS,
  CREATE_UPI_ORDER ,
  RAZORPAY_CALLBACK_URL,
  FETCH_USER_DETAILS,
  GET_ADDRESS_LIST_CACHE,
  RESUME_CART,
  DELETE_ADDRESS,
  FETCH_USER_DETAILS_TRUECALLER,
  FETCH_UPSELL_PRODUCTS,
  FETCH_CTP_LINK,
  FETCH_CTP_DATA,
  COD_ORDER_VERIFY,
  GET_CUSTOMER_CREDS,
  VERIFY_TOKEN,
  CHECKOUT_COMPELTE,
  GET_CARD_DETAILS,
  CUSTOM_CHECKOUT_CONFIG,
  EXIT_FORM_SUBMIT,
  FETCH_ESTIMATED_DELIVRY,
  CATALOGUE_SYNC,
  FETCH_SHOPIFY_CREDENTIALS,
  COMBINATION_API,
  UPI_COLLECT_VERIFY
};

export default apiUrls;