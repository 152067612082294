var Delivery_initialstate = {
    loader: 1,
    fail: 0,
    spinner:false,
    pincode: '',
    Delivery_data: {},
    Pincode_data: {},
    wrongPincode: false,
    city: '',
    state: '', 
    tags: '',
    selectedTag: 'home',
    tagValue: ''
}

const Delivery_reducer = (state = Delivery_initialstate, action) =>{
    switch(action.type){
        case "FETCH_Delivery_REQUEST":
            return{
                ...state,
                loader:1,
                spinner:true
            }

        case "FETCH_Delivery_SUCCESS":
            return{
                ...state,
                Delivery_data: action.payload,
                spinner:false,
                loader:0
            }

        case "FETCH_Delivery_FAILURE":
            return{
                ...state,
                fail:1,
                spinner:false,
                loader:0
            }

            case "FETCH_Pincode_REQUEST":
                return{
                    ...state,
                    loader:1,
                    spinner:true,
                    wrongPincode: false
                }
    
            case "FETCH_Pincode_SUCCESS":
                return{
                    ...state,
                    Pincode_data: action.payload,
                    spinner:false,
                    loader:0,
                    city: action.payload.postcode_details?.city,
                    state: action.payload.postcode_details?.state
                }
    
            case "FETCH_Pincode_FAILURE":
                return{
                    ...state,
                    fail:1,
                    spinner: false,
                    loader:0,
                    // pincode: '',
                    wrongPincode: true,
                    city: '',
                    state: ''
                }
            
            case "Update_valid_cod_form_address":
                return{
                    ...state,
                    ValidCodFormAddress : action?.payload ?? false
                }
            case "Update_first_error_key_address":
                return{
                    ...state,
                    firstErrorKey : action?.payload
                }
            case "update_delivery_add_cod_form":
                return{
                    ...state,
                    ...action?.payload
                }

        default: return(state);
    }
};

export default Delivery_reducer;

