var Upi_initialstate = {
  loader: 1,
  fail: 0,
  Upi_data: {},

}

const Upi_reducer = (state = Upi_initialstate, action) =>{
  switch(action.type){
      case "FETCH_Upi_REQUEST":
          return{
              ...state,
              loader:1
          }

      case "FETCH_Upi_SUCCESS":
      return{
              ...state,
              Upi_data: action.payload,
              loader:0
          }

        

      case "FETCH_Upi_FAILURE":
          return{
              ...state,
              fail:1,
              loader:0
          }

      default: return(state);
  }
};

export default Upi_reducer;
