export const editIcon = (color) => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M5.0549 10.4731H7.05926C7.12148 10.4735 7.18315 10.4616 7.24075 10.438C7.29834 10.4145 7.35073 10.3799 7.3949 10.3361L10.6662 7.06102L12.0087 5.74722C12.053 5.70328 12.0882 5.65101 12.1122 5.59343C12.1362 5.53584 12.1486 5.47407 12.1486 5.41168C12.1486 5.34929 12.1362 5.28752 12.1122 5.22993C12.0882 5.17234 12.053 5.12007 12.0087 5.07614L10.0044 3.04873C9.96041 3.00443 9.90812 2.96927 9.85052 2.94528C9.79291 2.92129 9.73112 2.90894 9.66872 2.90894C9.60631 2.90894 9.54452 2.92129 9.48692 2.94528C9.42931 2.96927 9.37703 3.00443 9.33308 3.04873L7.99999 4.38616L4.71926 7.66121C4.67545 7.70537 4.64079 7.75774 4.61726 7.81532C4.59374 7.8729 4.58181 7.93455 4.58217 7.99675V10.0005C4.58217 10.1259 4.63198 10.2461 4.72063 10.3347C4.80929 10.4233 4.92953 10.4731 5.0549 10.4731ZM9.66872 4.05062L11.0065 5.38805L10.3353 6.05913L8.99745 4.7217L9.66872 4.05062ZM5.52763 8.19051L8.3309 5.38805L9.66872 6.72548L6.86545 9.52794H5.52763V8.19051ZM12.6185 11.4183H4.10945C3.98407 11.4183 3.86383 11.4681 3.77518 11.5567C3.68652 11.6453 3.63672 11.7656 3.63672 11.8909C3.63672 12.0162 3.68652 12.1364 3.77518 12.2251C3.86383 12.3137 3.98407 12.3635 4.10945 12.3635H12.6185C12.7439 12.3635 12.8642 12.3137 12.9528 12.2251C13.0415 12.1364 13.0913 12.0162 13.0913 11.8909C13.0913 11.7656 13.0415 11.6453 12.9528 11.5567C12.8642 11.4681 12.7439 11.4183 12.6185 11.4183Z"
        fill={color}
      />
    </svg>
  );
  return icon;
};

export const infoIcon = (color) => {
  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <g clip-path="url(#clip0_6239_115275)">
        <path
          d="M5.95898 3.79158H7.04232V4.87492H5.95898V3.79158ZM5.95898 5.95825H7.04232V9.20825H5.95898V5.95825ZM6.50065 1.08325C3.51065 1.08325 1.08398 3.50992 1.08398 6.49992C1.08398 9.48992 3.51065 11.9166 6.50065 11.9166C9.49065 11.9166 11.9173 9.48992 11.9173 6.49992C11.9173 3.50992 9.49065 1.08325 6.50065 1.08325ZM6.50065 10.8333C4.1119 10.8333 2.16732 8.88867 2.16732 6.49992C2.16732 4.11117 4.1119 2.16659 6.50065 2.16659C8.8894 2.16659 10.834 4.11117 10.834 6.49992C10.834 8.88867 8.8894 10.8333 6.50065 10.8333Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6239_115275">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );

  return icon;
};

export const timerIcon = (color) => {
  const icon = (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.15 4.95H6.05V2.75C6.05 2.60413 5.99206 2.46424 5.88891 2.36109C5.78577 2.25795 5.64587 2.2 5.5 2.2C5.35413 2.2 5.21424 2.25795 5.11109 2.36109C5.00795 2.46424 4.95 2.60413 4.95 2.75V5.5C4.95 5.64587 5.00795 5.78576 5.11109 5.88891C5.21424 5.99205 5.35413 6.05 5.5 6.05H7.15C7.29587 6.05 7.43576 5.99205 7.53891 5.88891C7.64205 5.78576 7.7 5.64587 7.7 5.5C7.7 5.35413 7.64205 5.21423 7.53891 5.11109C7.43576 5.00794 7.29587 4.95 7.15 4.95ZM5.5 0C4.4122 0 3.34884 0.322569 2.44437 0.926917C1.5399 1.53126 0.834947 2.39025 0.418665 3.39524C0.00238306 4.40023 -0.106535 5.5061 0.105683 6.57299C0.317902 7.63989 0.841726 8.6199 1.61091 9.38908C2.3801 10.1583 3.36011 10.6821 4.42701 10.8943C5.4939 11.1065 6.59977 10.9976 7.60476 10.5813C8.60975 10.1651 9.46874 9.4601 10.0731 8.55563C10.6774 7.65116 11 6.5878 11 5.5C10.9984 4.04181 10.4184 2.6438 9.3873 1.6127C8.3562 0.581605 6.95819 0.00162172 5.5 0ZM5.5 9.9C4.62976 9.9 3.77907 9.64194 3.05549 9.15846C2.33192 8.67498 1.76796 7.9878 1.43493 7.18381C1.10191 6.37981 1.01477 5.49512 1.18455 4.6416C1.35432 3.78809 1.77338 3.00408 2.38873 2.38873C3.00408 1.77338 3.78809 1.35432 4.6416 1.18454C5.49512 1.01477 6.37981 1.1019 7.18381 1.43493C7.9878 1.76796 8.67499 2.33191 9.15847 3.05549C9.64194 3.77907 9.9 4.62976 9.9 5.5C9.89867 6.66654 9.43467 7.78492 8.6098 8.60979C7.78493 9.43466 6.66654 9.89866 5.5 9.9Z"
        fill={color}
      />
    </svg>
  );
  return icon;
};

export const verifySeller = () => {
  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="33" viewBox="0 0 25 33" fill="none">
      <path d="M15.8844 24.8452L15.8844 24.8452L15.8783 24.8495L12.494 27.247L9.10974 24.8495L9.10977 24.8495L9.10362 24.8452C7.71035 23.8793 6.56072 22.5661 5.75885 21.0185C4.95738 19.4716 4.52881 17.7396 4.51264 15.9749V8.17019L12.494 4.98308L20.4754 8.17019V15.9748C20.4592 17.7396 20.0306 19.4716 19.2292 21.0185C18.4273 22.5661 17.2777 23.8793 15.8844 24.8452Z" stroke="#B1B1B1" stroke-width="1.47059"/>
      <path d="M7.18728 28.7675L7.18692 28.7673C6.18437 28.0517 5.26246 27.218 4.43921 26.2823L4.43826 26.2812C1.83212 23.3422 0.376651 19.4746 0.367647 15.4521V5.65356C0.367849 5.51138 0.409596 5.37418 0.48518 5.26051L0.179036 5.05694L0.485181 5.26051C0.560665 5.14699 0.665401 5.06345 0.783103 5.01794L0.783112 5.01796L0.787128 5.01635L12.2827 0.414605L12.2842 0.414019C12.4367 0.35219 12.6048 0.35219 12.7574 0.414021L12.7588 0.414581L24.2437 5.01414C24.3557 5.06384 24.4542 5.1486 24.5245 5.26062C24.5958 5.37423 24.634 5.50949 24.6323 5.64874H24.6323V5.65332V15.4459C24.6141 18.0822 23.9833 20.6732 22.7955 22.9937C21.6077 25.3145 19.8991 27.2937 17.8198 28.7606L17.8178 28.762L12.8689 32.3035L12.868 32.3041C12.7596 32.3822 12.6338 32.4238 12.5061 32.4262C12.3758 32.4269 12.2466 32.3866 12.1352 32.3082C12.1349 32.308 12.1346 32.3078 12.1343 32.3076L7.18728 28.7675ZM12.6473 0.754083L12.5106 0.699257L12.3739 0.753912L0.862694 5.35566L0.631518 5.44808V5.69704L0.631516 15.4525L0.631519 15.4536C0.642791 19.3761 2.0645 23.1524 4.61639 26.0255C5.42337 26.9413 6.3276 27.7577 7.31165 28.4587L7.31204 28.459L12.2975 32.0005L12.511 32.1522L12.7242 31.9998L17.6768 28.4594C17.6771 28.4592 17.6774 28.459 17.6777 28.4588C19.7126 27.0196 21.3807 25.082 22.5387 22.8154C23.6969 20.5485 24.3103 18.0201 24.3268 15.4494V15.4471V5.65332V5.40256L24.0933 5.31104L20.2347 3.79823L20.232 3.79716L12.6473 0.754083Z" stroke="#B1B1B1" stroke-width="0.735294"/>
      <path d="M12.1474 20.2716L8.22363 16.2382L8.95219 15.4567L12.0746 18.6648L16.7738 12.7842L17.5752 13.4892L12.1474 20.2716Z" fill="#B1B1B1"/>
    </svg>
  )
  return icon;
}


export const securePayment = () => {
  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="32" viewBox="0 0 43 32" fill="none">
      <path d="M11.646 22.1441C11.3048 22.339 11.3657 22.8506 11.7434 22.9846C11.914 23.0577 13.1933 23.1064 14.57 23.1064C16.6656 23.1064 17.1408 23.0699 17.3723 22.9115C17.7012 22.6801 17.7134 22.5705 17.3967 22.2538C17.1652 22.0223 16.9946 22.0101 14.5091 22.0101C12.9252 22.0101 11.7678 22.071 11.646 22.1441Z" fill="#495771"/>
      <path d="M19.7334 22.205C19.3923 22.4365 19.3923 22.6801 19.7334 22.9115C19.9649 23.0699 20.44 23.1064 22.5478 23.1064C24.0586 23.1064 25.1795 23.0577 25.3257 22.9846C25.4597 22.9115 25.5572 22.7288 25.5572 22.5583C25.5572 22.3877 25.4597 22.205 25.3257 22.1319C25.1795 22.0589 24.0586 22.0101 22.5478 22.0101C20.44 22.0101 19.9649 22.0467 19.7334 22.205Z" fill="#495771"/>
      <path d="M11.4498 26.1746C11.2549 26.3695 11.2671 26.5766 11.4986 26.808C11.6691 26.9786 12.3514 27.0029 17.2005 27.0029C23.1583 27.0029 23.0731 27.0151 22.9756 26.3695L22.939 26.0893L17.2736 26.0528C12.9606 26.0284 11.5595 26.065 11.4498 26.1746Z" fill="#495771"/>
      <path d="M27.995 0.0816851C27.7635 0.154773 22.0859 1.45817 15.3971 3.0052C8.69608 4.54005 2.98194 5.91654 2.68953 6.06271C1.65392 6.56215 0.947266 7.68283 0.947266 8.82787C0.947266 9.47348 1.20312 10.5576 1.34933 10.5576C1.41024 10.5576 6.24716 9.4613 12.0831 8.10917C17.9191 6.76923 24.7298 5.19784 27.2031 4.6375C29.6885 4.06498 31.8085 3.56554 31.9425 3.529C32.1253 3.48027 32.1496 3.395 32.0887 2.96866C31.906 1.84798 31.1628 0.763839 30.2733 0.300949C29.7129 0.0207787 28.6286 -0.0888538 27.995 0.0816851Z" fill="#B1B1B1"/>
      <path d="M24.8878 7.53473L17.0902 9.37411L13.7397 9.45937C10.9618 9.52028 10.2917 9.58119 9.7922 9.76391C9.21956 9.97099 8.30579 10.7384 8.04993 11.2135C7.95246 11.384 7.27018 11.5789 4.93091 12.1271C3.28612 12.5047 1.90936 12.8458 1.86063 12.8823C1.77534 12.9798 4.16334 23.4313 4.45575 24.1988C4.88218 25.356 6.11273 26.2696 7.23363 26.2696H7.61132L7.67224 27.4025C7.76971 29.12 8.33015 30.1676 9.53634 30.8132C10.0237 31.0812 10.0846 31.0812 18.3451 31.1178L26.6544 31.1543V30.7889V30.4234L18.467 30.3869C10.9618 30.3503 10.2308 30.3381 9.87748 30.1432C9.36577 29.8631 8.80532 29.1687 8.63475 28.5962C8.53728 28.2673 8.50073 25.7823 8.50073 20.1911C8.50073 13.3209 8.52509 12.188 8.68348 11.8104C8.95152 11.1769 9.1952 10.9333 9.90185 10.6044L10.511 10.3121H24.1446C37.6197 10.3121 37.7903 10.3121 38.302 10.5557C38.8746 10.8359 39.362 11.4693 39.5569 12.2002C39.6422 12.4925 39.6909 14.0395 39.6909 16.0616V19.4481H40.0564H40.4219V15.8789C40.4219 13.8325 40.3732 12.1271 40.3001 11.8713C40.0686 11.0429 39.4472 10.2877 38.6675 9.88572C37.9487 9.52028 37.9121 9.52028 35.7678 9.48374L33.5991 9.43501L33.4894 8.99648C33.4285 8.74068 33.2336 7.90017 33.0508 7.10838C32.8681 6.32878 32.7097 5.68317 32.7097 5.69535C32.6975 5.69535 29.1764 6.53586 24.8878 7.53473Z" fill="#B1B1B1"/>
      <path d="M34.116 15.8449C33.0914 16.043 32.2043 16.546 31.5008 17.2928C30.4456 18.4358 30.2315 19.1064 30.1244 21.4687L30.0479 23.5262L29.4056 23.6024C28.6256 23.6938 28.0292 24.1816 27.9068 24.8521C27.8609 25.1265 27.8304 26.6505 27.8609 28.2203L27.9068 31.1008L28.4115 31.5428L28.9162 32L35.1101 31.9543L41.3193 31.9086L41.6864 31.4513C42.0534 30.9941 42.0534 30.9484 42.0534 27.7631C42.0534 24.593 42.0534 24.5321 41.6864 24.1206C41.4264 23.8005 41.1511 23.6786 40.5852 23.6176L39.8359 23.5262L39.7441 21.3925C39.6982 19.8532 39.5912 19.0912 39.4076 18.6644C38.5206 16.6679 36.2113 15.4334 34.116 15.8449ZM36.0583 17.0794C37.0524 17.3233 38.123 18.4053 38.4441 19.5027C38.7653 20.539 38.7806 23.1909 38.4747 23.4348C38.3371 23.5567 37.2359 23.6024 34.8501 23.5719L31.4244 23.5262V21.4687C31.4244 19.5789 31.4549 19.3655 31.7914 18.8016C32.709 17.2471 34.2384 16.6222 36.0583 17.0794ZM35.8289 25.6447C36.3948 26.1628 36.5171 27.0316 36.1501 27.7174C35.9819 28.007 35.8289 28.6013 35.8136 29.0128C35.7525 30.1559 35.0489 30.6436 34.4066 30.0035C34.1925 29.7901 34.1007 29.5005 34.1007 29.0281C34.1007 28.6623 33.9937 28.1594 33.8713 27.9307C33.749 27.6869 33.6419 27.1992 33.6419 26.8334C33.6419 25.538 34.9419 24.8369 35.8289 25.6447Z" fill="#495771"/>
    </svg>
  )
  return icon;
}


export const assuredDelivery = () => {
  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34" fill="none">
      <g clip-path="url(#clip0_12_95522)">
        <path d="M23.9904 29.623C24.0433 29.6607 24.1057 29.6809 24.1696 29.6809C24.2334 29.6809 24.2959 29.6607 24.3488 29.623L26.512 28.0827C27.4307 27.4425 28.1864 26.5739 28.7126 25.5532C29.2387 24.5325 29.5193 23.391 29.5296 22.229V17.3076C29.5292 17.2439 29.5102 17.1818 29.4753 17.1299C29.4404 17.0779 29.3912 17.0386 29.3344 17.0171L24.2848 14.9904C24.2106 14.9602 24.1285 14.9602 24.0544 14.9904L19.0048 17.0171C18.9479 17.0386 18.8988 17.0779 18.8638 17.1299C18.8289 17.1818 18.81 17.2439 18.8096 17.3076V22.229C18.8198 23.391 19.1004 24.5325 19.6266 25.5532C20.1527 26.5739 20.9084 27.4425 21.8272 28.0827L23.9904 29.623Z" fill="#B1B1B1"/>
        <path d="M20.6495 1.49316V1.55059L17.1679 6.89761H17.1295H10.1279L13.6095 1.55059V1.49316H20.6495Z" fill="#B1B1B1"/>
        <path d="M17.1308 6.95488V14.3286L13.6108 11.6263L10.0908 14.3286V6.95488L10.1292 6.89746H17.1308V6.95488Z" fill="#B1B1B1"/>
        <path d="M10.1292 6.89746L10.0908 6.95488V6.89746H10.1292Z" fill="#B1B1B1"/>
        <path d="M25.7809 13.5414C25.6961 13.5414 25.6147 13.5059 25.5547 13.4425C25.4947 13.3792 25.4609 13.2932 25.4609 13.2037V6.89735C25.4609 6.80776 25.4947 6.72185 25.5547 6.6585C25.6147 6.59516 25.6961 6.55957 25.7809 6.55957C25.8658 6.55957 25.9472 6.59516 26.0072 6.6585C26.0672 6.72185 26.1009 6.80776 26.1009 6.89735V13.2037C26.1009 13.2932 26.0672 13.3792 26.0072 13.4425C25.9472 13.5059 25.8658 13.5414 25.7809 13.5414Z" fill="#495771"/>
        <path d="M19.3806 28.853H2.10063C1.84602 28.853 1.60184 28.7463 1.4218 28.5562C1.24177 28.3662 1.14063 28.1084 1.14062 27.8397V6.89746H1.78063V27.8397C1.78063 27.9293 1.81434 28.0152 1.87435 28.0785C1.93436 28.1419 2.01576 28.1775 2.10063 28.1775H19.3806V28.853Z" fill="#495771"/>
        <path d="M29.3001 14.6664H28.6601V2.6719L26.0425 7.09341C26.0137 7.14209 25.9736 7.18221 25.926 7.20998C25.8784 7.23776 25.8249 7.25228 25.7705 7.25216H1.4601C1.40172 7.25206 1.34448 7.23511 1.29458 7.20313C1.24467 7.17116 1.20401 7.12538 1.17698 7.07076C1.14996 7.01614 1.1376 6.95476 1.14125 6.89326C1.1449 6.83176 1.16442 6.77248 1.1977 6.72185L4.7177 1.31741C4.74527 1.26953 4.78368 1.22965 4.82949 1.20134C4.8753 1.17304 4.92705 1.15721 4.9801 1.15527H28.9801C29.065 1.15527 29.1464 1.19086 29.2064 1.25421C29.2664 1.31755 29.3001 1.40347 29.3001 1.49305V14.6664ZM2.0681 6.55972H25.5945L28.3945 1.83083H5.1401L2.0681 6.55972Z" fill="#495771"/>
        <path d="M24.1801 32.6225C24.0514 32.6235 23.9254 32.5835 23.8185 32.5077L20.7753 30.3189C20.1446 29.8664 19.5648 29.3395 19.0473 28.7482C17.4073 26.8893 16.4929 24.4451 16.4873 21.9049V15.8485C16.4875 15.7166 16.5259 15.588 16.5974 15.4799C16.6689 15.3718 16.7701 15.2894 16.8873 15.2439L23.9561 12.3998C24.1044 12.3394 24.2686 12.3394 24.4169 12.3998L31.4825 15.2439C31.5955 15.2932 31.6919 15.3772 31.7593 15.485C31.8266 15.5928 31.8617 15.7194 31.8601 15.8485V21.9015C31.8488 23.5662 31.4526 25.2028 30.7058 26.6692C29.9591 28.1355 28.8845 29.3872 27.5753 30.3155L24.5321 32.5043C24.4283 32.5794 24.3059 32.6205 24.1801 32.6225ZM24.1801 13.0314L17.1017 15.8755V21.9049C17.1085 24.2753 17.9634 26.5555 19.4953 28.2889C19.9803 28.8421 20.5235 29.3351 21.1145 29.7582L24.1801 31.947L27.2265 29.7582C28.4475 28.8904 29.4494 27.7212 30.1454 26.352C30.8413 24.9829 31.2102 23.4552 31.2201 21.9015V15.8485L28.8457 14.9129L24.1801 13.0314Z" fill="#495771"/>
        <path d="M23.9567 24.8844L21.5439 22.3916L21.9919 21.9086L23.9119 23.8913L26.8015 20.2568L27.2943 20.6926L23.9567 24.8844Z" fill="#495771"/>
      </g>
      <defs>
        <clipPath id="clip0_12_95522">
          <rect width="32" height="33.7778" fill="white" transform="translate(0.5)"/>
        </clipPath>
      </defs>
    </svg>
  )
  return icon;
}
