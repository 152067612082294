import React, { useEffect, useState ,useRef} from "react";
import styles from "./Select.module.css";
import clsx from "clsx";
import { Input as AntdInput, Select as AntdSelect } from "antd";

import FloatLabel from "../../../utils/FloatLabel";
import { useSelector } from "react-redux";
interface SelectField {
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  className?: string;
  value?: string | number;
  onChange?: any;
  onBlur?: any;
  onFocus?: any;
  error?: any;
  loading?: boolean;
  disabled?: boolean;
  touched?: boolean;
  lenError?: string;
  defaultValue?: any,
  maxLength?: any
  marginTop?: any
  activeErrors? : any
  skippedArray ? : any
  setSkipErrorField ? : any
  mandatory ? : boolean
  skipPincodeFocus ? : boolean
  marginRight ? : any
  children? : any
}

const Select = (props: SelectField) => {
  const ref : any = useRef(null);
  const ref1 : any = useRef(null);
  const [touched, setTouched] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const cartstate = useSelector((state: any) => state.Cart);

  useEffect(() => {
    if (props.touched) {
      setTouched(true);
    }
  }, [props.touched]);

  const onBlur = () => {
    setTouched(true);
    setIsActive(false)
    if (props.onBlur != null) props.onBlur();
  };
  const onFocus = () => {
    setIsActive(true)
    if(props.skippedArray?.length > 0){
      props.setSkipErrorField(props.skippedArray)
    }
    // if (props.onFocus != null) props.onFocus();
  };

  console.log(props,'select props')
  return (
    <div className={clsx(!cartstate?.cardDesignEnabled ? styles.fieldContainer : styles.fieldContainerCard, props.className)} style={{marginTop: props.marginTop ? props.marginTop : "" , marginRight : props.marginRight ? props.marginRight : ''}}>
      <div
        className={clsx("field d-flex text-left", styles.field, {
          [styles.error]: touched && (props.error || props.activeErrors),
        })}
      >
        {cartstate?.cardDesignEnabled ? 
                <div onClick={()=>{
                  props.disabled && document.getElementById("pincode")?.focus()
                }}
                style={{width:'100%'}}>
                {/* <h5 style={{fontFamily:'Metropolis', fontWeight:'400'}}>{props.mandatory && <span className={styles.asterisk}>*</span>}{props.label}</h5> */}
                <FloatLabel disabled = {props.disabled} label={props.label} value={props.value} mandatory = {props.mandatory}>
                 <select
                  className={styles.antSelect}
                  id={props.id}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  disabled = {props?.disabled}
                  onChange={(e : any)=>{props.onChange(e)}}
                  value={props.value}
                >
                  {props?.children}
                </select>
                </FloatLabel>
                </div>
                :
                <div onClick={()=>{
                  props.disabled && document.getElementById("pincode")?.focus()
                }}
                style={{width:'100%'}}>
                <h5 style={{fontFamily:'Metropolis', fontWeight:'400'}}>{<span className={styles.asterisk}>*</span>}Gender</h5>
                <select
                  className={styles.select}
                  id={props.id}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  disabled = {props?.disabled}
                  onChange={(e : any)=>{props.onChange(e)}}
                  value={props.value}
                >
                  {props?.children}
                </select>
                </div>
              }

        {/* <label htmlFor={props.id} className={clsx("", styles.label, (props.value ? styles.labelFocus : ""))}>
          {props.label}
        </label> */}
        {props.loading ? (
          <div className={!cartstate?.cardDesignEnabled ? styles.spinnerContainer : styles.spinnerContainerCard} style={{marginTop:!cartstate?.cardDesignEnabled ? '18px' : ''}}>
            <span className={!cartstate?.cardDesignEnabled ? styles.spinner : styles.spinnerCard}></span>
          </div>
        ) : null}
      </div>
      {(props.activeErrors && isActive) ? 
      (
        props.label == "Flat, House no., Floor, Building" || props.label == "Area, Street, Sector, Village" ? (
          <span className={styles.errorSpanParent}>
            <span className={styles.errorSpan}>{props.activeErrors}</span>
            <span className={styles.errorSpanRight}>{props.lenError ? props.lenError: "0"}/{props.maxLength}</span>
          </span>
        ) : (
          <span className={styles.errorSpan}>{props.activeErrors}</span>
        )
      ) 
      :
      (touched) ? 
      (props.name !== "email") ?
          (props.error || props.activeErrors) && (
            props.label == "Flat, House no., Floor, Building" || props.label == "Area, Street, Sector, Village" ? (
              <span className={styles.errorSpanParent}>
                <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
                <span className={styles.errorSpanRight}>{props.lenError ? props.lenError: "0"}/{props.maxLength}</span>
              </span>
            ) : (
              <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
            )
          )
        : 
          !isActive && 
          (
            <span className={styles.errorSpan}>{props.error ? props.error : props.activeErrors}</span>
          )
      : null 
      }
    </div>
  );
};
export default Select;
