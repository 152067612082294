import axios from "axios";

export const sendShiprocketCheckoutEvent = async(data) => {
    try {
        const payload = {
            etype: data?.name,
            et: new Date().toISOString(),
            ptype: data?.category ? data?.category : 'N/A',
            data: {
                env: 'PROD',
                payload: JSON.stringify({
                    ...(data?.payload && data?.payload),
                    site: data?.domain
                })
            }
        }
        const response = await axios.post(
            `https://events.pickrr.com/collect`,
            payload,
        );

    } catch (e) { }
}

export const sendUCEvent = (data) => {
    try {
        //send uc event
        window.parent.postMessage(
            {
                trigger : 'shiprocket-checkout-listener',
                actions: [
                {
                    action: "ucEventHandler",
                    data: {
                        eventName: data?.eventName,
                        payload: data?.payload
                    }
                }
                ]
            },
            '*'
        )
    } catch (e) {}
}
