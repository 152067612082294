const { gtag, install } = require("ga-gtag");

let clientDetails = {
  id: "",
  purchase_conversion: "",
};

const initialiseGoogleAds = (clientGoogleAdsDetails?: any) => {
  if (clientGoogleAdsDetails) {
    clientDetails["id"] = clientGoogleAdsDetails?.conversion_id;
    clientDetails["purchase_conversion"] =
      clientGoogleAdsDetails?.conversion_label;

    gtag("config", clientDetails.id, {
      allow_enhanced_conversions: true,
      cookie_flags: "max-age=7200;secure;samesite=none",
    });
  }
};


// not being called from anywhere at this juncture--prashant
const trackPurchase = (userData: any, cartData: any) => {
  if (clientDetails?.id?.length > 0) {
    gtag("set", "user_data", {
      email: userData.email,
      phone_number: userData.mobile_number,
    });
    console.log({
      send_to: clientDetails.id + "/" + clientDetails.purchase_conversion, // 'AW-814217828/MrBsCL6CjIADEOT0n4QD'
      value: cartData["final_total"],
      currency: cartData["currency"],
      transaction_id : cartData['id']
    })
    gtag("event", "conversion", {
      send_to: clientDetails.id + "/" + clientDetails.purchase_conversion, // 'AW-814217828/MrBsCL6CjIADEOT0n4QD'
      value: cartData["final_total"],
      currency: cartData["currency"],
      transaction_id : cartData['id']
    });
  }
};
const GoogleAdSense = {
  trackPurchase,
  initialiseGoogleAds,
};
export default GoogleAdSense;
