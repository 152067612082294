import style from "./NoInternet.module.css";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { channels } from "../../utils/constants";
import { sendShiprocketCheckoutEvent } from "../../utils/eventCollectors";
import NotificationScreen from "../NotificationScreen/NotificationScreen";

const ServerErr = ({
  isHeadless,
  redirectUrl = "",
  platform, 
  errorMessage,
  domainName
}: {
  isHeadless: boolean;
  redirectUrl?: string;
  platform: string,
  errorMessage?:string
  domainName?: string
}) => {
  const loginState = useSelector((state: any) => state.Login);
  const cartstate = useSelector((state: any) => state.Cart);
  const cart = useSelector((state: any) => state.Cart);
  const { channel } = cart?.orderDetails;

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);

  let userProfileId:any = params.get('user_profile_id') ? params.get('user_profile_id') : null;
  let cartId:any = params.get('cart_id') ? params.get('cart_id') : null;
  let fastrrStatus = params.get('fastrr_status') != null ? params.get('fastrr_status') : null;

  loginState.onLoginPage = false; //if any Api breaks then enable change number button in exit checkout modal
  const refreshIframe = () => {
    var iframe = document.getElementById("headless-iframe") as HTMLImageElement;
    if (iframe) {
      iframe!.src = iframe!.src;
    } else {
      window.location.reload();
    }
  };

  let customCheckout:string;
  platform === "CUSTOM"
  ?  customCheckout = "CUSTOM"
  : customCheckout = ""


  if (!loginState.redirectEventFired) {
    loginState.redirectEventFired = true;
    if(loginState?.sellerConfig?.walletThresholdBreach){
      sendShiprocketCheckoutEvent({
        name: 'NATIVE_REDIRECT_THRESHOLD_BREACHED',
        domain:loginState.sellerConfig?.domainName, 
        payload :{
          "otpLess":loginState?.sellerConfig?.enabledFeatureCodForm
        }
    })
    }
    else
    Sentry.captureMessage(`Redirection to ${customCheckout ? 'custom' : platform} Native - Checkout`);
  }

  useEffect(() => {
    let cartData = ""
    if(cartstate?.Cart_data?.items) {
      for (let item of cartstate?.Cart_data?.items?.length) {
        cartData = cartData + item?.itemId + ':' + item?.quantity + ','
      }
    }
    
    const modifiedRedirectUrl = (fastrrStatus && cartId && userProfileId) ? `http://${domainName}/cart/${cartData}` : redirectUrl
      
    if ((channels.shopify === platform && !customCheckout)) {
      //for Shopify
      const trimmedUrl = modifiedRedirectUrl[modifiedRedirectUrl?.length - 1] === "," ? modifiedRedirectUrl?.slice(0, modifiedRedirectUrl?.length - 1) : modifiedRedirectUrl
      const url = trimmedUrl?.includes('?') ? `${modifiedRedirectUrl}&attributes[fastrr_redirect]=1`
        : `${modifiedRedirectUrl}?attributes[fastrr_redirect]=1`

      window.parent.postMessage(
        {
          trigger: "headless-redirect",
          actions: [
            {
              action: "redirectTo",
              data: {
                url: domainName === "ranbazaar.com"  ? "https://ranbazaar.com" : url
              },
            },
          ],
        },
        "*"
      );
    }

    if ((channels.wooCommerce === platform || customCheckout)) {
      //for WOOCOMMERCE
      if(!customCheckout){
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: modifiedRedirectUrl?.includes('?') ? `${modifiedRedirectUrl}&attributes[fastrr_redirect]=1` : `${modifiedRedirectUrl}?attributes[fastrr_redirect]=1`,
                },
              },
            ],
          },
          "*"
        );
      }else {
        if(customCheckout){
          window.parent.postMessage(
            {
              trigger: "headless-redirect",
              actions: [
                {
                  action: "redirectTo",
                  data: {
                    url: modifiedRedirectUrl
                  },
                },
              ],
            },
            "*"
          );
        }else {
          setTimeout(() => {
            window.parent.postMessage({ trigger: "headless-close" }, "*");
          }, 3000)
        }
        
        
      }
    }
  }, []);

  return (
    <>
      <div
        className={
          !isHeadless ? style.wholeContainer : style.wholeContainerHeadless
        }
      >
        {/* <img
          src={
            process.env.PUBLIC_URL + "./assets/images/server_error.svg"
          }
          alt="server Error"
        /> */}
        {(fastrrStatus && loginState?.batchAPI) ?
          <div className="App" style={{ boxShadow: params.get('seller-domain') ? "0 5px 5px #E8ECFF, 2px 2px 5px #E8ECFF" : "", overflow: "scroll", height: '100vh', background: '#FFF' }}>
            <NotificationScreen image='paymentFailed' heading="Payment Failed" subheading="Your payment was not completed. Any amount if deducted will get refunded within 4-7 days.." ctaText="Back to Home" />
          </div> : 
          <p className="h3 mb-10">{
          errorMessage 
          ? errorMessage 
          : customCheckout ? 'Something went wrong...' : 'Taking you to checkout...'}</p>
        }
        {/* <p className={clsx(style.maxWidth60per, "h4 grey-medium mb-20")}>
          We're looking into it, Please wait for a few moments and try again
        </p>
        <button className="btn-grey-outline" onClick={() => refreshIframe()}>
          Try Again
        </button> */}
      </div>
    </>
  );
};
export default ServerErr;
