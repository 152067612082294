import style from "./NoInternet.module.css";
import clsx from "clsx";
const NoInternet = ({isHeadless} : {isHeadless: boolean}) => {
  const refreshIframe = () => {
    var iframe = document.getElementById("headless-iframe") as HTMLImageElement;
    if (iframe) {
      iframe!.src = iframe!.src;
    } else {
      window.location.reload();
    }
  };

  return (
    <>
      <div className={!isHeadless ? style.wholeContainer : style.wholeContainerHeadless}>
        <p className="h3 mb-10">No Internet Connection</p>
        <p className={clsx(style.maxWidth60per, "h4 grey-medium mb-20")}>
          Check your connection then refresh the page
        </p>
        <button className="btn-grey-outline" onClick={() => refreshIframe()}>
          Refresh
        </button>
      </div>
    </>
  );
};
export default NoInternet;
