import { useCallback, useEffect, useState } from "react";
import style from "./address.module.css";
import AddressList from "./addressList";
import { useDispatch, useSelector } from "react-redux";
import Delivery from "../Delivery/Delivery";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { getAddressList, getAllAddressList } from "../../Redux/Address/add_actions";
import Loader from "../Utils/Loader";
import Alert from "../Utils/Alert";
import { render } from "react-dom";
import EditAddress from "../EditAddress/EditAddress";
import DeleteModal from "../DeleteModal/DeleteModal";
import Http from "../../service/Http";
import apiUrls from "../../api/Apis";
import { CallTracker } from "assert";
import { Store } from "../../service/StorageHelper";
import DeliveryNew from "../DeliveryNew/DeliveryNew";
import EditAddressNew from "../EditAddressNew/EditAddressNew";

const Address = (props: any) => {
  const [spinner, setSpinner] = useState(false);
  const [isAddressSelected, setIsAddressSelected] = useState(true);
  const [newAddress, setNewAddress] = useState(false);
  const [editAddress, setEditAddress] = useState(false)
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [deleteID, setDeleteID] = useState("")
  const [deletingAddress, setDeletingAddress] = useState(false)
  const deliverystate = useSelector((state: any) => state.Delivery);
  const [activeMenuIndex, setActiveMenuIndex] = useState<any>('')
  // const cartItems: cartModel = React.useContext(UserContext);
  const dispatch = useDispatch();
  const addressstate = useSelector((state: any) => state.Address);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const loginstate = useSelector((state: any) => state.Login);
  const otpstate = useSelector((state: any) => state.Otp);
  const cartState = useSelector((state: any) => state.Cart);
  const couponState = useSelector((state: any) => state.Coupon);
  let addressData: any = addressstate.Address_data;
  let addressDataLength = addressstate.Address_data?.length;
  const [editAddressData, setEditAddressData] = useState({})
  useEffect(() => {
    dispatch(getAddress);
  }, []);
  const getAddress = async () => {
    setSpinner(true);
  };


  useEffect(() => {
    cartState.fireSetuRequest = false
  },[])

  const selectAddress = () => {
    setIsAddressSelected(false);
    props.onClose();
  };

  useEffect(() => {
    if(!addressstate.allAddressFetched) {
      dispatch(getAllAddressList(loginstate.userInfo?.user_id, otpstate.fetchAddressPermission, addressstate.isAddressSavedCalled, loginstate.sellerConfig.seller_id, loginstate.sellerConfig.blockOldAddress));
    }
  }, [addressstate.allAddressFetched])

  useEffect(() => {
    if(addressDataLength === 0 && addressstate.allAddressFetched) {
      setNewAddress(true)
    }
  }, [addressDataLength, addressstate.allAddressFetched])

  useEffect(() => {
    console.log("Okays", deleteID)
  }, [deleteID])

  const deleteAddress = async () => {
    try {
      setDeletingAddress(true)
      const response = await Http.put(apiUrls.DELETE_ADDRESS(loginstate.userInfo.user_id, deleteID, loginstate.mobileNumber), {addressID: deleteID }, loginstate?.sellerConfig?.seller_id)
      if(response) {

        addressstate.allAddressFetched = false
        shippingstate.serviceFetched = false
        cartState.orderFetched = false
        setDeleteVisible(false)
        loginstate.isAnyModalOpen = false
        setDeletingAddress(false)
        setDeleteID("")
        AnalyticService.additionalEvents(
          "address-deleted",
          cartState?.Cart_data,
          couponState.couponCode, 
          Store.getItem('refreshToken') ? true : false,
          false
        );
      }
      setActiveMenuIndex('')
    } catch (e) {
      setDeletingAddress(false)
      setActiveMenuIndex('')
    }
  }

  function mobileNoInternationalFormat(number: string) {
    const firstPart = number.slice(0,5);
    const secondPart = number.slice(5);
    return `+91 ${firstPart} ${secondPart}`
  }

  useEffect(()=>{
    if(loginstate.otpSkip){
      setEditAddress(true)
      setEditAddressData(addressstate?.selectedAddress)
    }
  },[loginstate?.otpSkip])

  const renderContent = () => {
    if(newAddress) {
      return (
        <>
        {(loginstate?.sellerConfig?.landmarkMandatory || !loginstate?.sellerConfig?.addressLayoutNew) ?
        <Delivery
          SetIsOpen={props.SetIsOpen}
          setAddressToShow={props.setAddressToShow}
          onRender={props.onRender}
          onRenderLoading={props.onRenderLoading}
        />
        :
        <DeliveryNew
        SetIsOpen={props.SetIsOpen}
          setAddressToShow={props.setAddressToShow}
          onRender={props.onRender}
          onRenderLoading={props.onRenderLoading}
        />
        }
        </>
      )
    } else if(editAddress) {
      return (
        <>
        {(loginstate?.sellerConfig?.landmarkMandatory || !loginstate?.sellerConfig?.addressLayoutNew) ?
        <EditAddress
          SetIsOpen={props.SetIsOpen}
          setAddressToShow={props.setAddressToShow}
          onRender={props.onRender}
          onRenderLoading={props.onRenderLoading}
          setEditAddress={setEditAddress}
          editAddressData={editAddressData}
        />
        :
        <EditAddressNew
          SetIsOpen={props.SetIsOpen}
          setAddressToShow={props.setAddressToShow}
          onRender={props.onRender}
          onRenderLoading={props.onRenderLoading}
          setEditAddress={setEditAddress}
          editAddressData={editAddressData}
        />
        }
        </>
      )
    } else {
      return (
        <div className={style.addressContainer}>
          <p className="mb-18 h3 light">
            Including addresses related to <b>{mobileNoInternationalFormat(loginstate.mobileNumber)}</b> where Shiprocket has previously delivered orders.

          </p>
          <div className={cartState?.cardDesignEnabled ? 'd-flex' : "d-flex space-between"} style={{marginBottom : cartState?.cardDesignEnabled ? '8px' : '', justifyContent : cartState?.cardDesignEnabled ? 'flex-end' : ''}}>
            {!cartState?.cardDesignEnabled && <div className="h2 semi-bold">All addresses</div>}
            {
              otpstate.fetchAddressPermission &&
              (!cartState?.cardDesignEnabled ?
              <div className="btn-link semi-bold" onClick={newAddressHandler}>
                + Add new
              </div>
               : 
               <button 
               className="card-primary-button btn-link"
               onClick={newAddressHandler}
               >
                Add new address
               </button>
              )
            }
          </div>
          {AddressContent}
          {!cartState?.cardDesignEnabled && <div className={style.extraDiv}></div>}
          {
            deleteVisible && 
            <DeleteModal 
              setDeleteVisible={setDeleteVisible}
              setDeleteID={() => setDeleteID("")}
              deleteAddress={deleteAddress}
              deletingAddress={deletingAddress}
            />
          }
        </div>
      )
    }
  }

  let AddressContent: any;
  if (!addressstate.allAddressFetched || shippingstate.serviceFetching || cartState.orderFetching) {
    (addressstate.fetchingAllAddress || shippingstate.serviceFetching || cartState.orderFetching) &&
    (
      AddressContent = 
      <div>
        <Alert
          alertMessage="Fetching addresses..."
          type="loading"
        />
        <Loader />
      </div>
    ) 
  } else {
    if(addressData.length === 0 && addressstate.allAddressFetched) {
      AddressContent = <div className="mb-10">No Address Found</div>;
    } else {
    AddressContent = addressData?.map((address: any, index: any) => (
      <>
        <AddressList
          onSelectAddressMethod={props.onSelectAddressMethod}
          SetIsOpen={props.SetIsOpen}
          name={`${address?.firstName ?? "Customer"} ${address?.lastName ?? ""}`}
          email={address?.email}
          tags = {address?.tags}
          // addressType={address.addressType}
          address1={
            address?.line1 +
            ", " +
            (address?.line2 ? (address?.line2 + ', ') : "") +
            (address?.landmark ? ("Landmark - " + address?.landmark + ', ') : "") +
            //address?.address2 +
            //", " +
            address?.city +
            ", " +
            address?.state +
            ", " +
            address?.pincode +
            (loginstate?.sellerConfig?.enableAdrrPhone ? address?.alternatePhone ? `, Ph: ${address?.alternatePhone}` : '' : '')
          }
          key={address?.addressID}
          index={index}
          address_obj={address}
          customer_id={loginstate.userInfo.user_id}
          count={addressDataLength}
          selectedAddress={props.addressToShow}
          setEditAddressData={setEditAddressData}
          setEditAddress={setEditAddress}
          setTitle={props.SetTitle}
          setDeleteVisible={setDeleteVisible}
          setDeleteID={() => setDeleteID(address.addressID)}
          activeMenuIndex = {activeMenuIndex}
          setActiveMenuIndex = {setActiveMenuIndex}
        />
      </>
    ));
    }
  }

  const newAddressHandler = useCallback(() => {
    deliverystate.wrongPincode = false
    cartState.fireSetuRequest = false;
    addressstate.fail = false;
    addressstate.failMsg = null;
    setNewAddress(true);
    AnalyticService.addNewAddressClicked(
      cartState.orderDetails,
      couponState?.couponCode,
      addressstate?.Address_data?.length
     );
    AnalyticService.additionalEvents(
      "add-new-address-clicked",
      cartState?.Cart_data,
      couponState.couponCode, 
      Store.getItem('refreshToken') ? true : false,
      false
    );
    AnalyticService.newAddressDrawerOpened();
    loginstate.AuthTokenGenerated = false;
    addressstate.Updated_address_data = {};
    props.SetTitle("Add new address");
  }, []);
  return (
    <>
      {renderContent()}
    </>
  );
};
export default Address;
