import ReactPixel from "react-facebook-pixel";

let fbpId: any;

let cart_id : any
const initialiseFb = (clientFbpId: any, cartId: any, pixelPayload:any) => {
  fbpId = clientFbpId;
  
  cart_id = cartId;
  if (fbpId) {
    // {/*// @ts-ignore */}
    // console.log("7 rings",fbpId, {external_id: cartId})
    // @ts-ignore
    ReactPixel.init(fbpId, {external_id: cartId, ...pixelPayload});
  }
};

const trackEvent = (eventName: any, properties?: any) => {
  
  let fbPixelProperties = {
    ...properties
  };

  


  console.log(fbPixelProperties, 'checking pixel properties')
  console.log('firing facebook pixel events', eventName, properties)
  // console.log("FB fired parv","track",eventName, fbPixelProperties,{eventId:`${cart_id}_${eventName}`})
  ReactPixel.fbq("track", eventName, fbPixelProperties, {eventID:`${cart_id}_${eventName}`}); 

};

const trackCustomEvent = (eventName: any, properties?: any) => {
  ReactPixel.trackCustom(eventName, properties);
};

var FacebookPixel = {
  initialiseFb,
  trackEvent,
  trackCustomEvent,
}
export default FacebookPixel;
