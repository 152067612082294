import React, { useState, useEffect, useMemo } from "react";
import styles from "./Delivery.module.css";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  addExistingUserAddress,
  addNewUserAddress,
  addSilentUserAddress,
  fetchAddress,
} from "../../Redux/Address/add_actions";
import { fetchPincode } from "../../Redux/Delivery/del_actions";
import Shipping from "../Shipping/Shipping";
import ErrorHandler from "../Error/ErrorHandler";
import {
  createCartDetails,
} from "../../Redux/Cart/Cart_action";
import { checkServicability } from "../../Redux/Shipping/Ship_actions";
import { fetchShipping } from "../../Redux/Shipping/Ship_actions";
import Otp from "../Login/Otp";
import AnalyticService from "../../service/analytics/AnalyticsService";
import Loader from "../Utils/Loader";
import { LanguageServiceMode } from "typescript";
import { channels } from "../../utils/constants";
import Select from "../UI/Select/Select";
import { Store } from "../../service/StorageHelper";
import { sendShiprocketCheckoutEvent } from "../../utils/eventCollectors";
import { CITY_REGEX, CITY_REGEX_ERROR_MESSAGE } from "../../utils/constants";

const errMssgs = {
  pincode: "Please enter a valid 6 digit Pincode",
  name: "Please enter your full name",
  email: "Please enter a valid email",
  shipAddr: "Your input must be a minimum of 10 characters and maximum 255",
  contact: "Please enter a valid 10 digit phone number",
};

const Delivery = (props: any) => {

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  const [pincodeTouched, setPincodeTouched] = useState(false);
  const [pincodeHasError, setPincodeHasError] = useState(false);
  const [pincodeSpinner, setPincodeSpinner] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [showLandmark, setShowLandmark] = useState(false);
  const [state, updateState] = useState(0);
  const [newUserData, setnewUserData] = useState(true);
  const forceUpdate = () => updateState((prevState) => prevState ^ 1);
  const deliverystate = useSelector((state: any) => state.Delivery);
  const couponState = useSelector((state: any) => state.Coupon);
  const otpstate = useSelector((state:any )=> state.Otp);
  const addressstate = useSelector((state: any) => state.Address);
  const cartstate = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const [first, setfirst] = useState(true);
  const [pincodeFieldDisplayed, setPincodeFieldDisplayed] = useState(false);
  const [skipErrorField, setSkipErrorField] = useState<any>([])
  const [showOtherTag, setShowOtherTag] = useState<boolean>(false);
  const [selectedTag, setSelectedTag] = useState<string>('home');
  const [codFormFilled, setCodFormFilled] = useState(false);
  const [addressEventTrigerred, setAddressEventTrigerred] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState<any>(loginstate.mobileNumber ? loginstate.mobileNumber : otpstate.mobile_no ?  otpstate.mobile_no : '')
  const [values, setValues] = useState({
    city: "",
    state: "",
    name: "",
    lastName: "",
    gender : "",

    email: "",
    line1: "",
    line2:"",
    landmark: "",
    completeAdd: "",
    tags: ""
  });

  const [lenError, setLenError] = useState("")
  const [line2lenError, setLine2LenError] = useState("")
  const dispatch = useDispatch();

  const errMssgs = {
    pincode: "Please enter a valid 6 digit Pincode",
    name: "Enter your first name",
    lastName: "Enter your last name",
    line1: "Enter required address details",
    line2: "Enter required address details",
    landmark:"Enter a landmark" ,
    city: "Enter your City",
    email: "Enter valid email",
    contact: "Please enter a valid 10 digit phone number",
    gender : 'Please select your gender', 
    tags: "Enter a valid value"
  };

  const [errors, setErrors] = useState({
    name: errMssgs.name,
    lastName: errMssgs.lastName,
    gender : loginstate?.sellerConfig?.genderInAddressEnabled ? errMssgs.gender : '', 
    line1: errMssgs.line1,
    line2: errMssgs.line2,
    landmark: loginstate?.sellerConfig?.landmarkMandatory ? errMssgs?.landmark : '',
    phone : loginstate?.sellerConfig?.enableAdrrPhone ? (loginstate?.mobileNumber || otpstate.mobile_no) ? '' : 'Enter your phone number' : '',
    city: errMssgs.city,
    state: "",
    email:  loginstate?.sellerConfig?.removeEmailFromCodForm ? '' : loginstate.sellerConfig?.showEmail ? errMssgs.email : "",
    tags: selectedTag === "others" ? errMssgs?.tags : ''

  });
  const [activeErrors, setActiveErrors] = useState({
    name: "",
    lastName: "",
    gender : '', 
    line1: "",
    line2: "",
    landmark:"",
    phone : "",
    city: "",
    state: "",
    email: "",
    tags:""
  }); 

   // tags handle
   const handleAddressTag = (tag:string) =>{
    if(!(!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode)){
    if(tag === "others"){
      setShowOtherTag(true)
      setErrors((prevState) => ({ ...prevState, tags: errMssgs.tags }));
    }else {
      setShowOtherTag(false)
      setValues((prevState) => ({ ...prevState, tags: '' }));
      setErrors((prevState) => ({ ...prevState, tags: '' }));
      setActiveErrors((prevState) => ({ ...prevState, tags: ''}));
    }
    setSelectedTag(tag)
    deliverystate.selectedTag = tag;
    }
  }

  const setErrorMssg = (key: string, error: string) => {
    setErrors((prevState) => ({ ...prevState, [key]: error }));
  };
  const setActiveErrorsMssg = (key: string, error: string) => {
    setActiveErrors((prevState) => ({ ...prevState, [key]: error }));
  };
  
   const isFormValid = useMemo(() => {
    let isValid = true;
    let noMandatoryErrors = true
    let noActiveErrors = true
    Object.values(errors).forEach((error) => {
      if (error !== "") {
        noMandatoryErrors = false;
      }
    });
    Object.values(activeErrors).forEach((error) => {
      if (error !== "") {
        noActiveErrors = false;
      }
    });
    isValid = noMandatoryErrors && noActiveErrors
    dispatch({type: 'Update_valid_cod_form_address',payload: isValid})
    return isValid;
  }, [errors,activeErrors]);

  function getFirstErrorKey(object : any) {
    return Object.keys(object).find(key =>
      object[key] !== "");
  }

  const firstErrorKey = useMemo(()=>{
    let firstErrorKeyName = 'pincode'
    let firstMandatoryError = getFirstErrorKey(errors)
    let firstActiveError = getFirstErrorKey(activeErrors)
    firstErrorKeyName = Object.keys(errors).find(key =>key === firstMandatoryError || key === firstActiveError) ?? 'pincode';
    dispatch({type: 'Update_first_error_key_address',payload: firstErrorKeyName})
    return firstErrorKeyName
  },[errors,activeErrors])


  const pincodeErrorValue = useMemo(
    () =>
        !deliverystate.pincode ? 
        "Enter your pincode"
        :
        (deliverystate.pincode.length < 6 && deliverystate.pincode.length >= 1)||
        deliverystate.pincode.length > 6 ||
        pincodeHasError
        ? "Pincode must consist of 6 digit" : "",
    [deliverystate.pincode, pincodeHasError]
  );

  useEffect(()=>{
    if(Store.getItem('codFormAddress') && !codFormFilled){
      if(loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified){
        if(!addressEventTrigerred){
          sendShiprocketCheckoutEvent({
            name: 'ADDRESS_PREFILLED_COOKIE',
            domain:loginstate.sellerConfig?.domainName,
            payload: { 
              "cart_id": cartstate?.Cart_data?.id,
            }
          })
          setAddressEventTrigerred(true)
        }
        const codFormAddress = JSON.parse(Store.getItem('codFormAddress'))
        inputPincode(codFormAddress?.address?.pincode ?? '')
        inputValue("name",codFormAddress?.address?.firstName ? codFormAddress?.address?.firstName : codFormAddress?.address?.fullName ? codFormAddress?.address?.fullName : '')
        inputValue("lastName", codFormAddress?.address?.lastName ?? '')
        inputValue("line1", codFormAddress?.address?.line1 ? codFormAddress?.address?.line1 : codFormAddress?.address?.fullAddressLine ? codFormAddress?.address?.fullAddressLine : '')
        inputValue("line2", codFormAddress?.address?.line2 ?? '')
        if(!loginstate?.sellerConfig?.removeEmailFromCodForm){
          inputValue("email", codFormAddress?.address?.email ?? '')
        }
        setCodFormFilled(true)

        if(loginstate?.sellerConfig?.landmarkMandatory){
          inputValue("landmark", codFormAddress?.address?.landmark ?? '')
        }
  
        if(loginstate?.sellerConfig?.genderInAddressEnabled){
        inputValue("gender", codFormAddress?.address?.gender ?? '')
        }
  
        if(loginstate?.sellerConfig?.enableAdrrPhone){
          setPhoneNumber(codFormAddress?.address?.alternatePhone ?? '')
          let zeroCheck = codFormAddress?.address?.alternatePhone?.length > 0 && Number(codFormAddress?.address?.alternatePhone?.[0]) === 0;
          let flag = false;
          flag = zeroCheck ? true : false;
          if(codFormAddress?.address?.alternatePhone?.length === 0){
            setErrorMssg("phone","Enter your phone number")
          }else{
            setErrorMssg("phone","")
          }
    
          if (codFormAddress?.address?.alternatePhone?.length === 10 && !flag) {
            setActiveErrorsMssg("phone","")
          }else if(codFormAddress?.address?.alternatePhone?.length === 0){
            setActiveErrorsMssg("phone","")
          }else{
            setActiveErrorsMssg("phone","Please enter valid phone number")
          }
        }

        let tagName:any;
        if(codFormAddress?.address?.tags){
          tagName = (codFormAddress?.address?.tags === "home" || codFormAddress?.address?.tags === "office") ? codFormAddress?.address?.tags : "others";
          setShowOtherTag(true)
        }else {
          tagName = "home"
          setShowOtherTag(false)
        }
        setSelectedTag(tagName)
        deliverystate.selectedTag = tagName;

        let tagsValues;
        if(codFormAddress?.address?.tags && codFormAddress?.address?.tags?.includes('other')){
          tagsValues =  codFormAddress?.address?.tags?.split(':')?.[1]?.trim();
        }else {
          tagsValues = "";
        }
        
        inputValue("tags", tagsValues ?? '')
        deliverystate.tagValue = tagsValues;
  
        const addressDetails = {
          name : codFormAddress?.address?.firstName ? codFormAddress?.address?.firstName : codFormAddress?.address?.fullName ? codFormAddress?.address?.fullName : '',
          lastName : codFormAddress?.address?.lastName ?? '',
          ...(loginstate?.sellerConfig?.genderInAddressEnabled && {gender : codFormAddress?.address?.gender ?? ''}),
          line1 : codFormAddress?.address?.line1 ? codFormAddress?.address?.line1 : codFormAddress?.address?.fullAddressLine ? codFormAddress?.address?.fullAddressLine : '',
          line2 : codFormAddress?.address?.line2 ?? '',
          ...(!loginstate?.sellerConfig?.removeEmailFromCodForm && {email : codFormAddress?.address?.email ?? ''}),
          ...(loginstate?.sellerConfig?.landmarkMandatory && {landmark : codFormAddress?.address?.landmark ?? ''}),
          pincode : codFormAddress?.address?.pincode,
          ...(loginstate?.sellerConfig?.enableAdrrPhone && {phoneNumber : codFormAddress?.address?.alternatePhone ?? ''}),
          selectedTag: tagName ?? 'home', 
          tagValue: tagsValues ?? '', 
          tags: tagName === "others" ?  tagsValues : tagName,
        }
        dispatch({type: 'update_delivery_add_cod_form',payload :addressDetails })
      }
    }else{
      if(loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified){
        if(!addressEventTrigerred)
        {
          sendShiprocketCheckoutEvent({
          name: 'ADDRESS_FILLED_MANUALLy',
          domain:loginstate.sellerConfig?.domainName,
          payload: { 
            "cart_id": cartstate?.Cart_data?.id,
          }
        })
        setAddressEventTrigerred(true)
      }
      }
      setCodFormFilled(true)

    }

  },[Store.getItem('codFormAddress'),loginstate?.sellerConfig?.enabledFeatureCodForm, !otpstate?.verified])

  const activePincodeError = useMemo(
    ()=>
    deliverystate.wrongPincode ? 
        "Please enter a valid pincode"
        : "",
      [deliverystate.wrongPincode]
  )

  useEffect(() => {
    // error to be shown only if error msg received
    if (addressstate.failMsg !== null) {
      values.line1 = "";
      values.line2 = ""
      deliverystate.Pincode_data = {};
      deliverystate.pincode = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
      forceUpdate();
      if (!pincodeFieldDisplayed) {
        AnalyticService.pincodeFieldDisplayed();
        console.log("hi parv")
      }
      setPincodeFieldDisplayed(true);
    }
  }, [addressstate.failMsg]);

  useEffect(() => {
    if (addressstate.invalidEmail !== null) {
      values.email = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
    }
  }, [addressstate.invalidEmail]);

// Abhishek
/*   useEffect(() => {
    //setaddresstoshow function to be updated in payment component for verified customer with no address
    if (
      addressstate.Updated_address_data?.status === 201 &&
      !addressstate.addAddress
    ) {
      props.setAddressToShow({
        name: values.name,
        email: values.email,
        address: values.completeAdd,
        address_id: addressstate.Updated_address_data.data.address_id,
        customer_id: addressstate.Updated_address_data.data.customer_id,
        count: "",
        index: "",
      });
      props.SetIsOpen(false);
    }
  }, [addressstate.Updated_address_data]); */


  useEffect(() => {
    if(addressstate?.selectedAddress && cartstate?.orderDetails?.id) {
      props.setAddressToShow({
        name: `${addressstate?.selectedAddress.firstName ?? "Customer"} ${addressstate?.selectedAddress.lastName ?? ""}`,
        email: addressstate?.selectedAddress.email,
        address: `${addressstate?.selectedAddress.line1}, ${addressstate?.selectedAddress?.line2 ? (addressstate?.selectedAddress?.line2 + ',') : ""} ${addressstate?.selectedAddress?.landmark ? ("Landmark-" + addressstate?.selectedAddress?.landmark + ',') : ""} ${addressstate?.selectedAddress.city}, ${addressstate?.selectedAddress.state}, ${addressstate?.selectedAddress.pincode}`,
        address_id: addressstate?.selectedAddress.addressID,
        customer_id: loginstate.userInfo.user_id,
        count: "",
        index: "",
        alternatePhone : addressstate.selectedAddress?.alternatePhone, 
        tags: addressstate.selectedAddress?.tags
      });
      //props.SetIsOpen(false);
    }
  }, [JSON.stringify(addressstate?.selectedAddress)])

  const inputPincode = (inputPin: string) => {
    // setPincode(inputPin.substring(0, 6));
    deliverystate.wrongPincode = false;
    addressstate.fail = false;
    deliverystate.pincode = inputPin.substring(0, 6);
    forceUpdate();
    let reqpayload = {
      postcode: inputPin.substring(0, 6),
    };
    if (inputPin.length === 6) {
      AnalyticService.pincodeAdded();
      AnalyticService.addressFieldsDisplayed();
      deliverystate.spinner = true;
      deliverystate.wrongPincode = false;
      dispatch(fetchPincode(reqpayload));
    }
  };

  const updateAddressViaServiceability = async() => {
    const payload = {
      "sellerId":loginstate.sellerConfig.seller_id,
      "address":{
        phone: loginstate?.mobileNumber ?? '',
        line1: deliverystate?.line1?.replace(/\n/g, " ")?.trim() ?? '',
        line2: deliverystate?.line2?.replace(/\n/g, " ")?.trim() ?? '',
        gender : deliverystate?.gender ?? '',
        city: deliverystate?.city?.trim(),
        pincode: deliverystate?.pincode,
        state: deliverystate?.Pincode_data?.postcode_details?.state ?? '',
        country: "IN",
        sellerID:loginstate.sellerConfig?.seller_id,
        landmark: deliverystate?.landmark?.trim() ?? '',
        firstName: deliverystate?.name?.trim() ?? '',
        lastName: deliverystate?.lastName?.trim() ??'',
        email: deliverystate?.email ?? `${loginstate?.mobileNumber ? `${loginstate?.mobileNumber?.trim()}@fastrr.com` : ''}`,
        countryCode: "+91",
        addressType:["Shipping"],
        cartId : cartstate?.Cart_data?.id,
        ...(loginstate?.sellerConfig?.enableAdrrPhone ? {alternatePhone : deliverystate?.phoneNumber ?? ''} : {}),
        tags: deliverystate?.selectedTag === "others" ?  `other: ${deliverystate?.tagValue}` : deliverystate?.selectedTag,
        // adding random address id to update the address in report events by triggering order_screen event
        addressID: `random-${(Math.random()*100000)?.toFixed(0)}`,
        // isDefault: true,
        //  lastUsedDateTime:"2022-07-21T07:44:04.001+00:00",
        // noOfDeliveredOrders:1,
        // totalOrders:12  
      },
      "billingAddress" : {}
    }
    await dispatch(checkServicability(payload, otpstate.pim_sid_updated, loginstate.sellerConfig.seller_id))
    cartstate.orderFetched = false
  }

  useEffect(() => {
    if(deliverystate.Pincode_data?.success){
      document.getElementById("name")?.focus()
      if(!(otpstate?.verified || loginstate?.otpSkip) && loginstate?.sellerConfig?.enabledFeatureCodForm){

        updateAddressViaServiceability()

      }
    }
  }, [deliverystate.Pincode_data])

  const createNewCart = () => {
    let cart_item: object[] = loginstate?.cartItems?.map((item: any) => {
      return {itemId : item.variantId, quantity: item.quantity}
    })

  };

  useEffect(() => {
    inputValue("city", deliverystate.city)
  }, [deliverystate.city])

  const isNumber = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = e.target.value.substring(0,13);
    var inputs = document.querySelectorAll('input[type="number"]');
    for (var i = inputs.length; i--; )
      inputs[i].setAttribute("pattern", "\\d*");
  };

  const mobileInputHandler = async (event: any) => {
    let number = event.target.value;
    setPhoneNumber(number)
    deliverystate.phoneNumber = number
    let zeroCheck = number.length > 0 && Number(number[0]) === 0;
    let flag = false;
    flag = zeroCheck ? true : false;

    if(number.length === 0){
      setErrorMssg("phone","Enter your phone number")
    }else{
      setErrorMssg("phone","")
    }

    if (number.length === 10 && !flag) {
      setActiveErrorsMssg("phone","")
    }else if(number.length === 0){
      setActiveErrorsMssg("phone","")
    }else{
      setActiveErrorsMssg("phone","Please enter valid phone number")
    }
  };

  const updateAddress = () => {
    let updateCartPayload = {
      cart_id: cartstate.Cart_data?.data?.cart_id,
      address_selected: 0,
      address_id: addressstate.Silent_User_Address.data.address_id,
      customer_id: addressstate.Silent_User_Address.data.customer_id,
      name: values.name,
      email: values.email,
      address: addressstate.Silent_User_Address.data.address_details,
      saved_addresses: 0,
    };
    updateCartPayload.address.mobile_no = updateCartPayload.address.mobile;
    loginstate.onPage = "delivery";
    // update mobile number from redux
    if (!updateCartPayload.address.mobile_no) {
      updateCartPayload.address.mobile_no = loginstate.mobileNumber;
    }

  };

  const fetchShippingDetails = () => {
    let channelData = {
      channel_code: loginstate.channel.channel_code,
      channel_id: loginstate.channel.channel_id,
      cart_id: {
        cart_id: cartstate.Cart_data?.data?.cart_id,
      },
    };
    //dispatch(fetchShipping(channelData));
  };

  const handleInternationalShippingRedirect = () => {
    if(!params.get('cart-resume-id')){
      if (channels.shopify === loginstate?.platform) {
        //for Shopify
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:
                    loginstate.channel?.redirectUrl[loginstate.channel?.redirectUrl?.length - 1] === ","
                      ? loginstate.channel?.redirectUrl?.slice(0, loginstate.channel?.redirectUrl?.length - 1)
                      : loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }

      if (channels.wooCommerce === loginstate?.platform) {
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
    }else{
      if (channels.wooCommerce === cartstate?.platform?.toUpperCase()) {
        let redirectUrl =`http://${loginstate?.sellerConfig?.domainName}/checkout`;
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
      let cartData = "";
      for (let item of cartstate?.orderDetails?.lineItems) {  
        cartData = cartData + item.itemId+':'+item.quantity+','
      }

      const redirectUrl  = `http://${loginstate?.sellerConfig?.domainName}/cart/${cartData}`;
      //for Shopify
      window.parent.postMessage(
        {
          trigger: "headless-redirect",
          actions: [
            {
              action: "redirectTo",
              data: {
                url:
                redirectUrl[redirectUrl?.length - 1] === ","
                    ? redirectUrl?.slice(0, redirectUrl?.length - 1)
                    : redirectUrl,
              },
            },
          ],
        },
        "*"
      );
    }
  }

  const addressTagSelectedClass  = 
  (!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode) ? "" :
  `${cartstate?.cardDesignEnabled ? styles.addressTag_selected_card : styles.addressTag_selected}`;
  
  const renderaddressdetails = () => {
    if (
      loginstate.AuthTokenGenerated &&
      cartstate.sendCartInitiate
    ) {
      createNewCart();
    }
    if (
      loginstate.AuthTokenGenerated &&
      cartstate.updateAddressfired &&
      cartstate.Cart_data?.success
    ) {
      updateAddress();
    }
    if (
      addressstate.addressFetched
    ) {
      const payload = {
        "sellerId":loginstate.sellerConfig.seller_id,
        "addressId":addressstate.Address_data[0]?.addressID
      }
   //   checkServicability(payload)
    }

    if (
      loginstate.AuthTokenGenerated &&
      cartstate.UpdatedShipping_data?.success &&
      first
    ) {
      setfirst(false);
      props.onRender();
      loginstate.addSilentUserAddress = false;
      addressstate.spinner = false;
    }
    if (newUserData) {
      return (
        <>
          <div>
            {
              (addressstate?.Address_data?.length === 0 || !addressstate?.Address_data) &&
              <>
              {loginstate.customLogin 
                ? <h4 style={{margin:"12px 0 8px", fontSize: 20, fontWeight: 600, paddingBottom: 6}}>Add new address</h4>
                :cartstate?.cardDesignEnabled ? <div className={ 'card-main-heading'} style={{paddingBottom:'10px'}}>Add shipping address</div>
                : <h4 style={{paddingBottom:'10px'}}>Add shipping address</h4>
              }
              </>
            }
            {loginstate?.sellerConfig?.enableInternationalAddress &&
              <h5 style={{paddingBottom:'10px',font : '500 14px/14px "Metropolis"'}}>Do you like to have your order <a onClick={()=>handleInternationalShippingRedirect()} style={{color : 'var(--secondary-color)',cursor : 'pointer'}}> shipped outside India?</a></h5>
            }
            <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
              <Input
                label="Pincode"
                type="text"
                id="pincode"
                name="pincode"
                value={deliverystate.pincode}
                className="mt-18"
                onChange={(e: any) => {
                  if(e.target.value.match(/^[\d]{0,6}$/g))
                  inputPincode(e.target.value)
                }}
                disabled = {(cartstate?.orderFetching || shippingstate.serviceFetching || deliverystate.spinner) && loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified}
                error={pincodeErrorValue}
                activeErrors={activePincodeError}
                loading={deliverystate.spinner}
                touched={pincodeTouched}
                autoComplete="postal-code"
                mandatory={true}
                marginTop={!cartstate?.cardDesignEnabled ? '' : '8px'}
              />
            </div>
            {/* {deliverystate.wrongPincode && (
              <ErrorHandler
                msg={
                  "City/State not found for the entered pincode"
                }
              />
            )} */}
            {addressstate.failMsg && (
              <ErrorHandler msg={addressstate.failMsg} />
            )}
            {true ? (
              <div>
                  <Input
                  label="First name"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  className="mr-10 mt-18"
                  onInput={(e: any) => inputValue("name", e.target.value)}
                  error={errors.name}
                  maxLength="60"
                  activeErrors = {activeErrors.name}
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  autoComplete="given-name"
                  touched = {skipErrorField?.includes("name")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label="Last name"
                  type="text"
                  id="lastName"
                  name="name"
                  value={values.lastName}
                  className="mr-10 mt-18"
                  maxLength="60"
                  onInput={(e: any) => inputValue("lastName", e.target.value)}
                  error={errors.lastName}
                  activeErrors = {activeErrors.lastName}
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  autoComplete="family-name"
                  skippedArray={["name"]}
                  touched = {skipErrorField?.includes("lastName")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                {loginstate?.sellerConfig?.genderInAddressEnabled &&
                <Select
                id="gender"
                label="Gender"
                placeholder="Gender"
                name="gender"
                className="mt-18"
                value={values.gender}
                onChange={(e: any) => {inputValue("gender", e.target.value)}}
                error={errors.gender}
                disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                touched = {skipErrorField?.includes("gender")}
                activeErrors = {activeErrors.gender}
                skippedArray={["name","lastName"]}
                setSkipErrorField={setSkipErrorField}
                mandatory={true}
                >
                  <option disabled value = "" hidden>{!cartstate?.cardDesignEnabled ? 'Select your gender' : ''}</option>
                  <option value="male">
                    Male
                  </option>
                  <option value="female">
                   Female
                  </option>
                </Select>
                }
                <Input
                  label={loginstate?.sellerConfig?.domainName !== "thehatke.com" ? "Flat, house number, floor, building" : "Address line 1 (Flat, house number, floor, building)"}
                  type="textarea"
                  id="line1"
                  name="address"
                  value={values.line1}
                  onInput={(e: any) => inputValue("line1", e.target.value)}
                  error={errors.line1}
                  activeErrors = {activeErrors.line1}
                  lenError={lenError}
                  className="mt-18"
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  autoComplete="address-line1"
                  maxLength="255"
                  skippedArray={["name","lastName","gender"]}
                  touched = {skipErrorField?.includes("line1")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label={loginstate?.sellerConfig?.domainName !== "thehatke.com" ? "Area, street, sector, village" : "Address line 2 (Area, street, sector, village)"}
                  type="textarea"
                  id="line2"
                  name="address"
                  value={values.line2}
                  onInput={(e: any) => inputValue("line2", e.target.value)}
                  error={errors.line2}
                  activeErrors = {activeErrors.line2}
                  lenError={line2lenError}
                  className="mt-18"
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  autoComplete="address-line2"
                  maxLength="170"
                  skippedArray={["name","lastName","line1","gender"]}
                  touched = {skipErrorField?.includes("line2")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />

                {showLandmark || loginstate?.sellerConfig?.landmarkMandatory ? (
                  <Input
                    error={errors.landmark}
                    activeErrors = {activeErrors.landmark}
                    label="Landmark"
                    type="text"
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onInput={(e: any) => inputValue("landmark", e.target.value)}
                    className="mt-18"
                    maxLength="50"
                    disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                    skippedArray={["name","lastName","line1","line2","gender"]}
                    touched = {skipErrorField?.includes("landmark")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={loginstate?.sellerConfig?.landmarkMandatory ? true : false}
                  />
                ) : (
                  !loginstate?.sellerConfig?.landmarkMandatory &&
                  (
                    <button
                      onClick={() => setShowLandmark(true)}
                      className={!cartstate?.cardDesignEnabled ? styles.LandmarkButton : styles.LandmarkButtonCard }
                    >
                      + Landmark area
                    </button>
                  )
                )}
                {loginstate?.sellerConfig?.enableAdrrPhone &&
                  <Input
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="tel"
                    autoComplete="tel"
                    // placeholder="10-digit phone number"
                    className="mt-18"
                    onChange={mobileInputHandler}
                    onInput={isNumber}
                    skippedArray={["name","lastName","line1","line2","landmark","gender"]}
                    touched = {skipErrorField?.includes("phone")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={ true}
                    activeErrors={activeErrors.phone}
                    error = {errors.phone}
                    value={phoneNumber}
                    disabled = {!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  />
                }
                {/* <div className={clsx(styles.title, "mt-24")}>Address</div> */}
                <div className={styles.addressDiv}>
                  <Input
                    label="City"
                    type="text"
                    id="city"
                    name="city"
                    error={errors.city}
                    activeErrors={activeErrors.city}
                    value={deliverystate.city}
                    onInput={(e: any) => inputValue("city", e.target.value)}
                    className="mr-18"
                    maxLength="100"
                    //disabled={true}
                    disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                    autoComplete="address-level2"
                    skippedArray={["name","lastName","line1","line2","landmark","phone","gender"]}
                    touched = {skipErrorField?.includes("city")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={true}
                  />
                  <Input
                    label="State"
                    type="text"
                    id="state"
                    name="state"
                    value={deliverystate.state}
                    disabled={true}
                    skippedArray={["name","lastName","line1","line2","landmark","city","phone","gender"]}
                    touched = {skipErrorField?.includes("state")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={true}
                    skipPincodeFocus = {!(!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode)}
                    //autoComplete="address-level1"
                  />
                </div>
{/*             <div className={clsx(styles.title, "mt-18")}>
                  Contact Details
                </div> */}
                {/* <Input
                  label="First Name"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  className="mr-10 mt-18"
                  onInput={(e: any) => inputValue("name", e.target.value)}
                  error={errors.name}
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6}
                  autoComplete="given-name"
                />
                <Input
                  label="Last Name"
                  type="text"
                  id="name"
                  name="name"
                  value={values.lastName}
                  className="mr-10 mt-18"
                  onInput={(e: any) => inputValue("lastName", e.target.value)}
                  error={errors.lastName}
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6}
                  autoComplete="family-name"
                /> */}
                {!loginstate?.sellerConfig?.removeEmailFromCodForm &&
                  <>
                  <Input
                  label={loginstate.sellerConfig?.showEmail ? "E-mail" : "E-mail (optional)"}
                  type="text"
                  id="email"
                  name="email"
                  value={values.email}
                  onInput={(e: any) => inputValue("email", e.target.value)}
                  error={errors.email}
                  className="mt-18"
                  disabled={!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                  autoComplete="email"
                  skippedArray={["name","lastName","line1","line2","landmark","city","phone","gender"]}
                  touched = {skipErrorField?.includes("email")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={loginstate.sellerConfig?.showEmail ? true : false}
                />
                <div className={styles.deliveryLabel}>
                  Order delivery details will be sent here
                </div>
                {addressstate.invalidEmail && (
                  <ErrorHandler msg={addressstate.invalidEmail} />
                )}
                </>
                }
                <div className={`${styles.addressDiv}`}>
                  <div></div>
                <div style={{width: '100%', marginTop: 24}}>
                  <div className={styles.addressTagContainer}>
                    <div className={`${styles.addressTag} ${selectedTag === "home" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('home')}>Home</div>
                    <div className={`${styles.addressTag} ${selectedTag === "office" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('office')}>Office</div>
                    <div className={`${styles.addressTag} ${selectedTag === "others" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('others')} >
                      Others
                    </div>
                </div>
                {(showOtherTag && selectedTag === "others")
                  ? 
                  <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
                    <Input
                      label="Save address as"
                      type="text"
                      id="tags"
                      name="tags"
                      value={values.tags}
                      className="mr-10 mt-18"
                      onInput={(e: any) => inputValue("tags", e.target.value)}
                      error={errors.tags}
                      maxLength="25"
                      activeErrors = {activeErrors.tags}
                      onChange={(e: any) => deliverystate.tagValue = e.target.value}
                      // autoComplete="given-name"
                      // touched = {skipErrorField?.includes("addressTag")}
                      // setSkipErrorField={setSkipErrorField}
                      mandatory={selectedTag === "others" && true}
                    />
                  </div> 
                  : ''
                }
              </div>
              </div>
              {(!loginstate?.sellerConfig?.enabledFeatureCodForm || (loginstate?.sellerConfig?.enabledFeatureCodForm && (otpstate?.verified || loginstate?.otpSkip))) &&
                <div className={!cartstate?.cardDesignEnabled ? styles.mb70 : ''}>
                    {/* <button className='Button_button__KXFVC' onClick={handleAddData}
                      >Continue</button> */}
                    {addressstate.spinner && <Loader />}
                    <Button
                      id="addAddressBtn"
                      // disabled={!isFormValid || deliverystate.pincode.length < 6 || deliverystate.wrongPincode}
                      type="button"
                      onClick={handleAddData}
                      loading={addressstate.addressAdding}
                      className={clsx("mt-30",{
                        [styles.disabled]: (!isFormValid || deliverystate.pincode.length < 6 || deliverystate.wrongPincode),
                      })}
                      isCardDesign={cartstate?.cardDesignEnabled}
                    >
                      {addressstate.addressAdding ? "Adding address... " : "Add address"}
                      
                    </Button>
                </div>
    }
              </div>
            ) : null}
          </div>
        </>
      );
    } else if (!newUserData) {
      return (
        <>
          <Otp />
        </>
      );
    }
  };


  const checkError = (key: string, inputValue: string) => {
    switch (key) {
      case "name": {
        const re = /^[A-Za-z\s]+$/
        if(inputValue === ""){
          setErrorMssg(key, "Enter your first name.")
        }else{
          setErrorMssg(key, "");
        }
        if(inputValue.length === 0){
          setActiveErrorsMssg(key, "")
        }else if ((params.get('login') === "sr-login") && inputValue.length < 3){
          setErrorMssg(key, "First name can’t be smaller than 3 characters")
        }else if ((params.get('login') === "sr-login") && inputValue.length > 49){
          setErrorMssg(key, "First name can’t be longer than 49 characters")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key, "Avoid starting with a space")
        } else if(!re.test(inputValue)){
          setActiveErrorsMssg(key, "Use only alphabets (A-Z, a-z)")
        } else if(inputValue.length > 60){
          setActiveErrorsMssg(key, "First name can’t be longer than 60 characters")
        } else {
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "lastName": {
        const re = /^[A-Za-z\s]+$/
        if(inputValue === ""){
          setErrorMssg(key, "Enter your last name.")
        }else{
          setErrorMssg(key, "");
        }
        if(inputValue.length === 0){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key, "Avoid starting with a space")
        }else if ((params.get('login') === "sr-login") && inputValue.length < 3){
          setErrorMssg(key, "Last name can’t be smaller than 3 characters")
        }else if ((params.get('login') === "sr-login") && inputValue.length > 49){
          setErrorMssg(key, "Last name can’t be longer than 49 characters")
        }else if(!re.test(inputValue)){
          setActiveErrorsMssg(key, "Use only alphabets (A-Z, a-z)")
        } else if(inputValue.length > 60){
          setActiveErrorsMssg(key, "Last name can’t be longer than 60 characters")
        } else {
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "gender": {
        if(inputValue === ""){
          setErrorMssg(key, "Select your gender.")
        }else{
          setErrorMssg(key, "");
        }
        break;
      }
      case "landmark": {
        // inputValue.length > 50 || inputValue[0] == " "
        if (loginstate?.sellerConfig?.landmarkMandatory && inputValue === ""){
          setErrorMssg(key, "Enter your landmark");
        } else {
          setErrorMssg(key, "");
        }
        if(inputValue === ""){
          setActiveErrorsMssg(key,"")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key,"Avoid starting with a space")
        }else if(inputValue.length > 50){
          setActiveErrorsMssg(key,"Landmark can’t be longer than 50 characters")
        }else{
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "email": {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((loginstate.sellerConfig?.showEmail && (inputValue === "" || !re.test(inputValue))) || (inputValue !== "" && !re.test(inputValue))) {
          setErrorMssg(key, errMssgs.email);
        } else {
          setErrorMssg(key, "");
        }
        break;
      }
      case "line1": {
        // /^[ A-Za-z0-9_@./,#&+\-()/\n]{0,255}$/
        // const re = /^[ A-Za-z0-9_@./,#&+-/\n]{0,255}$/;
        const re = /^[ A-Za-z0-9_@./,#&+\-()/\n]{0,255}$/;
        setLenError(inputValue.trim().length.toString())
        if(inputValue.trim() === ""){
          setErrorMssg(key, errMssgs.line1)
        }else{
          setErrorMssg(key, "")
        }
        if(inputValue.trim() === ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue.trim().length > 255){
          setActiveErrorsMssg(key,"Address line 1 cannot be longer than 255 characters");
        }else if(!re.test(inputValue.trim())){
          setActiveErrorsMssg(key, "Allowed special characters _ @ . / , # & + - ");
        }else if(loginstate?.sellerConfig?.domainName === "thehatke.com" && inputValue.trim().length < 4 ){
          setActiveErrorsMssg(key,"Address line 1 cannot be less than 4 characters");
        } else {
          setActiveErrorsMssg(key, "");
        }
        break;
      }
      case "line2": {
        const re = /^[ A-Za-z0-9_@./,#&+-/\n]{0,170}$/;
        setLine2LenError(inputValue.trim().length.toString())
        if(inputValue.replace(/\s/g,"") === ""){
          setErrorMssg(key,errMssgs.line2)
        }else if(inputValue.trim().length < 10){
          setErrorMssg(key,"Minimum 10 characters required")
        }else{
          setErrorMssg(key, "")
        }
        if(inputValue.trim() === ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue.trim().length > 170){
          setActiveErrorsMssg(key,"Address line 2 cannot be longer than 170 characters");
        }else if (!re.test(inputValue.trim())) {
          setActiveErrorsMssg(key,"Allowed special characters _ @ . / , # & + - ");
        } else {
          setActiveErrorsMssg(key, "");
        }
        break;
      }
      case "city": {
        // const re = /^[A-Za-z-\s]*$/;
        const re = CITY_REGEX;
        deliverystate.city = inputValue
        if (inputValue == "") {
          setErrorMssg(key, errMssgs.city);
        } else {
          setErrorMssg(key, "");
        }

        if(inputValue == ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key,"Avoid starting with a space")
        }else if(!re.test(inputValue)){
          // setActiveErrorsMssg(key,"Use alphabets (A-Z, a-z) or hyphen (-) only")
          setActiveErrorsMssg(key, CITY_REGEX_ERROR_MESSAGE)
        }else if(inputValue.length > 100){
          setActiveErrorsMssg(key,"City can’t be longer than 100 characters")
        }else{
          setActiveErrorsMssg(key, "")
        }
        break;
      }
      case "tags": {
        console.log(inputValue?.length, 'checking input value')
        if (inputValue?.trim() == "" && selectedTag === "others") {
          setErrorMssg(key, errMssgs.tags);
        }else {
          setErrorMssg(key, '');
        }

        const trimmedInput = inputValue?.trim();
        if (trimmedInput === "") {
          setActiveErrorsMssg(key, "");
        } else if (inputValue.startsWith(" ")) {
            setActiveErrorsMssg(key, "Avoid starting with a space");
        } else {
            setActiveErrorsMssg(key, "");
        }


        // if(inputValue == ""){
        //   setActiveErrorsMssg(key, "")
        // }else if(inputValue[0] === " "){
        //   setActiveErrorsMssg(key,"Avoid starting with a space")
        // }
        break;
      }
      case "contact": {
        inputValue = inputValue.substring(0, 10);
        if (inputValue === "" || inputValue.length !== 10) {
          setErrorMssg(key, errMssgs.contact);
        } else {
          setErrorMssg(key, "");
        }
      }
    }
  };

  const inputValue =async (key: string, inputValue: string) => {
    checkError(key,inputValue)

    setValues((prevState) => ({ ...prevState, [key]: inputValue }));
    deliverystate[key] = inputValue
  };

  const handleAddData = (e: any) => {
    if(!(!isFormValid || deliverystate.pincode.length < 6 || deliverystate.wrongPincode)){
      shippingstate.serviceFetched = false
      cartstate.orderFetched = false
      e.preventDefault();
      addressstate.addingNewAddress = Math.random() * 10;
      loginstate.addSilentUserAddress = true;
      cartstate.cartAddressUpdated = false;
      // reset values in case user enters wrong address again
      addressstate.failMsg = null;
      addressstate.invalidEmail = null;
      document.getElementById("addAddressBtn")?.setAttribute("disabled", "true");
      var addressButton = document.getElementById('addAddressBtn')
      if (addressButton != null) {
        addressButton.innerHTML = 'Adding address...'
      }
      let fn = values.name;
      let ln = values.lastName;
  /*     if (values.name.indexOf(" ") === -1) {
        fn = values.name.substring(values.name.indexOf(" ") + 1);
      } else {
        fn = values.name.substring(0, values.name.indexOf(" "));
        ln = values.name.substring(values.name.indexOf(" ") + 1);
      } */
      let AddressPayload = {
        "address1": values.line1?.replace(/[()]/g, ' '),
        "landmark":values.landmark,
        "city": deliverystate.Pincode_data?.postcode_details?.city?.trim()?.replace(/\./g, " "),
        "email": values.email,
        "fname": fn,
        "lname": ln,
        "pincode": deliverystate.pincode,
        "state": deliverystate.Pincode_data?.postcode_details?.state,
        "state_code": "",
        "phone": loginstate.mobileNumber,
        "mobile": loginstate.mobileNumber,   
        "gender" : values.gender ?? '',
        "country": "India",
        "country_code": "IN",
        "addressType":["shipping"],  
        "seller_id": loginstate.sellerConfig?.seller_id,
        tags: selectedTag === "others" ?  `other: ${values?.tags}` : selectedTag
    }
    
      let ExistingAddressPayload ={
          phone: loginstate.mobileNumber,
          line1: values.line1.replace(/\n/g, " ").trim()?.replace(/[()]/g, ' '),
          line2: values.line2.replace(/\n/g, " ").trim(),
          gender : values.gender ?? '',
          city: deliverystate.city.trim()?.replace(/\./g, " "),
          pincode: deliverystate.pincode,
          state:deliverystate.Pincode_data?.postcode_details?.state,
          country: "IN",
          sellerID:loginstate.sellerConfig?.seller_id,
          landmark:values.landmark.trim(),
          firstName:fn.trim(),
          lastName:ln.trim(),
          addressType:["Shipping"],
          email:values.email,
          countryCode: "+91",
          cartId : cartstate?.Cart_data?.id,
          alternatePhone : loginstate?.sellerConfig?.enableAdrrPhone ? (phoneNumber !== loginstate.mobileNumber && phoneNumber !==otpstate.mobile_no) ? phoneNumber : null :null, 
          tags: selectedTag === "others" ?  `other: ${values?.tags}` : selectedTag
        // isDefault: true,
        //  lastUsedDateTime:"2022-07-21T07:44:04.001+00:00",
        // noOfDeliveredOrders:1,
        // totalOrders:12  
      }

      // let AddressPayload = {
      //   newAddress: {
      //     address1: values.line1,
      //     city: deliverystate.Pincode_data?.postcode_details?.city,
      //     email: values.email,
      //     fname: fn,
      //     lname: ln,
      //     pincode: deliverystate.pincode,
      //     state: deliverystate.Pincode_data?.postcode_details?.state,
      //     state_code: deliverystate.Pincode_data?.postcode_details?.state_code,
      //     phone: loginstate.mobileNumber,
      //     mobile: loginstate.mobileNumber,
      //     country: deliverystate.Pincode_data?.postcode_details?.country,
      //     country_code: "IN",
      //     channel_id: loginstate.channel.channel_id,
      //     company_id: loginstate.channel.sr_company_id,
      //     channel_code: loginstate.channel.channel_code,
      //   },
      //   channel_code: loginstate.channel.channel_code,
      //   channel_id: loginstate.channel.channel_id,
      // };
      values.completeAdd =
        values.line1 +
        ", " +
        AddressPayload.city +
        ", " +
        AddressPayload.state +
        ", " +
        deliverystate.pincode;
      if (loginstate.AuthTokenGenerated) {
        addressstate.New_User_Address_Payload = AddressPayload;
        cartstate.showPreferredShipping = false;
        //dispatch(addSilentUserAddress(AddressPayload));
        dispatch(addNewUserAddress(AddressPayload))
        //  setnewUserData(false)
      } else if(loginstate?.otpSkip){
        dispatch({
          type : 'SKIP_Otp_Address_add',
          payload : {
            ...ExistingAddressPayload,
            emailConfirmed : true
          }
        })
      } else {
        dispatch(addExistingUserAddress(
          ExistingAddressPayload, 
          loginstate.userInfo.user_id,
          otpstate.fetchAddressPermission,
          loginstate.sellerConfig.seller_id,
          loginstate.sellerConfig.blockOldAddress
          )
        )
      }   
    }else{
      if(!deliverystate.Pincode_data?.success || deliverystate.loader || deliverystate.pincode.length < 6 || deliverystate.wrongPincode){
        document.getElementById("pincode")?.focus()
      }else{
        document.getElementById(firstErrorKey)?.focus()
      }
    }
  };

  return <>{renderaddressdetails()}</>;
};

export default Delivery;
