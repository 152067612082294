
const checkoutStartedWebengage = (data) => {
    window.parent.postMessage(
        {
        trigger: "Webengage",
        eventName : 'Checkout created' ,
        data : data
        },
        "*"
    )
}

const loginWebengage = (data) => {
    window.parent.postMessage(
        {
        trigger: "Webengage",
        eventName : 'login' ,
        data : data
        },
        "*"
    )
}

const phoneWebengage = (data) => {
    window.parent.postMessage(
      {
        trigger: "Webengage",
        eventName : 'we_phone' ,
        data : data
      },
      "*"
    )
};

const firstNameWebengage = (data) => {
    window.parent.postMessage(
      {
        trigger: "Webengage",
        eventName : 'we_first_name' ,
        data : data
      },
      "*"
    )
};
const lastNameWebengage = (data) => {
    window.parent.postMessage(
      {
        trigger: "Webengage",
        eventName : 'we_last_name' ,
        data : data
      },
      "*"
    )
};

const emailWebengage = (data) => {
    window.parent.postMessage(
      {
        trigger: "Webengage",
        eventName : 'we_email' ,
        data : data
      },
      "*"
    )
};

const checkoutUpdatedWebengage = (data) => {
  window.parent.postMessage(
    {
      trigger: "Webengage",
      eventName : 'Checkout updated' ,
      data : data
    },
    "*"
  )
};


var WebEngage = {
    checkoutStartedWebengage,
    loginWebengage,
    phoneWebengage,
    firstNameWebengage,
    lastNameWebengage,
    emailWebengage,
    checkoutUpdatedWebengage
};

export default WebEngage;
