import React, { useEffect } from "react";
import styles from "./OrderSummary.module.css";
import { useSelector } from "react-redux";
import Modal from "../Modal/Modal";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { Store } from "../../service/StorageHelper";
import Alert from "../Utils/Alert";

const OrderSummaryDrawer = (props : any) => {

    const loginstate = useSelector((state: any) => state.Login);
    const addressstate = useSelector((state: any) => state.Address);
    const cartState = useSelector((state: any) => state.Cart);
    const couponState = useSelector((state: any) => state.Coupon);
    const shippingstate = useSelector((state: any) => state.Shipping);
    const otpstate = useSelector((state: any) => state.Otp);   
    const UrlQueryStrings = window.location.search;
    let params = new URLSearchParams(UrlQueryStrings);
    let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false

    const displayCoupon = (couponName:string) => {
        if(couponName?.length > 20){
          return `${couponName?.slice(0, 20)}...`
        }else {
          return couponName
        }
      }

    return(
        <Modal
            open={props.orderSummaryModalVisible}
            title= { <div style={{display:'flex',flexDirection : 'column'}}>
                <div style={{display:'flex', gap: 4}}>
            <div className={styles.titleCard}>Order summary</div>
            <div className={styles.labelCard}>(
            {cartState?.orderDetails?.lineItems?.length && addressstate.selectedAddress ? cartState?.orderDetails?.lineItems?.length : cartState?.Cart_data?.items?.length || cartState?.initialCartData?.length} Item
            {(cartState?.orderDetails?.lineItems?.length
                ? cartState?.orderDetails?.lineItems?.length
                : cartState?.Cart_data?.items?.length) === 1 ? "" : "s"}
            )
            </div>
            </div>
            { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && !cartState.orderPending && !cartState?.hasBundleProduct && (
                (!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) ? 
                    <div 
                    className={styles.selectGiftRow} 
                    onClick={(e) => {
                        e.stopPropagation()
                    }} 
                    >
                    <input
                    id="selectAllGift"
                    type="checkbox"
                    onChange={(e) => {
                        if((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching)){
                            if(e.target.checked) {
                            props.GiftWrap("ALL", "APPLY")
                            } else {
                            props.GiftWrap("ALL", "REMOVE")
                            }
                        }
                    }}
                    disabled = {shippingstate?.orderBlock}
                    style={{accentColor:'#3173E3', marginTop:'4px', cursor : 'pointer' }}
                    checked={props.checkIfAllGiftWrap()}
                    />
                    {props.checkIfAllGiftWrap() ? 
                    <h3 className={styles.singleGiftTextCard}>Your order is gift wrapped at ₹{props.getPriceForGiftWrap()}</h3> : 
                    <h3 className={styles.singleGiftTextCard}>Select all for gift wrapping at ₹{props.getPriceForGiftWrap()}</h3>}
                    </div> 
                    :
                    <div className='skeleton__text__small width30' style={{marginLeft:'16px',marginBottom : '12px'}}></div>
                )
                }
          </div>}
            onClose={() => {
                AnalyticService.orderSummaryCollapsed();
                AnalyticService.additionalEvents(
                    "order-summary-collapsed",
                    cartState?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                );
                props.setOrderSummaryModalVisible(false);
                
            }}
            showClose={true}
        >       
        <div style={{ transition:'all .5s', paddingTop: props.checkIfAnyGiftWrap() ? '10px' : ''}}>
                {((cartState?.orderDetails?.lineItems?.length > 0 && addressstate.selectedAddress) || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? (
                    cartState?.orderDetails?.lineItems?.map((item: any, index: any) => (
                    //card - order details
                    <div style={{overflow: 'visible', position: 'relative'}}>
                        {item?.customAttributes?.gift_wrap && <img src="/assets/images/svg/gift-ribbon.svg" style={{ zIndex: '10', position: 'absolute', top: '-4px', right: '-6px', transform: 'rotate(45deg)' }} width={24} />}

                        <div   
                        key={index} 
                        className={styles.orderItemsCard} 
                        style=
                        {{  
                            background: 
                            (item?.customAttributes?.freebie && !item?.customAttributes?.prepaidFreebie) ?
                            'linear-gradient(87.87deg, #FFFFFF 0.98%, #EDF2FC 99.48%)' :
                            (item?.customAttributes?.freebie && item?.customAttributes?.prepaidFreebie) ?
                            'linear-gradient(88.49deg, #FFFFFF 30.57%, #DEFFE2 78.28%)' :
                            (item?.customAttributes?.gift_wrap) ? 'linear-gradient(93.08deg, #FFF8F7 34.26%, #FFFFFF 100%)' :
                            "",
                            border: item?.customAttributes?.gift_wrap ? '1px solid #DDAF6A': '' 
                        }}
                    >
                        <div className="d-flex ml-15">
                        <div className={styles.itemImage}>
                        {loginstate.sellerConfig.domainName === "myxtur.com" ? <img src={item?.customAttributes?.image ?? item?.imageUrl} alt={item?.name}/> : <img src={item?.imageUrl} alt={item?.name} /> }
                        </div>
                        <div className={styles.itemDetails}>
                            <div className={styles.itemTitleCard}>{item?.name}</div>
                            {loginstate?.sellerConfig?.domainName === "everlasting.shop" && item?.customAttributes && (
                            <div className={styles.itemTitleCard}>{
                                Object.keys(item?.customAttributes).map((key, i) => (
                                (key !== 'For COD orders team will ask you for an advance payment of INR 200' && key !== 'contentId') ? `${item?.customAttributes[key]} / ` : ''
                                ))
                                }</div>                        
                            )
                            }
                            {loginstate?.sellerConfig?.domainName === "etchcraftemporium.in" && item?.item_meta_data?.properties && (
                            Object.keys(item?.item_meta_data?.properties).map((key, i) => (
                                <div className={styles.itemTitleCard}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                            ))
                            )}
                            <div className={styles.itemQuantityCard}>
                            Qty: {item?.quantity}
                            </div>
                            {shippingstate?.selected_service_data?.nonDeliverableItems?.find((nonDeliverableItem : any)=>nonDeliverableItem?.variantId == item?.itemId) && 
                            <Alert type = "error" alertMessage = "Product unavailable at selected pincode"/>
                            }
                            <div className={styles.itemPriceCard} >
                            {
                                    item?.customAttributes?.freebie ? 
                                    <>
                                        <span style={{color:'#000000'}}>₹0</span>
                                        <span style={{color:'#827979', textDecoration:'line-through', marginLeft:'3px'}}>₹{loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price  &&  (item?.compareAtPrice > item?.price) ? 
                                        item?.compareAtPrice.toFixed(2) : 
                                        item?.price?.toFixed(2)}</span>
                                        <span style={{color:'#238017', marginLeft:'3px'}}>FREE</span>
                                    </>
                                     : 
                                    `₹${item?.price?.toFixed(2)}`
                            }
                                {!item?.customAttributes?.freebie && loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price  &&  (item?.compareAtPrice > item?.price) &&  <span style={{ fontWeight: 400, color:'#909090', textDecoration:'line-through', marginLeft: '4px'}}>₹{item?.compareAtPrice?.toFixed(2)}</span>}
                            </div>
                            { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && String(loginstate.sellerConfig?.giftWrapDetails?.variantId) !== String(item.itemId) &&  !cartState.orderPending && 
                            !(loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) && !cartState.hasBundleProduct &&
                                ((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) && !item?.customAttributes?.freebie ? 
                                <div style={{marginTop : '4px'}} className={styles.selectGiftRow} onClick={(e) => e.stopPropagation()}>
                                <input
                                id="selectGift"
                                type="checkbox"
                                onChange={(e) => {
                                    if((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching)){
                                        if(e.target.checked) {
                                        props.GiftWrap("PRODUCT", "APPLY", item.itemId)
                                        } else {
                                        props.GiftWrap("PRODUCT", "REMOVE", item.itemId)
                                        }
                                    }
                                }}
                                disabled = {shippingstate?.orderBlock}
                                style={{ accentColor: '#3173E3', cursor : 'pointer' }}
                                checked={item?.customAttributes?.gift_wrap ? true : false}
                                />
                                <h3 className={styles.singleGiftTextCard} style={{marginTop : '0px'}}>Gift wrap at ₹{(item.quantity*loginstate.sellerConfig?.giftWrapDetails?.price).toFixed(2)}</h3>
                            </div> 
                            :
                            !item?.customAttributes?.freebie && <div className='skeleton__text__small' style={{width:'100px'}}></div>
                                )
                            }
                            {
                                item?.customAttributes?.freebie && !item?.customAttributes?.prepaidFreebie ? 
                                    <div 
                                        className={styles.freebieTag} 
                                        style={{ background: 'linear-gradient(90.34deg, rgba(255, 255, 255, 0.4026) 20.11%, rgba(230, 239, 255, 0.61) 76.33%)' }}>
                                        <img src={process.env.PUBLIC_URL + "assets/images/gift.gif"} alt="gift gif" height={30} style={{marginLeft: "-8px"}} ></img>
                                        Free Gift
                                    </div> :
                                    item?.customAttributes?.freebie && item?.customAttributes?.prepaidFreebie ?
                                    <div
                                        className={styles.freebieTag}
                                        style={{ background: 'linear-gradient(90.34deg, rgba(255, 255, 255, 0.4026) 4.48%, rgba(193, 255, 199, 0.61) 89.76%)' }}>
                                        <img src={process.env.PUBLIC_URL + "assets/images/gift.gif"} alt="gift gif" height={30} style={{marginLeft: "-8px"}} ></img>
                                        Prepaid Exclusive Gift
                                    </div> : null
                            }
                        </div>
                        </div>
                    </div>
                    </div>
                    
                    ))
                ) : (
                    loginstate?.cartItems && loginstate?.cartItems?.map((item: any, index: any) => (
                        // card - cart details
                        <div style={{overflow: 'visible', position: 'relative'}}>
                             {item?.customAttributes?.gift_wrap && 
                                <img src="/assets/images/svg/gift-ribbon.svg" 
                                    style={{ zIndex: '10', position: 'absolute', top: '-6px', right: '-6px', transform: 'rotate(45deg)' }} 
                                    width={24}
                                />
                            }
                            <div 
                            key={index} 
                            className={styles.orderItemsCard}
                            style=
                            {{  
                                background: 
                                (item?.customAttributes?.freebie && !item?.customAttributes?.prepaidFreebie) ?
                                'linear-gradient(87.87deg, #FFFFFF 0.98%, #EDF2FC 99.48%)' :
                                (item?.customAttributes?.freebie && item?.customAttributes?.prepaidFreebie) ?
                                'linear-gradient(88.49deg, #FFFFFF 30.57%, #DEFFE2 78.28%)' :
                                (item?.customAttributes?.gift_wrap) ? 'linear-gradient(93.08deg, #FFF8F7 34.26%, #FFFFFF 100%)' :
                                "",
                                border: item?.customAttributes?.gift_wrap ? '1px solid #DDAF6A': '' 
                            }}
                        >
                        
                       

                        <div className="d-flex ml-15">
                            <div className={styles.itemImage}>
                            <img src={item?.image || item?.imageUrl} alt={item?.name} />
                            </div>
                            <div className={styles.itemDetails}>
                            <div className={styles.itemTitleCard}>{item?.title || item?.name}</div>
                            {loginstate?.sellerConfig?.domainName === "everlasting.shop" && item?.customAttributes && (
                                <div className={styles.itemTitleCard}>{
                                Object.keys(item?.customAttributes).map((key, i) => (
                                // <div className={styles.itemTitle}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                                key !== 'For COD orders team will ask you for an advance payment of INR 200' ? `${item?.customAttributes[key]} / ` : ''
                            ))
                            }</div>
                            )
                            }
                            {loginstate?.sellerConfig?.domainName === "etchcraftemporium.in" && item?.item_meta_data?.properties  && (
                            Object.keys(item?.item_meta_data?.properties).map((key, i) => (
                                <div className={styles.itemTitleCard}>{`${key}: "${item?.item_meta_data?.properties[key]}"`}</div>
                            ))
                            )}
                            <div className={styles.itemQuantityCard}>
                                Qty: {item?.quantity}
                            </div>
                            {shippingstate?.selected_service_data?.nonDeliverableItems?.find((nonDeliverableItem : any)=>nonDeliverableItem?.variantId == item?.itemId) && 
                            <Alert type = "error" alertMessage = "Product unavailable at selected pincode"/>
                            }
                            <div className={styles.itemPriceCard}>
                            {
                                item?.customAttributes?.freebie ? 
                                <>
                                    <span style={{color:'#000000'}}>₹0</span>
                                    <span style={{color:'#827979', textDecoration:'line-through', marginLeft:'3px'}}>₹{loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price  &&  (item?.compareAtPrice > item?.price) ? 
                                        item?.compareAtPrice.toFixed(2) : 
                                        item?.price?.toFixed(2)}</span>
                                    <span style={{color:'#238017', marginLeft:'3px'}}>FREE</span>
                                </>
                                    : 
                                `₹${item?.price?.toFixed(2)}`
                            }
                                {!item?.customAttributes?.freebie && loginstate.sellerConfig?.showCompareAtPrice && item?.compareAtPrice && item?.price  &&  (item?.compareAtPrice > item?.price) &&  <span style={{ fontWeight: 400, color:'#909090', textDecoration:'line-through', marginLeft: '4px'}}>₹{item?.compareAtPrice?.toFixed(2)}</span>}
                            </div>
                            { loginstate.sellerConfig?.giftWrapDetails?.enabled && loginstate.sellerConfig?.giftWrapDetails?.variantId && String(loginstate.sellerConfig?.giftWrapDetails?.variantId) !== String(item.itemId) && !cartState.orderPending && 
                            !(loginstate?.sellerConfig?.giftWrapDetails?.singleGiftWrap) && !cartState.hasBundleProduct &&
                                ((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching) && !item?.customAttributes?.freebie ? 
                                <div style={{marginTop : '4px'}} className={styles.selectGiftRow} onClick={(e) => e.stopPropagation()}>
                                <input
                                id="selectGift"
                                type="checkbox"
                                onChange={(e) => {
                                    if((!props.giftWrapLoading && !couponState.loader && !shippingstate.serviceFetching && !addressstate.fetchingAllAddress && !cartState.orderFetching)){
                                        if(e.target.checked) {
                                        props.GiftWrap("PRODUCT", "APPLY", item.itemId)
                                        } else {
                                        props.GiftWrap("PRODUCT", "REMOVE", item.itemId)
                                        }
                                    }
                                }}
                                disabled = {shippingstate?.orderBlock}
                                style={{ accentColor: '#3173E3',cursor : 'pointer' }}
                                checked={item?.customAttributes?.gift_wrap ? true : false}
                                />
                                <h3 className={styles.singleGiftText} style={{marginTop : '0px'}}>Gift wrap at ₹{(item.quantity*loginstate.sellerConfig?.giftWrapDetails?.price).toFixed(2)}</h3>
                            </div> 
                            :
                            !item?.customAttributes?.freebie && <div className='skeleton__text__small' style={{width:'100px'}}></div>
                            )
                            }

                            {/* variants rendering */}
                            {/* {item.optionsArr.map((option: any) => (
                                <div className={styles.itemQuantity}>
                                {option.name}: {option.value}
                                </div>
                            ))} */}
                            {
                                item?.customAttributes?.freebie && !item?.customAttributes?.prepaidFreebie ? 
                                <div className={styles.freebieTag} 
                                style={{ background: 'linear-gradient(90.34deg, rgba(255, 255, 255, 0.4026) 20.11%, rgba(230, 239, 255, 0.61) 76.33%)' }}>
                                    <img src= {process.env.PUBLIC_URL + "assets/images/gift.gif"} alt="gift gif" height={30} style={{marginLeft: "-8px"}}></img>
                                    Free Gift
                                </div> : 
                                item?.customAttributes?.freebie && item?.customAttributes?.prepaidFreebie ?
                                <div className={styles.freebieTag}
                                style={{ background: 'linear-gradient(90.34deg, rgba(255, 255, 255, 0.4026) 4.48%, rgba(193, 255, 199, 0.61) 89.76%)' }}>
                                    <img src= {process.env.PUBLIC_URL + "assets/images/gift.gif"} alt="gift gif" height={30} style={{marginLeft: "-8px"}}></img>
                                    Prepaid Exclusive Gift
                                </div> : null
                            }
                        </div> 
                        </div>
                        </div>
                        </div>
                        
                    ))
                )}
        </div>

                <div className={styles.subHeading}>
                Bill summary
                </div>

                {cartState.orderDetails?.totalPrice || cartState.Cart_data?.totalPrice ? (
                <div className={styles.summaryLabelCard}>
                    <div>Sub total 
                        {
                            (
                                loginstate?.sellerConfig?.domainName === "arabianaroma.co.in" ||
                                loginstate?.sellerConfig?.domainName === "arabianaroma.in"
                            ) ? <span>&nbsp; (incl. of all taxes)</span> : ''
                        }
                        
                    </div>
                    <div>&#8377;{loginstate?.sellerConfig?.showCompareAtPrice && props?.isOrderMRPGreater ?
                    Number(cartState.orderDetails?.totalPrice
                        ? props?.totalOrderDetailsMRP
                        : props?.totalCartDetailsMRP).toFixed(2) :
                    Number(cartState.orderDetails?.totalPrice
                        ? cartState.orderDetails?.totalPrice
                        : cartState.Cart_data?.totalPrice).toFixed(2)
                    }</div>
                </div>
                ) : null}
            
                {(!cartState.orderDetails?.id || addressstate.selectedAddress === null) && !(loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock) && !loginstate.sellerConfig?.nativeShopifyCheckout && (
                    <>
                        {(loginstate?.sellerConfig?.showCompareAtPrice && props?.isOrderMRPGreater &&
                            <div className={styles.summaryLabelCard} style={{ color: '#238017' }}>
                                <div>Discount on MRP</div>
                                <div>- &#8377; {Number(cartState.orderDetails?.totalPrice
                                    ? props?.totalOrderDetailsMRP - cartState.orderDetails?.totalPrice
                                    : props?.totalCartDetailsMRP - cartState.Cart_data?.totalPrice).toFixed(2)}</div>
                            </div>)
                        }
                        {(cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) &&
                        (cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.map((couponCodeObj:any)=>{
                            return(
                            <div className={styles.summaryLabelCard} style={{color : '#238017'}}>
                                <div>Coupon discount 
                                ({ displayCoupon(couponCodeObj?.discount_code)})
                                </div> 
                                <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                            </div>
                            )
                        })
                        )}
                        {(cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0) &&
                        (cartState.Cart_data?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.map((couponCodeObj:any)=>{
                            return(
                                <div className={styles.summaryLabelCard} style={{color : '#238017'}}>
                                <div>Auto discount</div> 
                                <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                            </div>
                            )
                        })
                        )}
                    </>
                )}  

                {(cartState.orderDetails?.id && (addressstate?.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) && !loginstate.sellerConfig?.nativeShopifyCheckout) 
                && (
                    <>
                    {(loginstate?.sellerConfig?.showCompareAtPrice && props?.isOrderMRPGreater &&
                <div className={styles.summaryLabelCard} style={{color : '#238017'}}>
                  <div>Discount on MRP</div>
                  <div>- &#8377; {Number(cartState.orderDetails?.totalPrice
                    ? props?.totalOrderDetailsMRP - cartState.orderDetails?.totalPrice
                    : props?.totalCartDetailsMRP - cartState.Cart_data?.totalPrice).toFixed(2)}</div>
                </div>)
              }
                    {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.length > 0) &&
                    (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type !== "automatic")?.map((couponCodeObj:any)=>{
                        return(
                        <div className={styles.summaryLabelCard} style={{color : '#238017'}}>
                            <div>Coupon discount 
                            ({ displayCoupon(couponCodeObj?.discount_code)})
                            </div> 
                            <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                        </div>
                        )
                    })
                    )}
                    {(cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.length > 0) &&
                    (cartState.orderDetails?.discountDetail?.discount_data?.filter((couponObj : any)=>couponObj?.discount_type === "automatic")?.map((couponCodeObj:any)=>{
                        return(
                        <div className={styles.summaryLabelCard} style={{color : '#238017'}}>
                            <div>Auto discount</div> 
                            <div style={{color : '#238017'}}>- &#8377;{couponCodeObj?.discount_amount?.toFixed(2)} </div>
                        </div>
                        )
                    })
                    )}
                    {
                       (cartState.loyaltyPointDetails?.status === "APPLIED" &&  cartState.loyaltyPointDetails?.applicable_credits>0 &&
                        <div  style={{paddingLeft:'0px'}} className={styles.summaryLabel}>
                          <div>Rewards & Loyalty Points</div> 
                          <div className="new__green__text">- &#8377;{cartState.loyaltyPointDetails?.applicable_credits}</div>
                        </div>)
                    }
                    </>
                )} 


                {(addressstate.selectedPaymentMode === "COD")&&
                cartState.orderDetails?.codCharges > 0 ? (
                <div className={styles.summaryLabelCard}>
                    <div>COD charges</div>
                    <div >&#8377;{cartState.orderDetails?.codCharges?.toFixed(2)}</div>
                </div>
                ) : null}
                
                {cartState?.orderDetails?.shippingCharges && (addressstate.selectedAddress || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? (
                <div className={styles.summaryLabelCard}>
                    <div>Delivery charges</div>
                    <div className={cartState?.orderDetails?.shippingCharges < 0 ? 'ascent-green' : ''}>
                    {cartState?.orderDetails?.shippingCharges > 0 ? (
                        <>
                        &#8377;{Number(cartState.orderDetails?.shippingCharges).toFixed(2)}{" "}
                        </>
                    ) : (
                        "FREE"
                    )}
                    </div>
                </div>
                ) : null}

                <div className={styles.summaryTotalAmountCard}>
                <div>Total amount</div>
                {((Object.keys(cartState.orderDetails).length > 0 && addressstate.selectedAddress) || (loginstate?.sellerConfig?.enabledFeatureCodForm && !otpstate?.verified && cartState.orderFetched && !shippingstate?.orderBlock)) ? 
                <div>
                    &#8377;{(addressstate.selectedPaymentMode === "COD") ? 
                    cartState.orderDetails?.codPrice?.toFixed(2) : 
                    (cartState.orderDetails?.totalAmount + cartState.orderDetails?.prepaidDiscount ?? 0).toFixed(2)
                    } 
                </div>
                :
                <div>
                    &#8377;{cartState.Cart_data?.totalDiscount ? 
              (cartState.Cart_data?.totalPrice - cartState.Cart_data?.totalDiscount).toFixed(2) : 
              cartState.Cart_data?.totalPrice ? cartState.Cart_data?.totalPrice?.toFixed(2) : props?.finalPriceLoginPage.toFixed(2)
                    } 
                </div>
                }
                </div>

                {shippingstate?.selected_service_data?.nonDeliverableItems?.length && !props.showItems && !isProductPage &&
                    <div style={{width : '90%',margin : 'auto',display : 'flex', flexDirection : 'column',gap : '8px',textAlign : 'center'}}>
                        <span style={{fontWeight : '400',fontSize : '12px',lineHeight : '14px'}}>
                        You can continue checkout without that item
                        </span>
                        <button
                        className={ "modal_button_blue full-width"}
                        onClick={()=>{
                            if(props.isHeadless) {
                            window.parent.postMessage(
                            {
                                trigger: "headless-redirect",
                                actions: [
                                {
                                    action: "redirectTo",
                                    data: {
                                    url: `https://${loginstate.sellerConfig.domainName}/`,
                                    },
                                },
                                ],
                            },
                            "*"
                            );
                        } else {
                            window.parent.postMessage({ trigger: "headless-close" }, "*");
                        }}} 
                        style={{fontWeight : 500, cursor : 'pointer'}}>
                        Edit items in cart
                        </button>
                    </div>
                }
        </Modal>
    )
}

export default OrderSummaryDrawer