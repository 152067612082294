import AppStorage from "../../service/AppStorage";
import { Store } from "../../service/StorageHelper";
import { checkoutXwigzoHandler } from "../../utils/commonUtils";
import {removeLogoutSession} from '../../utils/guestSessions';

var Otp_initialstate = {
  loader: 1,
  fail: false,
  spinner: true,
  otpValue: "",
  Otp_data: {},
  verified: false,
  Otp_verified: {},
  counter: 30,
  mobile: "",
  wrongOtp: false,
  submitted: false,
  pim_sid_updated: null,
  failedAttempts: 0,
  errorMessage: '',
  resendErrorMessage: '',
  verifyFired: false,
  fetchAddressPermission: true,
  SSOPermission: true,
  user_profile_id_from_otp: "",
  otp_received:false,
  mobile_no: "",
  postOtpCouponsFetched: false,
  userLoggedIn: false
};

const Otp_reducer = (state = Otp_initialstate, action) => {
  switch (action.type) {
    case "SEND_Otp_REQUEST":
      return {
        ...state,
        Otp_data: {},
        loader: 1,
        wrongOtp: false,
        fail: false,
      };

    case "SEND_Otp_SUCCESS":
      return {
        ...state,
        Otp_data: action.payload,
        loader: 0,
        spinner: false,
        submitted: false,
        fail: false,
      };

    case "SEND_Otp_FAILURE":
      return {
        ...state,
        //fail: true,
        spinner: false,
        loader: 0,
        resendErrorMessage: action.payload
      };

      case "AFTER_FIVE_ATTEMPT":
      return {
        ...state,
        loader: 0,
        spinner: false,
        submitted: false,
        fail: false,
      }

    case "FETCH_OtpVerify_REQUEST":

      return {
        ...state,
        loader: 1,
        fail: false,
        wrongOtp: false,
        verifyFired: true
      };

    case "FETCH_OtpVerify_SUCCESS":
      removeLogoutSession(true)
      if(state.SSOPermission) {
        Store.setItem("refreshToken", action.refresh_token);
      }else{
        Store.removeItem('refreshToken')
      }
      Store.setItem('refreshTokenValue', action.refresh_token)
      Store.setItem('user_id', action.payload.user_id)
      //Call UC Handler
      checkoutXwigzoHandler(action.payload.mobile_no, action.payload.user_id)
      window.parent.postMessage(
        {
          trigger: 'set-cookie',
          data: {
            name: 'fastrr_user_id',
            data: action.payload.user_id,
          }
        },
        "*"
      )
      window.parent.postMessage(
        {
          trigger: 'set-cookie',
          data: {
            name: 'fastrr_user_profile_id',
            data: action.payload.user_profile_id,
          }
        },
        "*"
      )
      //AppStorage.setItem('user_profile_id', action.payload.user_profile_id)
      //AppStorage.setItem('mobile', action.payload.mobile_no)
      return {
        ...state,
        Otp_verified: action.payload,
        verified: true,
        loader: 0,
        fail: false,
        mobile: action?.payload?.mobile_no,
        pim_sid_updated : action?.pim_sid,
        verifyFired: false,
        user_profile_id_from_otp: action.payload.user_profile_id,
        otp_received: true,
        mobile_no: action?.payload?.mobile_no,
        userLoggedIn: true,
      };

    case "FETCH_OtpVerify_FAILURE":
      return {
        ...state,
        fail: true,
        Otp_verified: action.payload,
        loader: 0,
        wrongOtp: true,
        otpValue: "",
        failedAttempts: state.failedAttempts + 1,
        errorMessage: action.payload,
        verifyFired: false
      };

    default:
      return state;
  }
};

export default Otp_reducer;
