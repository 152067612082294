import AppStorage from "./AppStorage"
const parser = require("ua-parser-js")

var ua = new parser("", "");
let deviceType = ua.getResult().os.name

const setCookieStorage = (c_name, c_value, date) => {
    var exdate = new Date(date);
    exdate.setDate(exdate.getDate());
    document.cookie =
      encodeURIComponent(c_name) +
      '=' +
      encodeURIComponent(c_value) +
      '; expires=' +
      exdate.toUTCString()+';path=/;SameSite=None;Secure';
  };

  const getCookieStorage = (cname) => {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const removeCookieStorage = (c_name, c_value, date) => {
    var exdate = new Date(date);
    exdate.setDate(exdate.getDate());
    document.cookie =
      encodeURIComponent(c_name) +
      '=' +
      encodeURIComponent(c_value) +
      '; expires=' +
      exdate.toUTCString()+';path=/;SameSite=None;Secure';
  };

  try {
    setCookieStorage("fastrrTestCookie", "true", 'Thu, 01 Jan 2070 00:00:00 UTC')
  } catch (e) {
      
  }

  const checkIfSignedIn = () => {
      try {
          if(localStorage.getItem('refreshTokenValue') && deviceType !== "iOS") {
              return true
          } else {
              return false
          }
      } catch (e) {
          return false
      }
  }

  const checkIfLSAvailable = () => {
      try {
          if(typeof window.localStorage !== 'undefined' && deviceType !== "iOS") {
              return true
          } else {
              return false
          }
      } catch (e) {
          return false
      }
}

const checkLocalAccess = () => {
    try {
         if(deviceType !== "iOS" && getCookieStorage('fastrrTestCookie')) {
            return true
        } else {
            return false
        }
    } catch (e) {
        return false
    }
}

export const Store = {
    setItem: (name, value) => {
        if(checkIfSignedIn()) {
            localStorage.setItem(name, value)
            if(checkLocalAccess()) {
                setCookieStorage(name, value, 'Thu, 01 Jan 2070 00:00:00 UTC')
            }
        } else {
            if(checkLocalAccess()) {
                setCookieStorage(name, value, 'Thu, 01 Jan 2070 00:00:00 UTC')
            } else {
                AppStorage.setItem(name, value)
            }
        }
    },
    getItem: (name) => {
        if(checkIfSignedIn()) {
            if(checkLocalAccess()){
                if(!getCookieStorage('refreshToken')) {
                setCookieStorage("pim_sid",localStorage.getItem('pim_sid'), 'Thu, 01 Jan 2070 00:00:00 UTC')
                setCookieStorage("expireTime",localStorage.getItem('expireTime'), 'Thu, 01 Jan 2070 00:00:00 UTC')
                setCookieStorage("refreshToken",localStorage.getItem('refreshToken'), 'Thu, 01 Jan 2070 00:00:00 UTC')
                setCookieStorage("refreshTokenValue",localStorage.getItem('refreshTokenValue'), 'Thu, 01 Jan 2070 00:00:00 UTC')
                setCookieStorage("user_id",localStorage.getItem('user_id'), 'Thu, 01 Jan 2070 00:00:00 UTC')
            }}
            return localStorage.getItem(name)
        } else {
            if(checkLocalAccess()) {
                return decodeURIComponent(getCookieStorage(name))
            } else {
                return AppStorage.getItem(name)
            }
        }
    },
    removeItem: (name) => {
        if(checkIfLSAvailable()) {
            localStorage.removeItem(name)
        }
        if(checkLocalAccess()) {
            removeCookieStorage(name, "", 'Thu, 01 Jan 1970 00:00:00 UTC')
        } else {
            AppStorage.removeItem(name)
        }
    }
}
