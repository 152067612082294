import React, { useCallback, useEffect, useState, useRef} from "react";
import { customerExistModel, otpRequestModel } from "../../models/Model";
import Loader from "../Utils/Loader";
import style from "./Login.module.css";
import Delivery from "../Delivery/Delivery";
import ErrorHandler from "../Error/ErrorHandler";
import {
  createNewCustomer,
  isNumberExists,
  doesUserExist
} from "../../Redux/Login/Login_actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { requestOtp } from "../../Redux/Otp/Otp_actions";
import Otp from "./Otp";
import Shipping from "../Shipping/Shipping";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { fireAnalyticsEvent } from "../Event/Event";
import OtpSkeleton from "../Skeleton/OtpSkeleton";
import { walkUpBindingElementsAndPatterns } from "typescript";
import DeliveryNew from "../DeliveryNew/DeliveryNew";
import TermsCondition from "../CustomLogin/TermsCondition";
import Button from "../UI/Button/Button";
import apiUrls from "../../api/Apis";
import Http from "../../service/Http";
import { useOnScreen } from "../../utils/Hooks/onScreen";
import { coverageCartId as coverage } from "../../utils/constants";
import LoginTermsConditions from "../CustomLogin/TermsCondition/loginT&C";
import { Store } from "../../service/StorageHelper";


const Login = (props: any) => {
  // const [showError, setShowError] = useState(false);
  const [mobileScreenOpened, setMobileScreenOpened] = useState(false);
  const loginstate = useSelector((state: any) => state.Login);
  const otpstate = useSelector((state: any) => state.Otp);
  const cartstate = useSelector((state: any) => state.Cart);
  const addressstate = useSelector((state:any)=>state.Address)
  const couponState = useSelector((state:any)=>state.Coupon)
  loginstate.onLoginPage = true;
  const dispatch = useDispatch();
  const [userTypeChecking, setUserTypeChecking] = useState<boolean>(false)
  const [phoneNumberEnterd, setPhoneNumberEnterd] = useState<any>('');
  const [otpTimer, setOtpTimer] = useState<boolean>(false);
  const [phoneEntryStartedGtm, setPhoneEntryStartedGtm] = useState<boolean>(false)
  const [mobileValue, setMobileValue] = useState("")
  const [mobileError, setMobileError] = useState(false);
  const [displayPhoneError,setDisplayPhoneError]=useState<boolean>(false)
  const timerRef=useRef<any>(null)
  const [loginTermsConditionsChecked, setLoginTermsConditionsChecked] = useState((Store.getItem("marketingConsent")===null)||(Store.getItem("marketingConsent")===undefined)?(loginstate?.sellerConfig?.marketingConsentConfig?.isChecked||false):Store.getItem("marketingConsent")=="false"?false:true)
  
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false
  let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;
  const productPageBuyNowSection = useRef(null);
  const isOnScreen =  useOnScreen(productPageBuyNowSection);


  useEffect(() => {
    if (loginstate.sellerConfig?.seller_id && !mobileScreenOpened) {
      //AnalyticService.mobileNumberScreenOpened();
    }
    setMobileScreenOpened(true);
  }, [loginstate.sellerConfig?.seller_id]);

  useEffect(()=>{
    cartstate.customerMarketingConsent=loginTermsConditionsChecked
  },[loginTermsConditionsChecked])

  useEffect(() => {
    if (loginstate.AuthTokenGenerated) {
      document.getElementById("mobile")?.removeAttribute("disabled");
      AnalyticService.createUserProfile(loginstate.mobileNumber);
      AnalyticService.checkoutStarted({
        phone:loginstate?.mobileNumber,
        total_price: loginstate?.cartData?.total_price,
      })
    }
  }, [loginstate.NewUser_Token]);

  useEffect(() => {
    if (!isProductPage) {
      return
    }
    if(isOnScreen) {
      dispatch({
        type: "UPDATE_CART_STATE", 
        payload: {key: "productBuyNowVisible", value: true}
      })
    } else {
      dispatch({
        type: "UPDATE_CART_STATE", 
        payload: {key: "productBuyNowVisible", value: false}
      })
    }
  }, [isOnScreen])


  useEffect(() => {
    //phone number entered
    if(loginstate.user_type_for_text){
      setUserTypeChecking(loginstate.user_type_for_text === "new" ? true : false);
      AnalyticService.phoneEntered(phoneNumberEnterd, cartstate?.Cart_data, couponState.couponCode, loginstate.user_type_for_text,loginstate?.sellerConfig?.marketingConfig);
    }
  }, [loginstate.user_type_for_text])

  useEffect(()=>{  
    setTimeout(()=>{
      phoneNumberRef?.current?.focus()
    }) 
  },[props.phoneNumberFieldFocus])

 

  const phoneNumberRef=useRef<any>(null)
  const loginCall = (number: any) => {
    setPhoneNumberEnterd(number);

    loginstate.createCustomerFired = true;
    loginstate.sendOtpfired = true;
    loginstate.NewUser_Token = null;
    document.getElementById("mobile")?.setAttribute("disabled", "true");
    if(loginstate.customLogin && (loginstate.sellerConfig?.domainName === "beyoung.in" || loginstate.sellerConfig?.domainName === "www.beyoung.in")){
      const sentData = {
        eventName : 'Phone_entered_fastrr',
        data : {
          phone : number
        }
      }
      window.parent.postMessage(
        {
          trigger: 'beyoung-analytics',
          data: sentData
        },
        "*"
      )
    }
    loginstate.mobileNumber = number;
    otpstate.mobile = number;

    let reqPayload: customerExistModel = {
      cred: loginstate.mobileNumber,
      tenant_id: loginstate.sellerConfig.seller_id,
      cart_id: loginstate.customLogin ? loginstate.verifyTokenConfig?.cart?.id  :  cartstate.Cart_data?.id,
      skip_existing_address_check : false
    };
    
    loginstate.spinner = true;
    dispatch(doesUserExist(reqPayload, loginstate.sellerConfig.seller_id,!LoginPopup ? loginstate?.sellerConfig?.enabledFeatureSkipOtp : false));
    window.parent.postMessage(
      {
        trigger: 'otp-read-start',
      },
      "*"
    )
    setTimeout(()=>
    setOtpTimer(true), 1000)
  }


  


  const mobileInputHandler = async (event: any) => {

    const mobileRegex = /^(\+91|0|91)?[6-9]{1}[0-9]{9}$/
    let number = event.target.value?.trim();

    if(number.length >= 10 && !isProductPage) {
      if(mobileRegex.test(number)) {
        number = number.slice(-10);
        loginCall(number)
        setMobileError(false)
      } else {
        setMobileError(true)
      }
    } 

    //for product page
    if(isProductPage && number.length >= 10 ) {
      number = number.slice(-10);
      setMobileValue(number)
    }

  };

  // const handleBuyNow = async () => {
  //   try {
  //   let cartItems = cartstate.Cart_data?.items?.filter((item: any) => String(item.itemId) !== String(loginstate.sellerConfig?.giftWrapDetails?.variantId))
  //   if((Number(cartstate.selectedVariant) === Number(cartItems[0]?.itemId)) && (Number(cartstate.selectedQuantity) === Number(cartItems[0]?.quantity))) {
  //     loginCall(mobileValue)
  //   } else {
  //     let requestPayload = {
  //       variant: true,
  //       items: [{
  //         itemId: cartstate.selectedVariant,
  //         quantity: cartstate.selectedQuantity,
  //         customAttributes: cartstate.Cart_data?.items[0].customAttributes
  //       },
  //       ...(cartstate.giftWrapProductPage ?[{
  //         itemId: loginstate.sellerConfig?.giftWrapDetails?.variantId,
  //         quantity: cartstate.selectedQuantity
  //     }] : [])
  //     ],
  //       channel: cartstate?.platform?.toUpperCase(),
  //       couponCode: ""
  //     }
  
  //     const response = await Http.put(
  //       `${apiUrls.CREATE_CART_DETAILS()}/${
  //         loginstate?.userInfo?.user_profile_id
  //       }`,
  //       requestPayload,
  //       loginstate.sellerConfig.seller_id
  //     );  

  //     if(response) {
  //       cartstate.Cart_data = response.data
  //       loginCall(mobileValue)
  //     }
  //   }
  // } catch (e) {

  //   }
  // }

  loginstate.fail = false;
  const sendOtp = useCallback(async (mobileNumber: any) => {
    let reqPayload: otpRequestModel = {
      mobile: otpstate.mobile,
      shop_name: loginstate.channel?.shop_name,
      company_id: loginstate.channel?.sr_company_id,
    };
    // otpstate.wrongOtp = false;
    if (
      (loginstate.sendOtpfired || loginstate.changeNumber) &&
      !loginstate.paymentPageReached
    ) {
      dispatch(requestOtp(reqPayload));
      loginstate.sendOtpfired = false;
      loginstate.changeNumber = false;
    }
  }, []);

  const seller_id = loginstate?.sellerConfig?.id
  const cart_id = cartstate?.Cart_data?.id
  const seller_conf:any = {"63ca3519eec3ea4de4d8e2f6":"100/0",
                        "630752c3c6d67e44d5687487":"80/20",
                        "6436c5a8e9a93249f17bc96b":"50/50",
                        "6333fb454f354c57324887c0":"50/50",
                        }
                        
const show_sr_promise_by_coverage = (seller_coverage:any)=>{
  if (seller_coverage){
    switch (seller_coverage) {
      case "50/50":
        return coverage["50/50"].test(cart_id?.slice(-1))
        break;
        case "80/20":
          return coverage["80/20"]?.test(cart_id?.slice(-1))
        case "100/0":
          return true
        default:
            return false
            break;
    }
  }else{
    return false
  }
 
}
const renderSrPromiseCard = ()=>{
  return(
    show_sr_promise_by_coverage(seller_conf[seller_id]) &&  <div className={!cartstate?.cardDesignEnabled ?style.card_sr_promise : 'card-container'} style={{background :!cartstate?.cardDesignEnabled ? '' : 'transparent',border :!cartstate?.cardDesignEnabled ? '' :  'none',boxShadow :!cartstate?.cardDesignEnabled ? '' :  'none'}}>
    <div className={!cartstate?.cardDesignEnabled ? style.header_icon : style.header_icon_card}>
      <img
        src={process.env.PUBLIC_URL + "./assets/images/sr_checkout_promise/sr_promise.svg"}
        alt="SR Promise"
        height="20"
      />
    {/* </span> */}
      </div>
      {!cartstate?.cardDesignEnabled &&
        <hr className={style.hr}/>
        }

    <ul className={style.bgCheckoutFeature} style={{marginTop:!cartstate?.cardDesignEnabled ? '35px' : '0px',marginBottom : !cartstate?.cardDesignEnabled ? '' : '0px', fontSize:'12px'}}>
  <li>
    <span className={style.icon}>
      <img
        src={process.env.PUBLIC_URL + "./assets/images/sr_checkout_promise/rupee.svg"}
        alt="Delivery Guaranteed"
      />
    </span>

    <span className={style.sr_promise_text}>
      Delivery <br />
      Guranteed
    </span>
  </li>
  <li>
    <span className={style.icon}>
      <img
        src={process.env.PUBLIC_URL +"./assets/images/sr_checkout_promise/card.svg"}
        alt="Secure Transactions"
      />
    </span>
    <span className={style.sr_promise_text}>
      Secure <br />
      Transactions
    </span>
  </li>
  <li>
    <span className={style.icon}>
      <img
        src={
          process.env.PUBLIC_URL +
          "./assets/images/sr_checkout_promise/store.svg"
        }
        alt="7 Days Easy Return & Refund"
      />
    </span>
    <span className={style.sr_promise_text}>
      7 Days Easy <br /> Return
    </span>
  </li>
  <li>
    <span className={style.icon}>
      <img
        src={
          process.env.PUBLIC_URL +
          "./assets/images/sr_checkout_promise/ship_box_location.svg"
        }
        alt="Easy Order Tracking"
      />
    </span>
    <span className={style.sr_promise_text}>
      Easy Order <br /> Tracking
    </span>
  </li>
</ul>
    </div>)
}
  const renderlogin = () => {
    if (loginstate.newUserAdd) {
      return (
        <>
          <Shipping
            onRender={props.onRender}
            onRenderLoading={props.onRenderLoading}
          />
        </>
      );
    } else if (
      (loginstate.loader === 1 ||
        loginstate.fail ||
        loginstate.user_type === "new") &&
      !addressstate.newUserAddressAdded
    ) {
      if (
        loginstate.loader === 0 &&
        !loginstate.fail &&
        loginstate.user_type === "new"
      ) {
        let newuserpayload = {
          type: "silent",
          mobile_no: loginstate.mobileNumber,
        };
        if (loginstate.createCustomerFired) {
          // Abhishek
          //dispatch(createNewCustomer(newuserpayload));
          //loginstate.AuthTokenGenerated = true // TODO check this statement
        }
      }
      return (
        <>
          {loginstate.addSilentUserAddress && (
            <div className={style.disablenew}></div>
          )}
          {!loginstate.enteredNumber && 
          <section className={`${cartstate?.cardDesignEnabled ? 'card-container login' : "login"}`} 
            style={{background:cartstate?.cardDesignEnabled?'none':'',display:'flex',flexDirection:'column',alignItems:'center',boxShadow:'none',border:'none'}}
            >
            {!LoginPopup && <p className={cartstate?.cardDesignEnabled ? "headingCard" : "heading"} style={{marginBottom:"4px", fontSize:loginstate.customLogin ? 20 : '', fontWeight:loginstate.customLogin ? 500 : ''}}>{loginstate.customLogin && !isProductPage ? 'Get started ' : isProductPage ? 'Shipping Details' : 'Enter phone number'}</p>}
            {!LoginPopup && <p className={cartstate?.cardDesignEnabled ? "contentCard" : "content" } style={{color:'#928F8F'}}>
            Provide your phone number to continue
            </p>
            }

              <form autoComplete="off" onSubmit={(e) => e.preventDefault()} style={{width:'100%'}}>
                <div className={style.inputGroup}>
                  <span className={style.inputAddon}>
                    <img
                      src={
                        process.env.PUBLIC_URL + "./assets/images/indian_flag.svg"
                      }
                      alt="India"
                      className="w-16"
                    />{" "}
                    <span className="code" style={{fontSize:'16px', fontWeight:'400'}}>+91</span>
                  </span>
                  <input
                    maxLength={isProductPage ? 10 : 15}
                    ref={phoneNumberRef}
                    id="mobile"
                    type="tel"
                    autoComplete="tel"
                    autoFocus
                    placeholder="10-digit phone number"
                    className={!LoginPopup ? (cartstate?.cardDesignEnabled && !mobileError) ? `form-control h3 pl-74 ${style.inputCard}` : 'form-control h3 pl-74' : `form-control h3 pl-74 pr-68 ${style.inputLoginPopup}`}
                    onChange={mobileInputHandler}
                    onInput={isNumber}
                    style={{
                      fontSize:'15px', 
                      fontWeight:'400',
                      border: (mobileError ||( displayPhoneError && phoneNumberRef?.current?.value?.length>0)) ? "1px solid red" : "",
                      color:'#8f8f8f'
                    }}
                    onClick={()=>{
                      if(!phoneEntryStartedGtm){
                        AnalyticService.additionalEvents(
                          "started-phone-entry",
                          cartstate.orderDetails,
                          couponState?.couponCode,
                          addressstate?.Address_data?.length
                        )
                      }
                      setPhoneEntryStartedGtm(true)
                    }}
                    onFocus={()=>{
                      clearTimeout(timerRef.current)
                      setDisplayPhoneError(false)
                    }}
                    onBlur={()=>{
                      timerRef.current=setTimeout(()=>{
                        setDisplayPhoneError(true)
                      },4000)
                    }}
                  />
                  {displayPhoneError && phoneNumberRef?.current?.value?.length>0 && <div style={{color:'#FF4545',fontSize:'12px',marginTop:'6px',textAlign:'center'}}>Enter valid 10 digit phone number</div>}
                  {LoginPopup &&
                  <span className={style.inputAddonRight}>
                    Login
                  </span>
                  }
                </div>
                {/* Product Page Section */}
                {
                  isProductPage &&
                    <>
                    {
                      false &&
                    <div style={{display:'flex', marginTop:'5px', height: "20px", alignItems:'center'}}>
                    <input
                      disabled={!otpstate.fetchAddressPermission}
                      id="ssoPermission"
                      type="checkbox"
                      onChange={() => null}
                      style={{accentColor:cartstate?.cardDesignEnabled ? '#3173E3' : 'var(--secondary-color)', height : cartstate?.cardDesignEnabled ? '18px' : '', width : cartstate?.cardDesignEnabled ? '18px' : ''}}
                      checked={otpstate.SSOPermission}
                    />
                      <p style={{marginLeft:'5px', fontSize:'12px', color: cartstate?.cardDesignEnabled ? 'var(--Checkout-Primary-Color, #292929)' : 'rgba(113, 113, 113, 0.7)', fontWeight:'500'}}>
                          Get notified for orders, updates, and offers.
                      </p>
                  </div>
                    }
                  <div style={{marginTop: '10px'}}  ref={productPageBuyNowSection}>
                    <Button
                      id="productPageBuyNowAction"
                      isCardDesign={true}
                      disabled={cartstate.productSelectionOutStock <= 0}
                      onClick={
                        () => {
                          if(mobileValue.length === 10 && !cartstate.updatingCart) {
                            loginCall(mobileValue)
                          }
                        }
                        
                      }
                    >
                      Buy Now
                    </Button>
                  </div>
                  <div style={{display: 'flex', alignItems:'flex-start', marginTop:'5px'}}>
                  <img 
                    src={
                      process.env.PUBLIC_URL +
                      "./assets/images/" +
                      'info-icon-new.svg'}
                    alt={"info-icon"}
                    style={{marginTop:'5px'}}
                  />
                  <p className={style.info_text}>
                    By proceeding, you are agreeing to our Terms & conditions and Privacy policy.
                  </p>
                  </div>
                  </>
                }
                {LoginPopup &&
                <div style={{display: 'flex', alignItems:'flex-start', margin:'8px',justifyContent : 'center'}}>
                <img 
                  src={
                    process.env.PUBLIC_URL +
                    "./assets/images/" +
                    'info-icon-black.svg'}
                  alt={"info-icon"}
                  height={11}
                  width={11}
                  style={{filter : LoginPopup ? 'var(--login-popup-svg-filter)' : ''}}
                />
                <p className={style.info_text_login_popup} style={{color:"#717171"}}>
                  By proceeding, you are agreeing to our <a href="https://checkout.shiprocket.in/terms-conditions/" target="_blank" style={{textDecoration : 'underline',fontWeight : 700,color:"#717171"}}>T&C</a> and <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" target="_blank" style={{textDecoration : 'underline',fontWeight : 700,color:"#717171"}}>Privacy policy</a>.
                </p>
                </div>

                }

                {/* {loginstate.spinner && <Loader />} */}
                {loginstate.AuthTokenGenerated && (
                  (loginstate?.sellerConfig?.landmarkMandatory || !loginstate?.sellerConfig?.addressLayoutNew) ?
                  <Delivery
                    setAddressToShow={props.setAddressToShow}
                    onRender={props.onRender}
                    onRenderLoading={props.onRenderLoading}
                  />
                  :
                  <DeliveryNew
                    setAddressToShow={props.setAddressToShow}
                    onRender={props.onRender}
                    onRenderLoading={props.onRenderLoading}
                  />
                )}
              </form>
              {loginstate.customLogin ?<div className="mt-24"> <TermsCondition /> </div>:loginstate?.sellerConfig?.marketingConsentConfig?.isEnabled && <div className="mt-24"> <LoginTermsConditions loginTermsConditionsChecked={loginTermsConditionsChecked} setLoginTermsConditionsChecked={setLoginTermsConditionsChecked} html={loginstate?.sellerConfig?.marketingConsentConfig?.text}/></div>}
          </section>
        }
         {!isProductPage && !LoginPopup && renderSrPromiseCard()}
          {loginstate.fail && <ErrorHandler error={loginstate.error} />}{" "}
        </>
      );
    } else if (
      ((loginstate.loader === 0 &&
        loginstate.user_type === "known") ||
      (loginstate.AuthTokenGenerated && addressstate.newUserAddressAdded)) && !loginstate.otpSkip
    ) {
      loginstate.spinner = false;
      // Abhishek
      //sendOtp(loginstate.mobileNumber);

      // 
      return (
        <>
          <Otp
              onRender={props.onRender}
              onError={props.onError}
              onRenderLoading={props.onRenderLoading}
              otpFieldFocus={props.phoneNumberFieldFocus}
          />
        </>
      );
    } else {
      //props.onError();
    }
  };

  const isNumber = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = e.target.value.substring(0, 13);
    var inputs = document.querySelectorAll('input[type="number"]');
    for (var i = inputs.length; i--; )
      inputs[i].setAttribute("pattern", "\\d*");
  };

  return (
    <>
      
      {loginstate.enteredNumber && !loginstate.loginSuccess ? !cartstate?.cardDesignEnabled ?  <OtpSkeleton /> : <div className="card-container"><OtpSkeleton /></div> :
        renderlogin()
      }
      
      {/* <PaymentContainer/> */}
    </>
  );
};
export default React.memo(Login);
