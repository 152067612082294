import React from 'react';
import { useSelector } from 'react-redux';
import AnalyticService from '../../../service/analytics/AnalyticsService';
import { infoIcon } from '../icons';

const TermsCondition = () => {
    const loginstate = useSelector((state: any) => state.Login);
    
    const handleCloseCheckout = () => {
        window.parent.postMessage({ trigger: "headless-close" }, "*");
    }




    return (
        <>
        <div className='sr-label'>
            <div className='mt-12 mr-5'>
            {infoIcon('var(--secondary-color)')}
            </div>
            <p style={{fontSize:'12px', color:'rgba(113, 113, 113, 0.7)', margin:'10px 0px'}}>
                    By proceeding, you agree to our {" "}
                    <a href="https://checkout.shiprocket.in/terms-conditions/" 
                    onClick={()=>{
                    AnalyticService.additionalEvents(
                        "terms-and-conditions",
                        '',
                        '', 
                        '',
                        false
                    )
                    }}  
                    target="_blank" style={{color:'#292A2C'}}>Terms & conditions</a> and  
                    <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" target="_blank"
                    onClick={()=>{
                    AnalyticService.additionalEvents(
                        "privacy-policy",
                        '',
                        '', 
                        '',
                        false
                    )
                    }}
                    style={{color:'#292A2C'}}> Privacy policy</a>
            </p>
        </div>
        {(loginstate.sellerConfig?.domainName === "beyoung.in" || loginstate.sellerConfig?.domainName === "www.beyoung.in") ?
        <div className="d-flex container2 h6" style={{margin: '4px 0'}}>
            <p className="mr-5">Powered by</p>
            <img
              className="sr-logo"
              src={process.env.PUBLIC_URL + "./assets/images/SRLogoDark.png"}
              alt="Shiprocket"
            />
        </div>
        : ''}

        {/* {loginstate.customLogin ? 
        <div 
            className='text__button font-14'
            onClick={handleCloseCheckout}
        >
            Checkout as Guest
        </div>
        : ''} */}

        </>
    )
}

export default TermsCondition