import React, { useEffect } from "react";
import style from "./OrderPlaced.module.css";
import { useSelector } from "react-redux";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { fireAnalyticsEvent } from "../Event/Event";
import NotificationScreen from "../NotificationScreen/NotificationScreen";  // no need to lazy load as part of main
import AppStorage from "../../service/AppStorage";
import { useDispatch } from "react-redux";
import { formatPhoneNumber, getJoinedString } from "../../utils/commonUtils";
import { sendUCEvent } from "../../utils/eventCollectors";
  interface Window {
    dataLayer: any; 
  }

const BillDeskOrderPlaced = (props: any) => {
  const addressState = useSelector((state: any) => state.Address);
  const cartState = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const couponstate = useSelector((state: any)=> state.Coupon);
  const otpstate = useSelector((state: any) => state.Otp);
  const itemsArr = cartState.initialCartData
  const isShopifyUser=cartState.platform?.toUpperCase()==='SHOPIFY'
  const dispatch = useDispatch()

  const addMinutes = (date: any, minutes: number) => {
    date?.setMinutes(date?.getMinutes() + minutes)
    return date;
  } 

  useEffect(() => {
    if(!cartState.orderDetailsSaved && cartState.orderPlacedDetails?.clientOrderReturnUrl) {

      dispatch({
        type: "UPDATE_CART_STATE", 
        payload: {key: "orderDetailsSaved", value: true}
      })

      AppStorage.setItem(
        'shiprocketCheckoutOrderDetails', 
        JSON.stringify({
          "order_id": cartState.orderPlacedDetails?.clientOrderId,
          "amount" : addressState.selectedPaymentMode === "COD" ? cartState.orderPlacedDetails?.codPrice : cartState.orderPlacedDetails?.prepaidPrice,
          "discount" : getJoinedString(cartState.orderPlacedDetails?.discountDetail?.discount_data, "discount_code"),
          "products": getJoinedString(cartState.orderPlacedDetails?.lineItems, "name")
        })
      )
    }
  }, [cartState.orderPlacedDetails?.clientOrderReturnUrl])

  const continueShopping = () => {
    let actions = [];
    if (loginstate.type === "cart") {
      actions.push({
        action: "clearCart",
        data: {
          redirectTo: "/",
        },
      });
    }

    window.parent.postMessage(
      {
        trigger: "headless-complete",
        actions: actions,
      },
      "*"
    );
  };

  useEffect(() => {
    let actions = [];
      actions.push({
        action: "clearCart",
        data: {
          domain: loginstate.sellerConfig?.domainName
        },
      });

    window.parent.postMessage(
      {
        trigger: "clear-cart",
        actions: actions,
      },
      "*"
    );
  }, [])

  function gtag() {
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    if(!cartState?.isAnalyticsCookie) {
      cartState.isAnalyticsCookie = true

      window.parent.postMessage(
        {
          trigger: 'set-analytics-cookie',
          data: {
            name: 'analyticsData',
            data: {
              name :cartState?.clientOrderId,
              value: addressState?.selectedPaymentMode === "COD" ? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice,
            },
            time: addMinutes(new Date(), 5)
          }
        },
        "*"
      )
    }
  }, [])

  useEffect(() => {
    if(loginstate?.sellerConfig?.marketingConfig && Object.keys(loginstate?.sellerConfig?.marketingConfig).length > 0) {
      const clientWigzoId = loginstate?.sellerConfig?.marketingConfig?.wigzo?.wigzo_id;
      const clientGaId =
      loginstate?.sellerConfig?.marketingConfig?.ga4?.measurement_id;
    const fbPixelId =
      loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id;
    const googleAdsDetails = loginstate?.sellerConfig?.marketingConfig?.gads
    const fbIncludedEvents = loginstate?.sellerConfig?.marketingConfig?.facebook?.events;

    let productIdArr: [] = cartState?.orderDetails?.lineItems?.map((item: any) => item?.productId)
    // AnalyticService.buy(productIdArr);

    AnalyticService.initialiseAnalytics( clientGaId, fbPixelId, clientWigzoId, googleAdsDetails, cartState.orderDetails.cartId, cartState.orderDetails.shippinAddress, 'fireAllEvents');

    if(loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_array?.length){
      loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_array?.map((pixelObj : any) => {
        AnalyticService.initialiseAnalytics(
          '', 
          pixelObj?.pixel_id, 
          '',  
          '', 
          cartState.orderDetails.cartId, 
          cartState.orderDetails.shippingAddress, 
          'onlyPixel',
          cartState.orderDetails?.shippingAddress?.firstName,
          cartState.orderDetails?.shippingAddress?.lastName
          )
      })
    }
    else if(loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id){
      AnalyticService.initialiseAnalytics(
        '', 
        loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id, 
        '',  
        '', 
        cartState.orderDetails.cartId, 
        cartState.orderDetails.shippingAddress, 
        'onlyPixel',
        cartState.orderDetails?.shippingAddress?.firstName,
        cartState.orderDetails?.shippingAddress?.lastName
      )
    }

      console.log("Initializing FB Pixel", loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id, cartState.orderDetails.shippingAddress)

      const FbPurchasePayload = {
        content_ids : cartState?.Cart_data?.items?.length ? cartState?.Cart_data?.items?.map((item:any) => item?.productId) : [],
        content_type: 'product_group',
        contents : cartState?.Cart_data?.items?.length ? cartState?.Cart_data?.items?.map((item:any) => ({
          id: item?.productId,
          variant: item?.itemId,  
          name: item?.name,
          currency:"INR",
          price: item?.price,
          quantity: item?.quantity,
        })) : [],
        currency: 'INR',
        num_items: cartState?.Cart_data?.items?.length,
        value: addressState?.selectedPaymentMode === "COD" ? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice ,
        ph: cartState?.orderDetails?.shippingAddress?.phone?.length === 10 ? 
          `91${cartState?.orderDetails?.shippingAddress?.phone}` : cartState?.orderDetails?.shippingAddress?.phone, 
        ct: cartState?.orderDetails?.shippingAddress?.city?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
        country: 'in',
        st: cartState?.orderDetails?.shippingAddress?.state?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
        zp: cartState?.orderDetails?.shippingAddress?.pincode,
        fn:cartState?.orderDetails?.shippingAddress?.firstName,
        ln:cartState?.orderDetails?.shippingAddress?.lastName,          
        }
      AnalyticService.purchase(FbPurchasePayload,loginstate?.sellerConfig?.marketingConfig?.facebook?.events)

      if(
        loginstate?.sellerConfig?.marketingConfig?.ga4?.events?.order_placed && 
        !loginstate?.sellerConfig?.enableGa4MpEvents
      ){
        const eventData = {
          event: "purchase",
        
          data: {
            event_category: "ecommerce",
            send_to: loginstate?.sellerConfig?.marketingConfig?.ga4?.measurement_id,
            transaction_id: cartState?.orderDetails?.clientOrderId,
            currency: "INR",
            value:
              (cartState?.orderDetails?.totalPrice || 0) +
              (cartState?.orderDetails?.shippingCharges || 0) +
              (cartState?.orderDetails?.totaltax || 0) -
              ( cartState?.orderDetails?.prepaidDiscount
                || 0) -
              (cartState?.orderDetails?.couponDiscount || 0),
            tax: cartState?.orderDetails?.totalTax,
            shipping: cartState?.orderDetails?.shippingCharges,
            ecomm_prodid:itemsArr?.map((item:any)=>(isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId)),
            items: itemsArr?.map((item:any) => ({
              id: isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId,
              name: item.title,  
              variant: item.variantTitle,
              brand: item?.vendor,
              category: item?.product_type,
              price: item.price,
              quantity: item.quantity,
            })),
            coupon: cartState?.orderDetails?.couponData?.coupon_code,
          },
        };
        
        window.parent.postMessage({ trigger: "gtag", data: eventData }, "*");
        console.log("PURCHASE-GA-EVENT-FIRED eventData------>",eventData)
      }

      var enhanced_conversion_data = {
        ...(cartState?.orderDetails?.shippingAddress?.email && {email:cartState?.orderDetails?.shippingAddress?.email}),
        phone_number:formatPhoneNumber(cartState?.orderDetails?.shippingAddress?.phone),
        first_name:
        cartState?.orderDetails?.shippingAddress?.firstName,
        last_name:
        cartState?.orderDetails?.shippingAddress?.lastName,
        home_address: {
          street:
          cartState?.orderDetails?.shippingAddress?.line1 + (cartState?.orderDetails?.shippingAddress?.line2 ? cartState?.orderDetails?.shippingAddress?.line2: ""),
          city: cartState?.orderDetails?.shippingAddress?.city,
          region:
          cartState?.orderDetails?.shippingAddress?.state,
          postal_code:
          cartState?.orderDetails?.shippingAddress?.pincode,
          country:'IN',
        },
      };


        for(let i=0; i<loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.length; i++) {

        const scriptElement = document.createElement('script');
        document.head.appendChild(scriptElement);
        scriptElement.src =
        `https://www.googletagmanager.com/gtag/js?id=${loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_id}`;
        scriptElement.async = true;

        scriptElement.onload = () => {

        if(i === 0) {
          window.dataLayer = window.dataLayer || [];
          {/*// @ts-ignore */}
          gtag('js', new Date());
        }
        
        {/*// @ts-ignore */}
        gtag('config',loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id, { allow_enhanced_conversions: loginstate?.sellerConfig?.marketingConfig?.gads?.enhance_conversion});

    
            console.log("ga ads fired",{
              send_to: loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_id + "/" + loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_label,
              value:
              (cartState?.orderDetails?.totalPrice || 0) +
              (cartState?.orderDetails?.shippingCharges || 0) +
              (cartState?.orderDetails?.totaltax || 0) -
              ( cartState?.orderDetails?.prepaidDiscount
                || 0) -
              (cartState?.orderDetails?.couponDiscount || 0),
              currency: 'INR',
              transaction_id:cartState?.orderDetails?.clientOrderId
              
            })
            {/*// @ts-ignore */}
                gtag('event', 'conversion', {
                  send_to: loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_id + "/" + loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_label,
                  value:
                  (cartState?.orderDetails?.totalPrice || 0) +
                  (cartState?.orderDetails?.shippingCharges || 0) +
                  (cartState?.orderDetails?.totaltax || 0) -
                  ( cartState?.orderDetails?.prepaidDiscount
                    || 0) -
                  (cartState?.orderDetails?.couponDiscount || 0),
                  currency: 'INR',
                  transaction_id:cartState?.orderDetails?.clientOrderId
                });

                let enhanced_conversion_data = {
                  ...(cartState?.orderDetails?.shippingAddress?.email ? {email:cartState?.orderDetails?.shippingAddress?.email}:{}),
                  phone_number:
                  formatPhoneNumber(cartState?.orderDetails?.shippingAddress?.phone),
                  first_name:
                  cartState?.orderDetails?.shippingAddress?.firstName,
                  last_name:
                  cartState?.orderDetails?.shippingAddress?.lastName,
                  home_address: {
                    street:
                    cartState?.orderDetails?.shippingAddress?.line1 + (cartState?.orderDetails?.shippingAddress?.line2 ? cartState?.orderDetails?.shippingAddress?.line2: ""),
                    city: cartState?.orderDetails?.shippingAddress?.city,
                    region:
                    cartState?.orderDetails?.shippingAddress?.state,
                    postal_code:
                    cartState?.orderDetails?.shippingAddress?.pincode,
                    country:'IN',
                  },
                };
              
                
                let conversionData = {
                        send_to: loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_id + "/" + loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i]?.conversion_label,
                  value:
                  (cartState?.orderDetails?.totalPrice || 0) +
                  (cartState?.orderDetails?.shippingCharges || 0) +
                  (cartState?.orderDetails?.totaltax || 0) -
                  ( cartState?.orderDetails?.prepaidDiscount
                    || 0) -
                  (cartState?.orderDetails?.couponDiscount || 0),
                  currency: 'INR',
                  ecomm_prodid:itemsArr?.map((item:any)=>(isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId)),
                  transaction_id:cartState?.orderDetails?.clientOrderId,
                  items: itemsArr?.map((item:any) => ({
                    id: isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId
                  })),
                  }
              
                window.parent.postMessage(
                  {
                    trigger: "convesion-gtm-event",
                    eventName : 'conversion' ,
                    enhanced_conversion_data : enhanced_conversion_data,
                    conversionData : conversionData
                  },
                  "*"
                )
        }

      }


      console.log("enhanced_conversion_data--->", enhanced_conversion_data)


      AnalyticService.orderPlacedBilldesk(
        props.marketConfigPairs,
        cartState.orderDetails?.shippingAddress?.phone, 
        { items: cartState.orderDetails?.lineItems }, 
        cartState.orderDetails?.shippingAddress,
        cartState.orderDetails?.couponData?.coupon_code,
        cartState?.orderDetails, 
        cartState.orderDetails?.paymentMethod, 
        cartState.orderDetails?.shippingTag,
        cartState.orderDetails?.paymentGateway,
        cartState.orderDetails?.clientOrderId
      );

      if(loginstate?.sellerConfig?.enableGtmEventParentFrame){
      let customGtmOrderPlacedData = {
        "event": "order-placed",
        "firstName" : addressState.selectedAddress?.firstName ?? '',
        "LastName" : addressState.selectedAddress?.lastName ?? '',
        "email" : addressState.selectedAddress?.email ?? '',
        "fullName" : addressState.selectedAddress?.fullName ?? '',
        "phone_num":formatPhoneNumber(loginstate?.mobileNumber ?? otpstate.mobile_no),
        "city": addressState.selectedAddress?.city,
        "state": addressState.selectedAddress?.state,
        "pincode": addressState.selectedAddress?.pincode,
        "country": "in", 
        "ecommerce": {
          "currency": "INR",
          "value": addressState?.selectedPaymentMode === "COD" ? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice,
          "coupon": couponstate?.couponCode,
          "tax": cartState?.Cart_data?.totalTax ?? cartState?.orderDetails?.totalTax,
          "shipping":cartState?.orderDetails?.shippingCharges,
          "transaction_id": cartState?.orderDetails?.clientOrderId,
          "paymentType": cartState.paymentMethodType,
          "shipping_tier":  shippingstate?.selected_service_data?.pickrr_tag,
          // "items": cartState?.Cart_data?.items ?? cartState?.orderDetails?.lineItems
          "items": cartState?.Cart_data?.items?.map((cartItem:any)=>{
            const temp=itemsArr?.find((item:any)=>item.productId==cartItem.productId)
            return temp?.vendor ? {...cartItem,vendor:temp?.vendor}:{...cartItem}
          }) ?? cartState?.orderDetails?.lineItems.map((cartItem:any)=>{
            const temp=itemsArr?.find((item:any)=>item.productId==cartItem.productId)
            return temp?.vendor ? {...cartItem,vendor:temp?.vendor}:{...cartItem}
          })
        }
      }
      window.parent.postMessage(
        {
          trigger: "gtm-events",
          data: customGtmOrderPlacedData,
        },
        "*"
      );
      }

      if(loginstate?.sellerConfig?.marketingConfig?.ga4?.events?.payment_initiated && 
        !loginstate?.sellerConfig?.enableGa4MpEvents
        ){
        const eventData = {
          event: "add_payment_info",
          data: {
            event_category: "ecommerce",
            send_to: loginstate?.sellerConfig?.marketingConfig?.ga4?.measurement_id,
            currency: "INR",
            ecomm_prodid:itemsArr?.map((item:any)=>(isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId)),
            value:
            
              (cartState?.orderDetails?.totalPrice || 0) +
              (cartState.orderDetails?.shippingCharges || 0) +
              (cartState?.orderDetails?.totaltax || 0) -
              (cartState?.orderDetails?.prepaidDiscount || 0) -
              (cartState?.orderDetails?.couponDiscount || 0),

              items: itemsArr?.map((item:any) => ({
                id:isShopifyUser?`shopify_IN_${item.productId}_${item.variantId}`:item.productId,
                name: item.title,  
                variant: item.variantTitle,
                brand: item?.vendor,
                category: item?.product_type,
                price: item.price,
                quantity: item.quantity,
              })),
            payment_type: cartState.orderDetails?.paymentGateway,
            coupon: couponstate?.couponCode,
          },
        };
        window.parent.postMessage(
          {
            trigger: "gtag",
            data: eventData,
          },
          "*"
        );
        console.log("ADD-PAYMENT-INFO-GA-EVENT-FIRED eventData------>",eventData)
        }
      
      //Purchase Fired

    //END
  }}, [])

    //UC order event
    useEffect(() => {
      try {
        if(cartState.orderPlacedDetails?.clientOrderReturnUrl && !cartState.ucEventOrder) {
  
          //lock event firing
          cartState.ucEventOrder = true
  
          //create payload for buy event
          const payload: any = {
            id: "",
            status: "success",
            method: "PREPAID",
            order_id: cartState.orderPlacedDetails?.clientOrderId,
            customer_id: "",
            phone: String(cartState?.orderPlacedDetails?.shippingAddress?.phone),
            email: cartState?.orderPlacedDetails?.shippingAddress?.email,
            zip: String(cartState?.orderPlacedDetails?.shippingAddress?.pincode),
            referrer: "",
            sla: "",
            total_price: String(cartState?.orderPlacedDetails?.prepaidPrice),
            couponData: {
              "coupon_code": "",
              "coupon_type": "",
              "discount_amount": "",
            },
            items: cartState?.orderPlacedDetails?.lineItems?.map((item: any) => {
              return {
                "id": "",
                "name": item?.name,
                "mrp": "",
                "selling_price": String(item?.price),
                //"product_id": item?.productId,
                "variant_id": String(item?.itemId),
                "qty": String(item?.quantity),
                //"category": null
                //"image": item?.imageUrl,
                //"description": "", //N/A
                //"sku": item?.sku,
              }
            })
          }
          console.log('UCORDER', payload)
          //send event
          sendUCEvent({
            eventName: "order",
            payload: payload
          })
        }
      } catch (e) {}
    }, [cartState.orderPlacedDetails?.clientOrderReturnUrl])
  
  let   billingAddressData = 
    typeof(cartState.orderDetails.shippingAddress)  === "object" ?  
    cartState.orderDetails.shippingAddress : 
    JSON.parse(cartState.orderDetails.shippingAddress);

  return (
    <>
      <NotificationScreen image = 'orderPlacedNew' heading = "Order placed" subheading = "Redirecting you to the confirmation page." showButton={true}
      />
    </>
  );
};

export default BillDeskOrderPlaced;
