// / import Http from "./AxiosService";
import AppStorage from "./AppStorage";

const axios = require("axios").default;

const get = (url: string, id?: any) => {
  const Http = AxiosRequest();
  url = id !== undefined ? url + id : url;
  return Http.get(url);
};
const getWithParams = (url: string, data: any) => {
  const Http = AxiosRequest();
  return Http.get(url, { params: data });
};
const post = (url: string, data: any) => {
  const Http = AxiosRequest();
  return Http.post(url, data);
};
const update = (url: string, data?: any, id?: any,) => {
  const Http = AxiosRequest();
  return Http.put(url, data);
};
const remove = (url: string, id: any) => {
  const Http = AxiosRequest();
  return Http.delete(url + id);
};
const AxiosRequest = () => {
  const AUTH_TOKEN: any = AppStorage.getItem("checkoutToken");
  const SESSION_NAME = "x-session-token";
  const SESSION_TOKEN: any = AppStorage.getItem(SESSION_NAME);
  let headers: any = {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true
  };
  if(SESSION_TOKEN) {
    headers.headers[SESSION_NAME] = SESSION_TOKEN;
  }
  if (AUTH_TOKEN) {
      headers.headers["authorization"] = 'Bearer ' + AUTH_TOKEN;
  }
  
  const instance = axios.create(headers);
  instance.interceptors.response.use(function (response: any) {
    if(response.headers[SESSION_NAME]) {
      AppStorage.setItem(SESSION_NAME, response.headers[SESSION_NAME]);
    }
    return response;
  }, function (error: any) {
    return Promise.reject(error);
  });
  return instance;
}
const HttpService = {
  get,
  getWithParams,
  post,
  update,
  remove
};
export default HttpService;