import React, { useState } from "react";
import AnalyticService from "../../service/analytics/AnalyticsService";
import style from "./address.module.css";
import { useSelector } from "react-redux";
import { Store } from "../../service/StorageHelper";
const AddressList = (props: any) => {
  const updateAddress = () => {
    props.onSelectAddressMethod(props);
    props.SetIsOpen(false)
  };
  const couponState = useSelector((state: any) => state.Coupon);
  const cartState = useSelector((state: any) => state.Cart);
  const addressState = useSelector((state: any) => state.Address);
  const otpstate = useSelector((state: any) => state.Otp);
  const loginstate = useSelector((state: any) => state.Login);
  const [state, updateState] = useState(0);
  const forceUpdate = () => updateState((prevState) => prevState ^ 1);


  function shortenString(str:string) {
    if (str?.length > 25) {
      return str.substring(0, 25) + '...';
    } else {
      return str;
    }
  }
  return (
    <>
    {!cartState?.cardDesignEnabled ?
    <div
      className={`${style.row} ${addressState?.selectedAddress?.addressID === props?.address_obj?.addressID ?style.selected : '' }`}
      onClick={(event) => {
        updateAddress()
        AnalyticService.savedAddressChanged(
          cartState?.orderDetails,
          couponState.couponCode,
          addressState?.Address_data?.length
        );
        AnalyticService.additionalEvents(
          "different-address-selected",
          cartState?.Cart_data,
          couponState.couponCode, 
          Store.getItem('refreshToken') ? true : false,
          false
        );
      }}
    >
      <input type="radio" defaultChecked={addressState?.selectedAddress?.addressID === props?.address_obj?.addressID} className="sr-radio" name="address" id={props.id} />
      <label htmlFor={props.id} className="sr-label">
        <div style={{width:'20px'}}>
        <span className="circle" style={{width:'16px', borderColor: addressState?.selectedAddress?.addressID === props?.address_obj?.addressID ? "var(--secondary-color)" : ""}}>
          <span className="circle-dot" style={{backgroundColor: addressState?.selectedAddress?.addressID === props?.address_obj?.addressID ? "var(--secondary-color)" : ""}}></span>
        </span>
        </div>
        <div
          className="full-width pl-20"
        >
          <p className="grey-dark h3 semi-bold mb-3">
            <strong>{props.name}</strong>
            {props?.tags?.length > 0 && 
              <span className={style.tags_pill}>
                {
                  props?.tags?.includes('other') 
                    ? shortenString(props?.tags.split(":")?.[1]) 
                    : shortenString(props?.tags)
                }
              </span>
            }
          </p>
          <p className="h3 grey-dark light" style={{overflowWrap:'break-word', width:'90%'}}>{props.address1}</p>

          {(props.address_obj?.email && props.address_obj.email !== 'noreply@shiprocket.com') && <p className="h3 grey-dark light"> {props.address_obj.email}</p>}
         <span onClick={
            (e) => {
              e.preventDefault()
              e.stopPropagation()
              // if(loginstate.sellerConfig?.nativeShopifyCheckout) {
              //   props.setIsEdit(true)
              // }
              props.setEditAddressData(props.address_obj)
              console.log("Edit", props.address_obj)
              console.log(props.address_obj)
              props.setEditAddress(true)
              props.setTitle("Edit address")
              addressState.addressUpdated = false
              addressState.addressUpdateFail = false
              AnalyticService.additionalEvents(
                "edit-address",
                cartState?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
            }
          }>
            <img 
              src={process.env.PUBLIC_URL + "./assets/images/edit-icon.svg"}  
              alt="edit-icon"
              style={{marginTop:'10px'}}
              />
          </span>
          { otpstate.fetchAddressPermission &&
           <span onClick={
            (e) => {
              e.preventDefault()
              e.stopPropagation()
              props.setDeleteVisible(true)
              loginstate.isAnyModalOpen = true
              props.setDeleteID()
              AnalyticService.additionalEvents(
                "delete-address",
                cartState?.Cart_data,
                couponState.couponCode, 
                Store.getItem('refreshToken') ? true : false,
                false
              );
            }
          }>
            <img 
              src={process.env.PUBLIC_URL + "./assets/images/delete-icon.svg"} 
              alt="delete-icon"
              style={{marginTop:'10px', marginLeft:'10px'}}
              />
          </span>
          }
        </div>
      </label>
    </div>
     :
     <div
     className={style.addressContainerCard}
     onMouseLeave={()=>{
      props?.setActiveMenuIndex('')
     }}
     >
      <div 
      style={{width : !cartState?.cardDesignEnabled ? '' : '100%'}}
      onClick={(event) => {
        updateAddress()
        AnalyticService.savedAddressChanged(
          cartState?.orderDetails,
          couponState.couponCode,
          addressState?.Address_data?.length
        );
        AnalyticService.additionalEvents(
          "different-address-selected",
          cartState?.Cart_data,
          couponState.couponCode, 
          Store.getItem('refreshToken') ? true : false,
          false
        );
      }}
      >
      <input type="radio" defaultChecked={addressState?.selectedAddress?.addressID === props?.address_obj?.addressID} checked={addressState?.selectedAddress?.addressID === props?.address_obj?.addressID} className="sr-radio" name="address" id={props.id} />
      <label htmlFor={props.id} className="sr-label-card">
        <div style={{width:'20px'}}>
          <span className="circle-card" style={{width:'16px'}}>
            <span className="circle-dot"></span>
          </span>
        </div>
        <div
          className="full-width pl-8 pr-4"
        >
          <p className={style.nameHeading}>
            {props.name} 
            {props?.tags?.length > 0 && 
              <span className={style.tags_pill}>
                {
                  props?.tags?.includes('other') 
                    ? shortenString(props?.tags.split(":")?.[1]) 
                    : shortenString(props?.tags)
                }
              </span>
            }
          </p>
          <p className={style.addressDetail} style={{overflowWrap:'break-word'}}>{props.address1}</p>

          {(props.address_obj?.email && props.address_obj.email !== 'noreply@shiprocket.com') && <p className={style.addressDetail}> {props.address_obj.email}</p>}
        </div>
      </label>
      </div>
      <div 
      style={{width:'20px',display : 'flex',alignItems : 'center',flexDirection : 'column'}}>
          <div>
          <img
            src={process.env.PUBLIC_URL + "./assets/images/threeDot.svg"}
            onClick={(e)=>{
              e.preventDefault()
              props?.setActiveMenuIndex((prev : any)=>props.index === prev ? '' : props.index)
              forceUpdate();
            }}
            style={{cursor : 'pointer'}}
          />
          </div>
          {props?.activeMenuIndex === props.index ?
            <div 
            className={props?.activeMenuIndex === props.index ? style.dropdownMenuActive : style.dropdownMenu}
            // style={{display : activeMenuIndex === props.index ? 'flex' : 'none'}}
            >
              <span 
              className={style.dropdownOption}
              onClick={
                (e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  // if(loginstate.sellerConfig?.nativeShopifyCheckout) {
                  //   props.setIsEdit(true)
                  // }
                  props.setEditAddressData(props.address_obj)
                  console.log("Edit", props.address_obj)
                  console.log(props.address_obj)
                  props.setEditAddress(true)
                  props.setTitle("Edit address")
                  addressState.addressUpdated = false
                  addressState.addressUpdateFail = false
                  AnalyticService.additionalEvents(
                    "edit-address",
                    cartState?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  );
                }
              }
              >
                Edit
              </span>
              { otpstate.fetchAddressPermission &&
                <span 
                className={style.dropdownOption}
                onClick={
                  (e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    props.setDeleteVisible(true)
                    loginstate.isAnyModalOpen = true
                    props.setDeleteID()
                    AnalyticService.additionalEvents(
                      "delete-address",
                      cartState?.Cart_data,
                      couponState.couponCode, 
                      Store.getItem('refreshToken') ? true : false,
                      false
                    );
                  }
                }
                >
                  Delete
                </span>
              }
            </div>
            :
            <></>
          }
        </div>
     </div>
    }
    </>
  );
};
export default React.memo(AddressList);
