var Store = {};
const AppStorage = {

  getItem(key) {
    if(Store[key]) {
      return Store[key];
    } else return null;
  },

  setItem(key, value) {
    Store[key] = value
    window.parent.postMessage({
      trigger: "headless-storage",
      actions: [
        {
          action: "setItem",
          data: {
            key, value
          }
        }
      ]
    }, "*");
  },

  removeItem(key) {
    if(Store.hasOwnProperty(key)) {
      delete Store[key];
      window.parent.postMessage({
        trigger: "headless-storage",
        actions: [
          {
            action: "removeItem",
            data: {
              key
            }
          }
        ]
      }, "*");
    }
  },

  setLocalStorage(data) {
    if(data){
      Store = JSON.parse(data);}
    else{
      Store = localStorage
    }
  }
};
export default AppStorage;
