import AppStorage from "../service/AppStorage";
import { Store } from "../service/StorageHelper";
import { checkIfLSAvailable } from "./commonUtils";

let IS_EDIT_NUMBER_LOGOUT = checkIfLSAvailable() ? AppStorage?.getItem('logout_temp') ?? localStorage.getItem('logout_temp') : false;
let EDIT_PIM_SID:any;
let EDIT_EXPIRE_TIME;
let EDIT_SESSION_COLLECT:any = null


export const logoutSession = (data:any) => {
    EDIT_PIM_SID = data.initateCheckoutData?.headers['pim-sid'];
    EDIT_EXPIRE_TIME = data.initateCheckoutData?.headers['expires-at'];
    if(checkIfLSAvailable()){
        localStorage?.removeItem('logout_temp')
    }
    // Store.removeItem('logout_temp');
}

export const removeLogoutSession = (isLogin?:boolean) => {
    // Store.removeItem('logout_temp');
    if(checkIfLSAvailable()){
        localStorage?.removeItem('logout_temp');
        AppStorage?.removeItem('logout_temp');
    }

    if(isLogin){
        if(checkIfLSAvailable()){
          AppStorage?.removeItem('logout_temp');
        //   Store.removeItem('logout_temp');
        }
        EDIT_PIM_SID = null
        EDIT_EXPIRE_TIME = null;
        IS_EDIT_NUMBER_LOGOUT = null;
        EDIT_SESSION_COLLECT = true
    }
}

export const editNumberSellerCheck = [
    'harish-30.myshopify.com', 
    'fastrr-checkout.myshopify.com', 
    'www.erayastyle.com', 
    'buydeal.store', 
    'thekharidari.in', 
    'www.thecasecompany.in',
    'www.urbanscissorsindia.com', 
    'tanchui.co.in', 
    'www.zenpods.in',
    'rimchrono.com', 
    'nilormy.com',
    'loveearth.in',
    'o3plus.com',
    'cowveda.co.in', 
    'thecasefactory.in', 
    'organicsmokes.co', 
    'in.teabox.com', 
    'oralia.in', 
    'bummer.in', 
    'www.trrendo.com'
]


export {IS_EDIT_NUMBER_LOGOUT, EDIT_PIM_SID, EDIT_EXPIRE_TIME, EDIT_SESSION_COLLECT}