import React from "react";
import style from "./modal.module.css";

const ScarcityBanner = ({scarcityFeatureChoice, totalDiscount}:{scarcityFeatureChoice:string,totalDiscount:number}) => {
    const isBgRed=totalDiscount === 0 || scarcityFeatureChoice?.includes("LIMITED_STOCK")
    return <>
        <div className={style.scarcityBanner} style={isBgRed ?
            {background: 'linear-gradient(to right,#fff9ef,#ffd4c6)', 'color': '#e95123' }
            :{ background: 'linear-gradient(to right,#f3fdf5,#adf3bb)', 'color': '#248018' } 
            
        }>
            {isBgRed ?
                <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "./assets/images/hourglassRed.gif"
                        }
                        height={15}
                        width={13}
                        style={{ marginTop: '-2px' }}
                        alt="hourGlass"
                    />
                    Products in huge demand might run <span style={{ fontWeight: 'bold' }}> Out of Stock</span></div>
                : <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "./assets/images/HourglassGreen.gif"
                        }
                        height={15}
                        width={11}
                        style={{marginTop: '-2px' }}
                        alt="hourGlass"
                    />
                    <span>
                        You might miss out on savings of <span style={{ fontWeight: 'bold', fontSize: '14px' }}>₹{totalDiscount}</span>
                    </span>
                </div>}
        </div>
    </>

}
export default ScarcityBanner