import { useEffect, useState } from "react";
import {useSelector } from "react-redux";
import "../css/Header.css";
import HeaderNew from "../components/HeaderNew";
import Header from "../components/Header";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { Store } from "../../service/StorageHelper";
import {sendShiprocketCheckoutEvent} from '../../utils/eventCollectors';
import {editNumberSellerCheck} from '../../../src/utils/guestSessions';
import AppStorage from "../../service/AppStorage";
import { checkIfLSAvailable } from "../../utils/commonUtils";

const HeaderContainer = (props: any) => {
  const { cardDesignEnabled,onRender, onRenderLoading,isHeadless,  fetchingDetails, applicationType} = props
  const [isOpen, SetIsOpen] = useState(false);
  const [shopName, setShopName] = useState("");
  const [backgroundColor, setBackgroundColor] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showExitForm, setShowExitForm] = useState(false);
  const loginstate = useSelector((state: any) => state.Login);
  const cartstate = useSelector((state: any) => state.Cart);
  const couponState = useSelector((state: any) => state.Coupon);
  const otpstate = useSelector((state: any) => state.Otp);
  const addressstate = useSelector((state: any) => state.Address);
  const [activeButton, setActiveButton] = useState(false);

  useEffect(() => {

    setShopName(loginstate?.channel?.shop_name);
    loginstate?.sellerConfig?.uiConfig?.header?.background_color && setBackgroundColor(loginstate?.sellerConfig?.uiConfig?.header?.background_color)
  }, [loginstate]);

  useEffect(() => {
    loginstate?.sellerConfig?.uiConfig?.header?.background_color && setBackgroundColor(loginstate?.sellerConfig?.uiConfig?.header?.background_color)

  }, [loginstate]);


  const UrlQueryStrings = window.location.search;

  const changeNumber = async () => {
    await sendShiprocketCheckoutEvent({
      name: 'CHANGE_NUMBER',
      domain:loginstate.sellerConfig?.domainName,
      payload: { 
        "cart_id": cartstate?.Cart_data?.id,
        "otpLess":loginstate?.sellerConfig?.enabledFeatureCodForm
      }
    })


    AnalyticService.changeNumber();
    AnalyticService.additionalEvents(
      "back-change-number",
      cartstate?.Cart_data,
      couponState.couponCode,
      Store.getItem('refreshToken') ? true : false,
      false
    );
    setShowLoader(true);
    // editNumberSellerCheck?.includes(loginstate.sellerConfig?.domainName)
    if(loginstate.sellerConfig?.disableLogout && checkIfLSAvailable()){
      AppStorage.setItem('logout_temp', true)
      localStorage?.setItem('logout_temp', 'true')
      // Store.setItem('logout_temp', true)
      window.location.reload();
    }else {
    // let payload = {
    //   mobile: loginstate.mobileNumber,
    // };
    //dispatch(logoutUser(payload));
    Store.removeItem('refreshToken')
    Store.removeItem('refreshTokenValue')
    //AppStorage.removeItem('mobile')
    Store.removeItem('user_id')
    //AppStorage.removeItem('user_profile_id')
    Store.removeItem('seller_id')
    Store.removeItem('pim_sid')
    Store.removeItem('expireTime')
    //document.cookie = 'pim_sid=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=None;Secure'
    window.location.reload();
  }
  };


  const openModalHandler = () => {
    SetIsOpen(true);
  };
  let params = new URLSearchParams(UrlQueryStrings);
  let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false

  const exitCheckoutHandler = () => {
    if (!loginstate?.sellerConfig?.exitFormEnabled) {
      AnalyticService.exitCheckout(cartstate.orderDetails);
      if (loginstate.onPage === "shipping" && !loginstate.user_type_for_text) {
        AnalyticService.additionalEvents(
          "yes-exit-checkout",
          cartstate?.Cart_data,
          couponState.couponCode,
          Store.getItem('refreshToken') ? true : false,
          false
        );
      }
      else {
        AnalyticService.additionalEvents(
          "back-exit-checkout",
          cartstate?.Cart_data,
          couponState.couponCode,
          Store.getItem('refreshToken') ? true : false,
          false
        );
      }
      if (isProductPage && cardDesignEnabled) {
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: `https://${loginstate.sellerConfig.domainName}`,
                },
              },
            ],
          },
          "*"
        );
      } else {
        window.parent.postMessage({ trigger: "headless-close" }, "*");
      }
    } else {
      setShowExitForm(true)
    }
  };

  const tryAnotherPaymentMethod = () => {
    cartstate.paymentProcessing = false;
    loginstate.paymentPageReached = true;
    cartstate.verifyingPayment = false;
    cartstate.paymentMethodsFetched = false
    clearInterval(loginstate?.upiIntervalID);
    clearInterval(loginstate?.razorpayIntervalID)
    clearInterval(loginstate?.optionalPaymentIntervalId)
    clearInterval(cartstate.snapmintIntervalId)
    onRender();
    onRenderLoading()
  };

  return (
    cardDesignEnabled
      ?
      <HeaderNew cartstate={cartstate} couponState={couponState} loginstate={loginstate} otpstate={otpstate} SetIsOpen={SetIsOpen} isOpen={isOpen} backgroundColor={backgroundColor} openModalHandler={openModalHandler} shopName={shopName} exitCheckoutHandler={exitCheckoutHandler} tryAnotherPaymentMethod={tryAnotherPaymentMethod} isProductPage={isProductPage} setShowExitForm={setShowExitForm} showExitForm={showExitForm}  isHeadless={isHeadless}  fetchingDetails={fetchingDetails} applicationType={applicationType} setActiveButton={setActiveButton} activeButton={activeButton}/>
      :
      <Header 
      
      cartstate={cartstate} couponState={couponState} loginstate={loginstate} addressstate={addressstate}  otpstate={otpstate} SetIsOpen={SetIsOpen} isOpen={isOpen} backgroundColor={backgroundColor} openModalHandler={openModalHandler} shopName={shopName} exitCheckoutHandler={exitCheckoutHandler} tryAnotherPaymentMethod={tryAnotherPaymentMethod} setShowExitForm={setShowExitForm} showExitForm={showExitForm}  showLoader={showLoader}  changeNumber={changeNumber}  isHeadless={isHeadless}  fetchingDetails={fetchingDetails} applicationType={applicationType} setActiveButton={setActiveButton} activeButton={activeButton}  />)
}





export default HeaderContainer