import React, { useEffect, useRef, useState } from "react";
import { otpRequestModel, validateOtpModel, resendOtp, customerExistModel } from "../../models/Model";
import style from "./Otp.module.css";
import ErrorHandler from "../Error/ErrorHandler";
import { useDispatch, useSelector } from "react-redux";
import { verifyOtp, requestOtp, reSendOtp, loginAfterFiveOtp } from "../../Redux/Otp/Otp_actions";
import { fetchAddress } from "../../Redux/Address/add_actions";
import { fetchShipping } from "../../Redux/Shipping/Ship_actions";
import Input from "../UI/Input/Input";
import Login from "./Login";
import Delivery from "../Delivery/Delivery";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { doesUserExist, setUserDataAfterOtp } from "../../Redux/Login/Login_actions";
import AppStorage from "../../service/AppStorage";
import OtpInput from 'react18-input-otp';
import ReactTooltip from 'react-tooltip';
import { Store } from "../../service/StorageHelper";
import DeliveryNew from "../DeliveryNew/DeliveryNew";
import { editIcon, timerIcon } from "../CustomLogin/icons";
import { sendShiprocketCheckoutEvent } from "../../utils/eventCollectors";
const PaymentContainer= React.lazy(() => import(/* webpackChunkName: "paymentContainer" */ "../Payment/paymentContainer")); 
const parser = require("ua-parser-js");
var ua = new parser("", "");

const Otp = (props: any) => {
  const [counter, setCounter] = useState(30);
  const [whatsappCounter, setWhatsappCounter] = useState(30)
  const [analyticsServicesFired, setAnalyticsServicesFired] = useState(false);
  const otpstate = useSelector((state: any) => state.Otp);
  const loginstate = useSelector((state: any) => state.Login);
  const addressstate = useSelector((state: any) => state.Address);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const cartstate = useSelector((state: any) => state.Cart);
  const couponState = useSelector((state: any) => state.Coupon);
  const dispatch = useDispatch();
  const [state, updateState] = useState(0);
  const forceUpdate = () => updateState((prevState) => prevState ^ 1);
  loginstate.onLoginPage = false;
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;

  var ua = new parser("", "");
  let deviceType = ua.getResult().device.type;

  const fetchAddressChangeHandler = (e: any) => {
    if(e.target.checked) {
      otpstate.fetchAddressPermission = true
      otpstate.SSOPermission = true
    } else {
      otpstate.fetchAddressPermission = false
      otpstate.SSOPermission = false
    }
    props.onRenderLoading()
  }

  const SSOChangeHandler = (e: any) => {
    if(e.target.checked) {
      otpstate.SSOPermission = true
      AnalyticService.additionalEvents(
        "sso-reenabled",
        cartstate?.Cart_data,
        couponState.couponCode, 
        Store.getItem('refreshToken') ? true : false,
        false
      );
    } else {
      otpstate.SSOPermission = false
      AnalyticService.additionalEvents(
        "sso-disabled",
        cartstate?.Cart_data,
        couponState.couponCode, 
        Store.getItem('refreshToken') ? true : false,
        false
      );
    }
    props.onRenderLoading()
  }

  // const [showOtpSent, setShowOtpSent] = useState(true)
  let OtpSubHeading: any;
  let OtpHeading = "";
  // Otp Success messages removed, feedback from Vimal.
  // useEffect(() => {
  //   setShowOtpSent(true)
  //   const timeId = setTimeout(() => {
  //     setShowOtpSent(false)
  //   }, 3000)

  //   return () => {
  //     clearTimeout(timeId)
  //   }
  // }, [otpstate.Otp_data]);


  function mobileNoInternationalFormat(number: string) {
    const firstPart = number.slice(0,5);
    const secondPart = number.slice(5);
    return `+91 ${firstPart} ${secondPart}`
  }

  useEffect(() => {

  }, [shippingstate.selected_service_data])

  useEffect(()=>{
    if(otpstate?.verified){
      window.parent.postMessage(
        {
          trigger: 'otp-read-stop',
        },
        "*"
      )
    }
  },[otpstate?.verified])

  const otpRef=useRef<any>(null)
  useEffect(()=>{   
    otpRef?.current.focusInput(0)
   
},[props.otpFieldFocus])


  const verifyOtp1 = () => {
    if (
      (cartstate.fail ||
        otpstate.fail ||
        shippingstate.fail ||
        addressstate.fail) &&
      !otpstate.wrongOtp
    ) {
        props.onError()
      return <></>;
    } else if (loginstate.changeNumber) {
      return (
        <>
          <Login
            onRender={props.onRender}
            onRenderLoading={props.onRenderLoading}
          />
        </>
      );
    } else if (addressstate.addAddress && !otpstate.verified) {
      return (
        <>
          {(loginstate?.sellerConfig?.landmarkMandatory || !loginstate?.sellerConfig?.addressLayoutNew) ?
            <Delivery
              setAddressToShow={props.setAddressToShow}
              onRender={props.onRender}
              onRenderLoading={props.onRenderLoading}
            />
            :
            <DeliveryNew
              setAddressToShow={props.setAddressToShow}
              onRender={props.onRender}
              onRenderLoading={props.onRenderLoading}
            />
          }
        </>
      );
    } else if (otpstate.verified && addressstate.selectedAddress) {
      return (
        <>
          <PaymentContainer
            onRender={props.onRender}
            onRenderLoading={props.onRenderLoading}
          />
        </>
      );
    } else if (
      otpstate.loader === 1 ||
      //otpstate.fail ||
      !otpstate.verified
      //!cartstate.UpdatedShipping_data.success
    ) {
      if (otpstate.loader === 0 && otpstate.fail) {
        worngOtpHandler();
      } else if (otpstate.verified) {
        otpstate.spinner = false;
      }
      return (
        <>
        <div className={`${cartstate?.cardDesignEnabled ? 'card-container' : ''}`}
            style={{background:cartstate?.cardDesignEnabled?'none':'',display:'flex',flexDirection:'column',alignItems:'center',textAlign:'center',boxShadow:'none',border:'none',marginTop:LoginPopup?'20px':''}}
        >
          <div className="OtpLargeScreen">
          {!LoginPopup && <div className={cartstate?.cardDesignEnabled ? "headingCard" : "heading"} style={{marginBottom:'4px',fontSize: loginstate.customLogin ? 20 : '',fontWeight: loginstate.customLogin ? 500 : ''}}>{OtpHeading}</div>}
            <div className={cartstate?.cardDesignEnabled ? "contentCard" : "content"} style={{marginBottom:!LoginPopup ? loginstate.customLogin ? 0 : 16 : 0}}>{OtpSubHeading}</div>
          </div>
          <div>
{/*             <Input
              label="Enter OTP"
              type="number"
              id="otp"
              name="otp"
              onChange={(e: any) => otpInputHandler(e.target.value)}
              onInput={isNumber}
              loading={otpstate.verifyFired}
              autoComplete={false}
              value={otpstate.otpValue}
            />  */}
            <div id={!LoginPopup ? !cartstate?.cardDesignEnabled ? "otpbox" : "otpboxCard" : 'otpBoxLoginPopup'} style={{marginTop: '8px'}}>
            <OtpInput
            ref={otpRef} 
              onChange={(otp: any) => {
                otpInputHandler(otp)
              }
              }
              numInputs={6}
              value={otpstate.otpValue}
              separator={<span style={{ margin: '10px' }}> </span>}
              inputStyle={{
                border: !LoginPopup ? cartstate?.cardDesignEnabled ? '1px solid var(--Input-box, rgba(49, 115, 227, 0.20))' : '1px solid #DFE0EB' : 'var(--login-popup-primary-color)',
                width: '100%',
                height: '40px',
                background: cartstate?.cardDesignEnabled ? 'var(--card-backgorund-cta-button-text, #FFF)' : '#F9F9F9',
                fontSize: '16px',
              }}
              containerStyle={{
                justifyContent: 'space-between'
              }}
              //shouldAutoFocus={true}
              isInputNum={true}
              isDisabled={otpstate.verifyFired}
              autoComplete="one-time-code"
              shouldAutoFocus={ua.getResult().device.type === "mobile" ? false : true}
            />
            </div>
            {((otpstate.wrongOtp && otpstate.errorMessage && !otpstate.resendErrorMessage)) && <ErrorHandler msg={otpstate.errorMessage} />}
            {otpstate.resendErrorMessage && <div style={{ marginBottom: '10px' }}><ErrorHandler msg={otpstate.resendErrorMessage} type={"resend"} /> </div>}

                {(
                  !otpstate.resendErrorMessage &&
                <div className={!LoginPopup ? style.resendText : style.resendTextLoginPopup}
                style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#928F8F'}}>
                  {counter > 0 || whatsappCounter > 0
                  ?
                  <>
                  Resend OTP in
                  {(whatsappCounter > 0 || counter > 0)  ? (
                    <div style={{display : 'flex',alignItems : 'flex-start'}}>
                      <img
                        src={!cartstate?.cardDesignEnabled ? process.env.PUBLIC_URL + `${loginstate.customLogin ?  "./assets/images/svg/custom_login__timer.svg" : './assets/images/timer.svg'}` : process.env.PUBLIC_URL + "./assets/images/timer-blue.svg"}
                        alt="timer"
                        className={!cartstate?.cardDesignEnabled ? style.timerImg : style.timerImgCard}
                        style={{filter : LoginPopup ? 'var(--login-popup-svg-filter)' : ''}}
                      />
                      <span className={!cartstate?.cardDesignEnabled ? style.timer : style.timerCard}>
                        {whatsappCounter > 0 ? whatsappCounter < 10 ? "0" + whatsappCounter : whatsappCounter : counter > 0 ? counter < 10 ? "0" + counter : counter : ''}s
                      </span>
                    </div>
                  ) 
                  : (
                    ""
                  )}
                  </>
                  :
                  <>
                    Resend OTP via <span className={!cartstate?.cardDesignEnabled ? "" : "resend-button-card"} onClick={() => otpResendHandler(false)} style={{color : !LoginPopup ? !cartstate?.cardDesignEnabled ? loginstate.customLogin ? 'var(--secondary-color)' : 'rgb(111, 87, 233)' : '#3173E3' : 'var(--login-popup-primary-color)', cursor : 'pointer',border: !cartstate?.cardDesignEnabled ? '1px solid #3173E333' : '1px solid #3173E333',padding : '5px 6px',borderRadius : '6px'}}>SMS</span>{!loginstate.whatsappHidden && <> OR <span className={!cartstate?.cardDesignEnabled ? "" : "resend-button-card"} onClick={() => otpResendHandler(true)} style={{display : 'flex', color : !LoginPopup ? !cartstate?.cardDesignEnabled ? loginstate.customLogin ? 'var(--secondary-color)' : 'rgb(111, 87, 233)' :  '#3173E3' : 'var(--login-popup-primary-color)', cursor : 'pointer',border: !cartstate?.cardDesignEnabled ? '1px solid #3173E333' : '1px solid #3173E333',padding : '5px 6px',borderRadius : '6px',alignItems:'center',justifyContent:'center'}}><img src={process.env.PUBLIC_URL + "./assets/images/whatsapp.svg"} alt="whatsapp-icon" height="15px" style={{marginTop : '-2px',marginLeft : '3px'}}/></span></>}
                  </>
                  }
                </div>
                )
                }
            </div>
            {LoginPopup &&
              <div style={{display: 'flex', alignItems:'flex-start', marginTop:'8px',justifyContent : 'center'}}>
              <img 
                src={
                  process.env.PUBLIC_URL +
                  "./assets/images/" +
                  'info-icon-black.svg'}
                alt={"info-icon"}
                height={11}
                width={11}
                style={{filter : LoginPopup ? 'var(--login-popup-svg-filter)' : ''}}
              />
              <p className={style.info_text_login_popup} style={{color:"#717171"}}>
                By proceeding, you are agreeing to our <a href="https://checkout.shiprocket.in/terms-conditions/" target="_blank" style={{textDecoration : 'underline',fontWeight : 700,color:"#717171"}}>T&C</a> and <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" target="_blank" style={{textDecoration : 'underline',fontWeight : 700,color:"#717171"}}>Privacy policy</a>.
              </p>
              </div>

              }
          {
            loginstate.user_type_for_text === "new" && !LoginPopup && (
              <p style={{fontSize:'12px', color:'rgba(113, 113, 113, 0.7)', margin:'10px 0px'}}>
                By proceeding, you are agreeing to our{" "}
                <a href="https://checkout.shiprocket.in/terms-conditions/" 
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "terms-and-conditions",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }}  
                target="_blank" style={{color:'#292A2C'}}>Terms & conditions</a> and  
                <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" target="_blank"
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "privacy-policy",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }}
                style={{color:'#292A2C'}}> Privacy policy</a>
            </p>
            )
          }
          {
            deviceType !== "mobile" 
            &&  loginstate.user_type_for_text === "known" 
            &&  loginstate.Login_data.fetch_address_flag !== true && 
            !LoginPopup &&
            (
              <div style={{display:'flex', marginTop:'10px', alignItems:'flex-start'}}>
              <input
                id="fetchAddress"
                type="checkbox"
                onChange={fetchAddressChangeHandler}
                style={{accentColor: cartstate?.cardDesignEnabled ? '#3173E3' : 'var(--secondary-color)', marginTop:cartstate?.cardDesignEnabled ? '' : '4px', height : cartstate?.cardDesignEnabled ? '18px' : '', width : cartstate?.cardDesignEnabled ? '18px' : ''}}
                checked={otpstate.fetchAddressPermission}
              />
              <p style={{marginLeft:'5px', fontSize:'12px', color: cartstate?.cardDesignEnabled ? 'var(--Checkout-Primary-Color, #292929)' : 'rgba(113, 113, 113, 0.7)', fontWeight : cartstate?.cardDesignEnabled ? '500' : ''}}>
                Fetch my shipping addresses based on past orders
              </p>
              <p data-tip="">
              <img 
                src="https://d10srchmli830n.cloudfront.net/1663766402357_bf67f4db-5e4f-4632-9d62-6e992bdccd99_Group-27666tooltip.svg"
                alt="info"
                style={{marginLeft:'5px', marginTop:'3px'}}
                height="15px"
              />
              </p>
              <ReactTooltip 
                backgroundColor="#2B1E7C" 
                effect="solid" 
                className={style.tooltipDesktop} 
                offset={{left:150, top:0, right:0, bottom:5}}
                arrowColor="transparent"
                clickable={true}
                delayHide={1000}
              >
                Based on my past purchases, I hereby provide my consent to fetch my previous addresses. I understand that usage of this Shiprocket service is subject to our{" "}
                <a href="https://checkout.shiprocket.in/terms-conditions/" style={{color:"white"}}
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "terms-and-conditions",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }}   
                target="_blank">Terms & conditions</a> and {" "} 
                <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" style={{color:"white"}}
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "privacy-policy",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }}
                target="_blank">Privacy policy</a>.
              </ReactTooltip>
            </div>
            )
          }
          {
            deviceType === "mobile" && 
            loginstate.user_type_for_text === "known" &&
            loginstate.Login_data.fetch_address_flag !== true && 
            !LoginPopup && 
            (
              <div style={{display:'flex', marginTop:'10px', alignItems:'flex-start'}}>
              <input
                id="fetchAddress"
                type="checkbox"
                onChange={fetchAddressChangeHandler}
                style={{accentColor:cartstate?.cardDesignEnabled ? '#3173E3' : '#EFEFEF', marginTop:cartstate?.cardDesignEnabled ? '' : '4px', height : cartstate?.cardDesignEnabled ? '18px' : '', width : cartstate?.cardDesignEnabled ? '18px' : ''}}
                checked={otpstate.fetchAddressPermission}
              />
              <div style={{display:'flex', flexDirection:'column'}}>
              <p style={{marginLeft:'5px', fontSize:'12px', color: cartstate?.cardDesignEnabled ? 'var(--Checkout-Primary-Color, #292929)' : 'rgba(113, 113, 113, 0.7)', fontWeight : cartstate?.cardDesignEnabled ? 500 : ''}}>
                Fetch my shipping addresses based
              </p>
              <div style={{display:'flex'}}>
              <p style={{marginLeft:'5px', fontSize:'12px', color: cartstate?.cardDesignEnabled ? 'var(--Checkout-Primary-Color, #292929)' : 'rgba(113, 113, 113, 0.7)', fontWeight : cartstate?.cardDesignEnabled ? 500 : ''}}>
                on past orders
              </p>
              <p data-tip="">
              <img 
                src="https://d10srchmli830n.cloudfront.net/1663766402357_bf67f4db-5e4f-4632-9d62-6e992bdccd99_Group-27666tooltip.svg"
                alt="info"
                style={{marginLeft:'5px', marginTop:'3px'}}
                height="15px"
              />
              </p>
              <ReactTooltip 
                backgroundColor="#2B1E7C" 
                effect="solid" 
                className={style.tooltip} 
                offset={{left:0, top:0, right:45, bottom:5}}
                arrowColor="transparent"
                clickable={true}
              >
                Based on my past purchases, I hereby provide my consent to fetch my previous addresses. I understand that usage of this Shiprocket service is subject to our{" "}
                <a href="https://checkout.shiprocket.in/terms-conditions/" style={{color:"white"}}
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "terms-and-conditions",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }} 
                target="_blank">Terms & conditions</a> and {" "} 
                <a href="https://www.shiprocket.in/privacy-policy/my-shiprocket/" style={{color:"white"}}
                onClick={()=>{
                  AnalyticService.additionalEvents(
                    "privacy-policy",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  )
                }}
                target="_blank">Privacy policy</a>.
              </ReactTooltip>
              </div>  
              </div>
            </div>
            )
          }
          {
            !loginstate.sellerConfig?.ssoBlock && !LoginPopup &&
          <div style={{display:'flex', marginTop:'5px', height: "20px", alignItems:'center'}}>
              <input
                disabled={!otpstate.fetchAddressPermission}
                id="ssoPermission"
                type="checkbox"
                onChange={SSOChangeHandler}
                style={{accentColor:cartstate?.cardDesignEnabled ? '#3173E3' : 'var(--secondary-color)', height : cartstate?.cardDesignEnabled ? '18px' : '', width : cartstate?.cardDesignEnabled ? '18px' : ''}}
                checked={otpstate.SSOPermission}
              />
            <p style={{marginLeft:'5px', fontSize:'12px', color: cartstate?.cardDesignEnabled ? 'var(--Checkout-Primary-Color, #292929)' : 'rgba(113, 113, 113, 0.7)', fontWeight:'500'}}>
              Keep me logged in on this device.
            </p>
          </div>
          }
          {(loginstate?.sellerConfig?.enabledFeatureSkipOtp && loginstate?.sellerConfig?.featureCheckoutAsGuest) && !LoginPopup &&
              <div style={{display : 'flex' , width : '100%', justifyContent : 'flex-end',marginTop : '8px',flexDirection : 'column'}}>
                <div style={{display : 'flex',marginRight : '20px',justifyContent : 'center',marginBottom : '8px'}}>
                  OR
                </div>

                <button
                className={!cartstate?.cardDesignEnabled ? "modal_button"  : "modal_button_white"}
                onClick={()=>{
                  window.parent.postMessage(
                    {
                      trigger: 'otp-read-stop',
                    },
                    "*"
                  )
                  window.removeEventListener("message", messageEventListener, false);
                  dispatch({ type: "SKIP_OTP_SUCCESS"})
                  props.onRender()
                  props.onRenderLoading()
                }} 
                style={{marginRight : '20px',color :!cartstate?.cardDesignEnabled ?  '#6F57E9' : 'var(--Primary-shade, #3173E3)',fontWeight : 500, cursor : 'pointer'}}>
                Continue as guest
                </button>
              </div>
            }
        
        </div>
       
        </>
        
      );
    }
  };

  const isNumber = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = e.target.value.substring(0, 10);
    let inputs = document.querySelectorAll('input[type="number"]');
    for (let i = inputs.length; i--;)
      inputs[i].setAttribute("pattern", "\\d*");
  };

  const messageEventListener = function(event: any) {
    if (event.data.trigger === "otp-autofill") {
      if(!loginstate.otpSkip){
        otpInputHandler(event?.data?.data)
      }
      window.removeEventListener("message", messageEventListener, false);
    }
  };
  useEffect(()=>{
    window.addEventListener("message", messageEventListener, false);
  },[])

  const otpInputHandler = async (otp: any) => {
    otpstate.otpValue = otp.substring(0, 6);
    otpstate.wrongOtp = false;
    otpstate.fail = false;
    forceUpdate();
    if (otp.length === 6) {
      AnalyticService.otpAdded();
      document.getElementById("otp")?.setAttribute("disabled", "true");
      console.log(
        "jdhgjskehgvhj",
        addressstate.authentication_token_for_otp,
        loginstate.authentication_token
      );
      let reqPayload: validateOtpModel = {
        otp: otp,
        authentication_token: addressstate.newUserAddressAdded
          ? addressstate.authentication_token_for_otp
          : loginstate.authentication_token,
      };
      otpstate.spinner = true;
      otpstate.wrongOtp = false;
      dispatch(verifyOtp(reqPayload, loginstate.sellerConfig.seller_id));
    }
  };

  const worngOtpHandler = () => {
    otpstate.spinner = false;
    document.getElementById("otp")?.removeAttribute("disabled");
    otpstate.wrongOtp = true;
  };

  const otpResendHandler = async (sendOnWhatsapp: boolean) => {
    window.parent.postMessage(
      {
        trigger: 'otp-read-start',
      },
      "*"
    )
    AnalyticService.otpRequested();
    AnalyticService.additionalEvents(
      "resend-otp",
      cartstate?.Cart_data,
      couponState.couponCode, 
      Store.getItem('refreshToken') ? true : false,
      false
    );
    // let reqPayload: otpRequestModel = {
    //   mobile: otpstate.mobile,
    //   shop_name: loginstate?.channel?.shop_name,
    //   company_id: loginstate?.channel?.sr_company_id,
    // };
    //if (otpstate.failedAttempts < 5) {
    let resendPayload: resendOtp = {
      //authentication_token: loginstate.user_type === "known" ? loginstate.authentication_token : addressstate.authentication_token_for_otp,
      authentication_token: loginstate.authentication_token,
      mobile_no: loginstate.mobileNumber,
      channel: "SMS",
      resend_via_whatsapp: sendOnWhatsapp,
      engage_whatsapp_opted: loginstate.sellerConfig?.notifications?.engageWhatsappOpted,
      company_id: loginstate.sellerConfig?.companyId,
      cart_id: cartstate?.Cart_data?.id
    };
    // let resendPayload:any : {
    //   authentication_token: loginstate?.authTokenGenerated,
    //   mobile: cartstate?.mobile,
    //   channel: "SMS"
    // }
    // dispatch(requestOtp(reqPayload));
    dispatch(reSendOtp(resendPayload, loginstate.sellerConfig.seller_id));
    //}
    if(sendOnWhatsapp) {
      setWhatsappCounter(30)
    } else {
      setCounter(30);
    }
  };

  const goToLogin = async () => {
    await sendShiprocketCheckoutEvent({
      name: cartstate?.cardDesignEnabled ? 'CHANGE_NUMBER_VIA_OTP_SCREEN_CARD' : 'CHANGE_NUMBER_VIA_OTP_SCREEN',
      domain:loginstate.sellerConfig?.domainName,
      payload: { 
        "cart_id": cartstate?.Cart_data?.id,
        "otpLess":loginstate?.sellerConfig?.enabledFeatureCodForm
      }
    })



    AnalyticService.additionalEvents(
      "edit-phone-before-otp",
      cartstate?.Cart_data,
      couponState.couponCode, 
      Store.getItem('refreshToken') ? true : false,
      false
    );
    Store.removeItem('refreshToken')
    Store.removeItem('refreshTokenValue')
    //AppStorage.removeItem('mobile')
    Store.removeItem('user_id')
    //localStorage.removeItem('user_profile_id')
    Store.removeItem('seller_id')
    Store.removeItem('pim_sid')
    Store.removeItem('expireTime')
    window.location.reload();
  };

  useEffect(() => {

    counter > 0 &&
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);

      whatsappCounter > 0 && 
        setTimeout(() => {
          setWhatsappCounter(whatsappCounter - 1)
        }, 1000)

    if (!analyticsServicesFired) {
      AnalyticService.addOtpScreenOpened();
      AnalyticService.otpRequested();
      setAnalyticsServicesFired(true);
    }

    
  });

  useEffect(() => {
    if(whatsappCounter === 0 && loginstate.whatsappHidden) {
      loginstate.whatsappHidden = false
      props.onRenderLoading()
    }
  }, [whatsappCounter])


  
  if (loginstate.user_type_for_text === "known") {
    OtpHeading = loginstate.customLogin ? "Verify your phone number" :  "Verify phone number";
    OtpSubHeading = (
      <div className={!LoginPopup ? cartstate?.cardDesignEnabled ? `mb-10 content align-v-center ${style.cardSubHeading}` : "mb-10 content grey-dark align-v-center" : 'content login-popup-private-color align-v-center'} style={!LoginPopup ? {display : 'flex'} : {display : 'flex',fontSize : '12px',lineHeight : '12px'}}>
        <span style={cartstate?.cardDesignEnabled?{color:'#928F8F'}:{}}>{!LoginPopup?'Enter OTP send to': 'OTP sent to'}</span>
        <span className="semi-bold" style={{fontWeight:'600',fontSize : cartstate?.cardDesignEnabled ? '14px' : '',marginLeft:'2px',display:'flex',alignItems:'center',justifyContent:'center'}}>
          <span>
          {mobileNoInternationalFormat(loginstate.mobileNumber)}
          </span>
        &nbsp;
        <span style={{display : 'flex', color : !LoginPopup ? !cartstate?.cardDesignEnabled ? loginstate.customLogin ? 'var(--secondary-color)' : 'rgb(111, 87, 233)' :  '#3173E3' : 'var(--login-popup-primary-color)', cursor : 'pointer',border:'1px solid #3173E333',borderRadius : '6px',alignItems:'center',justifyContent:'center'}}>
        <img
          src={cartstate?.cardDesignEnabled ? process.env.PUBLIC_URL + "./assets/images/LogoutIcon.svg" : process.env.PUBLIC_URL + `${loginstate.customLogin ? './assets/images/svg/edit_pencil_icon.svg' : "./assets/images/EditIconViolet.svg" }`}
          alt="Edit Mobile number"
          onClick={goToLogin}
          style={{cursor: 'pointer',  filter : LoginPopup ? 'var(--login-popup-svg-filter)' : ''}}
          height={20}
          width={20}
        /> 
        </span>
        </span>
      </div>
    );
  } else {
    OtpHeading = loginstate.customLogin ? "Verify your phone number" :  "Verify phone number";
    OtpSubHeading = (
      <>
        {/*         <p className="mb-5">
          Save your details and enable 1-Click Checkout for all future orders
          across participating brands.
        </p> */}
        <span className={LoginPopup ? 'login-popup-private-color' : ''} style={!LoginPopup ? {} : {fontSize : '12px',lineHeight : '12px'}}>{!LoginPopup ? '6-digit OTP sent to' : 'OTP sent to'} </span>
        <span className={`semi-bold ${LoginPopup ? 'login-popup-private-color' : ''}`} style={!LoginPopup ? {fontWeight:'600'} : {fontWeight:'600',fontSize : '12px',lineHeight : '12px'}}>{mobileNoInternationalFormat(loginstate.mobileNumber)}
        &nbsp;
        <img
          src={cartstate?.cardDesignEnabled ? process.env.PUBLIC_URL + "./assets/images/edit-blue.svg" : process.env.PUBLIC_URL + `${loginstate.customLogin ? './assets/images/svg/edit_pencil_icon.svg' : "./assets/images/edit.svg" }` }
          alt="Edit Mobile number"
          onClick={goToLogin}
          style={{cursor: 'pointer', filter : LoginPopup ? 'var(--login-popup-svg-filter)' : '',height : 12 , width : 12}}
        />
        </span>
      </>
    );
  }

  return <>{verifyOtp1()}</>;
};

export default React.memo(Otp);