import React from "react";
import style from "./Error.module.css";
const ErrorHandler = (props: any) => {
  return (
    <div className={style.errorDanger}>
      <div className="h5">{props.msg || "Something Went Wrong!"}</div>
    </div>
  );
};
export default React.memo(ErrorHandler);
