import * as Sentry from "@sentry/react";
import { formatPhoneNumber } from "../../utils/commonUtils";
let containerId: string = "";

const initialiseGTM = ( clientContainerId:any) => {
  containerId= clientContainerId;
  if(containerId){
    (function (w, d, s, l, i) {
      {/*//@ts-ignore */}
      w[l] = w[l] || [];
      {/*//@ts-ignore */}
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
        {/*//@ts-ignore */}
      j.async = true;
      {/*// @ts-ignore */}
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      {/*/@ts-ignore */}
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", `${containerId}`);
  }

};

const hashedSHA256 = (value: any) => {
  const utf8 = new TextEncoder().encode(value);
  return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, "0"))
      .join("");
    return hashHex;
  });
};

const pushData = (data: any) => {
  try{
    window.dataLayer.push(data);
  }catch(error : any){
    Sentry.withScope(scope => {
      scope.setExtras({
        error: JSON.stringify(error?.response),
        data: JSON.stringify(data)
      })
      Sentry.captureMessage(`GTM-DATALAYER-PUSH-ERROR`)
    })
  }
};

const cartInIt = async (
  marketingConfig: any,
  cartData: any,
  couponCode: any,
  sso:boolean,
  newType: boolean,
  fbp: any,
  fbc: any
) => {
  if (containerId) {
    pushData({ ecommerce: null });
    let obj = {
      event: "cart-init",
      ...marketingConfig,
      cart_id: cartData?.id,
      sso: sso,
      fbp: fbp,
      fbc: fbc,
      ...(sso && {new: newType}),
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const deliverySpeedChanged = async (
  cartData: any,
  couponCode: any,
  sso:boolean,
  newType: boolean,
  old_delivery_name : any,
  old_delivery_price : any,
  new_delivery_name : any,
  new_delivery_price : any,
) => {
  if (containerId) {
    pushData({ ecommerce: null });
    let obj = {
      event: "delivery-speed-changed",
      cart_id: cartData?.id,
      sso: sso,
      ...(sso && {new: newType}),
      Old_delivery_speed_name : old_delivery_name,
      Old_delivery_speed_price : old_delivery_price,
      New_delivery_speed_name : new_delivery_name,
      New_delivery_speed_price : new_delivery_price,
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};


const additionalEvents = async (
  eventName: any,
  cartData: any,
  couponCode: any,
  sso:boolean,
  newType: boolean
) => {
  if (containerId) {
    pushData({ ecommerce: null });
    let obj = {
      event: eventName,
      cart_id: cartData?.id,
      sso: sso,
      ...(sso && {new: newType}),
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};





const phoneEntered = async (phoneNo: any, cartData: any, couponCode: any, userType: any) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    pushData({ ecommerce: null });
    let obj = {
      event: "phone-entered",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256: hashedPhone,
      new: userType === "new" ? true : false,
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const loggedIn = async (phoneNo: any, cartData: any, couponCode: any) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    pushData({ ecommerce: null });
    let obj = {
      event: "log-in",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: "INR",
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const addressAdded = async (
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedcity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    let hashedEmail= await hashedSHA256(addressDetails?.email);
    pushData({ ecommerce: null });
    let obj = {
      event: "address-add",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 :hashedPhone,
      email: addressDetails?.email,
      email_SHA256: hashedEmail,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      city_SHA256: hashedcity,
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      state_SHA256: hashedState,
      pincode: addressDetails?.pincode,
      pincode_SHA256: hashedPincode,
      country: 'in',
      country_SHA256: hashedCountry,
      ecommerce: {
        currency: "INR",
        value: cartData?.totalDiscount ? Number(cartData?.totalPrice) - Number(cartData?.totalDiscount) : cartData?.totalPrice,
        coupon: couponCode,
        items: cartData?.items?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const paymentScreenOpened = async (
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  pickrrTag: any,
  orderDetails:any
  
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    let hashedFirstName=await hashedSHA256(addressDetails?.firstName)
    let hashedLastName=await hashedSHA256(addressDetails?.lastName)
    let hashedEmail=await hashedSHA256(addressDetails?.email)
    pushData({ ecommerce: null });

    let obj = {
      event: "payment-screen",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      city_SHA256: hashedCity,
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      state_SHA256: hashedState,
      pincode: addressDetails?.pincode,
      pincode_SHA256: hashedPincode,
      country: 'in',
      country_SHA256: hashedCountry,
      first_name:addressDetails?.firstName,
      last_name:addressDetails?.lastName,
      email:addressDetails?.email,
      first_name_SHA256:hashedFirstName,
      last_name_SHA256:hashedLastName,
      email_SHA256:hashedEmail,
      ecommerce: {
        currency: "INR",
        value: orderDetails?.prepaidPrice ? orderDetails?.prepaidPrice : orderDetails?.codPrice,
        coupon: couponCode,
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const paymentInIt = async (
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  paymentMethod: any,
  pickrrTag: any,
  orderDetails:any, 
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    let hashedFirstName=await hashedSHA256(addressDetails?.firstName)
    let hashedLastName=await hashedSHA256(addressDetails?.lastName)
    let hashedEmail=await hashedSHA256(addressDetails?.email)
    pushData({ ecommerce: null });
    let obj = {
      event: "payment-init",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      city_SHA256: hashedCity,
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      state_SHA256: hashedState,
      pincode: addressDetails?.pincode,
      pincode_SHA256: hashedPincode,
      country: "in",
      country_SHA256: hashedCountry,
      first_name:addressDetails?.firstName,
      last_name:addressDetails?.lastName,
      email:addressDetails?.email,
      first_name_SHA256:hashedFirstName,
      last_name_SHA256:hashedLastName,
      email_SHA256:hashedEmail,
      ecommerce: {
        currency: "INR",
        payment_type: paymentMethod?.toUpperCase(),
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        value: orderDetails?.prepaidPrice ? orderDetails?.prepaidPrice : orderDetails?.codPrice,
        coupon: couponCode,
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};




const paymentSuccess = async (
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  orderDetails:any, 
  pickrrTag:any,
  paymentMethod:any
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    pushData({ ecommerce: null });
    console.log(cartData, 'checking cart data')


    let obj = {
      event: "payment-success",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      city_SHA256: hashedCity,
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      state_SHA256: hashedState,
      pincode: addressDetails?.pincode,
      pincode_SHA256: hashedPincode,
      country: 'in',  
      country_SHA256: hashedCountry,
      ecommerce: {
        currency: "INR",
        payment_type: paymentMethod?.toUpperCase(),
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        value: orderDetails?.prepaidPrice,
        coupon: couponCode,
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const paymentSuccessBilldesk = async (
  marketingConfig:any,
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  orderDetails:any, 
  pickrrTag:any,
  paymentMethod:any
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    pushData({ ecommerce: null });
    console.log(cartData, 'checking cart data')


    let obj = {
      event: "payment-success",
      ...marketingConfig,
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      city_SHA256: hashedCity,
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      state_SHA256: hashedState,
      pincode: addressDetails?.pincode,
      pincode_SHA256: hashedPincode,
      country: 'in',  
      country_SHA256: hashedCountry,
      ecommerce: {
        currency: "INR",
        payment_type: paymentMethod?.toUpperCase(),
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        value: orderDetails?.prepaidPrice,
        coupon: couponCode,
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};




const orderPlaced = async (
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  orderDetails: any,
  paymentType: any,
  pickrrTag: any,
  paymentMode: any,
  clientOrderId: any,
  partially?:any
) => {
  try {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    let hashedFirstName=await hashedSHA256(addressDetails?.firstName)
    let hashedLastName=await hashedSHA256(addressDetails?.lastName)
    let hashedEmail=await hashedSHA256(addressDetails?.email)
    pushData({ ecommerce: null });
    let obj = {
      event: "order-placed",
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      pincode: addressDetails?.pincode,
      country: 'in',
      city_SHA256: hashedCity,
      state_SHA256: hashedState,
      pincode_SHA256: hashedPincode,
      country_SHA256: hashedCountry,
      first_name:addressDetails?.firstName,
      last_name:addressDetails?.lastName,
      email:addressDetails?.email,
      first_name_SHA256:hashedFirstName,
      last_name_SHA256:hashedLastName,
      email_SHA256:hashedEmail,
      ecommerce: {
        currency: "INR",
        value: (paymentMode ===  'COD' || partially) ? orderDetails?.codPrice : orderDetails?.prepaidPrice,
        coupon: couponCode,
        tax: orderDetails?.totalTax,
        shipping: (paymentMode ===  'COD' || partially) ? Number(orderDetails?.shippingCharges) + Number(orderDetails?.codCharges) : orderDetails?.shippingCharges, 
        transaction_id: clientOrderId,
        paymentType: paymentType,
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    pushData(obj);
  }
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setExtras({
        error: JSON.stringify(e)
      })
      Sentry.captureMessage(`GTM-DATALAYER-PUSH-ERROR-S`)
    })
  }
};

const orderPlacedBilldesk = async (
  marketingConfig: any,
  phoneNo: any,
  cartData: any,
  addressDetails: any,
  couponCode: any,
  orderDetails: any,
  paymentType: any,
  pickrrTag: any,
  paymentMode: any,
  clientOrderId: any
) => {
  if (containerId) {
    let hashedPhone= await hashedSHA256(phoneNo);
    let hashedCity= await hashedSHA256(addressDetails?.city);
    let hashedState= await hashedSHA256(addressDetails?.state);
    let hashedPincode= await hashedSHA256(addressDetails?.pincode);
    let hashedCountry= await hashedSHA256('in');
    let hashedFirstName=await hashedSHA256(addressDetails?.firstName)
    let hashedLastName=await hashedSHA256(addressDetails?.lastName)
    let hashedEmail=await hashedSHA256(addressDetails?.email)
    pushData({ ecommerce: null });
    let obj = {
      event: "order-placed",
      ...marketingConfig,
      phone_num: formatPhoneNumber(phoneNo.toString()),
      phone_num_SHA256 : hashedPhone,
      city: addressDetails?.city?.replace(/\s/g,'')?.toLowerCase(),
      state: addressDetails?.state?.replace(/\s/g,'')?.toLowerCase(),
      pincode: addressDetails?.pincode,
      country: 'in',
      city_SHA256: hashedCity,
      state_SHA256: hashedState,
      pincode_SHA256: hashedPincode,
      country_SHA256: hashedCountry,
      first_name:addressDetails?.firstName,
      last_name:addressDetails?.lastName,
      email:addressDetails?.email,
      first_name_SHA256:hashedFirstName,
      last_name_SHA256:hashedLastName,
      email_SHA256:hashedEmail,
      ecommerce: {
        currency: "INR",
        value: paymentMode ===  'COD' ? orderDetails?.codPrice : orderDetails?.prepaidPrice,
        coupon: couponCode,
        tax: orderDetails?.totalTax,
        shipping: paymentMode ===  'COD' ? Number(orderDetails?.shippingCharges) + Number(orderDetails?.codCharges) : orderDetails?.shippingCharges, 
        transaction_id: clientOrderId,
        paymentType: paymentType,
        shipping_tier: pickrrTag.replace("Pickrr-", ""),
        items: orderDetails?.lineItems?.map((element: any) => {
          return {
            item_id: element.sku,
            item_name: element.name,
            currency: 'INR',
            // item_varient
            price: element.price,
            quantity: element.quantity,
          };
        }),
      },
    };
    // console.info(obj);
    pushData(obj);
  }
};

const GTM = {
  initialiseGTM,
  cartInIt,
  phoneEntered,
  loggedIn,
  paymentScreenOpened,
  paymentInIt,
  addressAdded,
  orderPlaced,
  paymentSuccess,
  orderPlacedBilldesk,
  paymentSuccessBilldesk,
  deliverySpeedChanged,
  additionalEvents
};

export default GTM;
