import { useEffect } from "react";
import { Store } from "./service/StorageHelper";



// to download chunks only

const Prefetch = () => {

    useEffect(()=>{
       
(() => import(/* webpackPrefetch: true , webpackChunkName: "LoadingScreen"  */ './components/Loading/LoadingScreen'))();

// Prefetching paymentContainerNew component with a specific chunk name
(() => import(/* webpackPrefetch: true, webpackChunkName: "paymentContainer" */ './components/Payment/paymentContainerNew'))();

    window.parent.postMessage(
        {
            trigger: 'set-cookie',
            data: {
                name: 'fastrr_user_id',
                data:Store.getItem('user_id') ? Store.getItem('user_id') : '',
                ...(!Store.getItem('user_id') ? {time: 'Thu, 01 Jan 1970 00:00:00 UTC'} : {} )
            }
        },
        "*"
    )
   
    window.parent.postMessage(
        {
            trigger: 'send-collect-events',
            data: {
                eventData : {
                    "etype": 'USER_EVENT_IDENTIFY',
                    "knownUser" : Store.getItem('user_id') ? true : false,
                    ...(Store.getItem('user_id') ? {user_id : Store.getItem('user_id')} : {}),
                }
            }
        },
        "*"
    )
    
    },[])
    return (
        <div id="prefetch"></div>
      );
}
 
export default Prefetch;