import { memo } from "react";
import Modal from "../../components/Modal/Modal";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { Store } from "../../service/StorageHelper";
import ExitForm from "../containers/ExitFormContainer";


const parser = require("ua-parser-js");

const HeaderNew = (
  {setActiveButton,activeButton,cartstate,couponState,loginstate,otpstate, isHeadless,  fetchingDetails, applicationType,SetIsOpen,isOpen,backgroundColor,openModalHandler ,shopName,exitCheckoutHandler,tryAnotherPaymentMethod,isProductPage,showExitForm,setShowExitForm} :
   {setActiveButton:any,activeButton:any,cartstate:any,couponState:any,loginstate:any,isHeadless: any, fetchingDetails?: any, applicationType ? : any,SetIsOpen:any,isOpen:any,backgroundColor:any,openModalHandler :any ,shopName:any,exitCheckoutHandler:any,otpstate:any,tryAnotherPaymentMethod:any,isProductPage:any,showExitForm:any,setShowExitForm:any }) => {
    
    const handleRedirectUrl = ()=>{
      if(isProductPage){
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:  `https://${loginstate?.sellerConfig?.domainName}`
                },
              },
            ],
          },
          "*"
        );
      }
    }

  return (
    <header 
    className="align-v-center d-flex" style={{position : 'relative',paddingBottom : '8px'}}
    >
      <Modal
        open={isOpen}
        title={showExitForm ? "Sorry to see you go.." : "Are you sure you want to exit?"}
        onClose={() => {
          SetIsOpen(false);
          setShowExitForm(false)
          setActiveButton(false)
          cartstate.fireSetuRequest = true;
          if(loginstate.onPage === "shipping" && !loginstate.user_type_for_text){
            AnalyticService.additionalEvents(
              "exit-drawer-closed",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }
          else{
            AnalyticService.additionalEvents(
              "back-drawer-closed",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }
        }}
        showClose={true}
        showAnotherMobile={true}
        size="sm"
        showBanner={true}
      > 
      {!showExitForm ?
        <>
        { (!isHeadless && applicationType !== "appmaker")  &&
        <button
          className={`${activeButton? 'modal_button_blue' : 'modal_button_white'} full-width`}
          onClick={exitCheckoutHandler}
          onMouseEnter={()=>{
            setActiveButton(true)
          }}
          onMouseLeave={()=>{
            setActiveButton(false)
          }}
          // style={{marginTop: (loginstate.onPage !== "shipping" || loginstate.user_type_for_text) ? "20px" : ""}}
        >
         {"Yes, exit checkout"}
        </button>
        
        }
        {
        <button
          className={!cartstate.cardDesignEnabled ? "modal_button modal_button_bg full-width relative mt-20" : `${activeButton ? 'modal_button_white'  : 'modal_button_blue'} full-width mt-16`}
          onMouseEnter={()=>{
            setActiveButton(false)
          }}
          onClick={() => {
            SetIsOpen(false)
            AnalyticService.additionalEvents(
              "no-continue-checkout",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }}
        >
         No, continue checkout
        </button>
        }   
        </> 
        :
        <ExitForm
        loginstate={loginstate}
        cartstate={cartstate}
        couponState={couponState}
        isProductPage={isProductPage}
        isCardDesign={true}      
        />
        } 
      </Modal>    
      {
        ((cartstate.orderPending && cartstate.activePaymentMode !== "UPI") || (isHeadless && !otpstate.verified) || (cartstate.orderPending && isHeadless) || cartstate.resumeCartFailed || cartstate.outofstock === true || fetchingDetails || (cartstate.isPaymentSuccess && (cartstate.verifyingPayment || cartstate.paymentProcessing))) ? (
          <></>
        ) :
        loginstate.onPage === "shipping" && !loginstate.user_type_for_text ? 
        <>
          {applicationType !== "appmaker" && (
            (!cartstate?.cardDesignEnabled) ?
              <div className="back" style={{color:backgroundColor && 'white'}} 
              onClick={()=>{
                  openModalHandler()
                  AnalyticService.additionalEvents(
                    "close-button-clicked",
                    cartstate?.Cart_data,
                    couponState.couponCode, 
                    Store.getItem('refreshToken') ? true : false,
                    false
                  );
                }}
              >
                <span className="cross-icon">&#x2715;</span>
              </div>
              :
              <div 
              style={{backgroundColor:backgroundColor ?backgroundColor: 'white',display : 'flex', alignItems : 'center',cursor : 'pointer',position : 'absolute'}}
              onClick={()=>{
                openModalHandler()
                AnalyticService.additionalEvents(
                  "close-button-clicked",
                  cartstate?.Cart_data,
                  couponState.couponCode, 
                  Store.getItem('refreshToken') ? true : false,
                  false
                );
              }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "./assets/images/BackCard.svg"
                  }
                  alt="arrow"
                  className="back-card-opacity"
                  height={14}
                  width={16}
                  style={{opacity : '0.4'}}
                />
              </div>
            )
          }
        
        </> :
        (
          !cartstate.isPaymentSuccess && (cartstate.verifyingPayment || cartstate.paymentProcessing) ?
          <div style={{backgroundColor:backgroundColor ?backgroundColor: 'white',display : 'flex', alignItems : 'center',cursor:'pointer',position : 'absolute'}} 
          onClick={() => {
              tryAnotherPaymentMethod()
          }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "./assets/images/BackCard.svg"
              }
              alt="arrow"
              className="back-card-opacity"
              height={14}
              width={16}
              style={{opacity : '0.4'}}
            />
          </div> :
          <div 
          style={{backgroundColor:backgroundColor ?backgroundColor: 'white',display : 'flex', alignItems : 'center',cursor : 'pointer',position : 'absolute',border:'none',}}
          onClick={() => {
            openModalHandler()
            AnalyticService.additionalEvents(
              "back-button-clicked",
              cartstate?.Cart_data,
              couponState.couponCode, 
              Store.getItem('refreshToken') ? true : false,
              false
            );
          }} 
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "./assets/images/BackCard.svg"
              }
              alt="arrow"
              className="back-card-opacity"
              height={14}
              width={16}
              style={{opacity : '0.4'}}
            />
          </div>
        )
      }  
      
        {
          loginstate?.sellerConfig?.logUrl ?
          <div style={{display : 'flex',alignItems : 'center',justifyContent : 'center',width : '100%'}}>
          <img className="logoCard" src={loginstate?.sellerConfig?.logUrl} alt={shopName} onClick={handleRedirectUrl} style={{cursor: isProductPage?"pointer": "default"}}/>
          </div> 
          :
          <></>
        }
      
    </header>
  );
};
export default memo(HeaderNew);
