import apiUrls from "../../api/Apis";
import HttpService from "../../service/HttpService";
import axios from "axios";
import Http from "../../service/Http";
import AppStorage from "../../service/AppStorage";
import store from "../store";
import { Store } from "../../service/StorageHelper";
import * as Sentry from "@sentry/react";






 export const createCartDetails =
  (user_id, 
  seller_id, 
  platform_id, 
  items, 
  pim_sid, 
  coupon, 
  platform, 
  cart_resume_id, 
  fbp, 
  fbc, 
  utm_params, 
  masonPayload, 
  ipAddress, 
  cartAttributes, 
  isProductPage,
  landingUrlFastrr, 
  loginCardDesign, 
  applicationType, 
  multipleClientAndSessionId

  ) => async (dispatch) => {

    dispatch({ type: "CART_INITIATE_REQUEST" });
    try {
      const response = await Http.postwithExtraParmas(
        `${apiUrls.CREATE_CART_DETAILS()}/${platform_id}`,
        {
          items: items,
          ...((new Date(Date.now() + 30000) < new Date(Store.getItem('expireTime')) || Store.getItem('refreshToken')) && {'forceCreate': true}),
          ...(coupon && {couponCode: coupon}),
          channel: platform,
          ...(cart_resume_id && {fields: {referenceId: cart_resume_id}}),
          ...(utm_params && {utmParam: utm_params}),
          ...(masonPayload && {discountConfig: {type: "mason", data: masonPayload}}),
          ...(isProductPage && { variant : true}),
          ...(cartAttributes && !landingUrlFastrr && {cartAttributes: cartAttributes}),
          ...(cartAttributes && landingUrlFastrr && {cartAttributes: {...cartAttributes, landing_page_url: landingUrlFastrr}}),
          ...(!cartAttributes && landingUrlFastrr && {cartAttributes: {landing_page_url: landingUrlFastrr}}),
          ...(multipleClientAndSessionId && {gaDetailsList: multipleClientAndSessionId}),
          mobileApp: applicationType ? 'appMaker' : ''
        },
        seller_id,
        fbp,
        fbc,
        ipAddress
      );
      if (response.status === 200) {
        const coverage = {"50/50":/[0-7]/,"80/20":/[0-2]/,"100/0":[]}
        const seller_conf = {
        }
        const show_card_design_by_coverage = (seller_coverage)=>{
          if (seller_coverage){
            switch (seller_coverage) {
              case "50/50":
                return coverage["50/50"].test(response?.data?.id?.slice(-1))
                break;
              case "80/20":
                return coverage["80/20"]?.test(response?.data?.id?.slice(-1))
                break;
              case "100/0":
                return true
                break;
              default:
                return true
                break;
            }
          }else{
            return true
          }
         
        }

        const cardDesignEnabled = loginCardDesign ? 
        (Store.getItem('isCardDesign') && new Date(Date.now() + 30000) < new Date(Store.getItem('cardDesignExpireAt'))) ? Store.getItem('isCardDesign') === '1' ?
        true
        :
        false
        : 
        show_card_design_by_coverage(seller_conf?.[seller_id])
        : false
        if(loginCardDesign){
          if(!Store.getItem('isCardDesign') || (new Date(Date.now() + 30000) > new Date(Store.getItem('cardDesignExpireAt')))){
            Store.setItem('isCardDesign',cardDesignEnabled ? '1' : '0')
            Store.setItem('cardDesignExpireAt',new Date(new Date().getTime() + 24 * 60 * 60 * 1000))
          }
        }else{
          Store.removeItem('isCardDesign')
          Store.removeItem('cardDesignExpireAt')
        }
        console.log(`card design enabled`,cardDesignEnabled)

        dispatch({ type: "CART_INITIATE_SUCCESS", payload: response?.data , cardDesignEnabled : cardDesignEnabled });
      }
    } catch (error) {
      console.log(error)
      dispatch({ type: "CART_INITIATE_FAILURE", payload: error });
      if(typeof error?.response?.status === "number" && error.response.status !== 400){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            error:error?.toJSON(),
            response_body: error?.response?.data,
          request_body: {
            items: items,
            ...((new Date(Date.now() + 30000) < new Date(Store.getItem('expireTime')) || Store.getItem('refreshToken')) && {'forceCreate': true}),
            ...(coupon && {couponCode: coupon}),
            channel: platform,
            ...(cart_resume_id && {fields: {referenceId: cart_resume_id}})
          }
          })
          Sentry.captureMessage(`Create-Cart-Failure-Non-Empty-${apiUrls.CREATE_CART_DETAILS()}/${platform_id}`)
        })
      } else {
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            error:error?.toJSON(),
            response_body: error?.response?.data,
          request_body: {
            items: items,
            ...((new Date(Date.now() + 30000) < new Date(Store.getItem('expireTime')) || Store.getItem('refreshToken')) && {'forceCreate': true}),
            ...(coupon && {couponCode: coupon}),
            channel: platform,
            ...(cart_resume_id && {fields: {referenceId: cart_resume_id}})
          }
          })
          Sentry.captureMessage(`Create-Cart-Failure-Other-${apiUrls.CREATE_CART_DETAILS()}/${platform_id}`)
        })
      }
    }
  };

//crete orders
export const createOrder =
  (bodyPayload, pims_id, seller_id) => async (dispatch) => {
    dispatch({ type: "CREATE_ORDER_REQUEST" });
    try {
      const response = await Http.post(
        apiUrls.FETCH_ORDERS(),
        {
          ...bodyPayload,
        },
        seller_id
      );
      if (response) {
        dispatch({ type: "CREATE_ORDER_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "CREATE_ORDER_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.toJSON(),
            response_body: JSON.stringify(error?.response?.data),
            request_body: bodyPayload
          })
          Sentry.captureMessage(`Order-POST-Failure-${apiUrls.FETCH_ORDERS()}`)
        })
    }
    }
  };

  export const updateOrder =
  (bodyPayload, seller_id) => async (dispatch) => {
    dispatch({ type: "UPDATE_ORDER_REQUEST" });
    try {
      const response = await Http.put(
        apiUrls.FETCH_ORDERS(),
        {
          ...bodyPayload,
        },
        seller_id
      )
      if (response) {
        dispatch({ type: "UPDATE_ORDER_SUCCESS", payload: response.data });
      }
    } catch (error) {
      dispatch({ type: "UPDATE_ORDER_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.toJSON(),
            request_body: bodyPayload,
            response_body: JSON.stringify(error?.response?.data)
          })
          Sentry.captureMessage(`Order-PUT-Failure-${apiUrls.FETCH_ORDERS()}`)
        })
    }
    }
  };

  export const deleteCart = (platform_id) => async (dispatch) => {
    try {
      const response = await Http.deletee(
        `${apiUrls.CREATE_CART_DETAILS()}/${platform_id}`,
      );
    } catch (error) {
      console.log(error)
    }
  };

  export const abandantCart = (cart_Id, x_pass, seller_id) => async (dispatch) => {
    dispatch({type: 'ABANDONED_INITIATE_REQUEST'})
    try{
      const response = await Http.abandantCartWith(`${apiUrls.CREATE_CART_DETAILS()}/${cart_Id}`, x_pass, seller_id)  
      if(response){
        dispatch({ type: "ABANDONED_INITIATE_SUCCESS", payload: response?.data});
      }
    } catch(error){
      dispatch({ type: "ABANDONED_INITIATE_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON() 
          })
          Sentry.captureMessage(`Abandoned-cart-failure-${apiUrls.CREATE_CART_DETAILS()}/${cart_Id}`)
        })
      }
    }
  } 

  export const resumeCart = (resume_cart_id, type) => async (dispatch) => {
    dispatch({type: 'RESUME_CART_REQUEST'})
    try {
      let url = `${apiUrls.RESUME_CART()}?id=${resume_cart_id}`
      if(type) {
        url += `&type=${type}`
      }
      const response = await Http.get(url)  
      if(response){
        dispatch({ type: "RESUME_CART_SUCCESS", payload: response?.data?.data});
      }
    } catch(error){
      dispatch({ type: "RESUME_CART_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON()
            })
          Sentry.captureMessage(`ResumeCart-or-headless-failure-${apiUrls.RESUME_CART()}?id=${resume_cart_id}`)
        })
      }
    }
  } 




  export const gstNumber = (
    reqPayload, 
    userProfileId, 
    createOrderAfterCoupon, 
    seller_id, 
    ) => async (dispatch) => {
    dispatch({type:"GST_APPLY_REQUESTED", payload: {}});
    try {
      const response = await Http.put(
        `${apiUrls.CREATE_CART_DETAILS()}/${
          userProfileId
        }`,
        reqPayload,
        seller_id
      );  
      // console.log(response.data.couponCode)  
      if (response) {
        dispatch({ type: "GST_APPLY_SUCCESS", payload: response?.data });
        console.log('it is working or not')
        // response.data.totalDiscount > 0 &&  
        // if(invalidCoupon && defaultAddressCorrect){
          createOrderAfterCoupon()
        // }else if(response.data.totalDiscount > 0 && defaultAddressCorrect){
          // createOrderAfterCoupon();
        // }
      }
    } catch (error) {
      dispatch({ type: "GST_APPLY_FAILURE", payload: error });
      if(typeof error?.response?.status === "number"){
        Sentry.withScope(scope => {
          scope.setExtras({
            request_headers: error?.config?.headers,
            response_body: JSON.stringify(error?.response?.data),
            error: error?.toJSON(),
            request_body: reqPayload
          })
          Sentry.captureMessage(`Coupon-Apply-Failure-${apiUrls.CREATE_CART_DETAILS()}/${userProfileId}`)
        })
    }
    }
  };
  
