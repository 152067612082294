var Shipping_initialstate = {
    loader: 1,
    fail:false,
    toShow: {
        name: '',
        eddmsg: '',
        price: 0
    },
    shippingDate: '',
    sendShippingfired:true,
    Shipping_data: {},
    serviceFetching : false,
    serviceFetched : false,
    selected_service_data: null,
    service_data: null,
    paymentMode: 'PREPAID',
    orderBlock: false,
    platformCustomerId: '',
    networkFail: false,
    payment_modes_discount:{},
    max_prepaid_discount: 0,
    updatePayloadIntermittentServiceability : {},
    savedCodFormAddress : false
}

const Shipping_reducer = (state = Shipping_initialstate, action) =>{
    switch(action.type){
        case "FETCH_Shipping_REQUEST":
            return{
                ...state,
                sendShippingfired:false,
                loader:1
            }

        case "FETCH_Shipping_SUCCESS":
            return{
                ...state,
                sendShippingfired:false,
                Shipping_data: action.payload,
                loader:0
            }

        case "FETCH_Shipping_FAILURE":
            return{
                ...state,
                fail:true,
                sendShippingfired:false,
                loader:0
            }

        case "FETCH_SERVICE_REQUEST":
            return {
                ...state,
                serviceFetching: true,
                //serviceFetched: false,
                loader: 1,
                fail: false
            }

        case "FETCH_SERVICE_SUCCESS":
            return {
                ...state,
                serviceFetching: false,
                serviceFetched: true,
                service_data: action.payload,
                loader: 0,
                orderBlock: action.payload?.order_block,
                platformCustomerId:action?.payload?.platform_customer_id,
                payment_modes_discount:action.payload?.payment_modes_discount,
                max_prepaid_discount: action.payload?.max_prepaid_discount,
                updatePayloadIntermittentServiceability : action?.requestPayload,
                ...(action?.savedCodFormAddress ? {savedCodFormAddress : true} : {})
            }
     case "FETCH_SERVICE_FAILED":
         return {
            ...state,
            fail: (typeof action?.payload?.response?.status === "number" ? true : false),
            networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
            serviceFetching: false,
            serviceFetched: true,
            loader: 0
         }

        case 'UPDATE_INTERMITTENT_UPDATED_ADDRESS_OTPLESS':
            return {
                ...state,
                updatePayloadIntermittentServiceability : action?.payload
            }
        default: return(state);
    }
};

export default Shipping_reducer;
