import React, { useEffect, useState } from "react";
import styles from "./FloatLabel.module.css";

const FloatLabel = props => {
    console.log(props,'props')
  const [focus, setFocus] = useState(false);
  const { children, label, value, mandatory,disabled } = props;

  const labelClass =
    disabled ? focus || (value && value.length !== 0) || label === "Pincode" ? "label label-float-disabled" : "label" : focus || (value && value.length !== 0) || label === "Pincode" ? "label label-float" : "label";

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label} {mandatory && <span className={`${focus || (value && value.length !== 0) || label === "Pincode" ? styles.asteriskRed : styles.asteriskGrey}`}>*</span>}</label>
    </div>
  );
};

export default FloatLabel;
