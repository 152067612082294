import style from "./modal.module.css";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ScarcityBanner from "./ScarcityBanner";
export default function Modal(props: any) {

  const addressstate = useSelector((state: any) => state.Address);
  const cartstate = useSelector((state: any) => state.Cart);
  const totalDiscount:number=cartstate?.Cart_data?.totalDiscount || 0;
  const loginstate = useSelector((state: any) => state.Login);
  const [isCloseModal, setIsCloseModal] = useState(false);
  const scarcityFeatureChoice = loginstate.sellerConfig?.scarcityFeatureChoice?.toUpperCase()

useEffect(() => {
  if(props.open) {
    document.body.style.overflow = "hidden"
    loginstate.isAnyModalOpen = true
  } else {
    document.body.style.overflow = "scroll"
    loginstate.isAnyModalOpen = false
  }
},[props.open])

useEffect(() => {
  if(props.title === "Edit address" && addressstate.addressUpdateFail !== true && !addressstate.updatingAddress) {
  const el = document.getElementById("modalBodyFastrr")
  el?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'auto'
  })
}
},[props.children])
  
  if (!props.open) return null
  return (
    <>
    {!cartstate?.cardDesignEnabled ?
      <>
        <div className={style.backDrop} onClick={props.onClose} id="modalOpened"/>
        <div
          className={clsx(style.modal, {
            [style.modalSm]: props?.size === "sm",
            [style.modalMd]: props?.size === "md",
            [style.modalMid]: props?.size === "mid",
            [style.modalMdExtra]: props?.size === "mdx"
          })}
          
          style ={{backgroundColor: `${props.bg === 'grey' ? '#F2F4F7' : '#fffff'}`} }
        >
          <div 
            className={`
              ${style.Header} 
              ${props.couponHeading === 'white' && style.bgWhite}`}
              style={(props.showBanner && scarcityFeatureChoice && !scarcityFeatureChoice?.includes('DISABLED') )? {border:'none'}:{}}
            >
            {props?.title && 
              <div className={style.title}>{props?.title}</div>
            }
            {/* {props.subtitle && (
              <div className={style.subTitle}>
                <img
                src={process.env.PUBLIC_URL + "./assets/images/coupon.svg"}
                alt="discount-icon"
                height="13"
                style={{marginRight:'5px'}}
                />
                {props.subtitle}
              </div>
            )} */}
            {props?.textSubtitle && (
              <div className="mt-10">{props?.textSubtitle}</div>
            )}
            {props?.showClose  &&  (
            <div className={style.closeWrapper}>            
              <div 
                onClick={props.onClose} 
                className={style.close}
              />             
            </div>
            )}
          </div>
            {(props.showBanner && scarcityFeatureChoice && scarcityFeatureChoice !== 'DISABLED') &&
            <ScarcityBanner scarcityFeatureChoice={scarcityFeatureChoice} totalDiscount={totalDiscount}/>
              }
            
          <div 
            className={style.body} 
            id="modalBodyFastrr" 
            style={{height: props.height ? props.height : ""}}
            >
            {props?.children}
          </div>
        </div>
      </>
      :
      <>
        <div className={clsx(style.backDrop, isCloseModal === true && (style.closeBackDrop))} 
        onClick={()=>{
          setIsCloseModal(true);
          setTimeout(() => {
              props.onClose();
              setIsCloseModal(false)
          }, 320)
        }}
        id="modalOpened"/>
        <div
          className={clsx(style.modalCard, isCloseModal === true && (style.closeModalCard))}
          
          style ={{backgroundColor: `${props.bg === 'grey' ? '#F2F4F7' : '#fffff'}`} }
        >
          <div 
            className={`
              ${style.HeaderCard} 
              ${props.couponHeading === 'white' && style.bgWhite}`}
            >
            {props?.title && 
              <div className={style.title}>{props?.title}</div>
            }
            {/* {props.subtitle && (
              <div className={style.subTitle}>
                <img
                src={process.env.PUBLIC_URL + "./assets/images/coupon.svg"}
                alt="discount-icon"
                height="13"
                style={{marginRight:'5px'}}
                />
                {props.subtitle}
              </div>
            )} */}
            {props?.textSubtitle && (
              <div className="mt-10">{props?.textSubtitle}</div>
            )}
            {props?.showClose  &&  (
            <div className={style.closeWrapper}>            
              <div 
                onClick={()=>{
                  setIsCloseModal(true);
                  setTimeout(() => {
                      props.onClose();
                      setIsCloseModal(false)
                  }, 320)
                }}
                className={style.close}
              />             
            </div>
            )}
          </div>
            {(props?.showBanner && scarcityFeatureChoice && !scarcityFeatureChoice?.includes('DISABLED')) && <div style={{marginBottom:'12px'}}>
              <ScarcityBanner scarcityFeatureChoice={scarcityFeatureChoice} totalDiscount={totalDiscount} />
              </div>
            }
          <div 
            className={style.bodyCard} 
            id="modalBodyFastrr" 
            style={{height: props.height ? props.height : ""}}
            >
            {props?.children}
          </div>
        </div>
      </>
    }
    </>
  );
}
