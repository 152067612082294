import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";

export const fetchPincode = (addressDetails) => async (dispatch) => {
  dispatch({type: 'FETCH_Pincode_REQUEST' })
  const response = await HttpService.getWithParams(apiUrls.GET_PINCODE(), addressDetails).then(response =>{
    dispatch({type: 'FETCH_Pincode_SUCCESS',payload: response.data})}
  ).catch(error=>{
    dispatch({type: 'FETCH_Pincode_FAILURE',payload: error})}
  );
};

export const addAddress = (addressDetails) => async (dispatch) => {
  dispatch({type: 'FETCH_Delivery_REQUEST'})
  const response = await HttpService.getWithParams(apiUrls.POST_ADD_ADDRESS(), addressDetails).then(response =>{
    dispatch({type: 'FETCH_Delivery_SUCCESS',payload: response.data})}
  ).catch(error=>{
    dispatch({type: 'FETCH_Delivery_FAILURE',payload: error})}
  );;
};

