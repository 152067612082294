import apiUrls from "../../api/Apis";
import HttpService from "../../service/HttpService";
import axios from 'axios';
import Http from "../../service/Http";
import * as Sentry from "@sentry/react";
import { checkServicability } from "../Shipping/Ship_actions";

export const couponApply = (reqPayload, pim_sid, sId, userProfileId, createOrderAfterCoupon, invalidCoupon, seller_id, servicabilityPayload, otpVerified,callServiceabilityOnCouponApply, defaultAddressCorrect, proceedToBuy, isProductPage, numberEntered, isCardDesign, codFormServiceabilityCallCondition) => async (dispatch) => {
  dispatch({type:"COUPON_APPLY_REQUESTED", payload: {}});
  try {
    const response = await Http.put(
      `${apiUrls.CREATE_CART_DETAILS()}/${
        userProfileId
      }`,
      reqPayload,
      seller_id
    );  
    // console.log(response.data.couponCode)  
    if (response) {
      dispatch({ type: "COUPON_APPLY_SUCCESS", payload: response });
      // response.data.totalDiscount > 0 &&  
      if(((otpVerified || codFormServiceabilityCallCondition) && callServiceabilityOnCouponApply && !!servicabilityPayload?.address && defaultAddressCorrect && (!isProductPage || (isProductPage && proceedToBuy) || (isProductPage && numberEntered) )) && (invalidCoupon || (response?.data?.totalDiscount > 0))){
        await dispatch(checkServicability(servicabilityPayload,pim_sid,seller_id))
      }
      if(invalidCoupon && defaultAddressCorrect && (!isProductPage || (isProductPage && proceedToBuy))){
        createOrderAfterCoupon()
      }else if((response.data.totalDiscount > 0) && defaultAddressCorrect && (!isProductPage || (isProductPage && proceedToBuy)  || (isProductPage && numberEntered) )){
        createOrderAfterCoupon();
      }
    }
  } catch (error) {
    dispatch({ type: "COUPON_APPLY_FAILURE", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers,
          response_body: JSON.stringify(error?.response?.data),
          error: error?.toJSON(),
          request_body: reqPayload
        })
        Sentry.captureMessage(`Coupon-Apply-Failure-${apiUrls.CREATE_CART_DETAILS()}/${userProfileId}`)
      })
  }
  }
};



export const getCouponSuggestion = (seller_id) => async (dispatch) => {
  dispatch({ type: "SUGGESTION_COUPON_APPLY_REQUEST" });
  try {
    const response = await Http.get(`${apiUrls.COUPON_SUGGESTION()}?id=${seller_id}&version=${1}`, seller_id);  
    if (response) {
      dispatch({ type: "SUGGESTION_COUPON_APPLY_SUCCESS", payload: response });
    }
  } catch (error) {
    dispatch({ type: "SUGGESTION_COUPON_APPLY_ERROR", payload: error });
    if(typeof error?.response?.status === "number"){
      Sentry.withScope(scope => {
        scope.setExtras({
          request_headers: error?.config?.headers, 
          response_body: JSON.stringify(error?.response?.data),
          error: error?.toJSON()
        })
        Sentry.captureMessage(`get-all-coupons-failure-${apiUrls.COUPON_SUGGESTION()}?id=${seller_id}`)
      })
  }
  }
};
