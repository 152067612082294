import React from "react";

const errorStyle = {
  color: "#FF0000",
  fontSize: "12px",
};
const successStyle = {
  color: "#008000",
  fontSize: "12px",
};
const normalStyle = {
  color: "#000000",
  fontSize: "12px",
};
const loadingMsg = {
  color: "darkgrey",
  textAlign: "center",
  marginTop: "100px",
  fontSize: "14px",
};
const Alert = (props: any) => {
  let alertStyle: any;
  switch (props.type) {
    case "success": {
      alertStyle = successStyle;
      break;
    }
    case "normal": {
      alertStyle = normalStyle;
      break;
    }
    case "loading": {
      alertStyle = loadingMsg;
      break;
    }
    case "error" : {
      alertStyle = errorStyle;
      break;
    }
    default: {
      alertStyle = {
        color:'#616161',
        fontSize: "12px"
      };
      break;
    }
  }

  return <p style={alertStyle}>{props.alertMessage}</p>;
};
export default React.memo(Alert);
