import { createStore, applyMiddleware , compose} from 'redux'
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
// import thunk from 'redux-thunk'

// export const PWAAppState = () => {
//     return ({});
// }

// let initialState = PWAAppState();

// if (typeof window !== "undefined" && window.__INITIAL_STATE__) {
//     initialState = window.__INITIAL_STATE__;
//   }
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancer = composeEnhancers(applyMiddleware(thunk))
// const store = createStore(rootReducer,initialState,composedEnhancer);
const store = createStore(rootReducer,composedEnhancer,);
// const store = createStore(Shipping_reducer)

export default store 