import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import AppStorage from "./service/AppStorage";
import * as Sentry from "@sentry/react";
import Prefetch from "./Prefetch";


Sentry.init({
  dsn: window.location.host === "fastrr-boost-ui.pickrr.com" ? "https://ddaf705e57e04f238f198082c81f476a@o4505012831911936.ingest.sentry.io/4505030651871232" : "https://d184220520c84b7bbb907252bc2a21b7@o4505012831911936.ingest.sentry.io/4505606984237056",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const UrlQueryStrings = window.location.search;
let params = new URLSearchParams(UrlQueryStrings);

let upSellId = params.get("after_sell_id") != null ? params.get("after_sell_id") : null; 
let fastrrCtpId = params.get("fastrr_ctp_id") != null ? params.get("fastrr_ctp_id") : null;
let srLogin = params.get("login") != null ? params.get("login") : null;
let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;
const isPrefetch=params?.get("prefetch")

// if (isPrefetch) ReactDOM.render(<React.StrictMode>  <Prefetch/> </React.StrictMode>,
//   document.getElementById("root"))

let messageEventListener = function (event: any) {
  if (event.data.trigger === "headless-storage") {
    AppStorage.setLocalStorage(event.data.data);
    if (isPrefetch) {
      ReactDOM.render(<React.StrictMode>  <Prefetch/> </React.StrictMode>,
      document.getElementById("root"))
    }
    else {
    ReactDOM.render(
      <BrowserRouter>
        <React.StrictMode>  
            {LoginPopup ? 
            <div className="LoginApp">            
              <App />
            </div>
            :
              (!upSellId && !fastrrCtpId) ?
              (
                <div style={{ width: '100%' }}>
                  <div className="App" style={{ background:'transparent'}}>
                    <App />
                  </div>
                </div>
              ) : 
              (
                <App />
              )
            }        
        </React.StrictMode>
      </BrowserRouter>,
      document.getElementById("root")
    );
  }
    window.removeEventListener("message", messageEventListener, false);
  }
};

window.onerror = function(message, source, lineno, colno, error) {
  // Check if the error message matches the ResizeObserver error
  if (typeof message === "string" && message.includes('ResizeObserver loop')) {
      // Suppress the error
      console.warn('ResizeObserver error suppressed:', message);
      return true; // Return true to prevent the browser's default error handling
  }
};

window.addEventListener("message", messageEventListener, false);
window.parent.postMessage(
  {
    trigger: isPrefetch ? 'headless-storage-prefetch' : LoginPopup ? 'headless-storage' : (fastrrCtpId || upSellId) ? fastrrCtpId ? "headless-storage-ctp" : "headless-storage-upsell" : "headless-storage",
    actions: [
      {
        action: "loadStorage",
        data : {
          trigger: isPrefetch ? 'headless-storage-prefetch' : LoginPopup ? 'headless-storage' : (fastrrCtpId || upSellId) ? fastrrCtpId ? "headless-storage-ctp" : "headless-storage-upsell" : "headless-storage",
        }
      },
    ],
  },
  "*"
);  

// console.log = function () {};

