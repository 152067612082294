import AnalyticService from "../../service/analytics/AnalyticsService";
import "./Footer.css";
import { useSelector } from "react-redux";
import { Store } from "../../service/StorageHelper";

const Footer = ({ show }: any) => {
  const loginstate = useSelector((state: any) => state.Login);
  const cartState = useSelector((state: any) => state.Cart);
  const couponState = useSelector((state: any) => state.Coupon);
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const uuid = urlSearchParams.get("uuid") ? urlSearchParams.get("uuid") : null;

  return (
    <>
      <div
        className={
          cartState?.cardDesignEnabled
            ? "whole-container-card grey-medium "
            : "whole-container grey-medium"
        }
      >
        {loginstate?.onPage === 'shipping' && show && !loginstate.sellerConfig?.nativeShopifyCheckout && !loginstate.customLogin && !LoginPopup &&
          (
            <div className='bgCheckoutFeature' style={{ fontSize: '12px', display: 'flex', gap: '5%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <span className='icon'>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "./assets/images/securePay.svg"
                    }
                    alt="Secure Transactions"
                  />
                </span>
                <span className='badgeTextOldDesign'>
                  Secure payments
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                <span className='icon'>
                  <img
                    src={process.env.PUBLIC_URL + "./assets/images/truckDelivery.svg"}
                    alt="Shop in One Login"
                  />
                </span>

                <span className='badgeTextOldDesign'>
                  Assured delivery
                </span>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                <span className='icon'>
                  <img
                    src={process.env.PUBLIC_URL + "./assets/images/verifiedSell.svg"}
                    alt="Checkout Faster"
                  />
                </span>
                <span className='badgeTextOldDesign'>
                  Verified seller
                </span>
              </div>

            </div>
          )
        }
        <div className="d-flex container2 h6" style={{marginBottom:0}}>
          {loginstate?.sellerConfig?.tncUrl && (
            <>
              <a
                rel="noreferrer"
                href={loginstate?.sellerConfig?.tncUrl}
                className="mr-5"
                target="_blank"
              >
                T&C
              </a>
              <p>|</p>
            </>
          )}
          {loginstate?.sellerConfig?.privacyPolicyUrl && (
            <>
              <a
                rel="noreferrer"
                href={loginstate?.sellerConfig?.privacyPolicyUrl}
                className="ml-5 mr-5"
                target="_blank"
              >
                Privacy Policy
              </a>
              <p>|</p>
            </>
          )}
          {loginstate?.sellerConfig?.refundPolicyUrl && (
            <>
              <a
                rel="noreferrer"
                href={loginstate?.sellerConfig?.refundPolicyUrl}
                className="ml-5 mr-5"
                target="_blank"
              >
                Refund Policy{" "}
              </a>
              <p>|</p>
            </>
          )}
          {loginstate?.sellerConfig?.shippingPolicyUrl && (
            <a
              rel="noreferrer"
              href={loginstate?.sellerConfig?.shippingPolicyUrl}
              className="ml-5 mr-5"
              target="_blank"
            >
              Shipping Policy
            </a>
          )}
        </div>
        <div style={{display:'flex',justifyContent:'space-around'}}>
        <div className="d-flex container2 h6" style={{marginBottom:1}}>
          <a
            rel="noreferrer"
            href="https://checkout.shiprocket.in/terms-conditions/"
            onClick={() => {
              AnalyticService.additionalEvents(
                "terms-and-conditions",
                cartState?.Cart_data,
                couponState.couponCode,
                Store.getItem("refreshToken") ? true : false,
                false
              );
            }}
            className="mr-5"
            target="_blank"
          >
            T&C
          </a>
          <p>|</p>
          <a
            rel="noreferrer"
            href="https://www.shiprocket.in/privacy-policy/my-shiprocket/"
            onClick={() => {
              AnalyticService.additionalEvents(
                "privacy-policy",
                cartState?.Cart_data,
                couponState.couponCode,
                Store.getItem("refreshToken") ? true : false,
                false
              );
            }}
            className="ml-5 mr-5"
            target="_blank"
          >
            Privacy Policy
          </a>
          {uuid && typeof uuid === "string" && (
            <>
              <p>|</p>
              <p className="ml-5 mr-5">{uuid}</p>
            </>
          )}
          </div>

          {show && (
            <div className="d-flex container2 h6" style={{ marginBottom: 0 }}>
              <p className="mr-5">Powered By</p>
              <img
                className="sr-logo"
                src={process.env.PUBLIC_URL + "./assets/images/SRLogoDark.png"}
                alt="Shiprocket"
              />
            </div>
          )}
     
        </div>
      </div>
    </>
  );
};
export default Footer;
