import style from "./NoInternet.module.css";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import * as Sentry from "@sentry/react";

const NetworkErr = ({isHeadless} : {isHeadless: boolean}) => {
  const loginState = useSelector((state: any) => state.Login);
  const cartstate = useSelector((state: any) => state.Cart);
  loginState.onLoginPage = false; //if any Api breaks then enable change number button in exit checkout modal
  const refreshIframe = () => {
    var iframe = document.getElementById("headless-iframe") as HTMLImageElement;
    if (iframe) {
      iframe!.src = iframe!.src;
    } else {
      window.location.reload();
    }
  };

/*   if(!loginState.redirectEventFired) {
    loginState.redirectEventFired = true
    Sentry.captureMessage("Redirection to Shopify Native - Checkout")
  } */

/*   useEffect(() => {
    window.parent.postMessage(
      {
        trigger: "headless-redirect",
        actions: [
          {
            action: "redirectTo",
            data: {
              url: shopifyUrl[shopifyUrl?.length - 1] === "," ? shopifyUrl?.slice(0, shopifyUrl?.length - 1) : shopifyUrl,
            },
          },
        ],
      },
      "*"
    );
  }, []) */


  return (
    <>
      <div className={ !isHeadless ? style.wholeContainer : style.wholeContainerHeadless}>
         <img
          src={
            process.env.PUBLIC_URL + "./assets/images/server_error.svg"
          }
          alt="server Error"
        />
        <p className="h3 mb-10">Something is temporarily wrong with your network connection.</p>
        <p className={clsx(style.maxWidth60per, "h4 grey-medium mb-20")}>
          Please make sure you are connected to the internet and then click on try again.
        </p>
        <button className="btn-grey-outline" onClick={() => refreshIframe()}>
          Try Again
        </button>
      </div>
    </>
  );
};
export default NetworkErr;
