import React, {useState, Suspense } from "react";
import "./App.css";

import { Provider } from "react-redux";
import store from "./Redux/store";

import NotificationScreen from "./components/NotificationScreen/NotificationScreen";  // no need to lazy load as part of main
import NetworkErr from "./components/layout/NetworkError";
import NoInternet from "./components/layout/NoInternet";
import ServerErr from "./components/layout/ServerError";
import AppStorage from "./service/AppStorage" ;




import { pickCartFromLS } from "./utils/constants";


// import Loading from "./components/Loading/LoadingScreen";


// import BillDeskVerfication from "./components/BillDeskVerification";


// import UpSell from "./components/UpSell/upSell";
// import CodToPrepaid from "./components/CodToPrepaid/codToPrepaid";
// import CustomLogin from "./components/CustomLogin";

// import ShiprocketLogin from "./components/ShiprocketLogin/ShiprocketLogin";

import BillDeskVerfication from './components/BillDeskVerification';


const UpSell =React.lazy(()=>import(/* webpackChunkName: "upSell" */ "./components/UpSell/upSell")) ;
const CodToPrepaid = React.lazy(()=>import( /* webpackChunkName: "codToPrepaid" */ "./components/CodToPrepaid/codToPrepaid"));
const CustomLogin =React.lazy(()=>import(/* webpackChunkName:"customLogin" */ "./components/CustomLogin")) ;

const ShiprocketLogin =React.lazy(()=>import(/* webpackChunkName: "ShiprocketLogin" */ "./components/ShiprocketLogin/ShiprocketLogin")) ;

const Loading =React.lazy(()=>import(/* webpackChunkName: "LoadingScreen" */ './components/Loading/LoadingScreen')) ;







export const UserContext = React.createContext({});

function App(props: any) {
  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  let channel: any = params.get("channel") != null ? params.get("channel") : null;
  let type = params.get("type") != null ? params.get("type") : null;
  let isHeadless = params.get('seller-domain') ? true : false
  let redirectUrl =
    params.get("redirect") != null ? params.get("redirect") : null;

    try {
      var domainNameCheck = JSON.parse(decodeURIComponent(window.atob(channel)))?.shop_url
    } catch (e) {}


  let applicationType = params.get("application") != null ? params.get("application") : null;  
  // let pickCartFromLS = ["ranbazaar.com", "thehouseofrare.com", "aramya.in", "blackberrys.com","wordpress-dev.pickrr.com","teescult.in"]
  let cartItems:any = (pickCartFromLS?.includes(domainNameCheck) && applicationType !== "appmaker") ? AppStorage.getItem('fastrrCart') : params.get("cart") != null ? params.get("cart") : null;

  let billDeskTransactionId = params.get("fastrr_transaction_id") != null ? params.get("fastrr_transaction_id") : null; 
  let fastrrSellerId = params.get("seller_id") != null ? params.get("seller_id") : null; 
  let sellerDomain = params.get("seller") != null ? params.get("seller") : null; 
  let fastrrStatus = params.get("fastrr_status") != null ? params.get("fastrr_status") : null; 
  let platform = params.get("platform") != null ? params.get("platform") : null;  

  let afterSellId = params.get("after_sell_id") != null ? params.get("after_sell_id") : null; 
  let fastrrCtpId = params.get("fastrr_ctp_id") != null ? params.get("fastrr_ctp_id") : null;

  let masonPayload = params.get("masonPayload") != null ? params.get("masonPayload") : null;
  
  let srLogin = params.get("login") != null ? params.get("login") : null;
  let themeColor: any = params.get("themeColor") != null ? params.get("themeColor") : null;
  let errorMessage: any = params.get("error_msg") != null ? params.get("error_msg") : null;
  let LoginPopup = params.get("popupType") == 'shiprocketLogin' ? true : false;
  let pageType = params.get("pageType") != null ? params.get("pageType") : null;


  const [internet, setInternet] = useState(true);
  const [serverError, setServerError] = useState(true);
  const [networkError, setNetworkError] = useState(true);

  let fbp = params.get("fbp");
  let fbc = params.get("fbc");

  let utm_params: any = params.get("utm_params") != null ? params.get("utm_params") : null;

  function fullyDecodeURL(url:any) {
    let previousURL = '';
    let decodedURL = url;
    // Loop until the decoded URL stops changing
    while (previousURL !== decodedURL) {
        previousURL = decodedURL;
        decodedURL = decodeURIComponent(decodedURL);
    }
    
    return decodedURL;
  }


  try {
    if (utm_params) {
      utm_params = fullyDecodeURL(escape(window.atob(utm_params)))
      if (utm_params) {
        let utmObject: any = {}
        let splittedUTM = platform == "WOOCOMMERCE" ? utm_params.split('|||'):utm_params.split('&');
        for (var i = 0; i < splittedUTM.length; i++) {
          let key: any = splittedUTM[i].split('=')[0]
          let value: any = splittedUTM[i].split('=')[1]
          utmObject[key] = value
        }

        utm_params = utmObject
        if(platform == "WOOCOMMERCE"){
          const fieldMappings:any = {
            "src": "utm_source",
            "mdm": "utm_medium",
            "cmp": "utm_campaign",
            "id": "utm_id",
            "trm": "utm_term",
            "cnt": "utm_content"
        };
        
        const mappedParams:any = {};
        
        for (const [key, value] of Object.entries(utm_params)) {
            if (fieldMappings[key]) {
                mappedParams[fieldMappings[key]] = value;
            }
        }
        utm_params=mappedParams
        }
      }
    }
  } catch (e) {
    utm_params = null
  }

  const setCookie = (c_name:any, c_value:any, date:any) => {
    var exdate = new Date(date);
    exdate.setDate(exdate.getDate());
    document.cookie =
      encodeURIComponent(c_name) +
      '=' +
      encodeURIComponent(c_value) +
      '; expires=' +
      exdate.toUTCString()+';path=/;SameSite=None;Secure';
  };

  const time = 'Thu, 01 Jan 2030 00:00:00 UTC'
    if(params.get("fbp")){
      const cookie_name = "_fbp";
      setCookie(cookie_name, params.get("fbp"), time);
    }
    if(params.get("fbc")){
      const cookie_name = "_fbc";
      setCookie(cookie_name, params.get("fbc"), time);
    }


  try {
    if (cartItems == null) {
      console.error("Checkout can't be initiated");
    } else {
      cartItems = JSON.parse(decodeURIComponent(window.atob(cartItems)));
    }
  } catch (e) {
    cartItems = null
  }


  if (channel == null) {
    console.error("Checkout can't be initiated");
  } else {
    channel = JSON.parse(decodeURIComponent(window.atob(channel)));
  }

  if(masonPayload) {
    masonPayload = JSON.parse(decodeURIComponent(window.atob(masonPayload)));
  }

  const checkInternetconnection = () => {
    window.ononline = (event) => {
      setInternet(true);
    };

    window.onoffline = (event) => {
      setInternet(false);
    };
  };

  const [render, setRender] = useState(false);
  const handleRender = () => {
    setRender(!render);
  };

  const setServiceError = (value: any) => {
    setServerError(value);
  };

  const setInternetError = (value: any) => {
    setNetworkError(value);
  }

  // <div style={{
  //   height: '100vh', 
  //   display:'flex', 
  //   justifyContent: 'center', 
  //   alignItems:'center'
  //   }}>
  // <Loader />
  // </div>


  return (
    <Provider store={store}>
      {checkInternetconnection()}
      {LoginPopup && 
      <Suspense fallback={<></>}><ShiprocketLogin
      shiprocketLoginPageType = {pageType}
      onRender={handleRender}
      onError={setServiceError}
      platform = {platform}
      /></Suspense>
      }
       {/* {internet && serverError && networkError && !afterSellId && !fastrrCtpId && !LoginPopup  &&(
        !billDeskTransactionId && !fastrrSellerId && !fastrrStatus ? */}
      {internet && serverError && networkError && !afterSellId && !fastrrCtpId && !LoginPopup  &&(
        !billDeskTransactionId && !fastrrSellerId ?
        (
          <Suspense fallback={<></>}>

            {srLogin === "sr-login" ? 
            <CustomLogin 
                channel={channel}
                cartItems={cartItems}
                type={type}
                onRender={handleRender}
                onError={setServiceError}
                onNetworkError={setInternetError}
                fbp = {fbp}
                fbc = {fbc}
                utm_params={utm_params}
                masonPayload={masonPayload}
                platform = {platform}
                applicationType = {applicationType}
                themeColor = {themeColor}
            />
            :
            <Loading
              channel={channel}
              cartItems={cartItems}
              type={type}
              onRender={handleRender}
              onError={setServiceError}
              onNetworkError={setInternetError}
              fbp = {fbp}
              fbc = {fbc}
              utm_params={utm_params}
              masonPayload={masonPayload}
              platform = {platform}
              applicationType = {applicationType}
            />  
          }
            </Suspense>
        ) :  //this also
        //commented because we want to reopen cart page at payment failure
        // (  fastrrStatus ? 
        //   (
        //   <div className="App" style={{boxShadow: params.get('seller-domain') ? "0 5px 5px #E8ECFF, 2px 2px 5px #E8ECFF" : "", overflow:"scroll", height:'100vh', background:'#FFF'}}>
        //     <NotificationScreen image = 'paymentFailed' heading = "Payment Failed" subheading = "Your payment was not completed. Any amount if deducted will get refunded within 4-7 days.." ctaText="Back to Home"/>
        //   </div>
        //    ) :
          <BillDeskVerfication 
            billDeskTransactionId={billDeskTransactionId}
            fastrrSellerId={fastrrSellerId}
            sellerDomain={sellerDomain}
            onRender={handleRender}
          />
        // )
      )}
      {!internet && !afterSellId && !fastrrCtpId && !LoginPopup  && <NoInternet isHeadless={isHeadless} />}
      {/*// @ts-ignore */}
      {!serverError && internet && !afterSellId && !fastrrCtpId && !LoginPopup  && <ServerErr isHeadless={isHeadless} redirectUrl={channel?.redirectUrl} platform = {platform} errorMessage = {errorMessage} domainName={channel?.shop_url} />}
      {!networkError && internet && !afterSellId && !fastrrCtpId && !LoginPopup  && <NetworkErr isHeadless={isHeadless} />}
      {afterSellId && !LoginPopup  && <Suspense fallback={<></>}><UpSell /></Suspense> }
      {fastrrCtpId && !LoginPopup  &&   <Suspense fallback={<></>}><CodToPrepaid /></Suspense>}
    
    </Provider>
  );
}
 
export default App;
