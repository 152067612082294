import React, { useEffect, useState } from "react";
import style from "./OrderPending.module.css";
import OrderSummary from "../OrderSummary/OrderSummary";
import { useSelector } from "react-redux";

declare global {
  interface Window {
    dataLayer: any; 
  }
}
const OrderPending = (props: any) => {
  const addressState = useSelector((state: any) => state.Address);
  const cartState = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const [orderSummaryModalVisible, setOrderSummaryModalVisible] = useState(false)
  const [showDetails, setShowDetails] = useState(false);
  
  let   billingAddressData = 
    typeof(cartState.orderDetails.shippingAddress)  === "object" ?  
    cartState.orderDetails.shippingAddress : 
    JSON.parse(cartState.orderDetails.shippingAddress);

    useEffect(() => {
      cartState.verifyingPayment = false
      window.scrollTo(0, 0);
    },[])

  return (
    <>
      <div className={style.wholeContainer}>
        <div className={style.orderSummaryContainer}>
          <div className={style.subheading1}>Order pending</div>
          <div className={style.container1} style={{display:'flex', justifyContent:'center'}} >
            <p style={{width: '90%'}}>It can take upto 15 minutes for payment confirmation after which your order will be placed. 
            If the order is not placed and money is deducted from your account, the amount will be fully refunded within 5 working days.
            </p>
          </div>
        </div>

        <div className={style.lowerContainer}>
          <hr />

          <div className="">
            <p className="h2 bold mb-10">Summary</p>
            <p className="h3 grey-dark semi-bold mb-3">
              {
              `
              ${billingAddressData?.firstName ? billingAddressData?.firstName : 'Customer' } 
              ${billingAddressData?.lastName ? billingAddressData?.lastName : '' }
              `
              }
            </p>
            <p className="h4 grey-dark light max-wid80 mb-10">
                {
                `
                 ${billingAddressData?.line1 ? billingAddressData?.line1 : '' } 
                 ${billingAddressData?.line2 ? billingAddressData?.line2 : '' }
                 ${billingAddressData?.city ? billingAddressData?.city : '' }
                 ${billingAddressData?.state ? billingAddressData?.state : '' }
                 ${billingAddressData?.pincode ? billingAddressData?.pincode : '' }
                `
                }
            </p>
            {
              shippingstate?.selected_service_data?.edd &&
              <>
            <p className="h3 grey-dark semi-bold mb-3">
              If confirmed, your order will reach you by:
            </p>
            <p className="h4 grey-dark light mb-6 mb-18">
              {shippingstate?.selected_service_data?.edd}
            </p>
              </>
            }
            <p className="h2 bold mb-neg8">
              {"₹ "}
              {addressState.selectedPaymentMode === "COD" ? 
                cartState.orderDetails.codPrice?.toFixed(2) : 
                cartState.payPartialCod ? cartState.orderDetails.partialAmount?.toFixed(2) : 
                cartState.orderDetails.prepaidPrice?.toFixed(2)
              } | {addressState.selectedPaymentMode}
            </p>
          </div>
          <div className={style.pullMargin}>
            <OrderSummary showItems={true}                     orderSummaryModalVisible ={orderSummaryModalVisible} 
                    setOrderSummaryModalVisible = {setOrderSummaryModalVisible}
                    showDetails ={showDetails} 
                    setShowDetails ={setShowDetails} />
          </div>

          <hr />

          <p className="h3 mb-18 text-center">
            You can close this page now. You will receive a message if your order is confirmed.
          </p>
        </div>
      </div>
    </>
  );
};

export default OrderPending;
