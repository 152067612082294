var Payment_initialstate = {
    loader: 1,
    fail: 0,
    Payment_data: {}
}

const Payment_reducer = (state = Payment_initialstate, action) =>{
    switch(action.type){
        case "FETCH_Payment_REQUEST":
            return{
                ...state,
                loader:1
            }

        case "FETCH_Payment_SUCCESS":
            return{
                ...state,
                Payment_data: action.payload,
                loader:0
            }

        case "FETCH_Payment_FAILURE":
            return{
                ...state,
                fail:1,
                loader:0
            }

        default: return(state);
    }
};

export default Payment_reducer;


