import AppStorage from "../../service/AppStorage";
import { Store } from "../../service/StorageHelper";
import { checkoutXwigzoHandler } from "../../utils/commonUtils";


var Login_initialstate = {
  loader: 1,
  newUserAdd: false,
  fail: false,
  spinner: false,
  user_type: "",
  Session_data: {},
  shouldComponentUpdate: true,
  verifySessionfired: true,
  Login_data: {},
  error: "",
  sessionExist: false,
  sendOtpfired: true,
  onPage: "shipping",
  changeNumber: false,
  showCoupon: false,
  mobileNumber: "",
  upiIntervalID: "",
  qrIntervalID: "",
  paymentStatus: false,
  createCustomerFired: true,
  newUser_data: {},
  NewUser_Token: null,
  AuthTokenGenerated: false,
  newSilentUser: false,
  addSilentUserAddress: false,
  paymentPageReached: false,
  selectedUpiApp: 0,
  analyticsData: null,
  userData: {},
  onLoginPage: Boolean,
  cartData: {},
  authentication_token: "",
  userInfo: {},
  pim_sid: '',
  sellerConfig: {},
  configFetched:false,
  creatingGuest: false,
  createdGuest: false,
  fetchingConfig: false,
  userDetailsFetched: false,
  unauthorizedUser:false,
  razorpayIntervalID: "",
  enteredNumber: false,
  redirectEventFired: false,
  loginWasCalled: false,
  newPayment: true,
  optionalPaymentIntervalId: "",
  networkFail: false,
  easeBuzzIntervalID: "",
  whatsappHidden: true,
  uiVersion: 'v1',
  isLayoutUpdated: false,
  codPullingInterval:"",
  mobileCheck: false,
  showUPIBanner: false,
  redirectPullingInterval:false,
  applyUPIDiscount: true,
  enteredEditNumber: false,
  changePhoneLoader : 1,
  customLogin:false,
  sellerVerifyToken:false,
  verifyTokenConfig: {},
  otpSkip : false,
  partialCod: false,
  customCheckoutConfig: {},
  userDetailsDataCustom: {},
  cardDesign : false, 
  fetchingUserDetails : false,
  fetchingShopifyCred : false,
  shopifyCred : {},
  shopifyCredEmailError : false,
  creatingShopifyCredError : false,
  fetchingShopifyCredError : false,
  loginSuccess: false, 
  initateCheckoutData: {}, 
  initiateCheckout:false,
  isAnyModalOpen : false,
  batchAPI:false,
  paymenFailedCartMismatch: false
};

const Login_reducer = (state = Login_initialstate, action) => {
  switch (action.type) {
    case "FETCH_Login_REQUEST":
      return {
        ...state,
        loader: 1,
        fail: false,
        sendOtpfired: true,
        enteredNumber:true,
        loginWasCalled: true
      };

    /* case "FETCH_Login_SUCCESS":
      AppStorage.setItem("checkoutToken", action.payload.data.token);
      AppStorage.setItem("user_type", action.payload.data.user_type); 
      return {
        ...state,
        Login_data: action.payload,
        loader: 0,
        user_type: action.payload.data.user_type,
        AuthTokenGenerated: false,
      }; */

    case "FETCH_Login_SUCCESS":
      return {
        ...state,
        loader: 0,
        Login_data: action.payload,
        user_type: "known",
        user_type_for_text: action.payload.user_type,
        authentication_token: action.payload.authentication_token,
        // Abhishek
        AuthTokenGenerated: false,
        spinner: false,
        enteredNumber:true,
        loginWasCalled: true, 
        loginSuccess:true
      }
    case "SKIP_OTP_SUCCESS":
      return { 
        ...state,
        otpSkip : true,
        spinner : false
      }

    case "FETCH_Login_FAILURE":
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        Login_data: action.payload,
        spinner: false,
        loader: 0,
        enteredNumber:false,
        // error: action.payload
        loginWasCalled: true
      };
    case "VERIFY_Session_REQUEST":
      return {
        ...state,
        verifySessionfired: false,
        sessionExist: false,
        fail: false,
      };

    case "VERIFY_Session_SUCCESS":
      //AppStorage.setItem("checkoutToken", action.payload.data.token);
      return {
        ...state,
        Session_data: action.payload,
        spinner: false,
        sessionExist: true,
        verifySessionfired: false,
      };

    case "VERIFY_Session_FAILURE":
      return {
        ...state,
        fail: true,
        Login_data: action.payload,
        spinner: false,
        sessionExist: true,
        verifySessionfired: false,
        // error: action.payload
      };

    case "Create_Customer_REQUEST":
      return {
        ...state,
        createCustomerFired: false,
        fail: false,
      };

    case "Create_Customer_SUCCESS":
      return {
        ...state,
        newUser_data: action.payload,
      };

    case "Create_Customer_FAILURE":
      return {
        ...state,
        fail: true,
        spinner: false,
        newSilentUser: true,
      };

    case "Auth_Token_REQUEST":
      return {
        ...state,
        createCustomerFired: false,
        NewUser_Token: null,
        fail: false,
      };

    case "Auth_Token_SUCCESS":
      //AppStorage.setItem("checkoutToken", action.payload.data.token);
      return {
        ...state,
        spinner: false,
        NewUser_Token: action.payload,
        AuthTokenGenerated: true,
      };

    case "Auth_Token_FAILURE":
      return {
        ...state,
        fail: true,
        spinner: false,
        newSilentUser: true,
      };

    case "Logout_User_REQUEST":
      return {
        ...state,
        fail: false,
      };

    case "Logout_User_SUCCESS":
      return {
        ...state,
      };

    case "Logout_User_FAILURE":
      return {
        ...state,
        fail: true,
      };

    case "VERIFY_Integration_SUCCESS":
      console.log("VERIFY_Integration_SUCCESS");
      return {
        ...state,
        analyticsData: action.payload.data,
      };
    case "VERIFY_Integration_FAILURE":
      console.log("VERIFY_Integration_FAILURE");
      return {
        ...state,
      };

    case "Create_Guest_User_REQUESTED":
      return {
        ...state,
        fail: false,
        creatingGuest: true
      };

    case "Create_Guest_User_SUCCESS":
      //AppStorage.setItem('user_id', action.payload.user_id)
      //AppStorage.setItem('user_profile_id', action.payload.user_profile_id)
      return {
        ...state,
        userInfo: action?.payload,
        pim_sid: action?.payload['pim_sid'],
        creatingGuest: false,
        createdGuest: true
      };

    case "Create_Guest_User_FAILURE":
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        creatingGuest: false,
        createdGuest: true
      };
      case "FETCH_Config_REQUEST" : 
      return {
        ...state,
        fail: false,
        fetchingConfig: true
      }
    case "FETCH_Config_SUCCESS":
      //Store.setItem('seller_id', action.payload.seller_id)
      try{
        if(action.payload?.exitResponseDetails && !action?.payload?.exitResponseDetails.includes('others')){
          action.payload?.exitResponseDetails.push("others");
        }
      }catch(error){
        console.log(error)
      }
     
      return {
        ...state,
        sellerConfig: action.payload,
        uiVersion: action.payload.uiVersion,
        configFetched:true,
        fetchingConfig: false,
        partialCod: action.payload?.partialCodConfig?.isEnabled,
        cardDesign : action.payload?.cardDesign
      };
      
    case "FETCH_Config_FAILURE": 
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        fetchingConfig: false
      }
      case "FETCH_Config_Network_Failure": 
      return {
        ...state,
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
      }
    case "SET_USER_DATA_AFTER_OTP":
      return {
        ...state,
        userInfo: action?.payload,
        pim_sid : action?.pim_sid
      }

      case "FETCH_User_Details_REQUEST":
        return {
          ...state,
          fetchingUserDetails : true,
        };
        
      case "FETCH_User_Details_SUCCESS":
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrr_user_id',
              data: action.payload.id,
            }
          },
          "*"
        )
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrr_user_profile_id',
              data: action.payload.user_profile_id,
            }
          },
          "*"
        )
        //Call UC Handler
        checkoutXwigzoHandler(action.payload.mobile_no, action.payload.id)
        return {
          ...state,
          userDetailsFetched: true,
          userInfo: {...state.userInfo, user_id: action.payload.id, user_profile_id: action.payload.user_profile_id},
          mobileNumber: action.payload.mobile_no,
          userDetailsDataCustom: action.payload,
          fetchingUserDetails : false,
        }
      case "FETCH_User_Details_FAILURE":
        return {
          ...state,
          userDetailsFetched: true,
          fail: (typeof action?.payload?.response?.status === "number" ? true : false),
          networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
          fetchingUserDetails : false,
        }

      case "UPDATE_LOGIN_STATE":
        return {
          ...state,
          [action.payload.key]: action.payload.value
        }

      case "FETCH_VERIFY_TOKEN_REQUEST":
          return {
            ...state,
            fail: false,
            fetchingConfig: true,
            sellerVerifyToken: false,
          };
          case "FETCH_VERIFY_TOKEN_SUCCESS":
          return {
            ...state,
            sellerConfig: {...action.payload?.seller_config, seller_id:action.payload?.seller_config?.id},
            verifyTokenConfig: action.payload,
            configFetched:true,
            fetchingConfig: false,
            sellerVerifyToken: true,
          }
        case "FETCH_VERIFY_TOKEN_FAILURE":
          return {
            ...state,
            sellerVerifyToken: false,
            fail: (typeof action?.payload?.response?.status === "number" ? true : false),
            networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
            fetchingConfig: false,
          };
    
          // custom config
          case "FETCH_CUSTOM_CHECKOUT_CONFIG_REQUEST":
            return {
              ...state,
              fail: false,
              fetchingConfig: true,
              sellerVerifyToken: false,
            };
            case "FETCH_CUSTOM_CHECKOUT_CONFIG_SUCCESS":
              try{
                if(action.payload?.seller_config.exitResponseDetails && !action?.payload.seller_config?.exitResponseDetails.includes('others')){
                  action.payload?.seller_config.exitResponseDetails.push("others");
                }
              }catch(error){
                console.log(error);
              }
             
            return {
              ...state,
              sellerConfig: {...action.payload?.seller_config, seller_id:action.payload?.seller_config?.id},
              customCheckoutConfig: action.payload,
              configFetched:true,
              fetchingConfig: false,
              sellerVerifyToken: true,
              partialCod: action.payload?.seller_config?.partialCodConfig?.isEnabled
            }
          case "FETCH_CUSTOM_CHECKOUT_CONFIG_FAILURE":
            return {
              ...state,
              sellerVerifyToken: false,
              fail: (typeof action?.payload?.response?.status === "number" ? true : false),
              networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
              fetchingConfig: false,
            };
          case "FETCH_SHOPIFY_CRED_REQUEST":
            return {
              ...state,
              fetchingShopifyCred : true,
            }
          case "FETCH_SHOPIFY_CRED_SUCCESS":
            return {
              ...state,
              fetchingShopifyCred : false,
              shopifyCred : action?.payload
            }
          case "FETCH_SHOPIFY_CRED_FAILURE":
            return {
              ...state,
              fetchingShopifyCred : false,
              fetchingShopifyCredError : true,
              fail: (typeof action?.payload?.response?.status === "number" ? true : false),
              networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
            }
          case "CREATE_SHOPIFY_CRED_REQUEST":
            return {
              ...state,
              creatingShopifyCred : true,
            }
          case "CREATE_SHOPIFY_CRED_SUCCESS":
            return {
              ...state,
              creatingShopifyCred : false,
              createdShopifyCred : action?.payload
            }
          case "CREATE_SHOPIFY_CRED_FAILURE":
            return {
              ...state,
              creatingShopifyCred : false,
              creatingShopifyCredError : true,
              fail: (typeof action?.payload?.response?.status === "number" ? true : false),
              networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
            }
          case "CREATE_SHOPIFY_CRED_EMAIL_ERROR":
            return {
              ...state,
              creatingShopifyCred : false,
              shopifyCredEmailError : true
            }
          case 'SHOPIFY_CRED_EMAIL_FIX' : 
            return {
              ...state,
              shopifyCredEmailError : false,
              creatingShopifyCredError : false
            }
          // custom config end
          case "COMBINATION_API_REQUEST":
            return {
              ...state,
              initiateCheckout:false
            }
          case "COMBINATION_API_SUCCESS":
            return {
              ...state,
              initiateCheckout:true, 
              initateCheckoutData:action?.payload
            }
          case "COMBINATION_API_FAILURE":
            return {
              ...state,
              initiateCheckout:false, 
              batchAPI:true,
              ...(action?.payload?.response?.status==402&&{sellerConfig:{ walletThresholdBreach:true}})
            }

    default:
      return state;
  }
};

export default Login_reducer;


    // case "FETCH_Config_FAILURE": 
    //   return {
    //     ...state,
    //     fail: (typeof action?.payload?.response?.status === "number" ? true : false),
    //     networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
    //     fetchingConfig: false
    //   }