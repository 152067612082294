import { API_ENDPOINT } from "../../Environment";
const { gtag, install } = require("ga-gtag");

let gaId: any = null;
const initialiseGa = (clientGaId: any) => {
  gaId = clientGaId;
  install(gaId, {
    cookie_flags: "max-age=7200;secure;samesite=none",
  });
  if (clientGaId) {
    gtag("config", gaId, {
      cookie_flags: "max-age=7200;secure;samesite=none",
    });
  }
};

const track = (eventName?: string, properties?: any, client?: boolean) => {
  let gaProperties = {
    ...properties,
  };
  if (API_ENDPOINT.debugTracking) {
    gaProperties["debug"] = true;
  }
  gaProperties["send_to"] = [API_ENDPOINT.gaId, client && gaId ? gaId : ""];
  console.log(gaId,eventName)
  gtag("event", eventName, gaProperties);
};

const GoogleAnalytics = {
  initialiseGa,
  track,
};
export default GoogleAnalytics;
