import axios from 'axios';

// let srapi ={
//     fetchShippingDetails(){
//         console.log("shipping details");
//         return "return from shipping details srapi"
//     }
// }

export default axios.create({
    baseURL: 'https://gorest.co.in/public/v2',
})

// export default srapi;