import React, {useEffect} from "react";
import styles from "./Thankyou.module.css";
import { useSelector } from "react-redux";
import AnalyticService from "../../service/analytics/AnalyticsService";
import { fireAnalyticsEvent } from "../Event/Event";
import { formatPhoneNumber, moment } from "../../utils/commonUtils";

const WooCommerceOrderPlaced = (props:any) => {
  const cartState = useSelector((state: any) => state.Cart);
  const addressstate = useSelector((state: any) => state.Address);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const otpstate = useSelector((state: any) => state.Otp);
  const itemsArr = cartState.initialCartData;
  const couponstate = useSelector((state: any)=> state.Coupon);

  // aftersell and cod to prepaid
  const addMinutes = (date: any, minutes: number) => {
    date.setMinutes(date.getMinutes() + minutes)
    return date;
  } 

  useEffect(() => {
    if(!cartState.isAfterSellCookieSet) {
      cartState.isAfterSellCookieSet = true

      if(!props.isUpsell && loginstate?.sellerConfig?.aftersellConfig?.isEnabled) {

      let variantArray = cartState?.orderDetails?.lineItems?.map((item: any) => {
        return {
          id: item.productId,
          variants: [item.itemId],
          quantity: item.quantity
        }
      })

      window.parent.postMessage(
        {
          trigger: 'set-cookie',
          data: {
            name: 'fastrrAfterSellData',
            data:  JSON.stringify({
              phoneNo: cartState?.orderDetails?.shippingAddress?.phone,
              cartTotal: (addressstate.selectedPaymentMode === "COD"  || cartState.payPartialCod)? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice,
              variantArray: variantArray
            }),
            time: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time)
          }
        },
        "*"
      )

      window.parent.postMessage(
        {
          trigger: 'set-cookie',
          data: {
            name: 'fastrrOrderPlacedTime',
            data: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time),
            time: addMinutes(new Date(), loginstate?.sellerConfig?.aftersellConfig?.time === 0 ? 5 : loginstate?.sellerConfig?.aftersellConfig?.time)
          }
        },
        "*"
      )

      } else {
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrAfterSellData',
              data: {},
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrOrderPlacedTime',
              data: {},
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'isUpsellFastrr',
              data: {},
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
      }


    }
  }, [])

  useEffect(() => {
    if(!cartState.isCtpCookieSet) {
      cartState.isCtpCookieSet = true

      if((addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod)  && loginstate.sellerConfig?.codToPrepaidDiscount?.is_enabled) {
        
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: "fastrrCtpData",
              data: cartState.orderId,
              time: addMinutes(new Date(), loginstate.sellerConfig?.codToPrepaidDiscount?.time === 0 ? 5 : loginstate.sellerConfig?.codToPrepaidDiscount?.time)
            },
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrOrderPlacedTimeCtp',
              data: addMinutes(new Date(), loginstate.sellerConfig?.codToPrepaidDiscount?.time === 0 ? 5 : loginstate.sellerConfig?.codToPrepaidDiscount?.time),
              time: addMinutes(new Date(), loginstate.sellerConfig?.codToPrepaidDiscount?.time === 0 ? 5 : loginstate.sellerConfig?.codToPrepaidDiscount?.time)
            }
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrSellerId',
              data: loginstate.sellerConfig.seller_id,
              time: addMinutes(new Date(), loginstate.sellerConfig?.codToPrepaidDiscount?.time === 0 ? 5 : loginstate.sellerConfig?.codToPrepaidDiscount?.time)
            }
          },
          "*"
        )
      } else {
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: "fastrrCtpData",
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            },
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrOrderPlacedTimeCtp',
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
  
        window.parent.postMessage(
          {
            trigger: 'set-cookie',
            data: {
              name: 'fastrrSellerId',
              data: "",
              time: 'Thu, 01 Jan 1970 00:00:00 UTC'
            }
          },
          "*"
        )
      }

  }

  }, [])
  
  const lineItems = cartState.orderDetails?.lineItems?.map((value: any) => {
    return (
      <div className={styles.itemCart}>
        <div className={styles.itemImg}>
          <img src={value?.imageUrl} alt={value?.name} />
        </div>
        <div className={styles.itemDetails}>
          <div>
            <div className={styles.productName}>{value?.name}</div>
            {/* <div className={styles.description}>Color: Blue</div> */}
            {/* <div className={styles.description}>Size: 37</div> */}
            <div className={styles.description}>Qty: {value?.quantity}</div>
          </div>
          <div className={styles.productPrice}>₹{value?.price?.toFixed(2)}</div>
        </div>
      </div>
    );
  });


  const fullName = `${cartState.orderDetails?.shippingAddress?.firstName}  ${cartState.orderDetails?.shippingAddress?.lastName}`;
  // const fullName = `${addressstate.selectedAddress?.firstName}  ${addressstate.selectedAddress?.lastName}`;
  const addressLine =
    cartState?.orderDetails?.shippingAddress?.line1 +
    (cartState?.orderDetails?.shippingAddress?.line2
      ? cartState?.orderDetails?.shippingAddress?.line2
      : "");
  const city = cartState?.orderDetails?.shippingAddress?.city;
  const pincode = cartState?.orderDetails?.shippingAddress?.pincode;
  const state = cartState?.orderDetails?.shippingAddress?.state;
  const email = cartState?.orderDetails?.shippingAddress?.email;
  const phone =
    cartState?.orderDetails?.shippingAddress?.phone?.length === 10
      ? `+91${cartState?.orderDetails?.shippingAddress?.phone}`
      : cartState?.orderDetails?.shippingAddress?.phone;
  const deliveryDate = shippingstate.selected_service_data?.edd
    ? `${moment(shippingstate.selected_service_data?.edd)?.format(
        `dddd`
      )}, ${moment(shippingstate.selected_service_data?.edd)?.format(
        `MMM DD YYYY`
      )}`
    : "";
  const shippingCharges = cartState?.orderDetails?.shippingCharges;

  const displayCoupon = (couponName:string) => {
    if(couponName?.length > 20){
      return `${couponName?.slice(0, 20)}...`
    }else {
      return couponName
    }
  }
  
  
  const exitCheckout = () => {
    window.parent.postMessage({ trigger: "continue-shopping", data:loginstate.sellerConfig?.domainName  }, "*");
  }

  
  // remove cart
  useEffect(() => {
    let actions = [];
      actions.push({
        action: "clearCart",
        data: {domain: loginstate.sellerConfig?.domainName}
      });

    window.parent.postMessage({ trigger: "clear-cart", actions: actions}, "*");
  }, [])


  useEffect(() => {
    if(!cartState?.isAnalyticsCookie) {
      cartState.isAnalyticsCookie = true
      window.parent.postMessage(
        {
          trigger: 'set-analytics-cookie',
          data: {
            name: 'analyticsData',
            data: {
              name :cartState?.orderDetails?.clientOrderId,
              value: (addressstate?.selectedPaymentMode ===  'COD' || cartState.payPartialCod)
              ? cartState?.orderDetails?.codPrice : 
              cartState?.orderDetails?.prepaidPrice,      
            },
            time: addMinutes(new Date(), 5)
          }
        },
        "*"
      )
    }
  }, [])



  useEffect(()=>{
    if(cartState.upiPaymentStatus ||  cartState.razorPaymentStatus){
      //Payment successful
      // console.info("Payment successful");
      cartState.upiPaymentStatus = false;
      AnalyticService.addPaymentInfo(cartState.orderDetails,loginstate?.sellerConfig?.marketingConfig?.facebook?.events);
      if(
        loginstate?.sellerConfig?.marketingConfig?.ga4?.events?.payment_initiated &&
        !loginstate?.sellerConfig?.enableGa4MpEvents
        ){
        const eventData = {
          event: "add_payment_info",
          data: {
            event_category: "ecommerce",
            send_to: loginstate?.sellerConfig?.marketingConfig?.ga4?.measurement_id,
            currency: "INR",
            value:
            
              (cartState?.orderDetails?.totalPrice || 0) +
              (shippingstate.selected_service_data?.charges || 0) +
              (cartState?.orderDetails?.totaltax || 0) -
              (cartState?.orderDetails?.prepaidDiscount || 0) -
              (cartState?.orderDetails?.couponDiscount || 0),

              items: itemsArr?.map((item:any) => ({
                id:item.productId,
                name: item.title,  
                variant: item.variantTitle,
                brand: item?.vendor,
                category: item?.product_type,
                price: item.price,
                quantity: item.quantity,
              })),
            payment_type: cartState.activePaymentGateway,
            coupon: couponstate?.couponCode,
          },
        };
        window.parent.postMessage(
          {
            trigger: "gtag",
            data: eventData,
          },
          "*"
        );
        console.log("ADD-PAYMENT-INFO-GA-EVENT-FIRED eventData------>",eventData)
        }
    }

  },[cartState.upiPaymentStatus])
 

  useEffect(() => {
    // Order placed Event
    AnalyticService.orderPlaced(
      loginstate?.mobileNumber, 
      cartState?.Cart_data, 
      addressstate.selectedAddress,
      couponstate?.couponCode,
      cartState?.orderDetails, 
      cartState.paymentMethodType, 
      shippingstate,
      addressstate?.selectedPaymentMode,
      cartState.clientOrderId,
      cartState.payPartialCod,
      cartState?.transaction_ID,
      loginstate?.sellerConfig?.marketingConfig
    );
    if(loginstate?.sellerConfig?.enableGtmEventParentFrame){
    let customGtmOrderPlacedData = {
      "event": "order-placed",
      "phone_num":formatPhoneNumber(loginstate?.mobileNumber ?? otpstate.mobile_no),
      "firstName" : addressstate.selectedAddress?.firstName ?? '',
      "LastName" : addressstate.selectedAddress?.lastName ?? '',
      "email" : addressstate.selectedAddress?.email ?? '',
      "fullName" : addressstate.selectedAddress?.fullName ?? '',
      "city": addressstate.selectedAddress?.city,
      "state": addressstate.selectedAddress?.state,
      "pincode": addressstate.selectedAddress?.pincode,
      "country": "in",
      "ecommerce": {
        "currency": "INR",
        "value": addressstate?.selectedPaymentMode === "COD" ? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice,
        "coupon": couponstate?.couponCode,
        "tax": cartState?.Cart_data?.totalTax ?? cartState?.orderDetails?.totalTax,
        "shipping":cartState?.orderDetails?.shippingCharges,
        "transaction_id": cartState?.orderDetails?.clientOrderId,
        "paymentType": cartState.paymentMethodType,
        "shipping_tier":  shippingstate?.selected_service_data?.pickrr_tag,
        "items": cartState?.Cart_data?.items?.map((cartItem:any)=>{
          const temp=itemsArr?.find((item:any)=>item.productId==cartItem.productId)
          return temp?.vendor ? {...cartItem,vendor:temp?.vendor}:{...cartItem}
        }) ?? cartState?.orderDetails?.lineItems.map((cartItem:any)=>{
          const temp=itemsArr?.find((item:any)=>item.productId==cartItem.productId)
          return temp?.vendor ? {...cartItem,vendor:temp?.vendor}:{...cartItem}
        })
      }
    }
    window.parent.postMessage(
      {
        trigger: "gtm-events",
        data: customGtmOrderPlacedData,
      },
      "*"
    );
    }
    
    let productIdArr: [] = cartState?.orderDetails?.lineItems?.map((item: any) => item?.productId)
    // AnalyticService.buy(productIdArr);
     AnalyticService.orderSuccessScreenOpened();
     const eventProp: any = {
       order_id: cartState?.orderDetails?.clientOrderId,
       cart_id: cartState?.Cart_data?.id,
       amount: cartState?.orderDetails?.totalPrice,
       discount:
         addressstate?.selectedPaymentMode === "COD"
           ? couponstate?.couponCode
             ? cartState?.orderDetails?.couponDiscount
             : 0
           : couponstate?.couponCode
           ? cartState?.orderDetails?.couponDiscount +
             cartState?.orderDetails?.prepaidDiscount
           : cartState?.orderDetails?.prepaidDiscount,
       tax: cartState?.orderDetails?.totalTax,
       payment_mode: addressstate?.selectedPaymentMode,
       coupon_code: couponstate?.couponCode,
       cod_charges:addressstate?.selectedPaymentMode == "COD" ? cartState?.orderDetails?.codCharges?.toFixed(2) : 0,
       payment_status:
         addressstate?.selectedPaymentMode == "COD" ? "Pending" : "Success",
       order: cartState?.orderDetails,
     };

     //remove publish event
    //  fireAnalyticsEvent("ORDER_PLACED",eventProp, loginstate?.sellerConfig?.seller_id)
     if(loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_array?.length){
        loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_array?.map((pixelObj : any)=>{
        AnalyticService.initialiseAnalytics(
          '', 
          pixelObj?.pixel_id,
          '',  
          '', 
          cartState?.Cart_data?.id ?? couponstate?.data?.id, 
          addressstate.selectedAddress, 
          'onlyPixel', 
          addressstate.selectedAddress?.firstName,
          addressstate.selectedAddress?.lastName,
        );
      })
    } else if(loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id){
      AnalyticService.initialiseAnalytics(
        '', 
        loginstate?.sellerConfig?.marketingConfig?.facebook?.pixel_id,
        '',  
        '', 
        cartState?.Cart_data?.id ?? couponstate?.data?.id, 
        addressstate.selectedAddress, 
        'onlyPixel', 
        addressstate.selectedAddress?.firstName,
        addressstate.selectedAddress?.lastName,
      );
    }
    const FbPurchasePayload = {
      content_ids : cartState?.Cart_data?.items?.length ? cartState?.Cart_data?.items?.map((item:any) => item?.productId) : [],
      content_type: 'product_group',
      contents : cartState?.Cart_data?.items?.length ? cartState?.Cart_data?.items?.map((item:any) => ({
        id: item?.productId,
        variant: item?.itemId,  
        name: item?.name,
        currency:"INR",
        price: item?.price,
        quantity: item?.quantity,
      })) : [],
      currency: 'INR',
      num_items: cartState?.Cart_data?.items?.length,
      value: addressstate?.selectedPaymentMode === "COD" ? cartState?.orderDetails?.codPrice : cartState?.orderDetails?.prepaidPrice,
      ph: cartState?.orderDetails?.shippingAddress?.phone?.length === 10 ? 
        `91${cartState?.orderDetails?.shippingAddress?.phone}` : cartState?.orderDetails?.shippingAddress?.phone, 
      ct: cartState?.orderDetails?.shippingAddress?.city?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
      country: 'in',
      st: cartState?.orderDetails?.shippingAddress?.state?.toLowerCase()?.replace(/[^a-zA-Z0-9 ]|\s/g, ''),
      zp: cartState?.orderDetails?.shippingAddress?.pincode,
      fn:cartState?.orderDetails?.shippingAddress?.firstName,
      ln:cartState?.orderDetails?.shippingAddress?.lastName,          
      }
    AnalyticService.purchase(FbPurchasePayload,loginstate?.sellerConfig?.marketingConfig?.facebook?.events)

    if(
      loginstate?.sellerConfig?.marketingConfig?.ga4?.events?.order_placed &&
      !loginstate?.sellerConfig?.enableGa4MpEvents
    ){
      const eventData = {
      event: "purchase",
    
      data: {
        event_category: "ecommerce",
        send_to: loginstate?.sellerConfig?.marketingConfig?.ga4?.measurement_id,
        transaction_id: cartState?.orderDetails?.clientOrderId || cartState?.orderDetails?.id,
        currency: "INR",
        value:
          (cartState?.orderDetails?.totalPrice || 0) +
          (shippingstate.selected_service_data?.charges || 0) +
          (cartState?.orderDetails?.totaltax || 0) -
          (addressstate.selectedPaymentMode === "UPI"
            ? cartState?.orderDetails?.prepaidDiscount
            : 0) +
          ((addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod)
            ? cartState?.orderDetails?.codCharges
            : 0) -
          (cartState?.orderDetails?.couponDiscount || 0),
        tax: cartState?.orderDetails?.totalTax,
        shipping: cartState?.orderDetails?.shippingCharges,
        items: itemsArr?.map((item:any) => ({
          id:item.productId,
          name: item.title,  
          variant: item.variantTitle,
          brand: item?.vendor,
          category: item?.product_type,
          price: item.price,
          quantity: item.quantity,
        })),
        coupon: couponstate?.couponCode,
      },
    };
    
    window.parent.postMessage({ trigger: "gtag", data: eventData }, "*");
    console.log("PURCHASE-GA-EVENT-FIRED eventData------>",eventData)
  }
  var enhanced_conversion_data = {
    ...(cartState?.orderDetails?.shippingAddress?.email && {email:cartState?.orderDetails?.shippingAddress?.email}),
    phone_number:
    formatPhoneNumber(cartState?.orderDetails?.shippingAddress?.phone),
    first_name:
    cartState?.orderDetails?.shippingAddress?.firstName,
    last_name:
    cartState?.orderDetails?.shippingAddress?.lastName,
    home_address: {
      street:
      cartState?.orderDetails?.shippingAddress?.line1 + (cartState?.orderDetails?.shippingAddress?.line2 ? cartState?.orderDetails?.shippingAddress?.line2: ""),
      city: cartState?.orderDetails?.shippingAddress?.city,
      region:
      cartState?.orderDetails?.shippingAddress?.state,
      postal_code:
      cartState?.orderDetails?.shippingAddress?.pincode,
      country:'IN',
    },
  };
  console.log("enhanced_conversion_data--->", enhanced_conversion_data)

  function gtag() {
    window.dataLayer.push(arguments);
  }
  

  for(let i=0; i<loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.length; i++) {

    const scriptElement = document.createElement('script');
    document.head.appendChild(scriptElement);
    scriptElement.src =
      `https://www.googletagmanager.com/gtag/js?id=${loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id}`;
    scriptElement.async = true;

    scriptElement.onload = () => {

      if(i === 0) {
        window.dataLayer = window.dataLayer || [];
        {/*// @ts-ignore */}
        gtag('js', new Date());
      }
      
      {/*// @ts-ignore */}
      gtag('config',loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id, { allow_enhanced_conversions: loginstate?.sellerConfig?.marketingConfig?.gads?.enhance_conversion});
  
    {/*// @ts-ignore */}
    gtag('event', 'conversion', {
      send_to: loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id + "/" + loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_label,
      value:
      (cartState?.orderDetails?.totalPrice || 0) +
      (shippingstate.selected_service_data?.charges || 0) +
      (cartState?.orderDetails?.totaltax || 0) -
      (addressstate.selectedPaymentMode === "UPI"
        ? cartState?.orderDetails?.prepaidDiscount
        : 0) +
      ((addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod)
        ? cartState?.orderDetails?.codCharges
        : 0) -
      (cartState?.orderDetails?.couponDiscount || 0),
      currency: 'INR',
      transaction_id: cartState?.orderDetails?.clientOrderId
    });
  }

  let enhanced_conversion_data = {
    ...(cartState?.orderDetails?.shippingAddress?.email ? {email:cartState?.orderDetails?.shippingAddress?.email}:{}),
    phone_number:
    formatPhoneNumber(cartState?.orderDetails?.shippingAddress?.phone),
    first_name:
    cartState?.orderDetails?.shippingAddress?.firstName,
    last_name:
    cartState?.orderDetails?.shippingAddress?.lastName,
    home_address: {
      street:
      cartState?.orderDetails?.shippingAddress?.line1 + (cartState?.orderDetails?.shippingAddress?.line2 ? cartState?.orderDetails?.shippingAddress?.line2: ""),
      city: cartState?.orderDetails?.shippingAddress?.city,
      region:
      cartState?.orderDetails?.shippingAddress?.state,
      postal_code:
      cartState?.orderDetails?.shippingAddress?.pincode,
      country:'IN',
    },
  };

  
  let conversionData = {
      send_to: loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id + "/" + loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_label,
      value:
      (cartState?.orderDetails?.totalPrice || 0) +
      (shippingstate.selected_service_data?.charges || 0) +
      (cartState?.orderDetails?.totaltax || 0) -
      (addressstate.selectedPaymentMode === "UPI"
        ? cartState?.orderDetails?.prepaidDiscount
        : 0) +
      ((addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod)
        ? cartState?.orderDetails?.codCharges
        : 0) -
      (cartState?.orderDetails?.couponDiscount || 0),
      currency: 'INR',
      transaction_id: cartState?.orderDetails?.clientOrderId||cartState.orderId,
    }
console.log("firing point of woo commerce orders",conversionData)
  window.parent.postMessage(
    {
      trigger: "convesion-gtm-event",
      eventName : 'conversion' ,
      config:{
        configId:loginstate?.sellerConfig?.marketingConfig?.gads?.google_ads_array?.[i].conversion_id,
        configObject:{ allow_enhanced_conversions: loginstate?.sellerConfig?.marketingConfig?.gads?.enhance_conversion}
      },
      enhanced_conversion_data : enhanced_conversion_data,
      conversionData : {...conversionData,enhanced_conversion_data}
    },
    "*"
  )
  }
  
  }, []);

  
  





  return (
    <div className={styles.thankyouContainer}>
      <div className={styles.order_placed}>
        <img
          src={
            process.env.PUBLIC_URL + "./assets/images/svg/successWoocomm.svg"
          }
          alt="success"
          className={styles.thankyou__success__icon}
        />
        <div className={styles.thankyou__text}>Thank you!</div>
        <div className={styles.order_id}>
          Your order {cartState?.orderDetails?.clientOrderId} has been placed.
        </div>
        <div className={styles.text}>
         <b>PLEASE DO NOT CLOSE THIS WINDOW.</b>
        </div>
        <div className={styles.text}>
          We'll send the order confirmation to your registered <br />
          phone number {phone}.
        </div>
        <div className={styles.divder}></div>
      </div>

      <div className={styles.order_details}>
        <div className={styles.heading}>Order Details</div>
        <div className={styles.userDetails}>
          <div style={{ fontWeight: 500 }}>{fullName}</div>
          <div>
            {addressLine}, {city}, {state}
            <br />
            {pincode}
          </div>
          {email && <div>{email}</div>}
          <div>{phone}</div>
        </div>

        {deliveryDate ? 
        <div className={styles.servicebility}>
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                "./assets/images/svg/servicebility__icon.svg"
              }
              alt="success"
              className={styles.thankyou__success__icon}
            />
          </div>
          <div>{deliveryDate}</div>
        </div>
        : ""}


        
        <div className={styles.divder}></div>
        <div className={styles.lineItems}>{lineItems}</div>
        <div className={styles.amountContainer}>
          <div className={styles.ammountDescription}>
            <div>Subtotal</div>
            <div className={styles.textBold}>
              ₹
              {Number(
                cartState.orderDetails?.totalPrice
                  ? cartState.orderDetails?.totalPrice
                  : cartState.Cart_data?.totalPrice
              ).toFixed(2)}
            </div>
          </div>
          {(addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod) &&
          cartState.orderDetails?.codCharges > 0 ? (
            <div className={styles.ammountDescription}>
              <div>COD charges</div>
              <div className={styles.textBold}>
                {cartState.orderDetails?.codCharges?.toFixed(2)}
              </div>
            </div>
          ) : (
            ""
          )}

          {shippingCharges ? (
            <div className={styles.ammountDescription}>
              <div>Shipping</div>
              <div className={styles.textBold}>
                {shippingCharges > 0
                  ? `₹${shippingCharges?.toFixed(2)}`
                  : "FREE"}
              </div>
            </div>
          ) : (
            ""
          )}


          {(cartState.orderDetails?.totalDiscount && addressstate.selectedPaymentMode !== "COD")? (
            <div className={styles.ammountDescription}>
              <div>Discount</div>
              <div className={styles.textBold}>
                {cartState.orderDetails?.totalDiscount?.toFixed(2) ??  '0'}
              </div>
            </div>
          ) : (
            ""
          )}

        {(cartState.orderDetails?.couponDiscount && (addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod))? (
            <div className={styles.ammountDescription}>
              <div>Discount</div>
              <div className={styles.textBold}>
                {cartState.orderDetails?.couponDiscount?.toFixed(2) ??  '0'}
              </div>
            </div>
          ) : (
            ""
          )}

        {/* {(cartState.orderDetails?.couponData?.coupon_code)? (
            <div className={styles.ammountDescription}>
              <div>Coupon discount ({ displayCoupon(cartState.orderDetails?.couponData?.coupon_code)})</div>
              <div className={styles.textBold}>
                {cartState.orderDetails?.couponDiscount?.toFixed(2) ??  '0'}
              </div>
            </div>
          ) : (
            ""
          )} */}

          {/* cartState?.orderDetails?.paymentGateway */}

          {/* {(cartState?.orderDetails?.paymentGateway !== "COD" && 
          cartState?.Cart_data?.couponData?.coupon_type !== "automatic")? (
            <div className={styles.ammountDescription}>
              <div>Prepiad discount</div>
              <div className={styles.textBold}>
                {cartState?.orderDetails?.prepaidDiscont?.toFixed(2) ??  '0'}
              </div>
            </div>
          ) : (
            ""
          )} */}


{/* cartState.orderDetails?.couponDiscount */}
        {/* {   (cartState.orderDetails?.id && 
            addressstate?.selectedAddress && 
            !loginstate.sellerConfig?.nativeShopifyCheckout) ?
            <div className={styles.ammountDescription}>
                {(cartState.orderDetails?.couponData?.coupon_code &&
                cartState?.orderDetails?.couponData?.coupon_type !== "automatic") 
                ? 
                }
              <div>Coupon discount</div>
              <div className={styles.textBold}>
                {shippingCharges > 0
                  ? `₹${shippingCharges?.toFixed(2)}`
                  : "FREE"}
              </div>
            </div> : ""
        } */}


          <div className={styles.divder}></div>
          <div className={styles.ammountDescription}>
            <div className={styles.textBold} style={{ fontSize: 14 }}>
              Total
            </div>
            <div className={styles.textBold} style={{ fontSize: 14 }}>
              ₹
              {(addressstate.selectedPaymentMode === "COD" || cartState.payPartialCod)
                ? cartState.orderDetails?.codPrice?.toFixed(2)
                : cartState.orderDetails?.prepaidPrice?.toFixed(2)
                // ? (
                //     cartState.orderDetails?.totalAmount +
                //       cartState.orderDetails?.prepaidDiscount ?? 0
                //   ).toFixed(2)
                // : }{" "}
              }
            </div>
          </div>
        </div>
       {!loginstate?.sellerConfig?.disableContinueShopping && <div className={styles.button} onClick={exitCheckout}>Continue Shopping</div>}
      </div>
    </div>
  );
};

export default WooCommerceOrderPlaced;
