// / import Http from "./AxiosService";
import AppStorage from "./AppStorage";
import { API_ENDPOINT } from "../Environment";
import { Store } from "./StorageHelper";
import * as Sentry from "@sentry/react";
import { IS_EDIT_NUMBER_LOGOUT, EDIT_PIM_SID, EDIT_EXPIRE_TIME } from "../utils/guestSessions";

const urlSearchParams = new URLSearchParams(window.location.search); 

const uuid  = urlSearchParams.get("uuid") ? urlSearchParams.get("uuid") : null

const axios = require("axios").default;

const get = (url, seller_id) => {
  const Http = AxiosRequest(seller_id);
  return Http.get(url);
};
const getWithParams = (url, data) => {
  const Http = AxiosRequest();
  return Http.get(url, { params: data });
};
const post = (url, data, seller_id) => {
  const Http = AxiosRequest(seller_id);
  return Http.post(url, data);
};

const postwithExtraParmas = (url, data, seller_id, fbp, fbc, ipAddress) => {
  const Http = AxiosRequest(seller_id);
  return Http.post(url,data, {headers: {'x-fbp': fbp, ...(fbc && {'x-fbc': fbc}), ...(ipAddress && {'x-cfip': ipAddress})}})
}

const postWithHeaders = (url, data, seller_id, headers) => {
  const Http = AxiosRequest(seller_id);
  const config = {};
  
  if (headers && Object.keys(headers).length) {
    config.headers = { ...headers };
  }
  return Http.post(url, data, config);
}

const put = (url, data, seller_id) => {
    const Http = AxiosRequest(seller_id);
    return Http.put(url, data);
};

const getWithUid = (url, uId, seller_id) => {
  const Http = AxiosRequest(seller_id);
  return Http.get(url, {headers: {'uId': uId}})
}

const abandantCartWith = (url, x_pass, seller_id) => {
  const Http = AxiosRequest(seller_id);
  return Http.get(url,{headers: {'user-passport': JSON.stringify(x_pass)}})
}
const deletee = (url, seller_id) => {
  const Http = AxiosRequest(seller_id);
  return Http.delete(url)
}

const postWithTimeout = (url, data, seller_id, timeout) => {
  const Http = AxiosRequest(seller_id, timeout);
  return Http.post(url, data);
};

const getAddressWithHeaders = (url, fetchAddressPermission, isAddressSavedCalled, seller_id) => {
  const Http = AxiosRequest(seller_id)
  return Http.get(url, {
    headers: {
      'X-FETCH-ADDRESS-FLAG': fetchAddressPermission,
      ...(!fetchAddressPermission && { 'X-IS-ADDRESS-SAVED': isAddressSavedCalled })
    }
  })
}

const getCookie = (cName) => {
  const name = cName + '=';
  const cDecoded = decodeURIComponent(document.cookie); //to be careful
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  if (!res) {
    return null;
  }
  return res.slice(0, 36);
};
const setCookie = (c_name, c_value, date) => {
  var exdate = new Date(date);
  exdate.setDate(exdate.getDate());
  document.cookie =
    encodeURIComponent(c_name) +
    '=' +
    encodeURIComponent(c_value) +
    '; expires=' +
    exdate.toUTCString()+';path=/;SameSite=None;Secure';
};

const AxiosRequest = (seller_id, timeout) => {

  // console.log('Thru Interceptor')

  const SESSION_NAME = "x-session-token";
  const SESSION_TOKEN = AppStorage.getItem(SESSION_NAME);
  const SELLER_ID = Store.getItem("seller_id");


  window.parent.postMessage(
    {
      trigger : 'get-uuid-usid-parent',
      actions: [
        {
          action: "getUUIDParent",
          data: {
           sendMessage:false,
          }
        }
      ]
    },
    '*'
  )

  let headers = {
    headers: {
      "Content-type": "application/json",
      "X-Device-Id": "fastrr"
    }
  };

  if (seller_id) {
    headers.headers["sId"] = seller_id;
  } 

  if (uuid) {
    headers.headers["uuid"] = uuid;
  } 

  const instance = axios.create(headers);
  instance.interceptors.request.use(async (request) => {
    let NORMAL_USER_PIM_SID = new Date(Date.now() + 30000) < new Date(Store.getItem('expireTime')) ? Store.getItem('pim_sid') : null
   
    let EDIT_GUEST_PIM_SID = (IS_EDIT_NUMBER_LOGOUT && EDIT_PIM_SID && EDIT_EXPIRE_TIME)  
    ? new Date(Date.now() + 30000) < new Date(EDIT_EXPIRE_TIME) 
      ? EDIT_PIM_SID
      : null 
    : null

    const PIM_SID =
    (IS_EDIT_NUMBER_LOGOUT && EDIT_PIM_SID && EDIT_EXPIRE_TIME) 
    ? EDIT_GUEST_PIM_SID 
    : NORMAL_USER_PIM_SID;
    
    if (PIM_SID) {
      request.headers['PIM-Sid'] = PIM_SID;
      return request;
    } else {
      try {
        const response = await axios.get(
          `${API_ENDPOINT.apiIdentityService}session/create`,
          {
            headers: {
              'Refresh-Token': Store.getItem('refreshTokenValue'),
              'X-Device-Id': 'fastrr',
              'Grant-Type': 'REFRESH_TOKEN',  
            },
          }
        );
        if (response.status === 201 && response?.data?.ok) {
          //AppStorage.setItem('pim_sid', response?.headers['pim-sid'])
          //AppStorage.setItem('expireTime', response?.headers['expires-at'])
          Store.setItem('pim_sid', response?.headers['pim-sid'])
          Store.setItem('expireTime', response?.headers['expires-at'])
          request.headers['PIM-Sid'] = response?.headers['pim-sid'];
          return request;
        } else {
          Sentry.withScope(scope => {
            scope.setExtras({response_headers: response.headers, response: response})
            Sentry.captureMessage(`Refresh-Token-${API_ENDPOINT.apiIdentityService}session/create`)
          })
          Store.removeItem('refreshToken')
          Store.removeItem('refreshTokenValue')
          //AppStorage.removeItem('mobile')
          Store.removeItem('user_id')
          //AppStorage.removeItem('user_profile_id')
          Store.removeItem('seller_id')
          Store.removeItem('pim_sid')
          Store.removeItem('expireTime')
          //document.cookie = 'pim_sid=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;SameSite=None;Secure'
          console.log('Refresh Token Call Failed 1')
          if(!urlSearchParams.get("after_sell_id") && !urlSearchParams.get("fastrr_ctp_id")) {
            setTimeout(function(){
              window.location.reload();
            }, 1000);
          }
        }
      } catch (error) {
        Store.removeItem('refreshToken')
        Store.removeItem('refreshTokenValue')
        //AppStorage.removeItem('mobile')
        Store.removeItem('user_id')
        //localStorage.removeItem('user_profile_id')
        Store.removeItem('seller_id')
        Store.removeItem('pim_sid')
        Store.removeItem('expireTime')
        console.log('Refresh Token Call Failed 2')
        if(!urlSearchParams.get("after_sell_id") && !urlSearchParams.get("fastrr_ctp_id")) {
          setTimeout(function(){
            window.location.reload();
          }, 1000);
        }
      }
    }
  });
  if(timeout) {
    instance.defaults.timeout = timeout
  }
  return instance
}

const Http = {
  get,
  getWithParams,
  post,
  put,
  getWithUid,
  deletee,
  abandantCartWith,
  getAddressWithHeaders,
  postwithExtraParmas,
  postWithTimeout,
  postWithHeaders
};
export default Http;