//stage
export const API_ENDPOINT = {
    apiBaseUrlV1: '',
    apiBaseUrlV2: '',
    srApiBaseUrl: 'https://apiv2.shiprocket.in/v1/',
    gaId:'',
    clevertapId: '',
    debugTracking: true,
    apiIdentityService: 'https://edge-service-dev.pickrr.com/identity-service/',
    pickrrAggregatorApi: 'https://edge-service-dev.pickrr.com/aggregator-fastrr/',
    pickrrCustomerApi: 'https://edge-service-dev.pickrr.com/customer-fastrr/',
    pickrrOrderApi: 'https://edge-service-dev.pickrr.com/order-fastrr/',
    couponSuggestionBaseUrl: 'https://edge-service-dev.pickrr.com/promotion-fastrr/',
    paymentServiceBaseUrl: 'https://edge-service-dev.pickrr.com/pay-fastrr/',
    paymentHandler: 'https://payment-handler-dev.pickrr.com/payment-fastrr/callback/razorpay/',
    pickrrCartService: 'https://edge-service-dev.pickrr.com/cart-fastrr/',
    resumeCartService: 'https://api-testing.pickrr.com/api/ve1/aggregator-service/abandon-checkout/', 
    edgeService: 'https://edge-service-dev.pickrr.com/', 
    RAZORPAY_CALLBACK: 'https://payment-aggregator-fastrr-dev.pickrr.com/api/ve1/payment-aggregator/transaction/verify-and-redirect/razorpay/',
    IS_PRODUCTION: false, 
    IDENTITY_SERVICE: "/identity-service/session/create",
    SELLER_CONFIG: "/aggregator-fastrr/api/ve1/aggregator-service/seller/config",
    CART_SERVICE  : "/cart-fastrr/api/ve1/cart-service/",
    RESUME_CART: "/aggregator-fastrr/api/ve1/aggregator-service/abandon-checkout/",
    ABANDONED_CART: "/api/ve1/aggregator-service/cart-service",
    USER_DETAILS: "/identity-service/user/details", // have to discuss back slash
    CUSTOM_CHECKOUT: "/identity-service/api/ve1/seller-customer-token/ui/access-token/checkout/data", 
    PAYU_URL:'https://test.payu.in/_payment'
}
