import React, { useEffect } from 'react';
import { Store } from '../../../service/StorageHelper';
const LoginTermsConditions = ({loginTermsConditionsChecked,setLoginTermsConditionsChecked,html}:{loginTermsConditionsChecked:any,setLoginTermsConditionsChecked:any,html:any}) => {
    return (
        <>
        <div className='sr-label'>
            <div className='mt-12 mr-5'>
            <input
                      id="termsAndConditions"
                      type="checkbox"
                      onChange={() => 
                      {
                        Store.setItem("marketingConsent",!loginTermsConditionsChecked)
                        setLoginTermsConditionsChecked(!loginTermsConditionsChecked)
                      }
                    }
                      checked={loginTermsConditionsChecked}
                    />
            </div>{
                html?    <div dangerouslySetInnerHTML={{ __html: html }} />: <p className='login-terms-text'>
                I agree to receiving marketing communications from the   
                    <a href="https://www.hul.co.in/brands/" className='login-terms-link' 
                    target="_blank" > Trusted Unilever Brands</a> . By proceeding, I agree to HUL 
                    <a href="https://www.theushop.in/pages/terms-and-conditions" target="_blank"
                    className='login-terms-link' 
                    > T&C </a> &   <a href="https://www.unilevernotices.com/privacy-notices/india-english.html" target="_blank"
                    className='login-terms-link' 
                    > Privacy Notice. </a>
            </p>
            }
           
        </div>

      

        </>
    )
}

export default LoginTermsConditions