import React, { useState, useEffect, useMemo } from "react";
import styles from "./EditAddress.module.css";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import {
  addExistingUserAddress,
  addNewUserAddress,
  updateAddress,
} from "../../Redux/Address/add_actions";
import ErrorHandler from "../Error/ErrorHandler";
import AnalyticService from "../../service/analytics/AnalyticsService";
import Loader from "../Utils/Loader";
import HttpService from "../../service/HttpService";
import apiUrls from "../../api/Apis";
import { Store } from "../../service/StorageHelper";
import { channels } from "../../utils/constants";
import Select from "../UI/Select/Select";
import { CITY_REGEX, CITY_REGEX_ERROR_MESSAGE } from "../../utils/constants";

const errMssgs = {
  pincode: "Please enter a valid 6 digit Pincode",
  name: "Enter your first name",
  lastName: "Enter your last name",
  line1: "Enter required address details",
  line2: "Enter required address details",
  landmark:"Enter a landmark" ,
  city: "Enter your City",
  email: "Enter valid email",
  contact: "Please enter a valid 10 digit phone number",
  gender : 'Please select your gender', 
  tags: "Enter a valid value"
};

const EditAddress = (props: any) => {

  const UrlQueryStrings = window.location.search;
  let params = new URLSearchParams(UrlQueryStrings);
  
  const [pincodeTouched, setPincodeTouched] = useState(false);
  const [pincodeHasError, setPincodeHasError] = useState(false);
  const [pincodeSpinner, setPincodeSpinner] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [showLandmark, setShowLandmark] = useState(false);
  const [state, updateState] = useState(0);
  const [newUserData, setnewUserData] = useState(true);
  const forceUpdate = () => updateState((prevState) => prevState ^ 1);
  const deliverystate = useSelector((state: any) => state.Delivery);
  const couponState = useSelector((state: any) => state.Coupon);
  const otpstate = useSelector((state:any )=> state.Otp);
  const addressstate = useSelector((state: any) => state.Address);
  const cartstate = useSelector((state: any) => state.Cart);
  const loginstate = useSelector((state: any) => state.Login);
  const shippingstate = useSelector((state: any) => state.Shipping);
  const [first, setfirst] = useState(true);
  const [pincodeFieldDisplayed, setPincodeFieldDisplayed] = useState(false);
  const [wrongPincode, setWrongPincode] = useState(false)
  const [pincode, setPincode] = useState(props?.editAddressData?.pincode ?? "")
  const [pincodeDataFetched, setPincodeDataFetched] = useState(false)
  const [pincodeLoader, setPincodeLoader] = useState(false)
  const [valid, setValid] = useState(true)
  const [skipErrorField, setSkipErrorField] = useState<any>([])
  const [showOtherTag, setShowOtherTag] = useState<boolean>(props?.editAddressData?.tags === "others" ? true : false);
  const [selectedTag, setSelectedTag] = useState<string>(props?.editAddressData?.tags ?? 'home');
  const [phoneNumber, setPhoneNumber] = useState<any>(loginstate?.sellerConfig?.enableAdrrPhone ? props?.editAddressData?.alternatePhone ? props?.editAddressData?.alternatePhone : props?.editAddressData?.phone : props?.editAddressData?.phone)
  
  let tagsValues;
  if(props?.editAddressData?.tags && props?.editAddressData?.tags?.includes('other')){
    tagsValues =  props?.editAddressData?.tags?.split(':')?.[1]?.trim();
  }else {
    tagsValues = "";
  }
  
  const [values, setValues] = useState({
    city: props?.editAddressData?.city ?? "",
    state: props?.editAddressData?.state ?? "",
    name: props?.editAddressData?.firstName ?? "",
    lastName: props?.editAddressData?.lastName ?? "",
    gender :props?.editAddressData?.gender ?? "",
    email:loginstate?.sellerConfig?.removeEmailFromCodForm ? '' : props?.editAddressData?.email ? props?.editAddressData?.email : "",
    line1: props?.editAddressData?.line1 ?? "",
    line2: props?.editAddressData?.line2 ?? "",
    landmark: props?.editAddressData?.landmark?.trim() ?? "",
    completeAdd: "",
    altPhone : props?.editAddressData?.alternatePhone ?? "", 
    tags: tagsValues,
  });
  

  const [errors, setErrors] = useState({
    name: errMssgs.name,
    lastName: errMssgs.lastName,
    line1: errMssgs.line1,
    line2: errMssgs.line2,
    gender : loginstate?.sellerConfig?.genderInAddressEnabled ? props?.editAddressData?.gender ? '' : errMssgs.gender : '',
    landmark: loginstate?.sellerConfig?.landmarkMandatory ? errMssgs.landmark : '',
    city: errMssgs.city,
    email: loginstate?.sellerConfig?.removeEmailFromCodForm ? '' : loginstate.sellerConfig?.showEmail ? errMssgs.email : "",
    state: "",
    phone : loginstate?.sellerConfig?.enableAdrrPhone ? (props?.editAddressData?.alternatePhone || props?.editAddressData?.phone) ? '' : 'Enter your phone number' : '',
    tags: selectedTag === "others" ? errMssgs?.tags : ''
  });
  const [activeErrors, setActiveErrors] = useState({
    name: "",
    lastName: "",
    gender : '',
    line1: "",
    line2: "",
    landmark:"",
    city: "",
    email: "",
    state: "",
    phone : "", 
    tags: ""
  }); 

  const [lenError, setLenError] = useState("")
  const [phoneActiveError, setPhoneActiveError] = useState("")
  const [line2lenError, setLine2LenError] = useState("")
  const dispatch = useDispatch();

  const setErrorMssg = (key: string, error: string) => {
    setErrors((prevState) => ({ ...prevState, [key]: error }));
  };

  const setActiveErrorsMssg = (key: string, error: string) => {
    setActiveErrors((prevState) => ({ ...prevState, [key]: error }));
  };

  useEffect(() => {
    let tagName:any;
    if(props?.editAddressData?.tags){
      tagName = (props?.editAddressData?.tags === "home" || props?.editAddressData?.tags === "office") ? props?.editAddressData?.tags : "others";
      setShowOtherTag(true)
    }else {
      tagName = "home"
      setShowOtherTag(false)
    }
    setSelectedTag(tagName)
  }, [])


  useEffect(() => {
    if(addressstate.addressUpdated) {
      if(params.get("login") === "sr-login") {
        addressstate.editAddressCustomCheckout = false;
        props?.onRenderLoading()
      }
      if(props.SetIsOpen){
      props.SetIsOpen(false)
      }
      AnalyticService.additionalEvents(
        "address-edited",
        cartstate?.Cart_data,
        couponState.couponCode, 
        Store.getItem('refreshToken') ? true : false,
        false
      );
      let existingAddress = addressstate?.Address_data
      let newAddressData = existingAddress?.map((address: any) => {
        if(address?.addressID === addressstate?.updatedAddress.addressID) {
          return addressstate?.updatedAddress
        } else {
          return address
        }
      })
      addressstate.Address_data = newAddressData
      //if(addressstate?.selectedAddress?.addressID === addressstate?.updatedAddress?.addressID) {
      addressstate.selectedAddress = addressstate?.updatedAddress
      shippingstate.serviceFetched = false
      cartstate.orderFetched = false
      addressstate.defaultAddressCorrect = true
      if(props?.verifyAddress){
        props?.onRender()
      }
      //}
    }
  },[addressstate.addressUpdated])

useEffect(() => {
    let noMandatoryErrors = true
    let noActiveErrors = true
    Object.values(errors).forEach((error) => {
      if (error !== "") {
        noMandatoryErrors = false;
      }
    });
    Object.values(activeErrors).forEach((error) => {
      if (error !== "") {
        noActiveErrors = false;
      }
    });
    setValid(noMandatoryErrors && noActiveErrors)
  }, [errors,activeErrors]);

  function getFirstErrorKey(object : any) {
    return Object.keys(object).find(key =>
      object[key] !== "");
  }

  const firstErrorKey = useMemo(()=>{
    let firstErrorKeyName = 'pincode'
    let firstMandatoryError = getFirstErrorKey(errors)
    let firstActiveError = getFirstErrorKey(activeErrors)

    firstErrorKeyName = Object.keys(errors).find(key =>key === firstMandatoryError || key === firstActiveError) ?? 'pincode';
    return firstErrorKeyName
  },[errors,activeErrors])

  const pincodeErrorValue = useMemo(
    () =>
        !pincode ? 
        "Enter your pincode"
        :
        (pincode.length < 6 && pincode.length >= 1)||
        pincode.length > 6 ||
        pincodeHasError
        ? "Pincode must consist of 6 digit"
        : "",
    [pincode, pincodeHasError]
  );

  const activePincodeError = useMemo(
    ()=>
      wrongPincode ? 
        "Please enter a valid pincode"
        : "",
      [wrongPincode]
  )


  useEffect(() => {
    console.log("IaminErrorBlock")
    // error to be shown only if error msg received
    if (addressstate.failMsg !== null) {
      values.line1 = "";
      values.line2 = "";
      deliverystate.Pincode_data = {};
      deliverystate.pincode = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
      forceUpdate();
      if (!pincodeFieldDisplayed) {
        AnalyticService.pincodeFieldDisplayed();
        console.log("hi parv")
      }
      setPincodeFieldDisplayed(true);
    }
  }, [addressstate.failMsg]);

  useEffect(() => {
    if (addressstate.invalidEmail !== null) {
      values.email = "";
      loginstate.addSilentUserAddress = false;
      props.onRender();
    }
  }, [addressstate.invalidEmail]);

  useEffect(() => {
    if(addressstate?.selectedAddress && cartstate?.orderDetails?.id) {
      props.setAddressToShow({
        name: `${addressstate?.selectedAddress.firstName ?? "Customer"} ${addressstate?.selectedAddress.lastName ?? ""}`,
        email: addressstate?.selectedAddress.email,
        address: `${addressstate?.selectedAddress.line1}, ${addressstate?.selectedAddress?.line2 ? (addressstate?.selectedAddress?.line2 + ',') : ""} ${addressstate?.selectedAddress?.landmark ? ("Landmark-" + addressstate?.selectedAddress?.landmark + ',') : ""} ${addressstate?.selectedAddress.city}, ${addressstate?.selectedAddress.state}, ${addressstate?.selectedAddress.pincode}`,
        address_id: addressstate?.selectedAddress.addressID,
        customer_id: loginstate.userInfo.user_id,
        count: "",
        index: "",
        alternatePhone : addressstate.selectedAddress?.alternatePhone,
        tags: addressstate.selectedAddress?.tags 

      });
      //props.SetIsOpen(false);
    }
  }, [JSON.stringify(addressstate.selectedAddress)])

  const inputPincode = async (inputPin: string) => {
    setWrongPincode(false)
    addressstate.fail = false;
    setPincode(inputPin.slice(0,6))
    //deliverystate.pincode = inputPin.substring(0, 6);
    forceUpdate();
    let reqpayload = {
      postcode: inputPin.substring(0, 6),
    };
    if (inputPin.length === 6) {
      AnalyticService.pincodeAdded();
      AnalyticService.addressFieldsDisplayed();
      //deliverystate.spinner = true;
      setWrongPincode(false)
      //dispatch(fetchPincode(reqpayload));
      try {
        setPincodeLoader(true)
        const response  = await HttpService.getWithParams(apiUrls.GET_PINCODE(), reqpayload)
        setPincodeLoader(false)
        if(response)  {
          console.log(response)
          setValues({...values, city: response?.data?.postcode_details?.city, state: response?.data?.postcode_details?.state})
          inputValue("city", response?.data?.postcode_details?.city)
          setPincodeDataFetched(true)
          setWrongPincode(false)
        }
      }
       catch(e) {
        console.log(e)
        setWrongPincode(true)
        setPincodeLoader(false)
      }
    }
  };


  const isNumber = (e: any) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = e.target.value.substring(0,13);
    var inputs = document.querySelectorAll('input[type="number"]');
    for (var i = inputs.length; i--; )
      inputs[i].setAttribute("pattern", "\\d*");
  };

  const mobileInputHandler = async (event: any) => {
    let number = event.target.value;
    setPhoneNumber(number)
    let zeroCheck = number.length > 0 && Number(number[0]) === 0;
    let flag = false;
    flag = zeroCheck ? true : false;

    if(!loginstate?.sellerConfig?.enableAdrrPhone){
      setErrorMssg("phone","")
    }else if(number.length === 0){
      setErrorMssg("phone","Enter your phone number")
    }else{
      setErrorMssg("phone","")
    }

    if(!loginstate?.sellerConfig?.enableAdrrPhone){
      setActiveErrorsMssg("phone","")
    }else if (number.length === 10 && !flag) {
      setActiveErrorsMssg("phone","")
    }else if(number.length === 0){
      setActiveErrorsMssg("phone","")
    }else{
      setActiveErrorsMssg("phone","Please enter valid phone number")
    }
  };


  // const updateAddress = () => {
  //   let updateCartPayload = {
  //     cart_id: cartstate.Cart_data?.data?.cart_id,
  //     address_selected: 0,
  //     address_id: addressstate.Silent_User_Address.data.address_id,
  //     customer_id: addressstate.Silent_User_Address.data.customer_id,
  //     name: values.name,
  //     email: values.email,
  //     address: addressstate.Silent_User_Address.data.address_details,
  //     saved_addresses: 0,
  //   };
  //   updateCartPayload.address.mobile_no = updateCartPayload.address.mobile;
  //   loginstate.onPage = "delivery";
  //   // update mobile number from redux
  //   if (!updateCartPayload.address.mobile_no) {
  //     updateCartPayload.address.mobile_no = loginstate.mobileNumber;
  //   }
  // };

// tags handle
const handleAddressTag = (tag:string) =>{
  if(!(pincode.length < 6 || wrongPincode ||  pincodeLoader)){
  if(tag === "others"){
    setShowOtherTag(true)
    setErrors((prevState) => ({ ...prevState, tags: errMssgs.tags }));
  }else {
    setShowOtherTag(false)
    setValues((prevState) => ({ ...prevState, tags: '' }));
    setErrors((prevState) => ({ ...prevState, tags: '' }));
    setActiveErrors((prevState) => ({ ...prevState, tags: ''}));
  }
  setSelectedTag(tag)
  }
}

  const handleInternationalShippingRedirect = () => {
    if(!params.get('cart-resume-id')){
      if (channels.shopify === loginstate?.platform) {
        //for Shopify
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:
                    loginstate.channel?.redirectUrl[loginstate.channel?.redirectUrl?.length - 1] === ","
                      ? loginstate.channel?.redirectUrl?.slice(0, loginstate.channel?.redirectUrl?.length - 1)
                      : loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }

      if (channels.wooCommerce === loginstate?.platform) {
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: loginstate.channel?.redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
    }else{
      if (channels.shopify === cartstate?.platform?.toUpperCase()) {
        let cartData = "";
        for (let item of cartstate?.orderDetails?.lineItems) {  
          cartData = cartData + item.itemId+':'+item.quantity+','
        }

        const redirectUrl  = `http://${loginstate?.sellerConfig?.domainName}/cart/${cartData}`;
        //for Shopify
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url:
                  redirectUrl[redirectUrl?.length - 1] === ","
                      ? redirectUrl?.slice(0, redirectUrl?.length - 1)
                      : redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }

      if (channels.wooCommerce === cartstate?.platform?.toUpperCase()) {
        let redirectUrl =`http://${loginstate?.sellerConfig?.domainName}/checkout`;
        //for WOOCOMMERCE
        window.parent.postMessage(
          {
            trigger: "headless-redirect",
            actions: [
              {
                action: "redirectTo",
                data: {
                  url: redirectUrl,
                },
              },
            ],
          },
          "*"
        );
      }
    }
  }

  const addressTagSelectedClass  = 
  (pincode.length < 6 || wrongPincode ||  pincodeLoader) ? "" : 
  `${cartstate?.cardDesignEnabled ? styles.addressTag_selected_card : styles.addressTag_selected}`;

  const renderaddressdetails = () => {
    return (
      <>
        <div>
        {
          !addressstate?.defaultAddressCorrect &&
          (cartstate?.cardDesignEnabled ? <div className={ 'card-main-heading'} style={{paddingBottom:'10px'}}>Add shipping address</div>
          : <h4 style={{paddingBottom:'10px'}}>Add shipping address</h4>
          )
        }
        {loginstate?.sellerConfig?.enableInternationalAddress &&
          <h5 style={{paddingBottom:'10px',font : '500 14px/12px "Metropolis"'}}>Do you like to have your order <a onClick={()=>handleInternationalShippingRedirect()} style={{color : 'var(--secondary-color)',cursor : 'pointer'}}> shipped outside India?</a></h5>
        }
          <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
            <Input
              label="Pincode"
              type="text"
              id="pincode"
              name="pincode"
              value={pincode}
              className="mt-18"
              onChange={(e: any) => {
                if(e.target.value.match(/^[\d]{0,6}$/g)){
                inputPincode(e.target.value)
                }
              }}
              error={pincodeErrorValue}
              activeErrors={activePincodeError}
              loading={pincodeLoader}
              touched={pincodeTouched}
              autoComplete="postal-code"
              mandatory = {true}
              marginTop={!cartstate?.cardDesignEnabled ? '' : '8px'}
            />
          </div>
          {/* {wrongPincode && (
            <ErrorHandler
              msg={
                "City/State not found for the entered pincode"
              }
            />
          )} */}
          {addressstate.failMsg && (
            <ErrorHandler msg={addressstate.failMsg} />
          )}
          {
            true ? (
            <div>
                <Input
                  label="First name"
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  className="mr-10 mt-18"
                  maxLength="60"
                  onInput={(e: any) => inputValue("name", e.target.value)}
                  error={errors.name}
                  activeErrors = {activeErrors.name}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="given-name"
                  touched = {skipErrorField?.includes("name")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label="Last name"
                  type="text"
                  id="lastName"
                  name="name"
                  value={values.lastName}
                  className="mr-10 mt-18"
                  maxLength="60"
                  onInput={(e: any) => inputValue("lastName", e.target.value)}
                  error={errors.lastName}
                  activeErrors = {activeErrors.lastName}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="family-name"
                  skippedArray={["name"]}
                  touched = {skipErrorField?.includes("lastName")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                {loginstate?.sellerConfig?.genderInAddressEnabled &&
                <Select
                id="gender"
                label="Gender"
                placeholder="Gender"
                name="gender"
                className="mt-18"
                value={values.gender}
                onChange={(e: any) => {inputValue("gender", e.target.value)}}
                error={errors.gender}
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                touched = {skipErrorField?.includes("gender")}
                activeErrors = {activeErrors.gender}
                skippedArray={["name","lastName"]}
                setSkipErrorField={setSkipErrorField}
                mandatory={true}
                >
                  <option disabled value = "" hidden>{!cartstate?.cardDesignEnabled ? 'Select your gender' : ''}</option>
                  <option value="male">
                    Male
                  </option>
                  <option value="female">
                   Female
                  </option>
                </Select>
                }
                <Input
                  label={loginstate?.sellerConfig?.domainName !== "thehatke.com" ? "Flat, house number, floor, building" : "Address line 1 (Flat, house number, floor, building)"}
                  type="textarea"
                  id="line1"
                  name="address"
                  value={values.line1}
                  onInput={(e: any) => inputValue("line1", `${e.target.value}`)}
                  error={errors.line1}
                  activeErrors = {activeErrors.line1}
                  lenError={lenError}
                  className="mt-18"
                  defaultValue={values.line1}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="address-line1"
                  maxLength="255"
                  skippedArray={["name","lastName","gender"]}
                  touched = {skipErrorField?.includes("line1")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label={loginstate?.sellerConfig?.domainName !== "thehatke.com" ? "Area, street, sector, village" : "Address line 2 (Area, street, sector, village)"}
                  type="textarea"
                  id="line2"
                  name="address"
                  value={values.line2}
                  onInput={(e: any) => inputValue("line2", `${e.target.value}`)}
                  error={errors.line2}
                  activeErrors = {activeErrors.line2}
                  lenError={line2lenError}
                  className="mt-18"
                  defaultValue={values.line2}
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  autoComplete="address-line2"
                  maxLength="170"
                  skippedArray={["name","lastName","line1","gender"]}
                  touched = {skipErrorField?.includes("line2")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                {showLandmark || values.landmark || loginstate?.sellerConfig?.landmarkMandatory ? (
                  <Input
                    label="Landmark"
                    type="text"
                    id="landmark"
                    name="landmark"
                    value={values.landmark}
                    onInput={(e: any) => inputValue("landmark", e.target.value)}
                    className="mt-18"
                    maxLength="50"
                    disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                    error={errors.landmark}
                    activeErrors = {activeErrors.landmark}
                    autoComplete="landmark"
                    skippedArray={["name","lastName","line1","line2","gender"]}
                    touched = {skipErrorField?.includes("landmark")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={loginstate?.sellerConfig?.landmarkMandatory ? true : false}
                  />
                ) : (
                  !loginstate?.sellerConfig?.landmarkMandatory &&
                  (
                  <button
                    onClick={() => setShowLandmark(true)}
                    className={!cartstate?.cardDesignEnabled ? styles.LandmarkButton : styles.LandmarkButtonCard }
                  >
                    + Landmark area
                  </button>
                  )
                )}
                {loginstate?.sellerConfig?.enableAdrrPhone  &&
                  <Input
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="tel"
                    autoComplete="tel"
                    // placeholder="10-digit phone number"
                    className="mt-18"
                    onChange={mobileInputHandler}
                    onInput={isNumber}
                    skippedArray={["name","lastName","line1","line2","landmark","gender"]}
                    touched = {skipErrorField?.includes("phone")}
                    setSkipErrorField={setSkipErrorField}
                    mandatory={ true}
                    activeErrors={activeErrors.phone}
                    error = {errors.phone}
                    value={phoneNumber}
                    disabled = {pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  />
                }
{/*               <div className={clsx(styles.title, "mt-24")}>Address</div> */}
              <div className={styles.addressDiv}>
                <Input
                  label="City"
                  type="text"
                  id="city"
                  name="city"
                  value={values.city}
                  onInput={(e: any) => inputValue("city", e.target.value)}
                  className="mr-18"
                  maxLength="100"
                  //disabled={true}
                  error={errors.city}
                  activeErrors={activeErrors.city}
                  autoComplete="address-level2"
                  disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                  skippedArray={["name","lastName","line1","line2","landmark","phone","gender"]}
                  touched = {skipErrorField?.includes("city")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                />
                <Input
                  label="State"
                  type="text"
                  id="state"
                  name="state"
                  value={values.state}
                  disabled={true}
                  skippedArray={["name","lastName","line1","line2","landmark","city","phone","gender"]}
                  touched = {skipErrorField?.includes("state")}
                  setSkipErrorField={setSkipErrorField}
                  mandatory={true}
                  skipPincodeFocus = {!(pincode.length < 6 || wrongPincode ||  pincodeLoader)}
                />
              </div>
{/*               <div className={clsx(styles.title, "mt-18")}>
                Contact Details
              </div> */}
              {/* <Input
                label="First Name"
                type="text"
                id="name"
                name="name"
                value={values.name}
                className="mr-10 mt-18"
                onInput={(e: any) => inputValue("name", e.target.value)}
                error={errors.name}
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                autoComplete="given-name"
              />
              <Input
                label="Last Name"
                type="text"
                id="name"
                name="name"
                value={values.lastName}
                className="mr-10 mt-18"
                onInput={(e: any) => inputValue("lastName", e.target.value)}
                error={errors.lastName}
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                autoComplete="family-name"
              /> */}
              {!loginstate?.sellerConfig?.removeEmailFromCodForm &&
              <>
              <Input
                label={loginstate.sellerConfig?.showEmail ? "E-mail" : "E-mail (optional)"}
                type="text"
                id="email"
                name="email"
                value={values.email}
                onInput={(e: any) => inputValue("email", e.target.value)}
                error={errors.email}
                className="mt-18"
                disabled={pincode.length < 6 || wrongPincode ||  pincodeLoader}
                autoComplete="email"
                skippedArray={["name","lastName","line1","line2","landmark","city","phone","gender"]}
                touched = {skipErrorField?.includes("email")}
                setSkipErrorField={setSkipErrorField}
                mandatory={loginstate.sellerConfig?.showEmail ? true : false}
              />
              <div className={styles.deliveryLabel}>
                Order delivery details will be sent here
              </div>
              {addressstate.invalidEmail && (
                <ErrorHandler msg={addressstate.invalidEmail} />
              )}
              </>
              }
              <div className={`${styles.addressDiv}`}>
                <div style={{width: '100%', marginTop: 24}}>
                  <div className={styles.addressTagContainer}>
                    <div className={`${styles.addressTag} ${selectedTag === "home" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('home')}>Home</div>
                    <div className={`${styles.addressTag} ${selectedTag === "office" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('office')}>Office</div>
                    <div className={`${styles.addressTag} ${selectedTag === "others" ? addressTagSelectedClass : ''}`} onClick={() => handleAddressTag('others')} >Others</div>
                </div>
                {(showOtherTag && selectedTag === "others")
                  ? 
                  <div className={!cartstate?.cardDesignEnabled ? !loginstate.AuthTokenGenerated ? "mtNeg-18" : "" : ''}>
                    <Input
                      label="Save address as"
                      type="text"
                      id="tags"
                      name="tags"
                      value={values.tags}
                      className="mr-10 mt-18"
                      onInput={(e: any) => inputValue("tags", e.target.value)}
                      error={errors.tags}
                      maxLength="25"
                      activeErrors = {activeErrors.tags}
                      // autoComplete="given-name"
                      // touched = {skipErrorField?.includes("addressTag")}
                      // setSkipErrorField={setSkipErrorField}
                      mandatory={selectedTag === "others" && true}
                    />
                  </div> 
                  : ''
                }
              </div>
            </div>
              <div className={!cartstate?.cardDesignEnabled ? styles.mb70 : ''}>
                {/* <button className='Button_button__KXFVC' onClick={handleAddData}
                  >Continue</button> */}
                {addressstate.spinner && <Loader />}
                <Button
                  loading={addressstate.updatingAddress}
                  id="addAddressBtn"
                  // disabled={!valid || pincode.length < 6 || addressstate.updatingAddress}
                  type="button"
                  //onClick={handleAddData}
                  onClick={() => {
                    //props.setEditAddress(false)
                    console.log("TheBat", values)
                    handleUpdateAddress()
                  }}
                  className={clsx("mt-30",{
                    [styles.disabled]: (!valid || pincode.length < 6 || addressstate.updatingAddress || wrongPincode),
                  })}
                  isCardDesign={cartstate?.cardDesignEnabled}
                >
                  {addressstate.updatingAddress ? "Saving address... " : "Save and select"}
                </Button>
                <input type="hidden" id="editAddressFastrr" />
              </div>
            </div>
          ) : null}
        </div>
      </>
    );
  };


  const checkError = (key: string, inputValue: string) => {
    switch (key) {
      case "name": {
        const re = /^[A-Za-z\s]+$/
        if(inputValue === ""){
          setErrorMssg(key, "Enter your first name.")
        }else{
          setErrorMssg(key, "");
        }
        if(inputValue.length === 0){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key, "Avoid starting with a space")
        } else if ((params.get('login') === "sr-login") && inputValue.length < 3){
          setErrorMssg(key, "First name can’t be smaller than 3 characters")
        }else if ((params.get('login') === "sr-login") && inputValue.length > 49){
          setErrorMssg(key, "First name can’t be longer than 49 characters")
        }else if(!re.test(inputValue)){
          setActiveErrorsMssg(key, "Use only alphabets (A-Z, a-z)")
        } else if(inputValue.length > 60){
          setActiveErrorsMssg(key, "First name can’t be longer than 60 characters")
        } else {
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "lastName": {
        const re = /^[A-Za-z\s]+$/
        if(inputValue === ""){
          setErrorMssg(key, "Enter your last name.")
        }else{
          setErrorMssg(key, "");
        }
        if(inputValue.length === 0){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key, "Avoid starting with a space")
        } else if ((params.get('login') === "sr-login") && inputValue.length < 3){
          setErrorMssg(key, "Last name can’t be smaller than 3 characters")
        }else if ((params.get('login') === "sr-login") && inputValue.length > 49){
          setErrorMssg(key, "Last name can’t be longer than 49 characters")
        }
        
        else if(!re.test(inputValue)){
          setActiveErrorsMssg(key, "Use only alphabets (A-Z, a-z)")
        } else if(inputValue.length > 60){
          setActiveErrorsMssg(key, "Last name can’t be longer than 60 characters")
        } else {
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "gender": {
        if(inputValue === ""){
          setErrorMssg(key, "Select your gender.")
        }else{
          setErrorMssg(key, "");
        }
        break;
      }
      case "landmark": {
        // inputValue.length > 50 || inputValue[0] == " "
        if (loginstate?.sellerConfig?.landmarkMandatory && inputValue === ""){
          setErrorMssg(key, "Enter your landmark");
        } else {
          setErrorMssg(key, "");
        }
        if(inputValue === ""){
          setActiveErrorsMssg(key,"")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key,"Avoid starting with a space")
        }else if(inputValue.length > 50){
          setActiveErrorsMssg(key,"Landmark can’t be longer than 50 characters")
        }else{
          setActiveErrorsMssg(key,"")
        }
        break;
      }
      case "email": {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if ((loginstate.sellerConfig?.showEmail && (inputValue === "" || !re.test(inputValue))) || (inputValue !== "" && !re.test(inputValue))) {
          setErrorMssg(key, errMssgs.email);
        } else {
          setErrorMssg(key, "");
        }
        break;
      }
      case "line1": {
        const re = /^[ A-Za-z0-9_@./,#&+\-()/\n]{0,255}$/;
        setLenError(inputValue.trim().length.toString())
        if(inputValue.trim() === ""){
          setErrorMssg(key, errMssgs.line1)
        }else{
          setErrorMssg(key, "")
        }
        if(inputValue.trim() === ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue.trim().length > 255){
          setActiveErrorsMssg(key,"Address line 1 cannot be longer than 255 characters");
        }else if(!re.test(inputValue.trim())){
          setActiveErrorsMssg(key, "Allowed special characters _ @ . / , # & + - ");
        } else if(loginstate?.sellerConfig?.domainName === "thehatke.com" && inputValue.trim().length < 4 ){
          setActiveErrorsMssg(key,"Address line 1 cannot be less than 4 characters");
        } else {
          setActiveErrorsMssg(key, "");
        }
        break;
      }
      case "line2": {
        const re = /^[ A-Za-z0-9_@./,#&+-/\n]{0,170}$/;
        setLine2LenError(inputValue.trim().length.toString())
        if(inputValue.replace(/\s/g,"") === ""){
          setErrorMssg(key,errMssgs.line2)
        }else if(inputValue.trim().length < 10){
          setErrorMssg(key,"Minimum 10 characters required")
        }else{
          setErrorMssg(key, "")
        }
        if(inputValue.trim() === ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue.trim().length > 170){
          setActiveErrorsMssg(key,"Address line 2 cannot be longer than 170 characters");
        }else if (!re.test(inputValue.trim())) {
          setActiveErrorsMssg(key,"Allowed special characters _ @ . / , # & + - ");
        } else {
          setActiveErrorsMssg(key, "");
        }
        break;
      }
      case "city": {
        const re = CITY_REGEX;
        deliverystate.city = inputValue
        if (inputValue == "") {
          setErrorMssg(key,errMssgs.city);
        } else {
          setErrorMssg(key, "");
        }

        if(inputValue == ""){
          setActiveErrorsMssg(key, "")
        }else if(inputValue[0] == " "){
          setActiveErrorsMssg(key,"Avoid starting with a space")
        }else if(!re.test(inputValue)){
          setActiveErrorsMssg(key,CITY_REGEX_ERROR_MESSAGE)
        }else if(inputValue.length > 100){
          setActiveErrorsMssg(key,"City can’t be longer than 100 characters")
        }else{
          setActiveErrorsMssg(key, "")
        }
        break;
      }
      case "tags": {
        if (inputValue?.trim() == "" && selectedTag === "others") {
          setErrorMssg(key, errMssgs.tags);
        }else {
          setErrorMssg(key, '');
        }
        const trimmedInput = inputValue?.trim();
        if (trimmedInput === "") {
          setActiveErrorsMssg(key, "");
        } else if (inputValue.startsWith(" ")) {
            setActiveErrorsMssg(key, "Avoid starting with a space");
        } else {
            setActiveErrorsMssg(key, "");
        }

        // if(inputValue == ""){
        //   setActiveErrorsMssg(key, "")
        // }else if(inputValue[0] == " "){
        //   setActiveErrorsMssg(key,"Avoid starting with a space")
        // }
        break;
      }
      case "contact": {
        inputValue = inputValue.substring(0, 10);
        if (inputValue === "" || inputValue.length !== 10) {
          setErrorMssg(key, errMssgs.contact);
        } else {
          setErrorMssg(key, "");
        }
      }
    }
  };

  const handleUpdateAddress = async() => {
    if(!(!valid || pincode.length < 6 || addressstate.updatingAddress || wrongPincode)){
      if(!loginstate?.otpSkip){
        dispatch(updateAddress(
          {
            ...props.editAddressData,
            city: values?.city.trim()?.replace(/\./g, " "),
            state: values?.state,
            firstName: values?.name.trim(),
            lastName: values?.lastName.trim(),
            email: values?.email,
            line1:values?.line1?.trim()?.replace(/[()]/g, ' '),
            line2:values?.line2.trim(),
            landmark: values.landmark.trim(),
            pincode: pincode,
            gender : values.gender ?? '',
            alternatePhone : phoneNumber !== loginstate.mobileNumber ? phoneNumber : null,
            cartId : cartstate?.Cart_data?.id,
            ...(props?.editAddressData?.addressTag && {addressTag: null}), 
            tags: selectedTag === "others" ? `other: ${values?.tags}` : selectedTag
          },
          loginstate.userInfo.user_id,
          loginstate.sellerConfig.seller_id,
          props?.verifyAddress ? true : false
          ))
      }else{
        await dispatch({
          type : 'SKIP_Otp_Address_add',
          payload : {
            ...props.editAddressData,
            city: values?.city.trim()?.replace(/\./g, " "),
            state: values?.state,
            firstName: values?.name.trim(),
            lastName: values?.lastName.trim(),
            email: values?.email,
            line1:values?.line1.trim()?.replace(/[()]/g, ' '),
            line2:values?.line2.trim(),
            gender : values.gender ?? '',
            landmark: values.landmark.trim(),
            pincode: pincode,
            alternatePhone : phoneNumber !== loginstate.mobileNumber ? phoneNumber : null,
            cartId : cartstate?.Cart_data?.id,
            emailConfirmed : true,
            ...(props?.editAddressData?.addressTag && {addressTag: null}), 
            tags: selectedTag === "others" ? `other: ${values?.tags}` : selectedTag
          }
          }
        )
        props.SetIsOpen(false)
        shippingstate.serviceFetched = false
        cartstate.orderFetched = false
        addressstate.defaultAddressCorrect = true
        props?.onRender()
      }

    }else{
      if(pincode.length < 6 || wrongPincode ||  pincodeLoader){
        document.getElementById("pincode")?.focus()
      }else{
        document.getElementById(firstErrorKey)?.focus()
      }
    }
  }


  const inputValue = (key: string, inputValue: string) => {
    checkError(key,inputValue)

    setValues((prevState) => ({ ...prevState, [key]: inputValue }));
  };

  useEffect(() => {
    inputValue("line1", values.line1)
    inputValue("city", values.city)
    inputValue("line2", values.line2)
    inputValue("name", values.name)
    inputValue("lastName", values.lastName)
    if(!loginstate?.sellerConfig?.removeEmailFromCodForm){
      inputValue("email", values.email)
    }
    inputValue("landmark", values.landmark)
  }, [])

  const handleAddData = (e: any) => {
    shippingstate.serviceFetched = false
    cartstate.orderFetched = false
    e.preventDefault();
    addressstate.addingNewAddress = Math.random() * 10;
    loginstate.addSilentUserAddress = true;
    cartstate.cartAddressUpdated = false;
    // reset values in case user enters wrong address again
    addressstate.failMsg = null;
    addressstate.invalidEmail = null;
    document.getElementById("addAddressBtn")?.setAttribute("disabled", "true");
    let fn = "";
    let ln = "";
    if (values.name.indexOf(" ") === -1) {
      fn = values.name.substring(values.name.indexOf(" ") + 1);
    } else {
      fn = values.name.substring(0, values.name.indexOf(" "));
      ln = values.name.substring(values.name.indexOf(" ") + 1);
    }
    let AddressPayload = {
      "address1": values.line1,
      "landmark":values.landmark,
      "city": deliverystate.Pincode_data?.postcode_details?.city,
      "email": values.email,
      "fname": fn,
      "lname": ln,
      "pincode": deliverystate.pincode,
      "state": deliverystate.Pincode_data?.postcode_details?.state,
      "state_code": "",
      "phone": loginstate.mobileNumber,
      "mobile": loginstate.mobileNumber,   
      "country": "India",
      "country_code": "IN",
      "addressType":["shipping"],
      tag: selectedTag === "others" ? `others: ${values?.tags}` : selectedTag,
      "seller_id": loginstate.sellerConfig?.seller_id,
          
  }
  
    let ExistingAddressPayload ={
      
        phone: loginstate.mobileNumber,
        line1: values.line1.replace(/\n/g, " ").trim(),
         city:deliverystate.Pincode_data?.postcode_details?.city,
         pincode: deliverystate.pincode,
         state:deliverystate.Pincode_data?.postcode_details?.state,
        country: "IN",
        sellerID:loginstate.sellerConfig?.seller_id,
        landmark:values.landmark,
        firstName:fn,
        lastName:ln,
        addressType:["Shipping"],
        email:values.email,
        countryCode: "+91",
        tag: selectedTag === "others" ? `others: ${values?.tags}` : selectedTag
       // isDefault: true,
      //  lastUsedDateTime:"2022-07-21T07:44:04.001+00:00",
       // noOfDeliveredOrders:1,
       // totalOrders:12  
    }

    
    values.completeAdd =
      values.line1 +
      ", " +
      AddressPayload.city +
      ", " +
      AddressPayload.state +
      ", " +
      deliverystate.pincode;
    if (loginstate.AuthTokenGenerated) {
      addressstate.New_User_Address_Payload = AddressPayload;
      cartstate.showPreferredShipping = false;
      //dispatch(addSilentUserAddress(AddressPayload));
      dispatch(addNewUserAddress(AddressPayload))
      //  setnewUserData(false)
    } else {
      dispatch(addExistingUserAddress(
        ExistingAddressPayload, 
        loginstate.userInfo.user_id,
        otpstate.fetchAddressPermission,
        loginstate.sellerConfig.seller_id,
        loginstate.sellerConfig.blockOldAddress
        )
      )
    }
  };

  return <>{renderaddressdetails()}</>;
};

export default EditAddress;
