import React from "react";
import styles from "./Button.module.css";
import clsx from "clsx";

interface ButtonField {
  id?: string;
  type?: string;
  text?: string;
  name?: string;
  className?: string;
  onClick?: any;
  onSubmit?: any;
  disabled?: boolean;
  loading?: boolean;
  children?: any;
  outline?: boolean;
  isCardDesign?: any;
  borderRadius?: any
}

const Button = (props: ButtonField) => {
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      className={clsx(
        props.isCardDesign ? styles.buttonCard : styles.button,
        props.className,
        props.outline ? styles.outlineButton : ""
      )}
      onClick={props.onClick}
      style={{
        borderRadius: props.borderRadius ? props.borderRadius : ""
      }}
    >
      {props.children}
    </button>
  );
};

export default Button;
