let Cart_state = {
  loader: 1,
  fail: false,
  sendCartInitiate: true,
  updateAddressfired: true,
  updateShippingfired: true,
  updatePaymentfired: true,
  Cart_data: {},
  UpdateAdd_data: {},
  UpdatedShipping_data: {},
  cod_total_price: 0,
  sub_total_price: 0,
  total_price: 0,
  prepaid_discount: 0,
  auto_discount: 0,
  coupon_discount: 0,
  cod_charges: 0,
  shipping_charges: 0,
  channel_order_id: "",
  prepaid_discount_type: "",
  prepaid_discount_value: "",
  order_id: 0,
  pg_order_id: "",
  showPrepaidDiscount: true,
  coupon_discount_type: "",
  coupon_discount_value: "",
  transaction_ID: 0,
  deliveryDate: "",
  showPreferredDelivery: true,
  showPreferredShipping: true,
  initiateRazorpayListener: false,
  cartAddressUpdated: false,
  orderComplete: false,
  paymentProcessing: false,
  verifyingPayment: false,
  paymentFailed: false,
  completeApiFired: false,
  orderCompleteUrl: "",
  fireSetuRequest: true,
  customerMarketingConsent:null,
  paymentMode: "",
  createCartCalled: false,
  paymentDetails: {},
  orderDetails:{},
  loyaltyPointDetails:{},
  orderFetched:false,
  orderFetching: false,
  cart_items : [],
  updateCartCalled: false,
  initialCartData: null,
  upiPaymentStatus : false,
  paymentMethodsFetching: false,
  paymentMethodsFetched: false,
  activePaymentGateway: "",
  activePaymentMode: "",
  fireRazorpayRequest: true,
  orderPending: false,
  activePayment: null,
  paymentMethodData: null,
  cartNotFound:false,
  cartNotFoundText:'',
  abandantCartFetched: false,
  mobileUpiLinkFetchError: false,
  paymentType:'',
  platform:'',
  resumeCartData: null,
  resumeCartDataFetched: false,
  outofstock: false,
  resumeCartFailed: false,
  paymentDone: false,
  paymentDoneAnalytics: false,
  billdeskDetailsFetched : false,
  fbp:'',
  fbc:'',
  clientOrderId: "",
  razorPaymentStatus: false,
  openingRazorpayStandard: false,
  otherPaymentMode: "",
  paymentMethodsError: false,
  isPaymentSuccess: false,
  orderCreatedForPayment: false,
  isCodAtTop: true,
  hiddenOptions: false,
  fireOptionalPaymentRequest: true,
  credPaymentFailed: false,
  showOtherMethods: false,
  shopifyCart: '',
  networkFail: false,
  openingEaseBuzzStandard:false,
  fireEaseBuzzRequest: true,
  paymentTnxId:'',
  trueCallerOpened: false,
  fireTrueCallerRequest: true,
  isAfterSellCookieSet: false,
  checkUpSell: false,
  showQrCode:false,
  fireQrCodeRequest:true,
  fireQrCodeInterval:'',
  qrCodeTimer: 2,
  discountDisable: false,
  showQrCodeDrawer: false,
  orderId: "",
  isCtpCookieSet: false,
  fireCtpPaymentRequest: true,
  ctpTimerId: "",
  ctpStatusId: "",
  loadAfterSellCookie: false,
  isCtpAttempted: false,
  globalPaymentLoader: false,
  clarityUserIdSet: false,
  isCtpInitialized: false,
  fireCodPullingRequest: false,
  checkoutButtonLoader: false,
  snapmintDetails: {},
  snapmintLoader: false,
  fireRedirectPullingRequest:false,
  initialPaymentOrder: [],
  bankOfferPaymentOrder: [],
  cardIINData: {},
  payPartialCod: false,
  cardDesignEnabled : false,
  paymentMethodCouponArray: [],
  defaultOrderSummaryOpen: false,
  gstRequest: false,
  selectedVariant: "",
  proceedToBuy: false,
  selectedQuantity: 0,
  productBuyNowVisible: false,
  giftWrapProductPage: false, 
  gtmIds:{}, 
  gaClientId: '',
  gaSessionId: '',
  gaFire:false, 
  easeBuzzScriptLoad:false,
  catalogueSyncCalled: false,
  catalogueProcessed: false,
  productBuyNowVisibleLoggedIn: false, 
  orderCodFailed: false, 
  orderCodPending: false,
  showConfetti : true,
  productSelectionOutStock: false,
  timeCalculateCall:false, 
  startTimeCalled:false, 
  orderDetailsSaved: false,
  orderPlacedDetails: {},
  showSaveAndPaySellers: ["www.soni.fashion", "krishnaayurved.com", 'in.teabox.com', 'estailofashion.com','beastlife.in'],
  showOrderDetailsInSummarySellers: ["www.soni.fashion"],
  snapmintPaymentVerify:false, 
  snapmintIntervalId:"",
  customSnapmint:{'acwo.com':'ACwO No Cost EMI','www.flashify.in' : 'Flashify No Cost EMI','www.figliving.com':'Fig Living Pay Later Zero cost EMI on UPI', 'jokerandwitch.com': 'JW Easy EMI', 'nypsun.com': 'Nypsun Pay Later', 'houseofayuda.com': 'Ayuda Pay Later (Zero cost EMI)','winstonindia.com':'Winston Pay Later', 'zaverishop.in': 'Zaveri Pay Later'},
  openSummaryOnAutomatic: ["blackberrys.com", "harish-30.myshopify.com"],
  prevProductQty: 0,
  prevSelectedVariantCombination: {},
  updatingCart: false,
  ucEventBuy: false,
  ucEventOrder: false,
  showGiftConfetti: false,
  showSnapmintOnTop: ["www.gavinparis.com", "harish-30.myshopify.com"],
  hasBundleProduct: false,
};

const UrlQueryStrings = window.location.search;
let params = new URLSearchParams(UrlQueryStrings);
let isProductPage = params.get("isFastrrProduct") != null ? params.get("isFastrrProduct") : false

const Cart_reducer = (state = Cart_state, action) => {
  switch (action.type) {
    case "CART_INITIATE_REQUEST":
      return {
        ...state,
        sendCartInitiate: false,
        loader: 1,
      };

    case "CART_INITIATE_SUCCESS":
      console.log('heyokay', action.payload)
      return {
        ...state,
        sendCartInitiate: false,
        Cart_data: action.payload,
        loader: 0,
        createCartCalled: true,
        outofstock: action.payload?.outOfStock,
        cardDesignEnabled : isProductPage ? true : action?.cardDesignEnabled
      };

    case "CART_INITIATE_FAILURE":
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        sendCartInitiate: false,
        loader: 0,
      };

    case "ABANDONED_INITIATE_REQUEST":
      return {
        ...state,
        sendCartInitiate: false,
        loader: 1,
        cartNotFound: false,
        abandantCartFetched:true,
      };

    case "ABANDONED_INITIATE_SUCCESS":
      return {
        ...state,
        sendCartInitiate: false,
        Cart_data: action.payload,
        loader: 0,
        createCartCalled: true,
        cartNotFound: false,
        abandantCartFetched:true,
      };

    case "ABANDONED_INITIATE_FAILURE":
      return {
        ...state,
        sendCartInitiate: false,
        cartNotFound: true,
        cartNotFoundText: action?.payload?.response?.data?.error?.message,
        loader: 0,
        abandantCartFetched:false,
      };

      case "RESUME_CART_REQUEST":
        return {
          ...state,
          sendCartInitiate: false,
          loader: 1,
          resumeCartDataFetched:false,
          resumeCartFailed: false
        };
  
      case "RESUME_CART_SUCCESS":
        return {
          ...state,
          sendCartInitiate: false,
          resumeCartData: action.payload,
          loader: 0,
          resumeCartDataFetched: true,
          platform: action.payload.channel,
          resumeCartFailed: false, 
          createCartCalled: true,
          Cart_data: action.payload
        };
  
      case "RESUME_CART_FAILURE":
        return {
          ...state,
          sendCartInitiate: false,
          loader: 0,
          resumeCartDataFetched: false,
          resumeCartFailed: true
        };
        
    case "Update_Transaction_REQUEST":
      return {
        ...state,
        loader: 1,
        fail: false,
      };

    case "Update_Transaction_SUCCESS":
      return {
        ...state,
        loader: 0,
      };

    case "Update_Transaction_FAILURE":
      return {
        ...state,
        // fail: true,
        loader: 0,
      };

    case "CREATE_ORDER_REQUEST":
      return {
        ...state,
        loader: 1,
        orderFetching:true,
        orderFetched: false
      };
    case "CREATE_ORDER_SUCCESS":
      return {
        ...state,
        paymentDetails: action.payload.paymentDetails,
        orderDetails: action.payload.orderDetails,
        loyaltyPointDetails: action.payload.loyaltyPointDetails,
        orderFetched: true,
        orderFetching:false,
        loader: 0,
        outofstock: action.payload.orderDetails.outOfStock,
        orderCreatedForPayment: true
      };
    case "CREATE_ORDER_FAILURE":
      return {
        ...state,
        fail: (typeof action?.payload?.response?.status === "number" ? true : false),
        networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
        orderFetched: true,
        orderFetching:false,
        loader: 0,
        orderCreatedForPayment: true
      };

      case "UPDATE_ORDER_REQUEST":
        return {
          ...state,
          loader: 1,
          orderFetching:true,
          orderFetched: false
        };
      case "UPDATE_ORDER_SUCCESS":
        return {
          ...state,
          paymentDetails: action.payload.paymentDetails,
          orderDetails: action.payload.orderDetails,
          loyaltyPointDetails: action.payload.loyaltyPointDetails,
          orderFetched: true,
          orderFetching:false,
          loader: 0,
          updateCartCalled: true,
          outofstock: action.payload.orderDetails.outOfStock
        };
      case "UPDATE_ORDER_FAILURE":
        return {
          ...state,
          fail: (typeof action?.payload?.response?.status === "number" ? true : false),
          networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
          orderFetched: true,
          orderFetching:false,
          loader: 0,
        };


        case "GST_APPLY_REQUESTED":
          return {
            ...state,
            sendCartInitiate: false,
            loader: 1,
            gstRequest:true
          };
    
        case "GST_APPLY_SUCCESS":
          console.log(action.payload, 'checking the payload')
          return {
            ...state,
            sendCartInitiate: false,
            Cart_data: action.payload,
            loader: 0,
            createCartCalled: true,
            gstRequest:false,
            outofstock: action.payload.outOfStock
          };
    
        case "GST_APPLY_FAILURE":
          return {
            ...state,
            fail: (typeof action?.payload?.response?.status === "number" ? true : false),
            networkFail: ((typeof action?.payload?.response?.status !== "number" || !action?.payload?.response?.status) ? true : false),
            sendCartInitiate: false,
            loader: 0,
            gstRequest:false,
          };

          case "UPDATE_CART_STATE":
            return {
              ...state,
              [action.payload.key]: action.payload.value
            }

    default:
      return state;
  }
};

export default Cart_reducer;
