let couponState = {
  loader: 0,
  fail: false,
  data: null,
  error: null,
  couponApplied: false,
  couponPlaceValue: "Enter coupon code",
  couponCode: null,
  couponFetched:false,
  suggestCoupon:[],
  couponListFetched: false,
  paymentMethodType: '',
  bestCouponApplied: false,
  loading: false
};

const Coupon_reducer = (state = couponState, action) => {
  switch (action.type) {
    case "COUPON_APPLY_REQUESTED":
      return {
        ...state,
        loader: 1,
        couponApplied: false,
        couponFetched:false,
        loading: true
      };

    case "COUPON_APPLY_SUCCESS":
      return {
        ...state,
        data: action?.payload?.data,
        error: null,
        loader: 0,
        couponApplied: true,
        couponCode: action.payload.data.couponCode,
        couponFetched:true,
        loading: false
        // bestCouponApplied: true
      };
    case "COUPON_APPLY_FAILURE":
      return {
        ...state,
        fail: true,
        data: null,
        error: action.payload.data,
        loader: 0,
        couponApplied: false,
        couponFetched:false,
        loading: false
      };
      
    case "SUGGESTION_COUPON_APPLY_REQUEST":
      return {
        ...state,
        suggestCoupon:[],
        couponListFetched: true,
      };

    case "SUGGESTION_COUPON_APPLY_SUCCESS":
      return {
        ...state,
        suggestCoupon:action.payload,
        couponListFetched: true,
      };

    case "SUGGESTION_COUPON_APPLY_ERROR":
      return {
        ...state,
        suggestCoupon:[],
        couponListFetched: false,
      };
    default:
      return state;
  }
};
export default Coupon_reducer;
