import { useEffect, useState } from "react";
import AnalyticService from "../../service/analytics/AnalyticsService";
import Http from "../../service/Http";
import apiUrls from "../../api/Apis";
import { Store } from "../../service/StorageHelper";
import ExitForm from "../components/ExitForm";

const ExitFormContainer = (props: any) => {
    const { loginstate, isCardDesign, cartstate, isProductPage, couponState } = props
    const [otherReason, setOtherReason] = useState('')
    const [checkedState, setCheckedState] = useState(
        new Array(loginstate?.sellerConfig?.exitResponseDetails?.length ?? 0)?.fill(false)
    );
    const [submitExitFormButtonDisabled, setSubmitExitFormButtonDisabled] = useState(false)

    useEffect(() => {
        if (loginstate?.sellerConfig?.allowSingleExitReason) {
            // loginstate?.sellerConfig?.exitResponseDetails.push("others")
            if(!loginstate?.sellerConfig?.exitResponseDetails.includes('others')){
                loginstate?.sellerConfig?.exitResponseDetails.push("others")
              }
            setCheckedState((state: any[]) => {
                state.push(false)
                return state
            })
        }
    }, [loginstate?.sellerConfig?.allowSingleExitReason]);

    const handleExitFormCheck = (position: any) => {
        const updatedCheckedState = checkedState?.map((item: any, index: Number) => {
            if (loginstate?.sellerConfig?.allowSingleExitReason) {
                return index === position ? true : false
            } else {
                return index === position ? !item : item

            }

        }
        );
        setCheckedState(updatedCheckedState);
    }

    const handleExitFormSubmit = async () => {
        setSubmitExitFormButtonDisabled(true)
        let reqResponses = checkedState?.map((item: any, index: number) => {
            if (item) {
                return ({
                    question: loginstate?.sellerConfig?.exitResponseDetails?.[index],
                    answer: loginstate?.sellerConfig?.exitResponseDetails?.[index]
                }
                )
            }
        })
        reqResponses = reqResponses?.filter((item: any) => {
            if (item?.question && item.question != "others") {
                return item
            } else {
                return false
            }
        })
        if (otherReason?.length > 0) {
            reqResponses = [...reqResponses, { question: "others", answer: otherReason }]
        }

        const reqPayload = {
            cartId: cartstate?.Cart_data?.id,
            reqResponses: reqResponses
        }
        AnalyticService.exitCheckout(cartstate?.orderDetails);
        if (loginstate.onPage === "shipping" && !loginstate.user_type_for_text) {
            AnalyticService.additionalEvents(
                "yes-exit-checkout",
                cartstate?.Cart_data,
                couponState.couponCode,
                Store.getItem('refreshToken') ? true : false,
                false
            );
        }
        else {
            AnalyticService.additionalEvents(
                "back-exit-checkout",
                cartstate?.Cart_data,
                couponState.couponCode,
                Store.getItem('refreshToken') ? true : false,
                false
            );
        }

        try {

            const response = await Http.post(apiUrls.EXIT_FORM_SUBMIT(), reqPayload, loginstate.sellerConfig.seller_id)
            if (response) setSubmitExitFormButtonDisabled(false)

            if (isProductPage && isCardDesign) {

                window.parent.postMessage(
                    {
                        trigger: "headless-redirect",
                        actions: [
                            {
                                action: "redirectTo",
                                data: {
                                    url: `https://${loginstate.sellerConfig.domainName}`,
                                },
                            },
                        ],
                    },
                    "*"
                );
            } else window.parent.postMessage({ trigger: "headless-close" }, "*");

        } catch (error) {
            window.parent.postMessage({ trigger: "headless-close" }, "*");
            setSubmitExitFormButtonDisabled(false)
        }

    }

    return (<ExitForm
        {...props}
        handleExitFormCheck={handleExitFormCheck}
        checkedState={checkedState}
        otherReason={otherReason}
        setOtherReason={setOtherReason}
        handleExitFormSubmit={handleExitFormSubmit}
        submitExitFormButtonDisabled={submitExitFormButtonDisabled}
        isCardDesign={isCardDesign}
    />
    );

}

export default ExitFormContainer;